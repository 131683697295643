import Constants from 'expo-constants'
import mime from 'mime'
import { Platform } from 'react-native'
import { ERRORS } from '../../../constants'
import { FileValue } from '../../types/application/formField.types'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

const postDocument = async (documentURI: string, documentType: string, userId: number): Promise<FileValue | null> => {
  let result: FileValue | null = null

  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const formData = new FormData()

  if (Platform.OS === 'web') {
    const fileType = mime.getType(documentURI.split(';')[0].split('/')[1])
    const fileExtension = mime.getExtension(fileType || '')
    const fileName = `${documentType}.${fileExtension}`

    const byteString = atob(documentURI.split(',')[1])

    const ab = new ArrayBuffer(byteString.length)
    const arr = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) arr[i] = byteString.charCodeAt(i)

    const blob = new Blob([arr], {
      type: fileType || 'image/png',
    })

    const file = new File([blob], fileName)

    formData.append('filesToUpload', file, fileName)
  } else {
    const fileName = documentURI.match('/[^/]*$')

    const fileType = fileName?.[0].split('.')[1]
    formData.append('filesToUpload', {
      uri: documentURI,
      type: `image/${fileType}`,
      name: `${fileName}.${fileType}`,
    })
  }

  await fetch(
    new Request(`${Constants?.expoConfig?.extra?.hostname}/ext/external-users/${userId}/documents/${documentType}`, {
      method: 'POST',
      headers,
      body: formData,
    })
  )
    .then(async response => {
      if (response.status == 200) {
        await response.json().then(body => {
          if (body.ids && body.ids.length > 0) {
            //Success
            const docId = body.ids[0]

            result = {
              uri: documentURI,
              externalDocumentId: docId,
            } as FileValue
          }
        })
      } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
        await signOutUser()
      }
    })
    .catch(err => {
      throw err
    })

  return result
}

export default postDocument
