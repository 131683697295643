import {
  ApplicationStage,
  ShallowApplication,
  ApplicationStageProperties,
  Application,
  ApplicationProperties,
} from '../../../types/application/application.types'

import { User } from '../../../types/user.types'
import postApplication from '../../api/postApplication'
import { FirebaseAuthUser } from '../firebase.types'

import postApplicationStage from './postApplicationStage'
import postApplicationStageFields from './postApplicationStageFields'

const postApplicationDeeply = async (
  shallowApplication: ShallowApplication,
  stages: ApplicationStage[],
  user: User,
  firebaseUser: FirebaseAuthUser
): Promise<Application> => {
  const { ref } = await postApplication(shallowApplication, stages, user, firebaseUser)

  await Promise.all(
    stages.map(async (stage, index) => {
      await postApplicationStage(ref, { [ApplicationStageProperties.TITLE]: stage.title }, index)

      return postApplicationStageFields(ref, stage.fields, index)
    })
  )

  const applicationId = ref['id']

  if (!applicationId) {
    throw 'Application was not created.'
  }

  return { ...shallowApplication, [ApplicationProperties.ID]: applicationId, [ApplicationProperties.STAGES]: stages }
}

export default postApplicationDeeply
