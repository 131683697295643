import * as BackgroundFetch from 'expo-background-fetch'
import * as Notifications from 'expo-notifications'
import { Platform } from 'react-native'

const registerBackgroundFetchAsync = async (taskName: string) => {
  return BackgroundFetch.registerTaskAsync(taskName, {
    minimumInterval: 60, // 15 minutes
    stopOnTerminate: false, // android only,
    startOnBoot: true, // android only
  })
}

const unregisterBackgroundFetchAsync = async (taskName: string) => {
  return BackgroundFetch.unregisterTaskAsync(taskName)
}

const registerForPushNotificationsAsync = async () => {
  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    })
  }

  const { status: existingStatus } = await Notifications.getPermissionsAsync()

  let finalStatus = existingStatus
  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync()
    finalStatus = status
  }
  if (finalStatus !== 'granted') {
    return
  }

  return Notifications.getDevicePushTokenAsync()
}

export { registerBackgroundFetchAsync, unregisterBackgroundFetchAsync, registerForPushNotificationsAsync }
