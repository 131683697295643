import Constants from 'expo-constants'
import { Platform } from 'react-native'

const postErrorMessageToSlack = async (
  error: Error,
  stackTrace: string,
  identificationMessage: string | undefined
): Promise<void> => {
  const ENVIRONMENT = process.env.ENVIRONMENT || 'dev'
  const SLACK_WEBHOOK_URL = Constants?.expoConfig?.extra?.slackWebhook

  const slackFallback = (r: Response) => {
    if (r.status === 200) {
      return
    }

    return fetch(SLACK_WEBHOOK_URL, {
      method: 'post',
      body: JSON.stringify({
        text: `Tried but failed to send an alert to Slack about a possible error in ${ENVIRONMENT}. 
          The most likely reason for alert failure is the error message containing too much text for the Slack API. 
          It is recommended that you check ${ENVIRONMENT} for errors or warnings using journalctl.`,
      }),
    })
  }

  const body = {
    text: `Alert from ${ENVIRONMENT}`,
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*New alert from OML in ${ENVIRONMENT}${
            identificationMessage ? ` raised for ${identificationMessage}` : ` raised without a user being logged in`
          }*`,
        },
      },
      {
        type: 'divider',
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Here are the lines that triggered the alert.`,
        },
      },
      {
        type: 'section',
        block_id: 'section567',
        text: {
          type: 'mrkdwn',
          text: `\`\`\`\n${error}\nOS: ${Platform.OS}\nApp Version: ${Constants?.expoConfig?.version}\nAPI Version: ${
            Constants?.expoConfig?.extra?.apiVersion
          }\n${stackTrace.split('\n').slice(0, 20).join('\n')}\`\`\``,
        },
      },
    ],
  }

  fetch(
    new Request(SLACK_WEBHOOK_URL, {
      method: 'post',
    }),
    {
      body: JSON.stringify(body),
    }
  ).then(slackFallback)
}

export default postErrorMessageToSlack
