import React, { ReactElement } from 'react'
import { StyleSheet, View } from 'react-native'
import { Badge, Caption } from 'react-native-paper'
import { colours } from '../styleguide'
import { ApplicationStatuses } from '../types/application/application.types'

const styles = StyleSheet.create({
  view: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  caption: {
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 15,
  },
})

interface Props {
  status: ApplicationStatuses
}

const undoCamelCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1')
  return (result.charAt(0) + result.slice(1)).toLowerCase()
}
const ApplicationSummaryStatus = (props: Props): ReactElement => {
  const { status } = props

  return (
    <View style={styles.view} accessibilityRole="summary">
      <View>
        <Badge
          style={{
            backgroundColor: colours.status[status] || colours.status.unknown,
          }}
          size={15}
        />
      </View>
      <Caption style={styles.caption}>
        {undoCamelCase(status)
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.substring(1))
          .join(' ')}
      </Caption>
    </View>
  )
}

ApplicationSummaryStatus.defaultProps = {
  status: '',
}

export default ApplicationSummaryStatus
