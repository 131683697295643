import * as Notifications from "expo-notifications";
import {getPresentedNotificationsAsync, Notification} from "expo-notifications";

export const scheduleNotification = async (title: string, body: string, data: any) => {
  return await Notifications.scheduleNotificationAsync({
    content: {
      title: title,
      body: body,
      data: {...data},
    },
    trigger: {seconds: 2},
  });
}

export const retrieveCurrentNotification = async (identifier: string): Promise<Notification> => {
  const currentNotifications = await getPresentedNotificationsAsync();
  return currentNotifications.filter((notification) => notification.request.identifier === identifier)?.[0];
}