import React, { ReactElement } from "react";
import ImageUpload, { ImageUploadProps } from "./ImageUpload";

const ImageUploadSingle = (props: ImageUploadProps): ReactElement => (
  <ImageUpload
    label={props.label}
    value={props.value}
    setLocalError={props.setLocalError}
    onChange={(unstableNewValue) => {
      // find the newest value: first look for the value that didn't previously exist, and otherwise take the one at the end of the array (in case of adding a duplicate value)
      const newValue =
        unstableNewValue.find((newVal) => !props.value.includes(newVal)) ||
        unstableNewValue[unstableNewValue.length - 1];
      return props.onChange(newValue ? [newValue] : []);
    }}
    uploading={props.uploading}
  />
);

export default ImageUploadSingle;
