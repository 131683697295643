import { ApplicationProperties, ShallowApplicationStage } from '../../../types/application/application.types'
import { DocumentReference } from '../firebase.types'

const getApplicationStages = async (applicationReference: DocumentReference): Promise<ShallowApplicationStage[]> => {
  const querySnapshot = await applicationReference.collection(ApplicationProperties.STAGES).get()

  return querySnapshot.docs.map(doc => doc.data() as ShallowApplicationStage)
}

export default getApplicationStages
