import React, { ReactElement, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { ERRORS } from '../../constants'
import deleteDocument from '../helpers/api/deleteDocument'
import patchUser from '../helpers/api/patchUser'
import postDocument from '../helpers/api/postDocument'
import signOutUser from '../helpers/firebase/auth/signOutUser'
import updateUserDetails from '../helpers/user/updateUserDetails'
import useOMLContext from '../hooks/useOMLContext'

import UserInfoForm from '../molecules/UserInfoForm'
import { optionalMyDocumentsForm, requiredMyDocumentsForm } from '../static-forms/myDocumentsForm'
import BannerTemplate from '../templates/BannerTemplate'
import { AnyField, FileValue, FormFieldTypes, FormFieldValue } from '../types/application/formField.types'
import { Pages, ScreenProps } from '../types/navigation.types'

import {
  User,
  UserAdditionalDocumentInformationProperties,
  UserDocumentProperties,
  UserInfo,
  UserProperties,
} from '../types/user.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    maxWidth: 500,
    width: '100%',
  },
})

interface Props extends ScreenProps<Pages.MY_DOCUMENTS> {
  loading: false
  error: ''
  showBanner: false
  requireInput: boolean
  onExit: () => unknown
  enforceValidation: boolean
}

const MyDocumentsScreen = (props: Props): ReactElement => {
  const { requireInput, onExit, enforceValidation = false } = props

  const [loading, setLoading] = useState<boolean>(props.loading)
  const [error, setError] = useState<string>(props.error)
  const [showBanner, setShowBanner] = useState<boolean>(props.showBanner)

  const [context, setContext] = useOMLContext()

  const postForm = async (formData: AnyField[]) => {
    const nonDocumentFields = formData.filter(
      data => data.type !== FormFieldTypes.IMAGE && data.type !== FormFieldTypes.IMAGE_SINGLE
    )
    const updatedUserData = nonDocumentFields.reduce((userData: any, formField) => {
      if (
        Object.values(UserAdditionalDocumentInformationProperties).includes(
          formField.identifier as UserAdditionalDocumentInformationProperties
        )
      ) {
        let value = formField.value
        if (formField.type === FormFieldTypes.DATE) {
          value = `${formField.value}`
        }

        const currentAdditionalDocumentInformation = userData[UserProperties.ADDITIONAL_DOCUMENT_INFORMATION] ?? {}
        currentAdditionalDocumentInformation[formField.identifier] = value

        return {
          ...userData,
          [UserProperties.ADDITIONAL_DOCUMENT_INFORMATION]: currentAdditionalDocumentInformation,
        }
      }

      return { ...userData, [formField.identifier]: formField.value }
    }, {})

    const updatedUser: User = {
      ...context.externalUser,
      ...updatedUserData,
    } as User

    if (!updatedUser[UserProperties.EXTERNAL_USER_ID]) {
      throw new Error('User ID is missing')
    }

    setContext({ ...context, externalUser: updatedUser })
    await patchUser(updateUserDetails(updatedUser), updatedUser.id).catch(err => {
      if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
        setContext({
          ...context,
          error: ERRORS.UPGRADE_REQUIRED,
        })
      } else if (err.status === ERRORS.TOKEN_EXPIRED.statusCode) {
        signOutUser()
      }
    })
  }

  const submitForm = async (newFields: AnyField[]) => {
    await postForm(newFields)
    setLoading(false)
    await onExit()
  }

  const setPageError = (errorMessage: string) => {
    setShowBanner(true)
    setError(errorMessage)
    setLoading(false)
  }

  const submitUserDocument = async (newValue: FormFieldValue, identifier: string) => {
    const user = context.externalUser

    if (!user) {
      return
    }

    const findDocumentType = (identifier: string) => {
      for (const documentType in UserDocumentProperties) {
        if (identifier === UserDocumentProperties[documentType as keyof typeof UserDocumentProperties]) {
          return identifier
        }
      }
    }

    const tryRemoveDocument = async (
      currentDocuments: FileValue[],
      currentImages: FileValue[],
      documentType: UserDocumentProperties
    ) => {
      const currentDocumentIds = currentDocuments.map(document => document.externalDocumentId)
      const currentImageIds = currentImages.map(image => image.externalDocumentId)

      const removedFiles = currentDocumentIds.filter(documentId => currentImageIds.indexOf(documentId) == -1)

      //Only want to deal with 1 at a time, or something else has happened entirely.
      if (removedFiles.length == 1) {
        const fileIdToDelete = removedFiles[0]

        if (fileIdToDelete) {
          const isDocumentDeleted = await deleteDocument(user[UserProperties.EXTERNAL_USER_ID], fileIdToDelete).catch(
            err => {
              if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
                setContext({
                  ...context,
                  error: ERRORS.UPGRADE_REQUIRED,
                })
              } else {
                setError('Failed to delete document. Please, try again later.')
                setShowBanner(true)
              }
            }
          )

          if (isDocumentDeleted) {
            //Save in user context, but don't call context to reload component.
            currentDocuments = currentDocuments.filter(document => document.externalDocumentId != fileIdToDelete)
            user[UserProperties.DOCUMENTS][documentType] = currentDocuments

            setContext({ ...context, externalUser: user })

            return true
          }
        }
      }
    }

    const currentImages = newValue as FileValue[]
    const documentType = findDocumentType(identifier)

    if (documentType && currentImages) {
      const currentDocumentsOfType: FileValue[] | undefined =
        user[UserProperties.DOCUMENTS][documentType as UserDocumentProperties]

      const currentDocuments: FileValue[] = currentDocumentsOfType || ([] as FileValue[])
      const currentDocumentIds = currentDocuments.map(document => document.externalDocumentId)

      if (currentImages.length == 0) {
        await tryRemoveDocument(currentDocuments, currentImages, documentType)

        return
      }

      for (const image of currentImages) {
        try {
          //Verify whether the document has already been uploaded to Tabled.
          if (!currentDocumentIds.includes(image.externalDocumentId)) {
            const uploadedDocument: FileValue | null = await postDocument(
              image.uri,
              documentType,
              user[UserProperties.EXTERNAL_USER_ID]
            )

            if (uploadedDocument) {
              image.externalDocumentId = uploadedDocument.externalDocumentId

              //Save to context without calling context (reloading UserInfoForm component)
              currentDocuments.push(uploadedDocument)
              user[UserProperties.DOCUMENTS][documentType] = currentDocuments
              setContext({ ...context, externalUser: user })

              return
            } else {
              // Make this an upload has failed image, not the default image cannot be previewed.
              image.uri = ''
            }
          } else {
            const hasDocumentBeenRemoved = await tryRemoveDocument(currentDocuments, currentImages, documentType)

            if (hasDocumentBeenRemoved) {
              return
            }
          }
        } catch (err) {
          if (err instanceof Error && err.message === ERRORS.UPGRADE_REQUIRED.message) {
            setContext({
              ...context,
              error: ERRORS.UPGRADE_REQUIRED,
            })
          }
        }
      }
    }
  }

  const dismissErrorMessage = () => {
    setShowBanner(false)
  }

  return (
    <BannerTemplate
      testID="MyDocuments"
      showBanner={showBanner}
      loading={loading}
      bannerText={error}
      dismissError={dismissErrorMessage}
      tabNavigatorActive={true}
    >
      <View style={styles.container}>
        <UserInfoForm
          title="Provide documents"
          form={requireInput ? requiredMyDocumentsForm : optionalMyDocumentsForm}
          seedData={
            context.externalUser?.[UserProperties.DOCUMENTS]
              ? ({
                  ...context.externalUser?.[UserProperties.DOCUMENTS],
                  ...context.externalUser?.[UserProperties.ADDITIONAL_DOCUMENT_INFORMATION],
                } as UserInfo)
              : ({} as UserInfo)
          }
          submitButtonText="Continue"
          submitButtonAction={submitForm}
          submitUserDocumentAction={submitUserDocument}
          setPageError={setPageError}
          setLoading={setLoading}
          enforceValidation={enforceValidation}
        />
      </View>
    </BannerTemplate>
  )
}

MyDocumentsScreen.defaultProps = {
  loading: false,
  error: '',
  showBanner: false,
}

export default MyDocumentsScreen
