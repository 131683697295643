import { FormFieldTypes, FormFieldValue } from '../../../types/application/formField.types'

const booleanFormFieldTypes = [FormFieldTypes.CHECKBOX, FormFieldTypes.RADIO, FormFieldTypes.TOGGLE]

const doesFormValueExist = (value: FormFieldValue, fieldType: FormFieldTypes | null = null): boolean => {
  if (fieldType && booleanFormFieldTypes.includes(fieldType)) {
    return !!value && value === 'true'
  }
  return Array.isArray(value) ? !!value.length : !!value
}

export default doesFormValueExist
