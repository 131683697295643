import { AnyField, FormFieldTypes } from '../../../types/application/formField.types'
import { InMemoryFormFields } from '../../../types/form.types'
import doesFormValueExist from './doesFormValueExist'

const selectVisibleFields = (
  startingFields: AnyField[],
  formFields: InMemoryFormFields,
  markets?: string[]
): AnyField[] => {
  const fields = startingFields
    .map(unfilledField => {
      const field = formFields[unfilledField.identifier] || unfilledField

      const booleanFormFieldTypes = [FormFieldTypes.CHECKBOX, FormFieldTypes.RADIO, FormFieldTypes.TOGGLE]

      if (booleanFormFieldTypes.includes(field.type)) {
        field.value = field.value || unfilledField.value || false
      }

      if (!field.appearanceConditions) {
        return field
      }

      if (field.appearanceConditions.positiveAnswer) {
        const isRequirementMet = field.appearanceConditions.positiveAnswer.every(siblingFieldIdentifier => {
          return doesFormValueExist(formFields[siblingFieldIdentifier].value, formFields[siblingFieldIdentifier].type)
        })

        return isRequirementMet && field
      }

      if (field.appearanceConditions.dependants) {
        const isRequirementMet = field.appearanceConditions.dependants.every(({ partnerField, value }) => {
          return `${formFields[partnerField].value}`.toLowerCase() === value.toLowerCase()
        })

        return isRequirementMet && field
      }

      return field
    })
    .filter((field): field is AnyField => !!field)

  if (!markets) {
    return fields
  }

  return fields.filter(
    field =>
      !field.identifier.endsWith(' days') ||
      (field.identifier.endsWith(' days') && markets.some(market => field.identifier.includes(market)))
  )
}

export default selectVisibleFields
