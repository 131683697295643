import React, { ReactElement } from "react";
import { StyleSheet, View, Text } from "react-native";
import { ShallowApplication } from "../types/application/application.types";
import ApplicationSummary from "./ApplicationSummary";

interface Props {
  applications: ShallowApplication[];
  fetchNextPage: () => void;
  goToApplication: (application: ShallowApplication) => void;
}

const styles = StyleSheet.create({
  noApplicationsMessage: {
    textAlign: "center",
  },
});

const getApplications = (
  applications: ShallowApplication[],
  goToApplication: (application: ShallowApplication) => void
) => {
  const cards = [];
  for (let i = 0; i < applications.length; i++) {
    cards.push(
      <ApplicationSummary
        council={applications[i].council}
        markets={applications[i].markets}
        status={applications[i].status}
        key={applications[i].id}
        onPress={() => goToApplication(applications[i])}
      />
    );
  }
  if (cards.length > 0) {
    return cards;
  }
  return (
    <Text style={styles.noApplicationsMessage}>
      You don&apos;t have any applications yet
    </Text>
  );
};

const ApplicationsBrowser = (props: Props): ReactElement => {
  const { applications, goToApplication } = props;

  return <View>{getApplications(applications, goToApplication)}</View>;
};

export default ApplicationsBrowser;
