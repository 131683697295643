import { CustomField } from '../types/application/formField.types'
import getCouncilStages from './firebase/firestore/getCouncilStages'

const formatCustomFieldDependencies = async (
  selectedCouncil: string,
  customFields: CustomField[]
): Promise<CustomField[]> => {
  const councilStages = await getCouncilStages(selectedCouncil)
  const stagesFields = councilStages[0].fields.concat(councilStages[1].fields)
  const formattedCustomFields: CustomField[] = []

  stagesFields.map(field => {
    const customField = customFields.find(cf => cf.name === field.identifier)
    if (customField) {
      if (field.appearanceConditions?.positiveAnswer) {
        field.appearanceConditions.positiveAnswer.every(siblingFieldIdentifier => {
          const dependee = customFields.find(cf => cf.name === siblingFieldIdentifier)
          if (dependee) {
            if (customField.dependee) {
              customField.dependee.push({ id: dependee.id, valueToMatch: 'true' })
            } else {
              customField.dependee = [{ id: dependee.id, valueToMatch: 'true' }]
            }
          }
        })
      }
      formattedCustomFields.push(customField)
    }
  })

  const otherMarketFields = customFields.filter(field => !stagesFields.some(sf => sf.identifier === field.name))
  formattedCustomFields.push(...otherMarketFields)

  return formattedCustomFields.sort((a, b) => a.id - b.id)
}

export default formatCustomFieldDependencies
