import React, { ReactElement, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { ERRORS } from '../../constants'

import patchUser from '../helpers/api/patchUser'
import signOutUser from '../helpers/firebase/auth/signOutUser'
import updateUserDetails from '../helpers/user/updateUserDetails'
import useOMLContext from '../hooks/useOMLContext'
import UserInfoForm from '../molecules/UserInfoForm'
import { optionalMyDetailsForm, requiredMyDetailsForm } from '../static-forms/myDetailsForm'
import BannerTemplate from '../templates/BannerTemplate'
import { AnyField } from '../types/application/formField.types'
import { Pages, ScreenProps } from '../types/navigation.types'
import { User, UserAdditionalFieldProperties, UserInfo, UserProperties } from '../types/user.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    maxWidth: 500,
    width: '100%',
  },
})

interface Props extends ScreenProps<Pages.MY_DETAILS> {
  loading: boolean
  error: ''
  showBanner: boolean
  requireInput: boolean
  enforceValidation: boolean
  onExit: () => unknown
}

const MyDetailsScreen = (props: Props): ReactElement => {
  const { requireInput, onExit, enforceValidation = false } = props

  const [loading, setLoading] = useState<boolean>(props.loading)
  const [error, setError] = useState<string>(props.error)

  const [showBanner, setShowBanner] = useState<boolean>(props.showBanner)

  const [context, setContext] = useOMLContext()

  const postForm = async (formData: AnyField[]) => {
    const updatedUserData = formData.reduce((userData: any, formField) => {
      if (
        Object.values(UserAdditionalFieldProperties).includes(formField.identifier as UserAdditionalFieldProperties)
      ) {
        const currentAdditionalFields = userData[UserProperties.ADDITIONAL_FIELDS] ?? {}
        currentAdditionalFields[formField.identifier] = formField.value

        return {
          ...userData,
          [UserProperties.ADDITIONAL_FIELDS]: currentAdditionalFields,
        }
      }

      return { ...userData, [formField.identifier]: formField.value }
    }, {})

    const updatedUser: User = {
      ...context.externalUser,
      ...updatedUserData,
    } as User

    if (!updatedUser[UserProperties.EXTERNAL_USER_ID]) {
      throw new Error('User ID is missing')
    }

    setContext({ ...context, externalUser: updatedUser })
    await patchUser(updateUserDetails(updatedUser), updatedUser.id).then(response => {
      if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
        setContext({
          ...context,
          error: ERRORS.UPGRADE_REQUIRED,
        })
      } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
        signOutUser()
      }
    })
  }

  const submitForm = async (newFields: AnyField[]) => {
    await postForm(newFields)
    setLoading(false)
    onExit()
  }

  const setPageError = (errorMessage: string) => {
    setShowBanner(true)
    setError(errorMessage)
    setLoading(false)
  }

  const dismissErrorMessage = () => {
    setShowBanner(false)
  }

  return (
    <BannerTemplate
      testID="MyDetails"
      showBanner={showBanner}
      loading={loading}
      bannerText={error}
      dismissError={dismissErrorMessage}
      tabNavigatorActive={true}
    >
      <View style={styles.container}>
        <UserInfoForm
          title="Enter your details"
          form={requireInput ? requiredMyDetailsForm : optionalMyDetailsForm}
          seedData={(context.externalUser ? context.externalUser : {}) as UserInfo}
          enforceValidation={enforceValidation}
          submitButtonText="Continue"
          submitButtonAction={submitForm}
          setPageError={setPageError}
          setLoading={setLoading}
        />
      </View>
    </BannerTemplate>
  )
}

MyDetailsScreen.defaultProps = {
  loading: false,
  error: '',
  showBanner: false,
  requireInput: false,
}

export default MyDetailsScreen
