import { AnyField, FormFieldTypes } from '../types/application/formField.types'

import { ValidatorTypes } from '../types/application/validator.types'
import { UserProperties, UserAdditionalFieldProperties } from '../types/user.types'

import { requiredValidator } from './genericValidators'

export const optionalMyDetailsForm: AnyField[] = [
  {
    type: FormFieldTypes.TEXT,
    identifier: UserProperties.FIRST_NAME,
    label: 'First name',
    validators: [requiredValidator],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.TEXT,
    identifier: UserProperties.LAST_NAME,
    label: 'Last name',
    validators: [requiredValidator],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.TEXT,
    identifier: UserProperties.EMAIL_ADDRESS,
    label: 'Email address',
    validators: [
      requiredValidator,
      {
        type: ValidatorTypes.TEXTMATCH,
        failureMessage: 'This is not a valid email',
        arguments: {
          pattern:
            '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])',
        },
      },
    ],
    error: '',
    value: '',
    editable: false,
  },
  {
    type: FormFieldTypes.TEXT,
    identifier: UserProperties.PHONE_NUMBER,
    label: 'Mobile phone number',
    validators: [
      requiredValidator,
      {
        type: ValidatorTypes.TEXTMATCH,
        failureMessage: 'Please enter a valid phone number.',
        arguments: { pattern: '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$' },
      },
    ],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.TEXT,
    identifier: UserAdditionalFieldProperties.HOME_PHONE_NUMBER,
    label: 'Home phone number',
    validators: [
      {
        type: ValidatorTypes.TEXTMATCH,
        failureMessage: 'Please only enter numbers and spaces, optionally preceded by a +',
        arguments: { pattern: '^\\+{0,1}[0-9 ]+$' },
      },
    ],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.DATE,
    identifier: UserProperties.DATE_OF_BIRTH,
    label: 'Date of birth',
    validators: [
      requiredValidator,
      {
        type: ValidatorTypes.PAST_TIME,
        failureMessage: 'The entered date of birth must be in the past',
      },
    ],
    error: '',
    value: null,
  },
  {
    type: FormFieldTypes.TEXTAREA,
    identifier: UserAdditionalFieldProperties.RESIDENTIAL_ADDRESS,
    label: 'Home address',
    validators: [requiredValidator],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.TEXT,
    identifier: UserAdditionalFieldProperties.BUSINESS_NAME,
    label: 'Business name',
    validators: [],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.RADIO,
    identifier: UserAdditionalFieldProperties.BUSINESS_TYPE,
    label: `Business type`,
    options: ['Sole Trader', 'Limited Company', 'Charity', 'Other'],
    validators: [],
    value: '',
    error: '',
  },
  {
    type: FormFieldTypes.TEXT,
    identifier: UserAdditionalFieldProperties.BUSINESS_TYPE_OTHER,
    label: `Please specify`,
    validators: [
      {
        type: ValidatorTypes.CONDITIONAL_REQUIRED,
        failureMessage: 'Business type is required',
        arguments: {
          partners: [UserAdditionalFieldProperties.BUSINESS_TYPE],
        },
      },
    ],
    appearanceConditions: {
      dependants: [{ partnerField: UserAdditionalFieldProperties.BUSINESS_TYPE, value: 'Other' }],
    },
    value: '',
    error: '',
  },
  {
    type: FormFieldTypes.TEXT,
    identifier: UserAdditionalFieldProperties.INSTAGRAM_NAME,
    label: 'Instagram',
    validators: [],
    error: '',
    value: '',
  },
]

export const requiredMyDetailsForm: AnyField[] = optionalMyDetailsForm.map(field => {
  return {
    ...field,
    validators: [...(field.validators || [])],
  }
})
