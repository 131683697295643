import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { User } from '../../types/user.types'

export interface PostUserResult {
  createdUser: User | null
  tabledAuthToken: {
    authToken: string | null
    authTokenExpiresAt: string | null
  }
}

/**
 * @throws {Error} - if the user is already created.
 */
const postUser = async (emailAddress: string): Promise<PostUserResult> => {
  const result: PostUserResult = {
    createdUser: null,
    tabledAuthToken: {
      authToken: null,
      authTokenExpiresAt: null,
    },
  }

  const headers: HeadersInit = {
    accessKeyId: `${Constants?.expoConfig?.extra?.accessKeyId}`,
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const formData = new FormData()
  formData.append('emailAddress', emailAddress)

  await fetch(
    new Request(`${Constants?.expoConfig?.extra?.hostname}/ext/external-users`, {
      method: 'POST',
    }),
    {
      body: formData,
      headers,
    }
  ).then(async response => {
    if (response.status == 200) {
      await response.json().then(({ externalUser, token }) => {
        result.createdUser = externalUser
        result.tabledAuthToken = token
      })
    } else if (response.status == 409) {
      const errorMessage = response.headers.get('x-exit')
      if (errorMessage === ERRORS.UPGRADE_REQUIRED.message) {
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      }
      throw Error(`There was a problem when trying to log in user: ${emailAddress}. Please, try again later.`)
    }
  })

  return result
}

export default postUser
