import React, { ReactElement } from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import { Badge, Card } from "react-native-paper";
import { colours } from "../styleguide";
import { Council } from "../types/miscellaneous.types";

interface Props {
  council: Council;
  markets: string;
  latestMessage: string;
  date: string;
  hasSeen: boolean;
  onPress: () => void;
}

const styles = StyleSheet.create({
  applicationSummary: {
    marginTop: "3%",
    borderRadius: 10,
  },
  messageContent: {
    padding: 10,
    flexDirection: "row",
  },
  messageText: {
    paddingLeft: "2%",
    flex: 6,
  },
  dateAndStatus: {
    position: "absolute",
    right: 10,
    top: 5,
    alignItems: "flex-end",
    width: "25%",
  },
  cardTitle: {
    fontWeight: "500",
    fontSize: 15,
  },
  cardDescription: {
    fontSize: Platform.OS === "android" ? 13 : 12,
    color: colours.description,
  },
  cardMarkets: {
    fontSize: Platform.OS === "android" ? 13.5 : 12,
    fontWeight: "500",
    marginBottom: 5,
    color: colours.description,
  },
  cardDate: {
    fontSize: 12,
    color: colours.description,
  },
  cardStatus: {
    marginTop: 5,
  },
  photoContainer: {
    width: "15%",
    aspectRatio: 1,
    borderWidth: 1,
    borderColor: colours.description,
    borderRadius: 50,
  },
  photo: {
    borderRadius: 50,
    backgroundColor: colours.lightGrey,
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  councilInitial: {
    fontSize: 25,
    color: colours.description,
  },
});

const MessageSummary = (props: Props): ReactElement => {
  const { council, markets, latestMessage, date, hasSeen, onPress } = props;

  return (
    <Card onPress={onPress} style={styles.applicationSummary}>
      <View style={styles.messageContent}>
        <View style={styles.photoContainer}>
          <View style={styles.photo}>
            {/* <Image
              style={styles.photo}
              source={{ uri: "https://picsum.photos/100" }}
            /> */}
            <Text style={styles.councilInitial}>
              {council.councilName.charAt(0).toUpperCase()}
            </Text>
          </View>
        </View>
        <View style={styles.messageText}>
          <Text style={styles.cardTitle}>{council.councilName} </Text>
          <Text style={styles.cardMarkets} numberOfLines={1}>
            {markets}
          </Text>

          <Text style={styles.cardDescription} numberOfLines={1}>
            {latestMessage}
          </Text>
        </View>
        <View style={styles.dateAndStatus}>
          <Text style={styles.cardDate}>{date}</Text>
          <View style={styles.cardStatus}>
            <View>
              {!hasSeen && (
                <Badge
                  style={{
                    backgroundColor: colours.messages.unread,
                  }}
                  size={15}
                />
              )}
            </View>
          </View>
        </View>
      </View>
    </Card>
  );
};

export default MessageSummary;
