import React, { createContext, ReactElement, ReactNode } from "react";
import { OMLContext } from "./src/types/context.types";

export const AppContext = createContext<OMLContext>({
  context: { firebaseUser: null, externalUser: null, socket: null, initialized: false, currentApplication: null, error: null, fetchApplications: false },
  setContext: () => {
    throw new Error("Context has not been initialised");
  },
});

interface Props extends OMLContext {
  children: ReactNode;
}

const OMLContextProvider = ({
  children,
  context,
  setContext,
}: Props): ReactElement => {
  return (
    <AppContext.Provider value={{ context, setContext }}>
      {children}
    </AppContext.Provider>
  );
};

export default OMLContextProvider;
