import { FormFieldValue } from '../../../types/application/formField.types'
import { InMemoryFormFields } from '../../../types/form.types'
import applyValidators from './applyValidators'
import cleanXorErrorMessages from './cleanXorErrorMessages'

const generateNewFormVersion = (
  formFields: InMemoryFormFields,
  newValue: FormFieldValue,
  identifier: string,
  dontValidate?: boolean
): InMemoryFormFields => {
  const newFormFields = { ...formFields }

  const fieldToUpdate = newFormFields[identifier]

  fieldToUpdate.value = newValue
  if (!dontValidate) {
    fieldToUpdate.error = applyValidators(fieldToUpdate, newFormFields)
  }

  const finalFormValues = cleanXorErrorMessages(fieldToUpdate, newFormFields)

  return finalFormValues
}

export default generateNewFormVersion
