import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'
/**
 * Fetches the credentials needed to make payments using PayPal
 *
 * @param {number} workspaceId - The ID of the workspaces for which to retrieve the credentials
 * @returns {Object}
 * @return {string} object.clientId - the clientId of the workspace's PayPal account
 * @return {string} object.payPalAuthToken - the authToken for the given PayPal account
 */

interface Result {
  merchantId: string
  clientId: string
  payPalAuthToken: string
  payPalPartnerAttributionId: string
  authAssertion: string
}

const getPayPalConfig = async (workspaceId: number): Promise<Result | void> => {
  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/workspaces/${workspaceId}/paypal`
  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const response = await fetch(url, {
    headers,
    method: 'GET',
  })

  if (response.status === 200) {
    return response.json()
  } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
    throw new Error(ERRORS.UPGRADE_REQUIRED.message)
  } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
    const res = await response.text()
    if (res === ERRORS.TOKEN_EXPIRED.message) {
      signOutUser()
    } else {
      throw new Error('Failed to retrieve credentials')
    }
  } else {
    throw new Error('Failed to retrieve credentials')
  }
}

export default getPayPalConfig
