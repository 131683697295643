import { ShallowApplication } from '../../../types/application/application.types'
import { DocumentReference } from '../firebase.types'

const getApplication = async (applicationReference: DocumentReference): Promise<ShallowApplication | null> => {
  const applicationSnapshot = await applicationReference.get()
  const application = applicationSnapshot.data() as ShallowApplication | undefined
  return application ? { ...application, id: applicationSnapshot.id } : null
}

export default getApplication
