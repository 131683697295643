/**
 * Returns a random number between 1 and a given maximum value
 *
 * @param {number} maximum - The maximum value to generate a number between
 * @returns a random number
 */

const generateRandomNumber = (maximum: number): number => {
  return Math.floor(Math.random() * maximum + 1)
}

export default generateRandomNumber
