/* eslint-disable react-hooks/exhaustive-deps */
import { useFocusEffect } from '@react-navigation/native'
import moment from 'moment'
import React, { ReactElement, useCallback, useState } from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { Button, IconButton } from 'react-native-paper'
import { ERRORS } from '../../constants'
import getLicence from '../helpers/api/getLicence'
import getLicences from '../helpers/api/getLicences'
import useOMLContext from '../hooks/useOMLContext'
import LicenceSummary from '../molecules/LicenceSummary'
import { colours } from '../styleguide'
import BannerTemplate from '../templates/BannerTemplate'
import { SimplifiedLicence } from '../types/licence/licence.types'
import { Pages, ScreenProps } from '../types/navigation.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    maxWidth: '100%',
    width: 500,
    backgroundColor: colours.backgroundGrey,
  },
  bottomButton: {
    marginTop: 20,
    backgroundColor: colours.primary,
  },
  title: {
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleContainer: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: '5%',
    alignItems: 'center',
  },
  newApplicationBtnContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    shadowOffset: Platform.OS === 'web' ? { width: 0, height: 0 } : { width: 0, height: 1 },
    shadowOpacity: 0.2,
    elevation: Platform.OS === 'android' ? 5 : 1,
  },
  newApplicationBtn: {
    borderRadius: 50,
    backgroundColor: colours.primary,
    elevation: Platform.OS === 'android' ? 5 : 1,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.1,
  },
  noLicencesText: {
    textAlign: 'center',
    fontWeight: '400',
    marginBottom: 20,
  },
})

interface Props extends ScreenProps<Pages.LICENCES> {
  showBanner: boolean
  loading: boolean
  error: string
}

const LicencesScreen = (props: Props): ReactElement => {
  const { navigation } = props
  const [showBanner, setShowBanner] = useState<boolean>(props.showBanner)
  const [loading, setLoading] = useState<boolean>(props.loading)
  const [error, setError] = useState<string>(props.error)
  const [context, setContext] = useOMLContext()

  const [licences, setLicences] = useState<SimplifiedLicence[]>([])

  navigation.setOptions({ headerShown: !loading })

  const loadLicences = async () => {
    getLicences()
      .then(licences => {
        if (licences) {
          setLicences(licences)
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
        if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
          setContext({
            ...context,
            error: ERRORS.UPGRADE_REQUIRED,
          })
        } else {
          setError('Failed to load applications. Please, try again later.')
          setShowBanner(true)
        }
      })
  }

  useFocusEffect(
    useCallback(() => {
      if (!context.firebaseUser?.uid || !context.externalUser) {
        return
      }

      if (!licences.length) {
        setLoading(true)
        loadLicences()
      }
    }, [context.firebaseUser?.uid, context.externalUser])
  )

  const dismissErrorMessage = () => {
    setShowBanner(false)
  }

  const createNewApplication = () => {
    setContext({
      ...context,
      currentApplication: null,
    })
    navigation.navigate(Pages.NEW_APPLICATION)
  }

  const openLicence = async (licenceId: number) => {
    setLoading(true)
    setShowBanner(false)
    if (!licenceId) {
      setLoading(false)
      setShowBanner(true)
      setError('This licence cannot be displayed. Please, try again later.')
      return
    }

    const licence = await getLicence(licenceId).catch(() => {
      setLoading(false)
      setShowBanner(true)
      setError('This licence cannot be displayed. Please, try again later.')
      return
    })

    if (licence) {
      navigation.navigate(Pages.LICENCE_SUMMARY, {
        licenceId: licence.id,
      })
    }
  }

  const isLicenceExpired = (expiryDate: number) => {
    const formattedExpiryDate = Date.parse(moment(expiryDate).format('YYYY-MM-DD'))
    const today = Date.parse(moment().format('YYYY-MM-DD'))

    return today > formattedExpiryDate
  }

  return (
    <BannerTemplate
      testID="LicencesScreen"
      loading={loading}
      showBanner={showBanner}
      bannerText={error}
      dismissError={dismissErrorMessage}
    >
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>Your Licences</Text>
          <View style={styles.newApplicationBtnContainer}>
            <IconButton
              testID="addApplicationBtn"
              style={styles.newApplicationBtn}
              icon="plus"
              size={25}
              onPress={createNewApplication}
            />
          </View>
        </View>
        {licences.length ? (
          licences.map((licence, index) => {
            return (
              <LicenceSummary
                key={`${index}`}
                council={licence.workspaceName}
                market={licence.market}
                isExpired={isLicenceExpired(Number(licence.expiryDate))}
                onPress={() => openLicence(licence.id)}
              />
            )
          })
        ) : (
          <View>
            <Text style={styles.noLicencesText}>No licences to display.</Text>
            <Text style={styles.noLicencesText}>Please apply for a licence below.</Text>
          </View>
        )}
        <Button
          icon="plus"
          mode="elevated"
          textColor={colours.black}
          style={styles.bottomButton}
          onPress={createNewApplication}
        >
          Create Application
        </Button>
      </View>
    </BannerTemplate>
  )
}

LicencesScreen.defaultProps = {
  showBanner: false,
  loading: true,
  error: '',
}
export default LicencesScreen
