import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { Application, ApplicationProperties, ShallowApplication } from '../../types/application/application.types'
import { Collections } from '../../types/firebase.types'
import signOutUser from '../firebase/auth/signOutUser'
import { firestore } from '../firebase/firebase'
import { retrieveTabledAuthToken } from '../localStorage'
import setApplicationStatus from '../setApplicationStatus'
import postErrorMessageToSlack from './postErrorMessageToSlack'

interface CustomField {
  id: number
  name: string
  label: string
  type: string
  options: string
  value: string
  values: string[]
}

interface Result {
  workspace: string
  externalId: string
  customFields: CustomField[]
  status: string
}

const getApplications = async (firebaseUserId: string, externalUserId: number): Promise<Application[]> => {
  const result: Application[] = []

  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/tasks`

  await fetch(
    new Request(url, {
      method: 'GET',
      headers,
    })
  ).then(async response => {
    if (response.status == 200) {
      await response.json().then(async ({ applications }) => {
        const applicationsQuery = await firestore
          .collection(Collections.APPLICATIONS)
          .where(ApplicationProperties.APPLICANT, '==', firebaseUserId)
          .get()

        const firebaseApplications = applicationsQuery.docs.map(doc => doc.data() as Application)

        for (const currentTabledApplication of applications as Result[]) {
          const currentFirebaseApplication = firebaseApplications.find(
            application => application[ApplicationProperties.EXTERNAL_ID] === currentTabledApplication.externalId
          )

          if (!currentFirebaseApplication) {
            const currentFirebaseApplicationError = new Error(
              `No external application exists with the id of ${ApplicationProperties.EXTERNAL_ID} (${currentTabledApplication.externalId}). (getApplications.ts)`
            )
            postErrorMessageToSlack(currentFirebaseApplicationError, '', `Tabled user ${externalUserId}`)
            continue
          }

          const currentFirebaseDocument = applicationsQuery.docs.find(
            document =>
              document.data()[ApplicationProperties.EXTERNAL_ID] ==
              currentFirebaseApplication[ApplicationProperties.EXTERNAL_ID]
          )?.ref

          if (!currentFirebaseDocument) {
            const currentFirebaseDocumentError = new Error(
              `No internal application exists with the id of ${ApplicationProperties.EXTERNAL_ID} (${currentTabledApplication.externalId}). (getApplications.ts)`
            )
            postErrorMessageToSlack(currentFirebaseDocumentError, '', `Tabled user ${externalUserId}`)
            continue
          }

          const result_status = setApplicationStatus(currentTabledApplication.status)

          const marketsField = currentTabledApplication.customFields?.find(field => field.label === 'Market')
          const markets = marketsField && Array.isArray(marketsField.values) ? marketsField.values : []

          currentFirebaseApplication[ApplicationProperties.MARKETS] = markets

          result.push({ ...currentFirebaseApplication, [ApplicationProperties.ID]: currentFirebaseDocument.id })

          const shallowApplication: ShallowApplication = {
            [ApplicationProperties.APPLICANT]: firebaseUserId,
            [ApplicationProperties.COUNCIL]: currentTabledApplication.workspace,
            [ApplicationProperties.MARKETS]: markets,
            [ApplicationProperties.STATUS]: result_status,
            [ApplicationProperties.EXTERNAL_ID]: currentTabledApplication.externalId,
          }

          //TODO we need to check if values are different via object comparison, similar to json stringify elsewhere.
          if (currentFirebaseDocument) {
            await currentFirebaseDocument.set(shallowApplication)
          }
        }
      })
    } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
      throw new Error(ERRORS.UPGRADE_REQUIRED.message)
    } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
      await signOutUser()
    }
  })

  return result
}

export default getApplications
