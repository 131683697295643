import { useState, useEffect } from 'react'
import { AnyField, FormFieldTypes, FormFieldValue } from '../../types/application/formField.types'
import { InMemoryFormFields } from '../../types/form.types'
import checkIsFormValid from './helpers/checkIsFormValid'
import convertFromFirebaseFormat from './helpers/convertFromFirebaseFormat'
import convertToFirebaseFormat from './helpers/convertToFirebaseFormat'
import generateNewFormVersion from './helpers/generateNewFormVersion'
import selectVisibleFields from './helpers/selectVisibleFields'

type UpdateFormValue = (newValue: FormFieldValue, identifier: string, dontValidate?: boolean) => void
type UpdateFormField = (newValue: AnyField) => void
type ValidateForm = () => boolean
type GetFirebaseFormattedForm = () => AnyField[]
interface ReturnValues {
  visibleFields: AnyField[]
  updateFormValue: UpdateFormValue
  validateForm: ValidateForm
  getFirebaseFormattedForm: GetFirebaseFormattedForm
  updateFormField: UpdateFormField
  cleanForm: () => void
}

const useForm = (startingFields: AnyField[], markets?: string[]): ReturnValues => {
  const InMemoryFormFields = convertFromFirebaseFormat(startingFields)
  const [formFields, setFormFields] = useState<InMemoryFormFields>(InMemoryFormFields)
  const [visibleFields, setvisibleFields] = useState<AnyField[]>([])

  const cleanForm = () => {
    setFormFields(InMemoryFormFields)
    if (markets) {
      setvisibleFields(selectVisibleFields(startingFields, formFields, markets))
    } else {
      setvisibleFields(selectVisibleFields(startingFields, formFields))
    }
  }

  const updateFormValue: UpdateFormValue = (newValue, identifier, dontValidate = false) => {
    const formField = formFields[identifier]
    if (formField && formField.type === FormFieldTypes.RADIO && newValue === formField.value) {
      newValue = ''
    }

    let cleanedValue = newValue

    if (newValue && typeof newValue === 'string' && newValue.trim().length === 0) {
      cleanedValue = ''
    }

    const newForm = generateNewFormVersion(formFields, cleanedValue, identifier, dontValidate)
    setFormFields(newForm)
  }

  const updateFormField: UpdateFormField = (field: AnyField) => {
    const fields = { ...formFields }

    fields[field.identifier] = field

    setFormFields(fields)
  }

  useEffect(() => {
    if (startingFields.length && Object.entries(formFields).length) {
      if (markets) {
        setvisibleFields(selectVisibleFields(startingFields, formFields, markets))
      } else {
        setvisibleFields(selectVisibleFields(startingFields, formFields))
      }
    }
  }, [startingFields, formFields, markets])

  const validateForm: ValidateForm = () => {
    const { isValid, copyOfFormFields } = checkIsFormValid(visibleFields, formFields)
    setFormFields(copyOfFormFields)
    return isValid
  }

  const getFirebaseFormattedForm: GetFirebaseFormattedForm = () => convertToFirebaseFormat(startingFields, formFields)

  return {
    visibleFields,
    updateFormValue,
    validateForm,
    getFirebaseFormattedForm,
    updateFormField,
    cleanForm,
  }
}

export default useForm
