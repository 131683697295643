import { FirebaseError } from '@firebase/util'
import React, { useState, ReactElement } from 'react'
import { Platform, StyleSheet, Image, View, Text, SafeAreaView, KeyboardAvoidingView } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { Button, Paragraph, ActivityIndicator } from 'react-native-paper'
import appleIconBlack from '../../assets/apple-logo-black.png'
import googleIcon from '../../assets/google-logo.png'
import { ERRORS } from '../../constants'
import Link from '../atoms/Link'
import signIn from '../helpers/firebase/auth/signIn'
import { FirebaseAuthUserCredential } from '../helpers/firebase/firebase.types'
import useAppleSignIn from '../hooks/useAppleSignIn'
import useForm from '../hooks/useForm/useForm'
import useGoogleSignIn from '../hooks/useGoogleSignIn'
import useOMLContext from '../hooks/useOMLContext'
import ErrorMessage from '../molecules/ErrorMessage'
import FormField from '../molecules/FormField'
import { logInForm } from '../static-forms/logInForm'
import { colours } from '../styleguide'
import { FormFieldValue, AnyField } from '../types/application/formField.types'
import { CodedError } from '../types/miscellaneous.types'
import { Pages, ScreenProps } from '../types/navigation.types'

const styles = StyleSheet.create({
  button: {
    marginBottom: 10,
    width: '100%',
    backgroundColor: colours.primary,
  },
  googleButton: {
    width: '100%',
    backgroundColor: colours.white,
    marginBottom: 10,
    elevation: 3,
  },
  appleButton: {
    width: '100%',
    backgroundColor: colours.white,
  },
  appleButtonLabel: {
    color: colours.black,
  },
  container: {
    flexGrow: 1,
    backgroundColor: Platform.OS === 'web' ? colours.primary : colours.primary,
  },
  logoAndCard: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  logoContainer: {
    marginVertical: '2%',
    width: '100%',
    flex: 1,
    alignItems: 'center',
  },
  logo: {
    width: 500,
    maxWidth: '70%',
    height: '100%',
  },
  linksContainer: {
    width: '100%',
    alignItems: 'center',
    position: 'absolute',
    left: Platform.OS === 'web' ? 0 : 20,
    bottom: 30,
  },
  spinnerContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colours.backgroundGrey,
  },
  loadingText: {
    color: colours.description,
    opacity: 0.7,
    marginTop: 10,
  },
  registerLinkContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: 10,
  },
  registerLink: {
    fontSize: 15,
  },
  footerContainer: {
    flex: 3,
    marginHorizontal: 'auto',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    elevation: 2,
  },
  footer: {
    width: 500,
    maxWidth: '100%',
    flex: 3,
    backgroundColor: colours.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
})

interface Props extends ScreenProps<Pages.LOGIN> {
  loading: boolean
  logInForm: AnyField[]
}

const LoginScreenWeb = (props: Props): ReactElement => {
  const { navigation, logInForm } = props
  const [loading, setLoading] = useState<boolean>(props.loading)
  const [context, setContext] = useOMLContext()
  const [showError, setShowError] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>('')

  const dismissErrorMessage = () => {
    setShowError(false)
  }

  const { promptGoogleSignIn } = useGoogleSignIn()

  const { promptAppleSignIn } = useAppleSignIn()

  const { visibleFields, updateFormValue, validateForm, getFirebaseFormattedForm } = useForm(logInForm)

  const onSignupPress = () => {
    navigation.navigate(Pages.REGISTER)
  }

  const onLoginPress = async () => {
    setLoading(true)

    const isFormValid = validateForm()
    if (!isFormValid) {
      setLoading(false)
      return
    }

    try {
      const [usernameField, passwordField] = await getFirebaseFormattedForm()

      const emailAddress = usernameField.value as string
      const password = passwordField.value as string

      const authUserCredential: FirebaseAuthUserCredential = await signIn(emailAddress, password)

      if (authUserCredential && authUserCredential.user) {
        setContext({ ...context, firebaseUser: authUserCredential.user })
      }
    } catch (error) {
      const { code, message } = error as FirebaseError

      if (!code && !message) {
        setErrorText('Sorry! This request could not be processed. Please try again later.')
        setShowError(true)
        return
      }

      switch (code) {
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          setErrorText('There are no accounts associated with this email.')
          setShowError(true)
          break
        case 'auth/invalid-email':
          setErrorText('The email you entered is invalid, please enter a valid email.')
          setShowError(true)
          break
        case 'auth/wrong-password':
          setErrorText('The password you entered is incorrect, please try again.')
          setShowError(true)
          break
        default:
          setErrorText(message)
          setShowError(true)
          break
      }
    } finally {
      setLoading(false)
    }
  }

  const onForgotPasswordPress = () => {
    navigation.navigate(Pages.RECOVER_ACCOUNT)
  }

  const onGoogleSignInPress = async () => {
    setLoading(true)

    try {
      await promptGoogleSignIn()
    } catch (error) {
      const { message } = error as CodedError

      if (
        message.includes(`${ERRORS.GOOGLE.SIGN_IN_CANCELLED.STATUS_CODE}`) ||
        message.includes(ERRORS.GOOGLE.SIGN_IN_CANCELLED.MESSAGE)
      ) {
        setLoading(false)
        return
      } else {
        setErrorText('This request could not be processed.')
        setShowError(true)
        setLoading(false)
        return
      }
    }
  }

  const onAppleSignInPress = async () => {
    setLoading(true)

    try {
      await promptAppleSignIn()
    } catch (error) {
      const { code, message } = error as CodedError

      if (!code || !message) {
        setErrorText('This request could not be processed.')
        setShowError(true)
        return
      }
      setErrorText(message)
      setShowError(true)
      setLoading(false)
      return
    }
  }

  return (
    <View style={styles.container} testID="LoginScreen">
      {loading ? (
        <SafeAreaView testID="Loading" style={styles.spinnerContainer}>
          <ActivityIndicator size="large" animating={true} />
          <Text style={styles.loadingText}>Loading</Text>
        </SafeAreaView>
      ) : (
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          enabled={false}
          style={styles.logoAndCard}
        >
          <View style={styles.logoContainer}>
            <Image
              source={require('../../assets/logo_clear_background.png')}
              resizeMode="contain"
              style={styles.logo}
            />
          </View>
          <View style={styles.footerContainer}>
            <Animatable.View animation="fadeInUpBig" style={styles.footer}>
              {visibleFields.map(field => (
                <FormField
                  key={field.identifier}
                  field={field}
                  onInput={(newValue: FormFieldValue) => updateFormValue(newValue, field.identifier)}
                />
              ))}
              <Button
                style={styles.button}
                mode="elevated"
                textColor={colours.black}
                uppercase={false}
                onPress={onLoginPress}
              >
                Log in
              </Button>
              <Button
                style={styles.googleButton}
                mode="elevated"
                textColor={colours.black}
                uppercase={false}
                icon={({ size }) => <Image source={googleIcon} style={{ width: size, height: size }} />}
                onPress={onGoogleSignInPress}
              >
                <Text>Sign in with Google</Text>
              </Button>
              {Platform.OS === 'ios' && (
                <Button
                  style={styles.appleButton}
                  labelStyle={styles.appleButtonLabel}
                  mode="elevated"
                  textColor={colours.black}
                  uppercase={false}
                  icon={({ size }) => <Image source={appleIconBlack} style={{ width: size, height: size }} />}
                  onPress={onAppleSignInPress}
                >
                  <Text>Sign in with Apple</Text>
                </Button>
              )}
              <View style={styles.registerLinkContainer}>
                <Paragraph style={styles.registerLink}>
                  Not registered yet? <Link onPress={onSignupPress}>Create an account</Link>
                </Paragraph>
              </View>
              <View style={styles.linksContainer}>
                <Paragraph>
                  Forgotten your password? <Link onPress={onForgotPasswordPress}>Send recovery email</Link>
                </Paragraph>
              </View>
            </Animatable.View>
          </View>
          <ErrorMessage errorMessage={errorText} showError={showError} onPress={dismissErrorMessage} />
        </KeyboardAvoidingView>
      )}
    </View>
  )
}

LoginScreenWeb.defaultProps = {
  loading: false,
  logInForm,
}

export default LoginScreenWeb
