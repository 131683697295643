import { useState } from 'react'
import postErrorMessageToSlack from '../helpers/api/postErrorMessageToSlack'
import { FirebaseAuthUser } from '../helpers/firebase/firebase.types'
import { User, UserProperties } from '../types/user.types'

const useErrorBoundary = () => {
  const [currentUserId, setCurrentUserId] = useState('')

  const errorHandler = (error: Error, stackTrace: string) => {
    postErrorMessageToSlack(error, stackTrace, currentUserId)
  }

  const setUserId = (externalUser: User, firebaseUser: FirebaseAuthUser) => {
    setCurrentUserId(
      externalUser
        ? `Tabled user ${externalUser[UserProperties.EXTERNAL_USER_ID]}`
        : `Firebase user ${firebaseUser?.uid}` || ''
    )
  }

  return { errorHandler, setUserId }
}

export default useErrorBoundary
