import * as BackgroundFetch from 'expo-background-fetch'
import { ApplicationStatuses } from '../../types/application/application.types'
import { ApplicationStatusNotification, ApplicationStatusNotifications } from '../../types/miscellaneous.types'
import getTabledApplications from '../api/getTabledApplications'
import {
  retrieveApplicationNotifications,
  retrieveExternalUserId,
  retrieveSettings,
  storeApplicationNotifications,
} from '../localStorage'
import { scheduleNotification } from './notifications'

const BACKGROUND_FETCH_APPLICATIONS_TASK_NAME = 'background-fetch-applications'

const backgroundApplicationNotificationTask = async () => {
  const currentExternalUserId = await retrieveExternalUserId()

  const deviceSettings = await retrieveSettings()

  if (!deviceSettings.notificationsEnabled || !currentExternalUserId) {
    return BackgroundFetch.BackgroundFetchResult.NoData
  }

  const allNotifications = await getTabledApplications(currentExternalUserId)
  const sentNotifications: ApplicationStatusNotifications = await retrieveApplicationNotifications()

  // Let's not send notifications for things already seen on other devices.
  if (Object.keys(sentNotifications).length == 0 && Object.keys(allNotifications).length > 1) {
    storeApplicationNotifications(allNotifications)
    return BackgroundFetch.BackgroundFetchResult.NoData
  }

  const pendingNotifications: ApplicationStatusNotifications = {}

  // Populate notifications to send.
  Object.keys(allNotifications).forEach(taskId => {
    if (!sentNotifications?.[taskId] || allNotifications?.[taskId].status != sentNotifications?.[taskId].status) {
      pendingNotifications[taskId] = allNotifications[taskId]
    }
  })

  // Send notifications.
  const notificationsToBeSent = Object.values(pendingNotifications)

  for (const notification of notificationsToBeSent) {
    const councilName = notification.workspaceName ?? 'OML'
    let message = ''

    if (!notification?.externalTask) {
      message = `Your application for ${councilName} has been created.`
    } else {
      message = `Your application for ${councilName} has been updated.`
    }

    await createNewApplicationNotification(
      councilName,
      message,
      notification.externalTask,
      notification.workspaceName,
      notification.status
    )
    sentNotifications[notification.externalTask] = notification
  }

  storeApplicationNotifications(sentNotifications)

  return BackgroundFetch.BackgroundFetchResult.NewData
}

const createNewApplicationNotification = async (
  message: string,
  councilName: string,
  externalTask: number,
  workspaceName: string,
  status: ApplicationStatuses
): Promise<ApplicationStatusNotification> => {
  const newApplicationMessage = {
    externalTask: externalTask,
    workspaceName: workspaceName,
    status: status,
  } as ApplicationStatusNotification

  await scheduleNotification(councilName, message, newApplicationMessage)

  return newApplicationMessage
}

export { BACKGROUND_FETCH_APPLICATIONS_TASK_NAME, backgroundApplicationNotificationTask }
