import { ApplicationStage } from '../../types/application/application.types'

import { FormFieldTypes, ImageUploadField, SingleImageUploadField } from '../../types/application/formField.types'
import getUrlFromPath from '../firebase/storage/getUrlFromPath'

const makeApplicationFilesImmutable = async (stages: ApplicationStage[]): Promise<ApplicationStage[]> => {
  return Promise.all(
    stages.map(async stage => {
      const newFields = await Promise.all(
        stage.fields.map(async field => {
          if (field.type !== FormFieldTypes.IMAGE && field.type !== FormFieldTypes.IMAGE_SINGLE) {
            return field
          }

          const imageField = field as ImageUploadField | SingleImageUploadField

          const newValue = await Promise.all(
            imageField.value.map(async file => {
              const firebaseStoragePath = file.firebaseStoragePath as string
              const uri = await getUrlFromPath(firebaseStoragePath)
              const externalDocumentId = file.externalDocumentId
              const externalDocumentName = file.externalDocumentName
              if (externalDocumentId && externalDocumentName) {
                return {
                  firebaseStoragePath,
                  uri,
                  externalDocumentId,
                  externalDocumentName,
                }
              }
              return {
                firebaseStoragePath,
                uri,
              }
            })
          )

          return { ...field, value: newValue }
        })
      )

      return { ...stage, fields: newFields } as ApplicationStage
    })
  )
}

export default makeApplicationFilesImmutable
