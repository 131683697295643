import React, { ReactElement, useState } from "react";
import { View, StyleSheet, Text, Linking, Animated, Platform } from "react-native";
import Collapsible from "react-native-collapsible";
import { Icon } from "react-native-elements";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Card, Paragraph } from "react-native-paper";
import Link from "../atoms/Link";
import { colours } from "../styleguide";
import BannerTemplate from "../templates/BannerTemplate";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    maxWidth: 500,
    width: "100%",
  },
  cardContainer: {
    maxWidth: "100%",
    width: "100%",
  },
  action: {
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: colours.lightGrey,
    paddingBottom: 5,
  },
  icon: {

  },
  title: {
    marginLeft: 10,
    textAlign: "left",
    fontWeight: "600",
    flex: 1,
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    padding: "2%",
  },
  childrenContent: {
    paddingLeft: Platform.OS === 'web' ? 40 : 35,
  },
});

interface FAQ {
  id: number,
  visible: boolean,
  question: string
  answer: ReactElement
}

const FAQScreen = (): ReactElement => {

  const [faqs, setFaqs] = useState<FAQ[]>([
    {
      id: 0,
      visible: false,
      question: `What is OpenMarkets.London?`,
      answer: <Text>
                OpenMarkets is a new portal to help traders find 
                available market stalls and to apply to trade in 
                those markets.  Each Local Authority has a separate
                licensing application process, which are often not
                easy for traders.  OpenMarkets is bringing these separate
                processes together into a single online portal and mobile
                app to make it easier for market traders to trade in markets across London.
                {"\n\n"}
                OpenMarkes was the winner of the Lord Mayor&apos;s Resilience Competition to help 
                Public Markets bounce back from Covid lockdowns and we have been working with 
                Local Authorities, led by Hackney Council, since the start.
              </Text>,
    },
    {
      id: 1,
      visible: false,
      question: `How do I use OpenMarkets to apply to trade in a market?`,
      answer: 
                <Text>
                  1. Find the markets that meet your criteria using the&nbsp;
                  <Link onPress={() => Linking.openURL('https://openmarkets.london/browse-markets/')}>
                  OpenMarkets market finder
                  </Link>
                  .{"\n\n"}
                  2. Once you have found the markets, you then use the app 
                    to do the applications. You can do this on your laptop 
                    or mobile phone. We recommend mobile as you can then take 
                    photos of the documents you need for the application. 
                    Find the apps for your device&nbsp;
                  <Link onPress={() => Linking.openURL('https://openmarkets.london/apply-to-trade/')}>
                    here
                  </Link>
                  .{"\n\n"}
                  3. Please register for an account and then start an application 
                    using the app. Select the Local Authorities you want to trade in, 
                    the markets and fill in the required fields. You will need to include 
                    details of the goods you want to sell and the proposed start date. 
                    If you want to sell food you may need a further Food Safety Certificate.{"\n\n"}
                  4. You can save your draft application as you go and once completed 
                    please submit the application.
                </Text>
    },
    {
      id: 2,
      visible: false,
      question: `Can I apply to trade in all London Local Authority Markets with the App?`,
      answer: <Text>
                At this time you can only apply to trade in markets in Local Authorities 
                that have signed up to OpenMarkets. Local Authorities are joining all the time.
              </Text>,
    },
    {
      id: 3,
      visible: false,
      question: `Which Local Authorities are participating in OpenMarkets?`,
      answer: <Text>
                You can find which Local Authorities you can apply to using 
                the Markets Finder tool or the application tool. OpenMarkets 
                is a new initiative and we will be adding more Local Authorities 
                as we go.  If your Local Authority is not on the platform, 
                please let them know they should join.
              </Text>,
    },
    {
      id: 4,
      visible: false,
      question: `How can partners of OpenMarkets help traders, including accepting payments?`,
      answer:
                <Text>
                  OpenMarkets is kindly supported by PayPal Zettle who are offering traders who sign 
                  up to Zettle through OpenMarkets a Zettle Reader for just £19 (excl. VAT) and your 
                  first £1,000 of transactions free of charge! (See Terms and Conditions below) To 
                  benefit from this offer you will need to sign up to Zettle using the link below:{"\n\n"}
                  <Link onPress={() => Linking.openURL('https://register.zettle.com/gb/?utm_source=partnership&utm_medium=reseller&utm_campaign=openmarkets&utm_term=localconnector')}>
                    Sign up to Zettle with OpenMarkets
                  </Link>
                  {"\n\n"}
                  Offer T&amp;Cs. Offer  valid for one (1) Zettle Reader per new business user who creates a Zettle account between 22.09.2022 and 22.09.2023. Offer limited to first 1,000 participants. Subject to availability.
                  {"\n\n"}
                  The offer of no fees up to a maximum sales volume of one thousand (£1,000) corresponds to a sum equivalent to £17.50 in fees (and will be credited on your Zettle by PayPal account by 22.09.2023 and must be transacted using the Zettle service before 22.09.2023).
                  {"\n\n"}
                  See Full Terms and Conditions of the Offer&nbsp;
                    <Link onPress={() => Linking.openURL('https://openmarkets.london/zettle-by-paypal-openmarkets-offer-terms-and-conditions/')}>
                      here
                    </Link>.
                  {"\n\n"}
                  See Zettle service Terms&nbsp;
                  <Link onPress={() => Linking.openURL('https://openmarkets.london/zettle-by-paypal-openmarkets-offer-terms-and-conditions/')}>
                    here
                  </Link> and the sale and provision of hardware delivery terms&nbsp;
                  <Link onPress={() => Linking.openURL('https://shop.izettle.com/gb/terms-and-conditions')}>
                    here
                  </Link>.
                </Text>
    },
    {
      id: 5,
      visible: false,
      question: `Who operates OpenMarkets?`,
      answer: <Text>
                OpenMarkets is operated by Tabled Technologies (
                <Link onPress={() => Linking.openURL('http://www.tabled.io')}>
                  www.tabled.io
                </Link>
                ) which is a 
                software company helping to streamline complex processes.  Applications to 
                trade made through OpenMarkets are still handled by each Local Authority.
              </Text>,
    },
    {
      id: 6,
      visible: false,
      question: `How do I contact OpenMarkets to participate or find out more?`,
      answer: <Text>
                We welcome enquiries from traders and Local Authorities. Please 
                use the form on&nbsp;
                  <Link onPress={() => Linking.openURL('https://openmarkets.london/contact/')}>
                    this
                  </Link>
                  &nbsp;page to contact us. 
              </Text>,
    }
  ]);

  const toggleFaq = (id: number) => {
    const updatedFaqs = [...faqs]
    const faqToToggle = updatedFaqs.find(faq => faq.id === id)
    if (faqToToggle) {
      faqToToggle.visible = !faqToToggle.visible
      setFaqs(updatedFaqs)
    }
  }

  const getFaqs = () => {
    const faqDropdowns = faqs.map(faq => {
      return (
        <View key={`faq-${faq.id}`} style={styles.action}>
          <TouchableOpacity
            onPress={() => toggleFaq(faq.id)}
            style={styles.content}
          >
            <Animated.View style={{transform: [{ rotate: !faq.visible ? '0deg' : '45deg' }] }} >
              <Icon
                name="plus"
                type="antdesign"
                color={colours.darkGrey}
                size={20}
                style={styles.icon}
              />
            </Animated.View>
            <Text style={styles.title}>{faq.question}</Text>
          </TouchableOpacity>
          <Collapsible
            style={styles.childrenContent}
            collapsed={!faq.visible}
          >
            <Paragraph>
              {faq.answer}
            </Paragraph>
          </Collapsible>
        </View>
      )
    });

    return faqDropdowns
  };


  return (
    <BannerTemplate testID="FAQ">
      <View style={styles.container}>
        <Card.Content style={styles.cardContainer}>
          {getFaqs()}
        </Card.Content>
      </View>
    </BannerTemplate>
  );
};

FAQScreen.defaultProps = {};

export default FAQScreen;
