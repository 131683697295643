import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { AssistantSummary } from '../../types/user.types'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

const getAssistants = async (externalUserId: number): Promise<AssistantSummary[] | void> => {
  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/external-users/${externalUserId}/assistants`
  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const assistants: AssistantSummary[] = []

  await fetch(
    new Request(url, {
      method: 'GET',
      headers,
    })
  ).then(async response => {
    if (response.status === 200) {
      await response.json().then(({ assistants: tabledAssistants }) => {
        assistants.push(...tabledAssistants)
      })
    } else {
      const errorMessage = response.headers.get('x-exit')
      if (errorMessage === ERRORS.UPGRADE_REQUIRED.message) {
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
        await signOutUser()
      } else {
        throw Error(`Failed to load assistants. Please, try again later.`)
      }
    }
  })

  return assistants
}

export default getAssistants
