import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { ApplicationStatusNotification, ApplicationStatusNotifications } from '../../types/miscellaneous.types'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'
import setApplicationStatus from '../setApplicationStatus'

interface CustomField {
  id: number
  name: string
  label: string
  type: string
  options: string
  value: unknown
}

interface Result {
  workspace: string
  externalId: string
  customFields: CustomField[]
  status: string
}

const getApplications = async (externalUserId: number): Promise<ApplicationStatusNotifications> => {
  const result: ApplicationStatusNotifications = {}

  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const queryParams = () => {
    return '?externalUserId=' + externalUserId
  }

  await fetch(
    new Request(`${Constants?.expoConfig?.extra?.hostname}/ext/tasks` + queryParams(), {
      method: 'GET',
      headers,
    })
  ).then(async response => {
    if (response.status == 200) {
      await response.json().then(async ({ applications }) => {
        for (const currentTabledApplication of applications as Result[]) {
          const result_status = setApplicationStatus(currentTabledApplication.status)

          result[currentTabledApplication.externalId] = {
            externalTask: Number(currentTabledApplication.externalId),
            workspaceName: currentTabledApplication.workspace,
            status: result_status,
          } as ApplicationStatusNotification
        }
      })
    } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
      throw new Error(ERRORS.UPGRADE_REQUIRED.message)
    } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
      await signOutUser()
    }
  })

  return result
}

export default getApplications
