import { auth } from "../firebase";
import {
  FirebaseAuthSubscribe,
  FirebaseAuthUnsubscribe,
} from "../firebase.types";

const subscribeToAuthStateChange = (
  fn: FirebaseAuthSubscribe
): FirebaseAuthUnsubscribe => auth.onAuthStateChanged(fn);

export default subscribeToAuthStateChange;
