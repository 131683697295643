export const colours = {
  primary: '#ffb700',
  secondary: '#ffeec4',
  accent: '#f4eae3',
  status: {
    draft: '#ffdf12',
    archived: '#ffdf12',
    pending: '#66a1ff',
    underReview: '#66a1ff',
    changesRequired: '#ff6666',
    paymentRequested: '#ff6666',
    paymentCompleted: '#58d455',
    approved: '#58d455',
    rejected: '#ff6666',
    unknown: 'grey',
  },
  messages: {
    unread: '#ff6666',
    council: '#dedede',
    grey: '#C0C0C0',
    messageWhite: '#fff',
  },
  white: '#fff',
  black: '#000000',
  darkPrimary: '#212121',
  description: '#919191',
  messageNotification: '#ff1100',
  lightGrey: '#e8e8e8',
  darkGrey: '#a8a8a8',
  omlSplashBackground: '#fffffa',
  omlYellow: '#ffd000',
  backgroundGrey: '#f2f2f2',
  transparent: 'transparent',
  errorPrimary: '#ff9195',
  errorSecondary: '#ffedee',
  zettlePrimary: '#ae9ff3',
  payPalGold: '#ffc439',
  payByCardBlack: '#2C2E2F',
  buyNowBlue: '#0070ba',
  placeholderGrey: '#C7C7CD',
}
