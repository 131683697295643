import { FirebaseError } from '@firebase/util'
import Constants from 'expo-constants'
import React, { useState, ReactElement } from 'react'
import { StyleSheet, View, Text, SafeAreaView, Platform, Image, KeyboardAvoidingView } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { Card, Button, Paragraph, ActivityIndicator } from 'react-native-paper'
import appleIconBlack from '../../assets/apple-logo-black.png'
import googleIcon from '../../assets/google-logo.png'
import { ERRORS } from '../../constants'
import Link from '../atoms/Link'
import getCurrentUser from '../helpers/firebase/auth/getCurrentUser'
import signUp from '../helpers/firebase/auth/signUp'
import putFirebaseUser from '../helpers/firebase/firestore/putFirebaseUser'
import useAppleSignIn from '../hooks/useAppleSignIn'
import useForm from '../hooks/useForm/useForm'
import useGoogleSignIn from '../hooks/useGoogleSignIn'
import useOMLContext from '../hooks/useOMLContext'
import ErrorMessage from '../molecules/ErrorMessage'
import FormField from '../molecules/FormField'
import ModalTemplate from '../molecules/ModalTemplate'
import PrivacyPolicy from '../molecules/PrivacyPolicy'
import TermsAndConditions from '../molecules/TermsAndConditions'
import { registrationForm } from '../static-forms/registrationForm'
import { colours } from '../styleguide'
import { AnyField, FormFieldValue } from '../types/application/formField.types'
import { CodedError } from '../types/miscellaneous.types'
import { Pages, ScreenProps } from '../types/navigation.types'
import { User, UserProperties } from '../types/user.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  titleAndCard: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: colours.primary,
  },
  googleButton: {
    width: '100%',
    backgroundColor: colours.white,
    marginBottom: 10,
    elevation: 3,
  },
  appleButton: {
    width: '100%',
    backgroundColor: colours.white,
  },
  appleButtonLabel: {
    color: colours.black,
  },
  footerContainer: {
    flex: 3,
    marginHorizontal: 'auto',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    elevation: 2,
  },
  footer: {
    width: 500,
    maxWidth: '100%',
    flex: 3,
    backgroundColor: colours.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  linksContainer: {
    width: '100%',
    alignItems: 'center',
    position: 'absolute',
    paddingHorizontal: 30,
    left: 20,
    bottom: 30,
  },
  titleContainerOuter: {
    width: 500,
    maxWidth: '100%',
    marginHorizontal: 'auto',
  },
  titleContainerInner: {
    padding: 20,
    marginLeft: 10,
    marginBottom: 20,
    width: '100%',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: 40,
  },
  text: {
    textAlign: 'center',
  },
  spinnerContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colours.backgroundGrey,
  },
  loadingText: {
    color: colours.description,
    opacity: 0.7,
    marginTop: 10,
  },
  registerLinkContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: 10,
  },
  registerLink: {
    fontSize: 15,
  },
})

interface Props extends ScreenProps<Pages.REGISTER> {
  showError: boolean
  loading: boolean
  error: string
  registrationForm: AnyField[]
}

const RegistrationScreen = (props: Props): ReactElement => {
  const { navigation, registrationForm } = props

  const [loading, setLoading] = useState<boolean>(props.loading)

  const [privacyModalVisible, setPrivacyModalVisible] = useState<boolean>(false)
  const [termsModalVisible, setTermsVisible] = useState<boolean>(false)

  const [showError, setShowError] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>('')

  const dismissErrorMessage = () => {
    setShowError(false)
  }

  const { promptGoogleSignIn } = useGoogleSignIn()

  const { promptAppleSignIn } = useAppleSignIn()

  const togglePrivacyPolicy = () => {
    setPrivacyModalVisible(!privacyModalVisible)
  }
  const toggleTermsAndConditions = () => {
    setTermsVisible(!termsModalVisible)
  }

  const [context, setContext] = useOMLContext()

  const { visibleFields, updateFormValue, validateForm, getFirebaseFormattedForm } = useForm(registrationForm)

  const onLoginPress = () => {
    navigation.navigate(Pages.LOGIN)
  }

  const onRegisterPress = async () => {
    const isFormValid = validateForm()
    if (!isFormValid) {
      setLoading(false)

      return
    }

    try {
      const [usernameField, passwordField] = getFirebaseFormattedForm()

      const emailAddress = usernameField.value as string
      const password = passwordField.value as string
      const { user } = await signUp(emailAddress, password)

      if (!user) {
        throw new Error('User not found')
      }

      const userData = {
        [UserProperties.EMAIL_ADDRESS]: emailAddress,
      } as User

      const currentUser = getCurrentUser()

      if (!currentUser) {
        throw new Error('User not found')
      }

      const actionCodeSettings = {
        url: `https://${Constants?.expoConfig?.extra?.firebaseConfig?.authDomain}/`,
        iOS: {
          bundleId: __DEV__ ? 'com.tabled.oml.staging' : 'london.openmarkets',
        },
        android: {
          packageName: __DEV__ ? 'com.tabled.oml.staging' : 'london.openmarkets',
          installApp: true,
        },
      }

      await Promise.all([putFirebaseUser(user.uid, userData), currentUser.sendEmailVerification(actionCodeSettings)])
      setLoading(true)
      setContext({ ...context, firebaseUser: currentUser })
    } catch (error) {
      const { code, message } = error as FirebaseError

      if (!code || !message) {
        setErrorText(`Sorry! This request could not be processed.`)
        setShowError(true)
        return
      }
      switch (code) {
        case 'auth/email-already-in-use':
          setErrorText(`Sorry! An account with this email already exists.`)
          setShowError(true)
          break
        case 'auth/invalid-email':
          setErrorText('The email you entered is invalid, please enter a valid email.')
          setShowError(true)
          break
        default:
          setErrorText(message)
          setShowError(true)
          break
      }
      setLoading(false)
    }
  }

  const onGoogleSignInPress = async () => {
    setLoading(true)

    try {
      await promptGoogleSignIn()
    } catch (error) {
      const { message } = error as CodedError

      if (
        message.includes(`${ERRORS.GOOGLE.SIGN_IN_CANCELLED.STATUS_CODE}`) ||
        message.includes(ERRORS.GOOGLE.SIGN_IN_CANCELLED.MESSAGE)
      ) {
        setLoading(false)
        return
      } else {
        setErrorText('This request could not be processed.')
        setShowError(true)
        setLoading(false)
        return
      }
    }
  }

  const onAppleSignInPress = async () => {
    setLoading(true)

    try {
      await promptAppleSignIn()
    } catch (error) {
      const { code, message } = error as CodedError

      if (!code || !message) {
        setErrorText('This request could not be processed.')
        setShowError(true)
        return
      }
      setErrorText(message)
      setShowError(true)
      setLoading(false)
      return
    }
  }

  return (
    <View style={styles.container}>
      {loading ? (
        <SafeAreaView style={styles.spinnerContainer}>
          <ActivityIndicator size="large" animating={true} />
          <Text style={styles.loadingText}>Loading</Text>
        </SafeAreaView>
      ) : (
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          enabled={false}
          style={styles.titleAndCard}
        >
          <View style={styles.titleContainerOuter}>
            <View style={styles.titleContainerInner}>
              <Text style={styles.title}>Register</Text>
            </View>
          </View>
          <View style={styles.footerContainer}>
            <Animatable.View animation="fadeInUpBig" style={styles.footer}>
              <Card.Content>
                {visibleFields.map(field => (
                  <FormField
                    key={field.identifier}
                    field={field}
                    onInput={(newValue: FormFieldValue) => updateFormValue(newValue, field.identifier)}
                  />
                ))}
              </Card.Content>
              <Button
                mode="elevated"
                textColor={colours.black}
                uppercase={false}
                onPress={onRegisterPress}
                style={styles.button}
              >
                Create account
              </Button>
              <Button
                style={styles.googleButton}
                mode="elevated"
                textColor={colours.black}
                uppercase={false}
                icon={({ size }) => <Image source={googleIcon} style={{ width: size, height: size }} />}
                onPress={onGoogleSignInPress}
              >
                <Text>Sign up with Google</Text>
              </Button>
              {Platform.OS === 'ios' && (
                <Button
                  style={styles.appleButton}
                  labelStyle={styles.appleButtonLabel}
                  mode="elevated"
                  textColor={colours.black}
                  uppercase={false}
                  icon={({ size }) => <Image source={appleIconBlack} style={{ width: size, height: size }} />}
                  onPress={onAppleSignInPress}
                >
                  <Text>Sign up with Apple</Text>
                </Button>
              )}
              <View style={styles.registerLinkContainer}>
                <Paragraph style={styles.registerLink}>
                  Already registered? <Link onPress={onLoginPress}>Log in</Link>
                </Paragraph>
              </View>
              <View style={styles.linksContainer}>
                <Paragraph style={styles.text}>
                  By clicking &quot;Create account&quot; you agree to our&nbsp;
                  <Link onPress={toggleTermsAndConditions}>Terms of Service</Link>
                  &nbsp;and&nbsp;
                  <Link onPress={togglePrivacyPolicy}>Privacy Policy</Link>.
                </Paragraph>
              </View>
            </Animatable.View>
          </View>
        </KeyboardAvoidingView>
      )}
      <ModalTemplate
        title="Privacy Policy"
        content={<PrivacyPolicy />}
        setModalVisible={togglePrivacyPolicy}
        isVisible={privacyModalVisible}
      />
      <ModalTemplate
        title="Terms of Service"
        content={<TermsAndConditions />}
        setModalVisible={toggleTermsAndConditions}
        isVisible={termsModalVisible}
      />
      <ErrorMessage errorMessage={errorText} showError={showError} onPress={dismissErrorMessage} />
    </View>
  )
}

RegistrationScreen.defaultProps = {
  showError: false,
  loading: false,
  error: '',
  registrationForm,
}

export default RegistrationScreen
