/* eslint-disable react-hooks/exhaustive-deps */
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { StyleSheet, View, Text, Alert, Platform } from 'react-native'
import { Button, HelperText } from 'react-native-paper'
import { ERRORS } from '../../constants'
import deleteAssistantDocument from '../helpers/api/deleteAssistantDocument'
import getAssistant from '../helpers/api/getAssistant'
import patchAssistant from '../helpers/api/patchAssistant'
import postAssistant from '../helpers/api/postAssistant'
import postAssistantDocument from '../helpers/api/postAssistantDocument'
import doesFormValueExist from '../hooks/useForm/helpers/doesFormValueExist'
import useForm from '../hooks/useForm/useForm'
import useOMLContext from '../hooks/useOMLContext'
import FormField from '../molecules/FormField'
import FormFieldSummary from '../molecules/FormFieldSummary'
import { assistantForm } from '../static-forms/assistantForm'
import { colours } from '../styleguide'
import BannerTemplate from '../templates/BannerTemplate'
import { AnyField, FileValue, FormFieldTypes, FormFieldValue } from '../types/application/formField.types'
import { Pages, ScreenProps } from '../types/navigation.types'
import { Assistant, AssistantDocumentProperties, AssistantProperties } from '../types/user.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    maxWidth: '100%',
    marginBottom: '10%',
    width: 500,
  },
  title: {
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleContainer: {
    marginVertical: 13,
    flexDirection: 'row',
  },
  buttonContainer: {
    flexDirection: 'row',
    width: '100%',
    flex: 1,
  },
  button: {
    marginTop: 10,
    flex: 1,
    marginHorizontal: 5,
    backgroundColor: colours.primary,
  },
  error: {
    paddingHorizontal: 0,
    paddingBottom: 14,
    textAlign: 'center',
  },
})

const AssistantSummaryScreen = (props: ScreenProps<Pages.ASSISTANT_SUMMARY>): ReactElement => {
  const { navigation, route } = props
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingAssistant, setLoadingAssistant] = useState<boolean>(false)
  const [awaitingImageUpload, setAwaitingImageUpload] = useState<boolean>(false)
  const [assistantId, setAssistantId] = useState<number | undefined>(route.params?.assistantId)
  const [assistant, setAssistant] = useState<Assistant | null>(null)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [generalFormError, setGeneralFormError] = useState<string>('')
  const [context, setContext] = useOMLContext()
  const isFocused = useIsFocused()
  const [showBanner, setShowBanner] = useState<boolean>(false)
  const [documentIds, setDocumentIds] = useState<number[]>([])
  const [error, setError] = useState<string>('')

  const { visibleFields, updateFormValue, validateForm, cleanForm } = useForm(assistantForm)

  useFocusEffect(
    useCallback(() => {
      setIsFormValid(false)
      cleanForm()
      setAssistant(null)
      setAssistantId(route.params?.assistantId)
      setDocumentIds([])
      setLoadingAssistant(false)
      // We do not need to load anything if creating a new assistant
      if (!route.params?.assistantId && visibleFields.length) {
        setLoading(false)
      }
    }, [route])
  )

  useEffect(() => {
    if (!route.params?.assistantId && visibleFields.length && !loadingAssistant) {
      setLoading(false)
    }
  }, [assistantId, visibleFields])

  useEffect(() => {
    if (route.params?.assistantId) {
      setAssistantId(route.params.assistantId)
    }
  }, [route.params?.assistantId])

  useEffect(() => {
    if (!context.externalUser || !assistantId) {
      return
    }

    // If the assistant has not been loaded, or the route param has changed, fetch the user
    // only if the screen is rendered/focused and the fetch is not ongoing
    if (
      ((!assistant && assistantId) || assistant?.id !== assistantId) &&
      !loadingAssistant &&
      isFocused &&
      visibleFields.length &&
      (route.path ? !route.path.includes('new-assistant') : true) &&
      route.name.toLowerCase() !== 'new assistant'
    ) {
      setLoadingAssistant(true)
      fetchAssistant(Number(assistantId))
    }
  }, [assistant, assistantId, isFocused, visibleFields])

  useEffect(() => {
    if (assistant && !loadingAssistant && !awaitingImageUpload) {
      setLoading(false)
    }
  }, [assistant, loadingAssistant])

  const validateForSubmission = () => {
    setTimeout(() => {
      const areAllFieldsFilledOut = visibleFields.every(
        field => doesFormValueExist(field.value) || field.type === FormFieldTypes.TOGGLE
      )
      const isValid = visibleFields.length === assistantForm.length && areAllFieldsFilledOut
      setIsFormValid(isValid)
    }, 500)
  }

  const fetchAssistant = async (assistantId: number) => {
    if (!context.externalUser || !assistantId) {
      return
    }
    setLoading(true)
    setLoadingAssistant(true)
    getAssistant(context.externalUser.id, assistantId)
      .then(assistant => {
        const documentFields = visibleFields
          .filter(field => field.type === FormFieldTypes.IMAGE)
          .map(field => field.identifier) as AssistantDocumentProperties[]
        const nonDocumentFields = visibleFields
          .filter(field => field.type !== FormFieldTypes.IMAGE)
          .map(field => field.identifier) as AssistantProperties[]

        if (assistant) {
          setAssistant(assistant)
          // Mapping the Assistant details from Tabled to the form
          nonDocumentFields.forEach(identifier => {
            if (assistant[identifier]) {
              const value = assistant[identifier] as FormFieldValue
              updateFormValue(value, identifier)
            } else {
              updateFormValue(null, identifier)
            }
          })

          // Mapping the Assistant documents from Tabled to the form
          const currentDocumentIds: number[] = []
          documentFields.forEach(identifier => {
            if (assistant.documents[identifier]) {
              const value = assistant.documents[identifier] as FormFieldValue
              const currentDocuments: FileValue[] = value as FileValue[]
              const externalDocumentIds: number[] = currentDocuments
                .map(doc => doc.externalDocumentId || -1)
                .flat()
                .filter(id => id > 0)
              currentDocumentIds.push(...externalDocumentIds)
              updateFormValue(value, identifier)
            } else {
              updateFormValue([], identifier)
            }
          })

          setDocumentIds([...currentDocumentIds])
          setLoadingAssistant(false)
          validateForSubmission()
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
        if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
          setContext({
            ...context,
            error: ERRORS.UPGRADE_REQUIRED,
          })
        }
        navigation.navigate(Pages.ASSISTANTS)
      })
  }

  const createAssistant = async (isSubmitting = false) => {
    if (!context.externalUser) {
      setLoading(false)
      return
    }

    const nonDocumentFields = visibleFields.filter(field => field.type !== FormFieldTypes.IMAGE)
    const completedFields = nonDocumentFields.filter(
      field => doesFormValueExist(field.value) || field.type === FormFieldTypes.TOGGLE
    )

    postAssistant(context.externalUser.id, completedFields, isSubmitting)
      .then(result => {
        if (result.assistant) {
          setLoadingAssistant(true)
          setAssistantId(result.assistant.id)
          setAwaitingImageUpload(true)
          const resultAssistantId = result.assistant.id
          handleAssistantImages(resultAssistantId).then(() => {
            if (route.params?.onSubmit) {
              route.params.onSubmit()
            } else {
              fetchAssistant(resultAssistantId)
            }
          })
        }
      })
      .catch(err => {
        setLoading(false)
        if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
          setContext({
            ...context,
            error: ERRORS.UPGRADE_REQUIRED,
          })
        }
        setError(err.message)
        setShowBanner(true)
        return
      })
  }

  const updateAssistant = async (isSubmitting = false) => {
    if (!context.externalUser || !assistantId) {
      setLoading(false)
      return
    }

    const nonDocumentFields = visibleFields.filter(field => field.type !== FormFieldTypes.IMAGE)
    const completedFields = nonDocumentFields.filter(
      field => doesFormValueExist(field.value) || field.type === FormFieldTypes.TOGGLE
    )

    patchAssistant(context.externalUser.id, assistantId, completedFields, isSubmitting)
      .then(result => {
        if (result.assistant) {
          setAwaitingImageUpload(true)
          setLoadingAssistant(true)
          handleAssistantImages(assistantId).then(() => {
            fetchAssistant(assistantId)
          })
        }
      })
      .catch(err => {
        setLoading(false)
        if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
          setContext({
            ...context,
            error: ERRORS.UPGRADE_REQUIRED,
          })
        } else {
          setError(err.message)
          setShowBanner(true)
        }
        return
      })
  }

  const handleAssistantImages = async (assistantId: number) => {
    if (!context.externalUser || !assistantId) {
      return
    }
    const user = context.externalUser
    const documentFields = visibleFields.filter(field => field.type === FormFieldTypes.IMAGE)
    const documentIdentifiers: string[] = []
    const currentDocuments: FileValue[] = []
    const documents: FileValue[] = documentFields.map(docField => docField.value).flat() as FileValue[]
    // Documents with External IDs that are still present in the form
    const currentDocumentIds = documents.filter(doc => doc.externalDocumentId).map(doc => doc.externalDocumentId)
    // Documents with External IDs that have been removed from the form
    const removedDocumentIds = documentIds.filter(id => !currentDocumentIds.includes(id))

    documentFields.map(async field => {
      if (!Array.isArray(field.value)) {
        return
      }
      field.value.forEach(() => documentIdentifiers.push(field.identifier))
      currentDocuments.push(...(field.value as FileValue[]))
    })

    let index = 0
    for await (const document of currentDocuments) {
      if (!document.externalDocumentId) {
        await postAssistantDocument(document.uri, documentIdentifiers[index], user.id, assistantId)
      }
      index += 1
    }

    await Promise.all(
      removedDocumentIds.map(async id => {
        await deleteAssistantDocument(id, user.id, assistantId)
          .then(() => {
            setDocumentIds(documentIds.filter(docId => docId !== id))
          })
          .catch(err => {
            setError(err.message)
          })
      })
    )

    setAwaitingImageUpload(false)
  }

  const saveDraft = async () => {
    const isFormValid = validateForm()
    if (!isFormValid) {
      setGeneralFormError('There are one or more errors in this form')
      setLoading(false)
      return
    }
    setLoading(true)
    setLoadingAssistant(true)
    if (!assistantId) {
      await createAssistant()
    } else {
      await updateAssistant()
    }
  }

  const asyncAlert = async (title: string, description: string): Promise<boolean> =>
    new Promise(resolve => {
      Alert.alert(title, description, [
        {
          text: 'Cancel',
          style: 'cancel',
          onPress: () => {
            resolve(false)
          },
        },
        {
          text: 'OK',
          onPress: async () => {
            resolve(true)
          },
        },
      ])
    })

  const submitAssistant = async () => {
    const isFormValid = validateForm()
    if (!isFormValid) {
      setGeneralFormError('There are one or more error in this form')
      setLoading(false)
      return
    }
    setLoading(true)
    setLoadingAssistant(true)
    // Assistants cannot be edited after submission, we want to confirm the user is aware of this
    let confirmSubmit = false
    const title = 'Confirm Submission'
    const description =
      'Once submitted, you will not be able to edit this assistant.\n\nAre you sure you want to submit?'
    if (Platform.OS === 'web') {
      confirmSubmit = window.confirm([title, `\n${description}`].filter(Boolean).join('\n'))
    } else {
      confirmSubmit = await asyncAlert(title, description)
    }
    if (!confirmSubmit) {
      setLoadingAssistant(false)
      setLoading(false)
      return
    }
    if (!assistantId) {
      await createAssistant(true)
    } else {
      await updateAssistant(true)
    }
  }

  const dismissErrorMessage = () => {
    setShowBanner(false)
  }

  const handleFieldValueUpdate = (value: FormFieldValue, identifier: string) => {
    updateFormValue(value, identifier)
    validateForSubmission()
  }

  return (
    <BannerTemplate
      testID="applicationSummary"
      loading={loading}
      showBanner={showBanner}
      bannerText={error}
      dismissError={dismissErrorMessage}
    >
      <View style={styles.container}>
        {assistant && assistant.isCompleted ? (
          <View>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>Assistant</Text>
            </View>
            {visibleFields.map((field: AnyField) => (
              <FormFieldSummary key={field.identifier} field={field} />
            ))}
          </View>
        ) : (
          <View>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>New Assistant</Text>
            </View>
            {visibleFields.map(field => (
              <FormField
                key={field.identifier}
                field={field}
                onInput={(newValue: FormFieldValue) => handleFieldValueUpdate(newValue, field.identifier)}
                uploading={false}
              />
            ))}
            <View>
              <HelperText type="error" visible={!!generalFormError} style={styles.error}>
                {generalFormError}
              </HelperText>
              <View
                style={[
                  styles.buttonContainer,
                  // eslint-disable-next-line react-native/no-inline-styles
                  { justifyContent: !route.params?.onSubmit ? 'space-between' : 'center' },
                ]}
              >
                {!route.params?.onSubmit && (
                  <Button mode="elevated" textColor={colours.black} onPress={saveDraft} style={styles.button}>
                    Save draft
                  </Button>
                )}
                <Button
                  mode="elevated"
                  textColor={colours.black}
                  onPress={submitAssistant}
                  style={styles.button}
                  disabled={!isFormValid}
                >
                  Submit
                </Button>
              </View>
            </View>
          </View>
        )}
      </View>
    </BannerTemplate>
  )
}

export default AssistantSummaryScreen
