import { AnyField } from '../../../../types/application/formField.types'
import { PastTimeValidator } from '../../../../types/application/validator.types'
import doesFormValueExist from '../doesFormValueExist'

const validatePastTimeField = (field: AnyField, validator: PastTimeValidator): string => {
  if (!doesFormValueExist(field.value)) {
    return ''
  }
  if (typeof field.value !== 'number') {
    throw new Error(`Non-number type passed to validatePastTimeField. field.value was ${field.value}`)
  }
  const now = Date.now()
  const isValid = now > field.value
  return isValid ? '' : validator.failureMessage
}

export default validatePastTimeField
