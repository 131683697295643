import React, { ReactElement } from 'react'
import { ImageBackground, View, StyleSheet, Text, StatusBar, Platform, ScrollView } from 'react-native'
import { Button, Card } from 'react-native-paper'
import { colours } from '../styleguide'
import { Pages, ScreenProps } from '../types/navigation.types'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colours.omlSplashBackground,
  },
  webContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  webImageLeft: {
    flex: 1,
    minWidth: 500,
  },
  webCard: {
    width: 500,
    height: '100%',
    position: 'absolute',
    zIndex: 1,
  },
  webImageRight: {
    flex: 1,
    minWidth: 500,
  },
  image: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepsContentContainerOuter: {
    width: Platform.OS === 'web' ? '100%' : '90%',
    height: Platform.OS === 'web' ? '100%' : '80%',
    backgroundColor: colours.white,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colours.black,
    display: 'flex',
    padding: '5%',
    alignItems: 'center',
  },
  stepsContentContainerInner: {
    alignItems: 'center',
  },
  stepsTitleContainer: {
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5%',
  },
  stepsTitle: {
    fontWeight: 'bold',
    fontSize: 40,
    textAlign: 'center',
  },
  stepsBodyContainer: {
    textAlign: 'left',
    justifyContent: 'center',
    marginTop: '5%',
  },

  stepsText: {
    fontSize: 20,
    marginTop: '5%',
  },
  getStartedBtn: {
    marginVertical: '5%',
    width: '100%',
    backgroundColor: colours.primary,
    fontSize: 20,
    fontWeight: 'bold',
  },
})

const WelcomeScreen = (props: ScreenProps<Pages.WELCOME>): ReactElement => {
  const { navigation } = props

  const getSteps = () => {
    return (
      <View style={styles.stepsContentContainerOuter}>
        <ScrollView
          scrollIndicatorInsets={{ right: 1 }}
          contentContainerStyle={styles.stepsContentContainerInner}
        >
          <View style={styles.stepsTitleContainer}>
            <Text style={styles.stepsTitle}>Welcome to OpenMarkets!</Text>
          </View>
          <View style={styles.stepsBodyContainer}>
            <Text style={styles.stepsText}>
              Apply for a licence to trade in markets across London.
              {'\n\n'}
              1. Add your details
              {'\n\n'}
              2. Upload required documents
              {'\n'}
              {'\t'}- ID
              {'\n'}
              {'\t'}- Proof of Address
              {'\n'}
              {'\t'}- National Insurance
              {'\n\n'}
              3. Add application details
              {'\n'}
              {'\t'}- Select local authority
              {'\n'}
              {'\t'}- Select markets
              {'\n'}
              {'\t'}- Add details
              {'\n\n'}
              4. Submit Application
              {'\n\n'}
              5. Respond to any messages from the local authority team
            </Text>
          </View>
          <Button
            mode="elevated"
            textColor={colours.black}
            style={styles.getStartedBtn}
            onPress={() => {
              navigation.navigate(Pages.PROFILE_AND_ACCOUNT)
            }}
          >
            Get Started
          </Button>
        </ScrollView>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      {Platform.OS === 'web' ? (
        <View style={styles.webContainer}>
          <ImageBackground
            source={require('../../assets/loadScreen.png')}
            resizeMode="stretch"
            style={styles.webImageLeft}
          />
          <Card style={styles.webCard}>{getSteps()}</Card>
          <ImageBackground
            source={require('../../assets/loadScreen.png')}
            resizeMode="stretch"
            style={styles.webImageRight}
          />
        </View>
      ) : (
        <View style={styles.container}>
          <StatusBar animated={true} backgroundColor="#61dafb" barStyle={'dark-content'} showHideTransition={'fade'} />
          <ImageBackground source={require('../../assets/loadScreen.png')} resizeMode="stretch" style={styles.image}>
            {getSteps()}
          </ImageBackground>
        </View>
      )}
    </View>
  )
}

WelcomeScreen.defaultProps = {}

export default WelcomeScreen
