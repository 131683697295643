import React, { ReactElement, useState } from "react";
import { View, StyleSheet, TextInput } from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";
import { colours } from "../styleguide";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
  icon: {
    color: colours.darkGrey,
  },
  textInput: {
    flex: 1,
    paddingVertical: 0,
    paddingLeft: 10,
  },
});

interface Props {
  iconSize?: number;
  iconColor?: string;
  label?: string;
  [rest: string]: unknown;
}

const PasswordTextField = ({
  iconSize = 25,
  iconColor = "#222222",
  label = "Password",
  ...rest
}: Props): ReactElement => {
  const [eyeIcon, setEyeIcon] = useState("visibility-off");
  const [isPassword, setIsPassword] = useState(true);

  const changePwdType = () => {
    setEyeIcon(isPassword ? "visibility" : "visibility-off");
    setIsPassword((prevState) => !prevState);
  };

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.textInput}
        {...rest}
        secureTextEntry={isPassword}
        placeholder={label}
      />
      <Icon
        style={styles.icon}
        name={eyeIcon}
        size={iconSize}
        color={iconColor}
        onPress={changePwdType}
      />
    </View>
  );
};

export default PasswordTextField;
