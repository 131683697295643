import React, { ReactElement } from 'react'
import { View, StyleSheet } from 'react-native'
import { Subheading, Checkbox } from 'react-native-paper'
import { colours } from '../styleguide'

interface OptionObject {
  label: string
  value: string
}

interface Props {
  title: string
  options: OptionObject[]
  value: string[]
  onPress: (option: string[]) => void
}

const ObjectMultipleSelectList = (props: Props): ReactElement => {
  const { title, options, value, onPress } = props

  const handleSelection = (option: OptionObject) => {
    const newValues = value.includes(option.value)
      ? value.filter(value => value !== option.value)
      : [...value, option.value]
    onPress(newValues)
  }

  return (
    <View>
      <Subheading>{title}</Subheading>
      {options.map(option => (
        <Checkbox.Item
          key={`${option.value}`}
          label={option.label}
          status={value.includes(option.value) ? 'checked' : 'unchecked'}
          onPress={() => handleSelection(option)}
          color={colours.primary}
          mode={'android'}
          style={styles.checkbox}
        />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  checkbox: {
    paddingHorizontal: 0,
  },
})

export default ObjectMultipleSelectList
