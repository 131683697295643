import { ApplicationStage } from '../../../types/application/application.types'
import { Collections } from '../../../types/firebase.types'
import { firestore } from '../firebase'

const getCouncilStages = async (selectedCouncil: string): Promise<ApplicationStage[]> => {
  const snapshot = await firestore
    .collection(Collections.COUNCIL_FORM_TEMPLATES)
    .where('council', '==', selectedCouncil)
    .get()

  const { stages } = snapshot.docs[0].data()

  return stages
}

export default getCouncilStages
