/**
 * A form field contains all the data necessary to ask and answer a question. See {@link AnyField} for the list of field types.
 * @module FormField
 */
import { Double } from 'react-native/Libraries/Types/CodegenTypes'
import { AnyValidator } from './validator.types'

/** The ms since the epoch (1970), or, if no time has been entered, null */
export type DateValue = number | null

/** The information needed to represent a file */
export interface FileValue {
  /** A value that can be used to display the file. In practice, this will be a URL, a base-64 encoded attribute which has not yet been submitted to the backend or a link to a file on the user's device */
  uri: string
  /** A External Document identifier if the file has been uploaded to Tabled, which can be used to identify the file **/
  externalDocumentId?: number
  /** A External Document name if the file has been uploaded to Tabled, which can be used to identify the file **/
  externalDocumentName?: string
  /** A Firebase document path if the file has been uploaded to firebase. **/
  firebaseStoragePath?: string
  /** A boolean flag indicating whether or not the document can be removed/deleted. **/
  canRemove?: boolean
  /** A the size of the file in MegaBytes. **/
  fileSize?: Double
}

/**
 * Any valid form field value. For greater specificity, look at the value attribute of specific kinds of form field.
 */
export type FormFieldValue = string | string[] | boolean | FileValue[] | DateValue

/**
 * An object determining which field and value must be met in order to display another field
 */
export interface AnswerValue {
  partnerField: string
  value: string
}
/**
 * Conditions on which a form field's appearance depend. If there are no appearanceConitions, the form field should always be rendered.
 */
export interface AppearanceConditions {
  positiveAnswer?: string[] | null
  dependants?: AnswerValue[]
}

/**
 * The valid types of form fields. Note these names are not TypeScript types. They are the possible values of {@link BasicField.type}, and differentiate non-basic fields from each other.
 */
export enum FormFieldTypes {
  MULTIPLE_SELECT = 'multiple-select',
  OBJECT_MULTIPLE_SELECT = 'option-multiple-select',
  CHECKBOX = 'checkbox',
  TEXT = 'text',
  PASSWORD_TEXT = 'password-text',
  TEXTAREA = 'textarea',
  IMAGE = 'image',
  IMAGE_SINGLE = 'image-single',
  DATE = 'date',
  RADIO = 'radio',
  TOGGLE = 'toggle',
}

export enum TabledFormFieldTypes {
  MULTIPLE_SELECT = 'multiple-selection',
  CHECKBOX = 'checkbox',
  TEXT = 'text',
  PASSWORD_TEXT = 'password-text',
  TEXTAREA = 'textarea',
  IMAGE = 'image',
  IMAGE_SINGLE = 'image-single',
  DATE = 'date',
  RADIO = 'radio',
  TOGGLE = 'toggle',
}

/**
 * The base interface for a field, used to extend other Field interfaces
 */
export interface BasicField {
  /** The order in which the field should appear in a form */
  index?: number
  /** An identifier created by whoever created the form template. Should be unique within the form. */
  identifier: string
  /** The type of this field.  */
  type: FormFieldTypes
  /** The current value of the field */
  value: FormFieldValue
  /** The current error message of the field, or an empty string */
  error: string
  /** The text used to describe the field */
  label: string
  /** A link for further information */
  link?: {
    url: string
    text: string
  }
  /** Is the field editable? */
  editable?: boolean
  /** Conditions on this field appearing */
  appearanceConditions?: AppearanceConditions
  /** A set of instructions about how to validate the field */
  validators: AnyValidator[]

  options?: string[]
}

export interface Dependency {
  id: number
  valueToMatch: string
}

export interface Validator {
  type: string
  value: string
}

export interface CustomField {
  /** The order in which the field should appear in a form */
  id: number
  /** An identifier created by whoever created the form template. Should be unique within the form. */
  name: string
  /** The type of this field.  */
  type: FormFieldTypes
  /** The current value of the field */
  value: FormFieldValue
  /** The text used to describe the field */
  label: string
  /** An object detailing a dependency for the field*/
  dependee?: Dependency[]
  /** An array of options for the field */
  options: string[]
  /** The index/position of the field in the form */
  position?: number
  /** An array containing any validators for the given field*/
  validators?: Validator[]
}

export interface MultipleSelectField extends BasicField {
  type: FormFieldTypes.MULTIPLE_SELECT
  /** The options from which the user can choose */
  options: string[]
  /** The options that the user has chosen */
  value: string[]
}

interface OptionObject {
  label: string
  value: string
}

export interface ObjectMultipleSelectField extends BasicField {
  /** The type of this field.  */
  type: FormFieldTypes.OBJECT_MULTIPLE_SELECT
  /** The options from which the user can choose */
  objectOptions: OptionObject[]
  /** The options that the user has chosen */
  value: string[]
}

export interface CheckboxField extends BasicField {
  type: FormFieldTypes.CHECKBOX
  /** Whether the user has checked the box */
  value: boolean
}

export interface TextField extends BasicField {
  type: FormFieldTypes.TEXT
  /** The text the use has entered, if any */
  value: string
  /** Placeholder text to display */
  placeholder?: string
  /** Whether or not the field should be uppercase */
  upperCase?: boolean
}

export interface PasswordTextField extends BasicField {
  type: FormFieldTypes.PASSWORD_TEXT
  /** The text the use has entered, if any */
  value: string
  /** Placeholder text to display */
  placeholder?: string
}

export interface TextAreaField extends BasicField {
  type: FormFieldTypes.TEXTAREA
  /** The text the use has entered, if any */
  value: string
  /** Placeholder text to display */
  placeholder?: string
}

export interface ImageUploadField extends BasicField {
  type: FormFieldTypes.IMAGE
  value: FileValue[]
  uploading?: boolean
}

export interface SingleImageUploadField extends BasicField {
  type: FormFieldTypes.IMAGE_SINGLE
  value: FileValue[]
  uploading?: boolean
}

export interface DateField extends BasicField {
  type: FormFieldTypes.DATE
  value: DateValue
}
export interface RadioField extends BasicField {
  type: FormFieldTypes.RADIO
  options: string[]
  value: string
}

export interface ToggleField extends BasicField {
  type: FormFieldTypes.TOGGLE
  value: boolean
}

export type AnyField =
  | MultipleSelectField
  | ObjectMultipleSelectField
  | CheckboxField
  | TextField
  | PasswordTextField
  | TextAreaField
  | ImageUploadField
  | SingleImageUploadField
  | DateField
  | RadioField
  | ToggleField
