import { AnyField } from '../../../../types/application/formField.types'
import { RequiredValidator } from '../../../../types/application/validator.types'
import doesFormValueExist from '../doesFormValueExist'

const validateRequiredField = (field: AnyField, validator: RequiredValidator): string => {
  const isValid = doesFormValueExist(field.value, field.type)
  return isValid ? '' : validator.failureMessage
}

export default validateRequiredField
