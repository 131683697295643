import doesFormValueExist from '../../hooks/useForm/helpers/doesFormValueExist'
import { AnyField, FormFieldTypes, ImageUploadField } from '../../types/application/formField.types'
import { storage } from '../firebase/firebase'

const unpackFilePaths = (fields: AnyField[]): string[] => {
  const fileFields = fields.filter(
    field =>
      (field.type === FormFieldTypes.IMAGE || field.type === FormFieldTypes.IMAGE_SINGLE) &&
      doesFormValueExist(field.value)
  ) as ImageUploadField[]

  return fileFields
    .flatMap(field => field.value)
    .map(file => file.firebaseStoragePath as string)
    .filter(path => !!path)
}

const deleteExpiredImages = async (originalFields: AnyField[], currentFields: AnyField[]): Promise<void> => {
  const originalPaths = unpackFilePaths(originalFields)

  const currentPaths = unpackFilePaths(currentFields)

  const expiredPaths = originalPaths.filter(url => !currentPaths.includes(url))

  await Promise.all(
    expiredPaths.map(path => {
      const ref = storage.ref(path)
      return ref.delete()
    })
  )
}

export default deleteExpiredImages
