import React, { ReactElement } from 'react'
import { HelperText } from 'react-native-paper'
import { FormFieldTypes, AnyField } from '../types/application/formField.types'
import ImageGrid from './ImageGrid'

interface Props {
  field: AnyField
}

const FormFieldValueSummary = (props: Props): ReactElement => {
  const { field } = props

  if (field.type === FormFieldTypes.IMAGE) {
    return <ImageGrid images={field.value} />
  }

  const determineFieldText = (field: AnyField) => {
    if (field.type === FormFieldTypes.MULTIPLE_SELECT) {
      return field.value.join(', ')
    }

    if (field.type === FormFieldTypes.CHECKBOX) {
      return field.value.toString() === 'true' ? 'Checked' : 'Unchecked'
    }

    if (field.type === FormFieldTypes.TEXT) {
      return field.value
    }

    if (field.type === FormFieldTypes.TEXTAREA) {
      return field.value
    }

    if (field.type === FormFieldTypes.DATE && typeof field.value === 'number') {
      return new Date(field.value).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })
    }

    if (field.type === FormFieldTypes.DATE && typeof field.value === 'string') {
      //@ts-expect-error TS not recognising field as an AnyField object
      return new Date(parseInt(field.value)).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })
    }

    if (field.type === FormFieldTypes.TOGGLE) {
      return field.value ? 'Yes' : 'No'
    }

    return ''
  }

  return <HelperText type="info">{determineFieldText(field) || 'No answer'}</HelperText>
}

export default FormFieldValueSummary
