import { ShallowApplication, ApplicationProperties } from '../../../types/application/application.types'
import { Collections } from '../../../types/firebase.types'
import { firestore } from '../firebase'

/**
 * These are values that may be returned from Tabled that we do not want to add/override in Firestore
 *
 * If any of the values are null/undefined it will cause an error
 * The `PAYMENT_AUTH_TOKEN` and `LICENCE_FEE` are not always returned, so we cannot set these null values in Firestore
 */
const ATTRIBUTES_TO_FILTER: string[] = [
  ApplicationProperties.ID,
  ApplicationProperties.LICENCE_FEE,
  ApplicationProperties.PAYMENT_AUTH_TOKEN,
  ApplicationProperties.WORKSPACE_ID,
]

const putApplication = (application: ShallowApplication): Promise<void> => {
  const applicationWithoutId = Object.fromEntries(
    Object.entries(application).filter(([key]) => !ATTRIBUTES_TO_FILTER.includes(key))
  )
  return firestore.collection(Collections.APPLICATIONS).doc(application.id).set(applicationWithoutId)
}

export default putApplication
