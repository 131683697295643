import { ApplicationProperties, ShallowApplicationStage } from '../../../types/application/application.types'
import { DocumentReference } from '../firebase.types'

const postApplicationStage = (
  applicationReference: DocumentReference,
  stageData: ShallowApplicationStage,
  index: number
): Promise<void> => applicationReference.collection(ApplicationProperties.STAGES).doc(index.toString()).set(stageData)

export default postApplicationStage
