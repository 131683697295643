import { useMemo } from 'react'
import { firestore } from '../helpers/firebase/firebase'
import { Collections } from '../types/firebase.types'
import { Council } from '../types/miscellaneous.types'

interface ReturnValue {
  getCouncils: Promise<Council[]>
}

const useFirebase = (): ReturnValue => {
  const getCouncils = useMemo(async (): Promise<Council[]> => {
    const res = await firestore.collection(Collections.COUNCIL_MARKETS).get()
    return res.docs.map(doc => {
      const docId = doc.id
      const { council, markets, workspaceId } = doc.data()
      return {
        councilName: council,
        markets: markets,
        workspaceId: workspaceId,
        documentId: docId,
      } as Council
    }, {})
  }, [])

  return { getCouncils }
}

export default useFirebase
