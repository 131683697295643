import { StackHeaderProps } from '@react-navigation/stack'
import React, { useState, ReactElement } from 'react'
import { Platform, Linking, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Icon } from 'react-native-elements'
import { Appbar, Divider, Menu, useTheme } from 'react-native-paper'
import calculateUnreadMessages from '../../helpers/calculateUnreadMessages'
import useOMLContext from '../../hooks/useOMLContext'
import { colours } from '../../styleguide'
import { NavAction, Pages } from '../../types/navigation.types'
import { UserProperties } from '../../types/user.types'
import ErrorMessage from '../ErrorMessage'
import MessageIcon from '../MessageIcon'

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    alignItems: 'center',
    elevation: 0,
    shadowOpacity: 0,
    backgroundColor: colours.transparent,
  },
  headerIcons: {
    flexDirection: 'row',
    display: 'flex',
    position: 'absolute',
    right: 5,

    alignItems: 'center',
  },
  titleContainer: {
    position: Platform.OS === 'ios' ? 'absolute' : 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    justifyContent: 'center',
  },
  titleColour: {
    color: colours.black,
  },
  backButton: {
    zIndex: +1,
  },
  menuContent: {
    paddingTop: 0,
  },
  menu: {
    backgroundColor: colours.transparent,
  },
  directoryActionItem: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: colours.primary,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    elevation: 1,
  },
  directoryTitle: {
    fontWeight: 'bold',
    color: colours.black,
  },
  navLink: {
    fontWeight: '500',
  },
})

interface Props {
  navigation: StackHeaderProps['navigation']
  back: StackHeaderProps['back']
  title: string
  navigationOptions: Pages[]
  actions: NavAction[]
  burgerVisible: boolean
  messagesVisible: boolean
}

const NavbarMobile = (props: Props): ReactElement => {
  const { navigation, back, title, navigationOptions, actions } = props
  const theme = useTheme()

  const [burgerVisible, setBurgerVisible] = useState(props.burgerVisible)
  const [messagesVisible] = useState(props.messagesVisible)
  const [showError, setShowError] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>('')

  const dismissErrorMessage = () => {
    setShowError(false)
  }

  const [context] = useOMLContext()

  const openMenu = () => setBurgerVisible(true)
  const closeMenu = () => setBurgerVisible(false)
  const performAction = (actionFunction: () => unknown) => {
    closeMenu()
    return actionFunction()
  }

  const directoryAction = () => {
    Linking.openURL('https://openmarkets.london/browse-markets/').catch(() => {
      setErrorText('Sorry! Something went wrong, try again later.')
      setShowError(true)
    })
  }

  const getIcon = (name: string): string => {
    switch (name) {
      case 'Applications':
        return 'home'
      case 'Messages':
        return 'message1'
      case 'Profile and account':
      case 'Register':
        return 'user'
      case 'Settings':
        return 'setting'
      case 'FAQ':
        return 'questioncircleo'
      case 'Log out':
        return 'logout'
      case 'Login':
        return 'login'
      case 'Recover account':
        return 'unlock'
      case 'Verify account':
        return 'checkcircleo'
      case 'Licences':
        return 'idcard'
      case 'Assistants':
        return 'adduser'
      default:
        return ''
    }
  }

  return (
    <View>
      <Appbar.Header style={styles.header}>
        {back && Platform.OS !== 'ios' ? (
          <Appbar.BackAction onPress={navigation.goBack} testID="backButton" style={styles.backButton} />
        ) : null}
        <View style={styles.titleContainer}>
          <Appbar.Content title={title} titleStyle={styles.titleColour} style={styles.title} />
        </View>
        {back && Platform.OS === 'ios' ? (
          <Appbar.BackAction onPress={navigation.goBack} testID="backButton" style={styles.backButton} />
        ) : null}
        <View style={styles.headerIcons}>
          {messagesVisible && (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(Pages.MY_MESSAGES)
              }}
            >
              <MessageIcon
                value={calculateUnreadMessages(context.externalUser?.[UserProperties.UNREAD_MESSAGES] ?? {})}
              />
            </TouchableOpacity>
          )}
          {!!navigationOptions.length || !!actions.length ? (
            <Menu
              visible={burgerVisible}
              onDismiss={closeMenu}
              anchor={
                <Appbar.Action icon="bars" color={theme.colors.onSurface} onPress={openMenu} testID="burgerButton" />
              }
              style={styles.menu}
              contentStyle={styles.menuContent}
            >
              <Menu.Item
                title={'Market Directory'}
                leadingIcon={() => <Icon name="isv" type="antdesign" color={colours.black} />}
                onPress={() => performAction(directoryAction)}
                key={`action-Market Directory`}
                style={styles.directoryActionItem}
                titleStyle={styles.directoryTitle}
              />
              <Divider />
              {navigationOptions.map(option => {
                const pageName: string = option
                return (
                  <Menu.Item
                    leadingIcon={() => (
                      <Icon
                        name={getIcon(option)}
                        type="antdesign"
                        color={title === option ? colours.primary : colours.black}
                      />
                    )}
                    title={option}
                    titleStyle={[styles.navLink, { color: title === option ? colours.primary : colours.black }]}
                    onPress={() => performAction(() => navigation.navigate(pageName))}
                    key={`page-${option}`}
                  />
                )
              })}
              {actions.map(action => (
                <Menu.Item
                  leadingIcon={() => (
                    <Icon
                      name={getIcon(action.title)}
                      type="antdesign"
                      color={title === action.title ? colours.primary : colours.black}
                    />
                  )}
                  title={action.title}
                  titleStyle={[styles.navLink, { color: title === action.title ? colours.primary : colours.black }]}
                  onPress={() => performAction(action.fn)}
                  key={`action-${action.title}`}
                />
              ))}
            </Menu>
          ) : null}
        </View>
      </Appbar.Header>
      <ErrorMessage errorMessage={errorText} showError={showError} onPress={dismissErrorMessage} />
    </View>
  )
}

NavbarMobile.defaultProps = {
  navigationOptions: [],
  actions: [],
  burgerVisible: false,
  messagesVisible: true,
}

export default NavbarMobile
