import {
  Application,
  ApplicationProperties,
  ApplicationStageProperties,
} from '../../../types/application/application.types'

import { Collections } from '../../../types/firebase.types'
import { firestore } from '../firebase'
import getApplication from './getApplication'
import getApplicationStageFields from './getApplicationStageFields'
import getApplicationStages from './getApplicationStages'

const getApplicationDeeply = async (applicationId: string): Promise<Application> => {
  const applicationReference = firestore.collection(Collections.APPLICATIONS).doc(applicationId)

  const shallowApplication = await getApplication(applicationReference)

  if (!shallowApplication) {
    throw new Error('Not found')
  }

  const shallowApplicationStages = await getApplicationStages(applicationReference)

  const applicationStages = await Promise.all(
    shallowApplicationStages.map(async (stage, index) => ({
      ...stage,
      [ApplicationStageProperties.FIELDS]: await getApplicationStageFields(applicationReference, index),
    }))
  )

  const application: Application = {
    ...shallowApplication,
    [ApplicationProperties.STAGES]: applicationStages,
  }

  return application
}

export default getApplicationDeeply
