/**
 * The Application module represents the progress of a user's application. It represents things like the questions they are asked, their answers and how their application has progressed. It culminates in the {@link Application} interface.
 *
 * @module Application
 */

import { AssistantSummaryProperties } from '../user.types'
import { AnyField } from './formField.types'

/**
 * The valid statuses of an appplication
 */
export enum ApplicationStatuses {
  DRAFT = 'draft',
  ARCHIVED = 'archived',
  PENDING = 'pending',
  UNDER_REVIEW = 'underReview',
  CHANGES_REQUIRED = 'changesRequired',
  PAYMENT_REQUESTED = 'paymentRequested',
  PAYMENT_COMPLETED = 'paymentCompleted',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum ApplicationStageProperties {
  TITLE = 'title',
  FIELDS = 'fields',
}

/**
 *  An {@link ApplicationStage} without its collections added on
 */
export interface ShallowApplicationStage {
  [ApplicationStageProperties.TITLE]: string
}

/**
 * An application stage is a part of an application. For instance, stages might be implemented as separate pages, to create a multi-step form.
 */
export interface ApplicationStage extends ShallowApplicationStage {
  [ApplicationStageProperties.FIELDS]: AnyField[]
}

/**
 * The properties of an {@link Application} object
 */
export enum ApplicationProperties {
  ID = 'id',
  APPLICANT = 'applicant',
  STATUS = 'status',
  COUNCIL = 'council',
  MARKETS = 'markets',
  STAGES = 'stages',
  EXTERNAL_ID = 'externalId',
  LICENCE_FEE = 'licenceFee',
  WORKSPACE_ID = 'workspaceId',
  ASSISTANTS = 'assistants',
  PAYMENT_AUTH_TOKEN = 'paymentAuthToken',
}

/**
 *  An {@link Application} without its collections added on
 */
export interface ShallowApplication {
  /** An ID given to the application, typically a Firebase ID */
  [ApplicationProperties.ID]?: string
  [ApplicationProperties.STATUS]: ApplicationStatuses
  /** The {@link User.id} of the creator of the application */
  [ApplicationProperties.APPLICANT]: string
  /** The name of the council the user is applying to */
  [ApplicationProperties.COUNCIL]: string
  /** The names of the council markets the user is applying to. Each market should belong to the council. */
  [ApplicationProperties.MARKETS]: string[]
  /** The user's application data */

  /** The externalId of the task created in Tabled */
  [ApplicationProperties.EXTERNAL_ID]: string

  /** The price of the licence associated with the application */
  [ApplicationProperties.LICENCE_FEE]?: number

  /** The ID of the workspace associated with the application */
  [ApplicationProperties.WORKSPACE_ID]?: number

  /** An auth token to authorise payment for a licence */
  [ApplicationProperties.PAYMENT_AUTH_TOKEN]?: string
}

export interface ApplicationAssistant {
  [AssistantSummaryProperties.ASSISTANT_ID]: number
  [AssistantSummaryProperties.FULL_NAME]: string
}

/**
 * A complete summary of a user's application to a council.
 */
export interface Application extends ShallowApplication {
  [ApplicationProperties.STAGES]: ApplicationStage[]
  [ApplicationProperties.ASSISTANTS]?: ApplicationAssistant[]
}

declare global {
  interface FormData {
    append(name: string, value: FormDataValue, fileName?: string): void
    set(name: string, value: FormDataValue, fileName?: string): void
  }
}
