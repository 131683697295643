import { ApplicationProperties, ApplicationStageProperties } from '../../../types/application/application.types'

import { AnyField } from '../../../types/application/formField.types'
import { DocumentReference } from '../firebase.types'

const getApplicationStageFields = async (
  applicationReference: DocumentReference,
  stageIndex: number
): Promise<AnyField[]> => {
  const querySnapshot = await applicationReference
    .collection(ApplicationProperties.STAGES)
    .doc(stageIndex.toString())
    .collection(ApplicationStageProperties.FIELDS)
    .get()

  return querySnapshot.docs.map(doc => doc.data() as AnyField)
}

export default getApplicationStageFields
