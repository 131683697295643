/* eslint-disable react-hooks/exhaustive-deps */
import { useFocusEffect } from '@react-navigation/native'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { Button, IconButton } from 'react-native-paper'
import { ERRORS } from '../../constants'
import getApplication from '../helpers/api/getApplication'
import getApplications from '../helpers/api/getApplications'
import postErrorMessageToSlack from '../helpers/api/postErrorMessageToSlack'
import getApplicationDeeply from '../helpers/firebase/firestore/getApplicationDeeply'
import getFirebaseApplications from '../helpers/firebase/firestore/getFirebaseApplications'
import putApplication from '../helpers/firebase/firestore/putApplication'
import useOMLContext from '../hooks/useOMLContext'
import ApplicationsBrowser from '../molecules/ApplicationsBrowser'
import ErrorCard from '../molecules/ErrorCard'
import { colours } from '../styleguide'
import BannerTemplate from '../templates/BannerTemplate'
import {
  Application,
  ApplicationProperties,
  ApplicationStatuses,
  ShallowApplication,
} from '../types/application/application.types'

import { Pages, ScreenProps } from '../types/navigation.types'
import { UserProperties } from '../types/user.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    maxWidth: '100%',
    width: 500,
    backgroundColor: colours.backgroundGrey,
  },
  bottomButton: {
    marginTop: 20,
    backgroundColor: colours.primary,
  },
  title: {
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleContainer: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: '5%',
    alignItems: 'center',
  },
  newApplicationBtnContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    shadowOffset: Platform.OS === 'web' ? { width: 0, height: 0 } : { width: 0, height: 1 },
    shadowOpacity: 0.2,
    elevation: Platform.OS === 'android' ? 5 : 1,
  },
  newApplicationBtn: {
    borderRadius: 50,
    backgroundColor: colours.primary,
    elevation: Platform.OS === 'android' ? 5 : 1,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.1,
  },
})

interface Props extends ScreenProps<Pages.APPLICATIONS> {
  applications: Application[]
  showBanner: boolean
  loading: boolean
  error: string
}

const HomeScreen = (props: Props): ReactElement => {
  const PAGE_SIZE = 100

  const { navigation } = props

  const [showBanner, setShowBanner] = useState<boolean>(props.showBanner)
  const [loading, setLoading] = useState<boolean>(props.loading)
  const [error, setError] = useState<string>(props.error)

  const [context, setContext] = useOMLContext()

  const [applications, setApplications] = useState<Application[]>(props.applications ?? [])
  const [visibleApplications, setVisibleApplications] = useState<Application[]>(props.applications ?? [])
  const [currentPage, setCurrentPage] = useState<number>(0)

  navigation.setOptions({ headerShown: !loading })

  const loadApplications = async (firebaseUid: string, externalUserId: number) => {
    setLoading(true)
    getApplications(firebaseUid, externalUserId)
      .then(async tabledApplications => {
        const updatedApplications = [...new Set([...applications, ...tabledApplications])]
        const filteredApplications = [...new Map(updatedApplications.map(item => [item['id'], item])).values()]
        setApplications(filteredApplications)

        await getFirebaseApplications(context.firebaseUser?.uid ?? '').then(draftApplications => {
          const updatedFirebaseApplications = [...new Set([...applications, ...draftApplications])]
          const filteredFirebaseApplications = [
            ...new Map(updatedFirebaseApplications.map(item => [item['id'], item])).values(),
          ]
          setApplications(filteredFirebaseApplications)
        })

        setLoading(false)
      })
      .catch(err => {
        if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
          setContext({
            ...context,
            error: ERRORS.UPGRADE_REQUIRED,
          })
        } else {
          setError('Failed to load applications. Please, try again later.')
          setShowBanner(true)
        }
        return
      })
  }

  useFocusEffect(
    useCallback(() => {
      if (!context.firebaseUser?.uid || !context.externalUser || context.fetchApplications) {
        return
      }
      setLoading(true)

      loadApplications(context.firebaseUser?.uid, context.externalUser[UserProperties.EXTERNAL_USER_ID])
    }, [context.firebaseUser?.uid, context.externalUser?.[UserProperties.EXTERNAL_USER_ID]])
  )

  useEffect(() => {
    if (!context.firebaseUser?.uid || !context.externalUser) {
      return
    }

    if (context.fetchApplications) {
      loadApplications(context.firebaseUser?.uid, context.externalUser[UserProperties.EXTERNAL_USER_ID]).then(() => {
        setContext({
          ...context,
          fetchApplications: false,
        })
      })
    }
  }, [context.fetchApplications])

  useEffect(() => {
    if (applications) {
      const sortedApplications = applications.sort((a, b) =>
        a[ApplicationProperties.COUNCIL].localeCompare(b[ApplicationProperties.COUNCIL])
      )

      const paymentRequiredApplications = sortedApplications.filter(
        a => a[ApplicationProperties.STATUS] === ApplicationStatuses.PAYMENT_REQUESTED
      )
      const draftApplications = sortedApplications.filter(
        a => a[ApplicationProperties.STATUS] == ApplicationStatuses.DRAFT
      )
      const otherApplications = sortedApplications.filter(
        a =>
          ![ApplicationStatuses.PAYMENT_REQUESTED, ApplicationStatuses.DRAFT].includes(a[ApplicationProperties.STATUS])
      )

      setVisibleApplications(
        [...paymentRequiredApplications, ...otherApplications, ...draftApplications].slice(
          0,
          (currentPage + 1) * PAGE_SIZE
        )
      )
    }
  }, [applications, currentPage])

  const fetchNextPage = async () => {
    if (!context.firebaseUser?.uid) {
      return
    }

    if (applications) {
      if (applications.length < (currentPage + 1) * PAGE_SIZE) {
        return
      }

      setCurrentPage(currentPage + 1)
    }
  }

  const addApplication = () => {
    if (
      applications.filter(application => application[ApplicationProperties.STATUS] === ApplicationStatuses.DRAFT)
        ?.length >= 15
    ) {
      setError('You have too many draft applications!')
      setShowBanner(true)
      return
    }

    setContext({
      ...context,
      currentApplication: null,
    })
    navigation.navigate(Pages.NEW_APPLICATION)
  }

  const goToApplication = async (shallowApplication: ShallowApplication) => {
    setLoading(true)
    setShowBanner(false)
    if (!shallowApplication.id) {
      setLoading(false)
      setShowBanner(true)
      setError('This application cannot be displayed')
      return
    }

    let doesApplicationExist = true

    if (shallowApplication.id && shallowApplication.status !== ApplicationStatuses.DRAFT) {
      const updatedApplication = await getApplication(
        context.firebaseUser?.uid || '',
        shallowApplication.externalId
      ).catch(err => {
        if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
          setContext({
            ...context,
            error: ERRORS.UPGRADE_REQUIRED,
          })
        } else if (err.message === ERRORS.NOT_FOUND.message) {
          doesApplicationExist = false
          setError('This application does not exit.')
          setShowBanner(true)
        } else {
          setError('Failed to load application. Please, try again later.')
          setShowBanner(true)
        }
        return
      })

      if (!doesApplicationExist) {
        const notFoundApplicationError = `Application could not be found for id ${shallowApplication.id}. The status will be set to DRAFT. (HomeScreen.tsx)`
        postErrorMessageToSlack(
          new Error(notFoundApplicationError),
          '',
          `Tabled user ${context.externalUser?.[UserProperties.EXTERNAL_USER_ID]}`
        )

        // If application doesn't exist in Tabled, we change the status to DRAFT
        const currentApplication = await getApplicationDeeply(shallowApplication.id)

        currentApplication.status = ApplicationStatuses.DRAFT
        await putApplication(currentApplication)

        const currentApplications = applications.map(application => {
          if (application.id === currentApplication.id) {
            return {
              ...application,
              [ApplicationProperties.STATUS]: ApplicationStatuses.DRAFT,
            }
          }

          return application
        })

        setApplications([...currentApplications])
        setLoading(false)
        return
      }

      if (updatedApplication) {
        navigation.navigate(Pages.APPLICATION_SUMMARY, {
          applicationId: updatedApplication.externalId,
        })
      }
    } else {
      const currentApplication = await getApplicationDeeply(shallowApplication.id)

      if (!currentApplication.id) {
        setError('Failed to load application. Please, try again later.')
        setShowBanner(true)
        setLoading(false)
        return
      }

      navigation.navigate(Pages.APPLICATION_SUMMARY, {
        applicationId: currentApplication.id,
      })
    }
    setLoading(false)
  }

  const dismissErrorMessage = () => {
    setShowBanner(false)
  }

  const countApplicationsWithPaymentPending = () => {
    return applications.filter(application => application.status === ApplicationStatuses.PAYMENT_REQUESTED).length
  }

  return (
    <BannerTemplate
      testID="HomeScreen"
      loading={loading}
      showBanner={showBanner}
      bannerText={error}
      atBottom={fetchNextPage}
      dismissError={dismissErrorMessage}
    >
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>Your Licence Applications</Text>
          <View style={styles.newApplicationBtnContainer}>
            <IconButton
              testID="addApplicationBtn"
              style={styles.newApplicationBtn}
              icon="plus"
              size={25}
              onPress={addApplication}
            />
          </View>
        </View>
        {countApplicationsWithPaymentPending() > 0 && (
          <ErrorCard
            title="Payment Required"
            bodyText={`You have ${countApplicationsWithPaymentPending()} licence ${
              countApplicationsWithPaymentPending() === 1 ? 'payment' : 'payments'
            } pending.`}
          />
        )}
        {visibleApplications && (
          <ApplicationsBrowser
            applications={visibleApplications}
            fetchNextPage={fetchNextPage}
            goToApplication={goToApplication}
          />
        )}
        <Button
          icon="plus"
          mode="elevated"
          textColor={colours.black}
          style={styles.bottomButton}
          onPress={addApplication}
        >
          Create Application
        </Button>
      </View>
    </BannerTemplate>
  )
}

HomeScreen.defaultProps = {
  applications: null,
  showBanner: false,
  loading: true,
  error: '',
}
export default HomeScreen
