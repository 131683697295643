/* eslint-disable react-native/no-inline-styles */
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useHeaderHeight } from "@react-navigation/elements";
import React, { createRef, ReactElement, useEffect, useState } from "react";
import {
  ActivityIndicator,
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  View,
  Text,
} from "react-native";
import {ScrollView, TouchableOpacity} from "react-native-gesture-handler";
import {TextInput} from "react-native-paper";
import { ERRORS } from "../../constants";
import patchMessages from "../helpers/api/patchMessages";
import postMessage from "../helpers/api/postMessage";
import signOutUser from "../helpers/firebase/auth/signOutUser";
import useFirebase from "../hooks/useFirebase";
import useOMLContext from "../hooks/useOMLContext";
import ErrorMessage from "../molecules/ErrorMessage";
import MessageConversation from "../molecules/MessageConversation";
import { colours } from "../styleguide";
import { Council} from "../types/miscellaneous.types";
import { Pages, ScreenProps } from "../types/navigation.types";
import { UserProperties, Message, Messages} from "../types/user.types";

const styles = StyleSheet.create({
  messageScreen: {
    backgroundColor: colours.backgroundGrey
  },
  container: {
    height: "100%",
    width: "100%",
  },
  conversation: {
    height: "100%",
    width: "100%",
  },
  keyboardWrapper: {
    flex: 1
  },
  inputOuterContainer: {
    width: "100%",
    alignItems: "center",
  },
  inputInnerContainer: {
    width: "100%",
    maxWidth: Platform.OS === "web" ? 500 : "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: Platform.OS === "web" ? colours.backgroundGrey : colours.darkGrey,
    borderBottomColor: colours.backgroundGrey,
  },
  inputInnerContainerKeyboardVisible: {
    paddingBottom: Platform.OS === "web" ? 30 : 0,
    height: Platform.OS === "web" ? 80 : 50,
    marginBottom: Platform.OS === "android" ? 25 : 0
  },
  inputInnerContainerKeyboardNotVisible: {
    paddingBottom: 30,
    height: 80
  },
  textInput: {
    flex: 1,
    height: 30,
    flexGrow: 1,
    borderRadius: 50,
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
  },
  scrollView: {
    flex: 1,
    width: "100%",
  },
  sendBtn: {
    height: 60,
    width: 40,
    alignItems: "center",
    justifyContent: "center"
  },
  scrollViewSpacer: {
    height: 10
  },
  councilNameContainer: {
    width: "100%",
    alignItems: "center",
    paddingBottom: 10,
  },
  councilName: {
    fontSize: 20,
    fontWeight: "500",
  },
});

const MessageConversationScreen = (
  props: ScreenProps<Pages.MESSAGE>
): ReactElement => {
  const {route, navigation} = props;
  const [showBanner] = useState<boolean>(false);
  const [loading] = useState<boolean>();
  const [bannerText] = useState<string>("");
  const [placeholder, setPlaceholder] = useState<string>("Write a message...");

  const [messages, setMessages] = useState<Message[]>([]);

  const [newMessage, setNewMessage] = useState<string>();
  const [context, setContext] = useOMLContext();
  
  const [council, setCouncil] = useState<Council>(route.params.council);
  const externalTaskId = route.params.externalTaskId;

  const [keyboardVisible, setKeyboardVisible] = useState<boolean>(false);
  const [showCouncilNameTitle, setShowCouncilNameTitle] = useState<boolean>(Dimensions.get('window').width > 1080);

  const headerHeight = useHeaderHeight();
  const {getCouncils} = useFirebase();

  useEffect(() => {
    readMessages();
  }, [
    context.externalUser?.[UserProperties.UNREAD_MESSAGES]?.[externalTaskId] ?? 0 > 0
  ]);

  useEffect(() => {
    setMessages(
      context.externalUser?.[UserProperties.MESSAGES]?.[externalTaskId] ?? []
    )
    if (!council) {
      getCouncils.then((councils: Council[]) => {
        if (messages.length) {
          const targetCouncil = councils.find(c => c.workspaceId === messages[0].workspace)
          if (targetCouncil) {
            navigation.setParams({ title: targetCouncil.councilName })
            setCouncil(targetCouncil);
          }
        }
      })
    }
  }, [context.externalUser?.[UserProperties.MESSAGES]?.[externalTaskId]])

  const readMessages = async () => {
    if (!context.externalUser) {
      return;
    }

    if (
      (context.externalUser?.[UserProperties.MESSAGES]?.[externalTaskId]
        ?.length ?? 0) == 0
    ) {
      return;
    }

    await patchMessages(
      context.externalUser[UserProperties.EXTERNAL_USER_ID],
      externalTaskId
    ).then(() => {
      if (!context.externalUser) {
        return;
      }

      const patchedMessages: Message[] = [] as Message[];
      Object.values(
        context.externalUser[UserProperties.MESSAGES][
          externalTaskId as keyof Messages
          ]
      ).forEach((message) => {
        if (!message.hasSeenDate) {
          message.hasSeenDate = Date.now();
        }

        patchedMessages.push(message);
      });

      //Let's also update this via context call so other elements can render.
      setContext({
        ...context,
        externalUser: {
          ...context.externalUser,
          [UserProperties.MESSAGES]: {
            ...context.externalUser[UserProperties.MESSAGES],
            [externalTaskId]: patchedMessages
          },
          [UserProperties.UNREAD_MESSAGES]: {
            ...context.externalUser?.[UserProperties.UNREAD_MESSAGES],
            [externalTaskId]: 0
          }
        }
      });
    }).catch(err => {
      if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
        setContext({
          ...context,
          error: ERRORS.UPGRADE_REQUIRED
        })
      }
    });
  };

  const sendMessage = async () => {
    if (!context.externalUser) {
      return;
    }

    if (newMessage && newMessage.length > 0) {
      await postMessage(
        context.externalUser?.[UserProperties.EXTERNAL_USER_ID] ?? -1,
        newMessage,
        council.workspaceId,
        externalTaskId
      ).then(response => {
        if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
          setContext({
            ...context,
            error: ERRORS.UPGRADE_REQUIRED
          })
        } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
          signOutUser()
        }
      })

      setNewMessage("");
    }
  };

  const scrollToBottom = () => {
    if (scrollViewRef !== null && scrollViewRef.current !== null) {
      scrollViewRef.current.scrollToEnd({animated: true});
    }
  };

  const handleKeyPress = (key: string) => {
    if (key === "Enter") {
      sendMessage();
    }
  };


  useEffect(() => {
    function handleResize() {
      if (Platform.OS === "web") {
        setShowCouncilNameTitle(Dimensions.get('window').width >= 1080)
      }
    }

    if (Platform.OS === "web") {
      window.addEventListener('resize', handleResize)
    }
  })

  const scrollViewRef = createRef<ScrollView>();
  return (
    <View
      testID="MessageConversationScreen"
      style={[styles.messageScreen, Platform.OS === "web" ? {height: Dimensions.get("window").height - headerHeight} : { height: '100%' }]}
    >
      <ErrorMessage errorMessage={bannerText} showError={showBanner}/>
      {loading ? (
        <ActivityIndicator size="large" animating={true}/>
      ) : (
        <View style={styles.container}>
          <KeyboardAvoidingView
            keyboardVerticalOffset={headerHeight}
            behavior={Platform.OS === "ios" ? "padding" : "height"}
            enabled
            style={styles.keyboardWrapper}
          >
            <View style={styles.conversation}>
              {showCouncilNameTitle && (
                <View style={styles.councilNameContainer}>
                  <Text style={styles.councilName}>{council.councilName}</Text>
                </View>
              )}
              {messages && (
                <ScrollView
                  scrollIndicatorInsets={{ right: 1 }}
                  style={styles.scrollView}
                  ref={scrollViewRef}
                  onContentSizeChange={() => {
                    scrollToBottom();
                  }}
                >
                  <MessageConversation messages={messages}/>
                  <View style={styles.scrollViewSpacer}/>
                </ScrollView>
              )}
              <View style={styles.inputOuterContainer}>
                <View
                  style={[
                    styles.inputInnerContainer,
                    keyboardVisible
                      ? styles.inputInnerContainerKeyboardVisible
                      : styles.inputInnerContainerKeyboardNotVisible
                  ]}
                >
                  <TextInput
                    onFocus={() => {
                      setKeyboardVisible(true);
                      setPlaceholder("");
                    }}
                    onBlur={() => {
                      setKeyboardVisible(false);
                      setPlaceholder("Write a message...");
                    }}
                    style={styles.textInput}
                    placeholder={placeholder}
                    activeUnderlineColor={"transparent"}
                    underlineColor={"transparent"}
                    underlineColorAndroid={"transparent"}
                    value={newMessage}
                    onChangeText={(text) => setNewMessage(text)}
                    selectionColor={colours.darkGrey}
                    onKeyPress={(e) => handleKeyPress(e.nativeEvent.key)}
                  />
                  <View style={styles.sendBtn}>
                    <TouchableOpacity onPress={() => sendMessage()}>
                      <MaterialCommunityIcons
                        name="send-circle"
                        color={colours.primary}
                        size={40}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </KeyboardAvoidingView>
        </View>
      )}
    </View>
  );
};

MessageConversationScreen.defaultProps = {
  councilName: "",
  workspaceId: null,

  showBanner: false,
  loading: true,
  error: ""
};

export default MessageConversationScreen;
