import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { Messages } from '../../types/user.types'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

const getLatestMessages = async (externalUserId: number, createdSinceEpoch: number): Promise<Messages | null> => {
  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const response = await fetch(
    new Request(
      `${Constants?.expoConfig?.extra?.hostname}/ext/external-users/${externalUserId}/messages?createdSince=${createdSinceEpoch}`,
      {
        method: 'GET',
        headers,
      }
    )
  )
  if (response.status === 200) {
    return await response.json()
  } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
    throw new Error(ERRORS.UPGRADE_REQUIRED.message)
  } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
    await signOutUser()
  }

  return null
}

export default getLatestMessages
