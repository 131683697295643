import Constants from 'expo-constants'
import { Platform } from 'react-native'
import { ERRORS } from '../../../constants'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

const postDeviceToken = async (externalUserId: number, deviceToken: string): Promise<void> => {
  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const formData = new FormData()
  formData.append('deviceToken', deviceToken)
  formData.append('deviceType', Platform.OS)

  await fetch(
    new Request(`${Constants?.expoConfig?.extra?.hostname}/ext/external-users/${externalUserId}/device-token`, {
      method: 'POST',
    }),
    {
      body: formData,
      headers,
    }
  ).then(async response => {
    if (response.status === 200) {
      return
    } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
      throw new Error(ERRORS.UPGRADE_REQUIRED.message)
    } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
      await signOutUser()
    }
  })
}

export default postDeviceToken
