import React, { ReactElement } from 'react'
import { Modal, StyleSheet, Text, View } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { Card, Button } from 'react-native-paper'
import { colours } from '../styleguide'

const styles = StyleSheet.create({
  absolute: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: colours.black,
    opacity: 0.5,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  animatableContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  animatable: {
    marginTop: 10,
    height: 100,
  },
  containerOuterError: {
    zIndex: 1,
    borderWidth: 1,
    borderColor: colours.errorPrimary,
    backgroundColor: colours.errorSecondary,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  containerOuter: {
    zIndex: 1,
    borderWidth: 1,
    borderColor: colours.primary,
    backgroundColor: colours.secondary,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  containerInner: {
    padding: '5%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerInnerError: {
    padding: '5%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  error: {
    color: colours.errorPrimary,
    textAlign: 'center',
    fontWeight: '500',
  },
  buttonError: {
    marginTop: 10,
    backgroundColor: colours.errorPrimary,
  },
  buttonTextError: {
    color: colours.errorSecondary,
    fontWeight: '500',
  },
  message: {
    color: colours.primary,
    textAlign: 'center',
    fontWeight: '500',
  },
  button: {
    marginTop: 10,
    backgroundColor: colours.primary,
  },
  buttonText: {
    color: colours.secondary,
    fontWeight: '500',
  },
})

interface Props {
  errorMessage: string
  showError: boolean
  onPress?: () => void
  isNotError?: boolean
}

const MessageSummary = (props: Props): ReactElement => {
  const { errorMessage, showError, onPress, isNotError } = props

  return (
    <Modal testID="errorMessage" animationType="fade" transparent={true} visible={showError}>
      <View style={styles.absolute}></View>
      <View style={styles.animatableContainer}>
        <Animatable.View animation="fadeInUpBig" style={styles.animatable}>
          <Card style={isNotError ? styles.containerOuter : styles.containerOuterError}>
            <View style={isNotError ? styles.containerInner : styles.containerInnerError}>
              <Text style={isNotError ? styles.message : styles.error}>{errorMessage}</Text>
              <Button
                mode="elevated"
                textColor={isNotError ? colours.black : colours.white}
                uppercase={false}
                style={isNotError ? styles.button : styles.buttonError}
                onPress={onPress}
              >
                <Text style={isNotError ? styles.buttonText : styles.buttonTextError}>Close</Text>
              </Button>
            </View>
          </Card>
        </Animatable.View>
      </View>
    </Modal>
  )
}

export default MessageSummary
