import { AnyField, FormFieldTypes } from '../types/application/formField.types'
import { ValidatorTypes } from '../types/application/validator.types'

export const logInForm: AnyField[] = [
  {
    type: FormFieldTypes.TEXT,
    identifier: 'email',
    label: 'Email',
    validators: [
      {
        type: ValidatorTypes.REQUIRED,
        failureMessage: 'An email is required',
      },
    ],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.PASSWORD_TEXT,
    identifier: 'password',
    label: 'Password',
    validators: [
      {
        type: ValidatorTypes.REQUIRED,
        failureMessage: 'A password is required',
      },
    ],
    error: '',
    value: '',
  },
]
