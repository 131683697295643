import React, {ReactElement} from "react";
import {View, StyleSheet, Text} from "react-native";
import {Icon} from "react-native-elements";
import { colours } from "../styleguide";


const circleColor = 'red';
const textColor = "white";

const styles = StyleSheet.create({
    parent: {
        position: "relative",
    },
    circle: {
        minWidth: 16,
        minHeight: 16,
        justifyContent: "center",
        borderRadius: 50,
        aspectRatio: 1,
        backgroundColor: circleColor,
        position: "absolute",
        left: 12,
        bottom: 12,
    },
    text: {
        justifyContent: "center",
        color: textColor,
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 10,
    }
});

interface Props {
    value: number,
}

const MessageIcon = ({value}: Props): ReactElement => {
    return (
        <View style={styles.parent}>
            <Icon
                name="message1"
                type="antdesign"
                size={20}
                color={colours.black}
            />

            {value > 0 &&
                <View style={styles.circle}>
                    <Text
                        style={styles.text}
                    >
                        {value}
                    </Text>
                </View>
            }
        </View>
    );
};

export default MessageIcon;
