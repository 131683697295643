import React, { ReactElement } from 'react'
import { StyleSheet, View, Platform, Text } from 'react-native'
import { Icon } from 'react-native-elements'
import { colours } from '../styleguide'

const styles = StyleSheet.create({
  paymentRequiredContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colours.status.paymentRequested,
    padding: 10,
    borderRadius: 5,
    shadowColor: colours.status.paymentRequested,
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.5,
    shadowRadius: 10,
    elevation: 34,
    marginBottom: 20,
  },
  paymentRequiredContent: {
    width: '95%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  paymentRequiredTextContainer: {
    width: Platform.OS === 'web' ? '100%' : '95%',
    marginLeft: 10,
  },
  paymentRequiredText: {
    color: colours.white,
  },
  paymentRequiredTitle: {
    fontSize: 15,
    fontWeight: '600',
  },
})

interface Props {
  title: string
  bodyText: string
}

const ErrorCard = (props: Props): ReactElement => {
  const { title, bodyText } = props

  return (
    <View style={styles.paymentRequiredContainer}>
      <View style={styles.paymentRequiredContent}>
        <Icon name="exclamationcircleo" type="antdesign" color={colours.white} />
        <View style={styles.paymentRequiredTextContainer}>
          <Text style={[styles.paymentRequiredText, styles.paymentRequiredTitle]}>{title}</Text>
          <Text style={styles.paymentRequiredText}>{bodyText}</Text>
        </View>
      </View>
    </View>
  )
}

export default ErrorCard
