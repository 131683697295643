
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import React, { ReactElement, useState } from "react";
import { View, StyleSheet, SafeAreaView, Text } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { ERRORS } from "../../constants";
import patchApplication from "../helpers/api/patchApplication";
import postApplication from "../helpers/api/postApplication";
import signOutUser from "../helpers/firebase/auth/signOutUser";
import { FirebaseAuthUser } from "../helpers/firebase/firebase.types";
import useOMLContext from "../hooks/useOMLContext";
import { colours } from "../styleguide";
import {
  Application,
  ApplicationProperties,
  ApplicationStatuses,
} from "../types/application/application.types";
import {
  Pages,
  RootStackParamList,
  ScreenProps,
} from "../types/navigation.types";
import {User} from '../types/user.types'
import MyDetailsScreen from "./MyDetailsScreen";
import MyDocumentsScreen from "./MyDocumentsScreen";

const screenOptions = {
  tabBarStyle: {
    backgroundColor: colours.backgroundGrey,
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  innerContainer: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 500,
    marginBottom: "5%",
    justifyContent: "center",
  },
  outerContainer: {
    flexGrow: 1,
    width: "100%",
    alignItems: "center",
  },
  loadingText: {
    width: "100%",
    textAlign: "center",
    color: colours.description,
    opacity: 0.7,
    marginTop: 10,
  },
});

const Tab = createMaterialTopTabNavigator<RootStackParamList>();

const MissingProfileInformationScreen = (
  props: ScreenProps<Pages.MISSING_PROFILE_INFORMATION>
): ReactElement => {
  const { navigation } = props;
  const { application } = props.route.params;
  const [loading, setLoading] = useState<boolean>(false);

  const [context, setContext] = useOMLContext();

  const goToSuccessPage = async (application: Application) => {
    setLoading(true)
    if (
      application[ApplicationProperties.STATUS] ===
        ApplicationStatuses.CHANGES_REQUIRED
    ) {
      await patchApplication(
        application,
        application.stages,
        context.externalUser ? context.externalUser : ({} as User),
        context.firebaseUser ? context.firebaseUser : ({} as FirebaseAuthUser)
      ).then(({ newStages, status }) => {
        application.stages = newStages;
        navigation.reset({
          index: 0,
          routes: [{ name: Pages.FINALISE, params: { application, status, submitApplicationForm: goToSuccessPage } }],
        });
      }).catch(err => {
        if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
          setContext({
            ...context,
            error: ERRORS.UPGRADE_REQUIRED
          })
        } else if (err.message === ERRORS.TOKEN_EXPIRED.message) {
          signOutUser()
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: Pages.FINALISE, params: { application, didSubmissionSucceed: false, submitApplicationForm: goToSuccessPage } }],
          });
          setLoading(false);
        }
      });
    } else {
      await postApplication(
        application,
        application.stages,
        context.externalUser ? context.externalUser : ({} as User),
        context.firebaseUser ? context.firebaseUser : ({} as FirebaseAuthUser),
        true
      ).then(({ newStages, status }) => {
        application.stages = newStages;
        navigation.reset({
          index: 0,
          routes: [{ name: Pages.FINALISE, params: { application, status, submitApplicationForm: goToSuccessPage } }],
        });
      }).catch(err => {
        if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
          setContext({
            ...context,
            error: ERRORS.UPGRADE_REQUIRED
          })
        } else if (err.message === ERRORS.TOKEN_EXPIRED.message) {
          signOutUser()
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: Pages.FINALISE, params: { application, didSubmissionSucceed: false, submitApplicationForm: goToSuccessPage } }],
          });
          setLoading(false);
        }
      });
    }
  };

  return (
    <View style={styles.container}>
      {loading ? (
      <SafeAreaView testID="Loading">
        <ActivityIndicator size="large" animating={true} />
        <Text style={styles.loadingText}>Loading</Text>
      </SafeAreaView>
      ) : 
      (
      <View style={styles.outerContainer}>
        <View style={styles.innerContainer}>
          <Tab.Navigator {...{ screenOptions }}>
            <Tab.Screen name={Pages.MY_DETAILS}>
              {(props) => (
                <MyDetailsScreen
                  {...props}
                  onExit={() => navigation.navigate(Pages.MY_DOCUMENTS)}
                  enforceValidation={true}
                  requireInput={true}
                />
              )}
            </Tab.Screen>
            <Tab.Screen name={Pages.MY_DOCUMENTS}>
              {(props) => (
                <MyDocumentsScreen
                  {...props}
                  onExit={() => goToSuccessPage(application)}
                  enforceValidation={true}
                  requireInput={true}
                />
              )}
            </Tab.Screen>
          </Tab.Navigator>
        </View>
      </View>
      )}
    </View>
  );
};

MissingProfileInformationScreen.defaultProps = {};

export default MissingProfileInformationScreen;
