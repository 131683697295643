import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import React, { ReactElement } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { colours } from '../styleguide'
import { Pages, RootStackParamList, ScreenProps } from '../types/navigation.types'
import MyDetailsScreen from './MyDetailsScreen'
import MyDocumentsScreen from './MyDocumentsScreen'

const styles = StyleSheet.create({
  innerContainer: {
    flexGrow: 1,
    width: '100%',
    maxWidth: 500,
    marginBottom: '5%',
    justifyContent: 'center',
  },
  outerContainer: {
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
  },
})

const ProfileAndAccountScreen = (props: ScreenProps<Pages.PROFILE_AND_ACCOUNT>): ReactElement => {
  const { navigation, route } = props

  const screenOptions = {
    title: Platform.OS === 'web' ? `OpenMarkets.London - ${route.name}` : undefined,
    tabBarStyle: {
      borderRadius: Platform.OS === 'web' ? 1 : 0,
      borderColor: colours.darkGrey,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      backgroundColor: colours.backgroundGrey,
    },
    swipeEnabled: Platform.OS === 'web' ? false : true,
  }

  const Tab = createMaterialTopTabNavigator<RootStackParamList>()

  return (
    <View style={styles.outerContainer}>
      <View style={styles.innerContainer}>
        <Tab.Navigator {...{ screenOptions }}>
          <Tab.Screen
            name={Pages.MY_DETAILS}
            options={() => ({
              tabBarLabel: 'My details',
            })}
          >
            {props => (
              <MyDetailsScreen
                {...props}
                onExit={() => {
                  navigation.navigate(Pages.MY_DOCUMENTS)
                  if (Platform.OS === 'web') {
                    window.scrollTo(0, 0)
                  }
                }}
                requireInput={false}
                enforceValidation={true}
              />
            )}
          </Tab.Screen>
          <Tab.Screen
            name={Pages.MY_DOCUMENTS}
            options={() => ({
              tabBarLabel: 'My documents',
            })}
          >
            {props => (
              <MyDocumentsScreen
                {...props}
                onExit={() => {
                  navigation.navigate(Pages.APPLICATIONS)
                  if (Platform.OS === 'web') {
                    window.scrollTo(0, 0)
                  }
                }}
                enforceValidation={false}
                requireInput={false}
              />
            )}
          </Tab.Screen>
        </Tab.Navigator>
      </View>
    </View>
  )
}

ProfileAndAccountScreen.defaultProps = {}

export default ProfileAndAccountScreen
