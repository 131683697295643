import Constants from 'expo-constants'
import { Platform } from 'react-native'
import generateRandomNumber from '../generateRandomNumber'

interface Link {
  href: string
  method: string
  rel: string
}
interface OrderResponse {
  id: string
  links: Link[]
  status: string
}
const createPayPalOrder = async (
  authToken: string | undefined,
  applicationId: number,
  purchsaseValue: number,
  merchantId: string,
  partnerAttributionId: string,
  authAssertion: string
): Promise<OrderResponse> => {
  const url = `${Constants.expoConfig?.extra?.payPalLink}/v2/checkout/orders`
  const payload = {
    intent: 'CAPTURE',
    purchase_units: [
      {
        description: 'A licence issued by a local authority for the right to trade at a given market.',
        items: [
          {
            name: 'Licence',
            quantity: '1',
            description: 'A licence issued by a local authority for the right to trade at a given market.',
            unit_amount: {
              currency_code: 'GBP',
              value: `${purchsaseValue}`,
            },
          },
        ],
        amount: {
          currency_code: 'GBP',
          value: `${purchsaseValue}`,
          breakdown: {
            item_total: {
              currency_code: 'GBP',
              value: `${purchsaseValue}`,
            },
          },
        },
        payee: {
          merchant_id: merchantId,
        },
      },
    ],
    payment_instruction: {
      disbursement_mode: 'INSTANT',
    },
    payment_source: {
      paypal: {
        experience_context: {
          shipping_preference: 'NO_SHIPPING',
          payment_method_preference: 'IMMEDIATE_PAYMENT_REQUIRED',
          user_action: 'PAY_NOW',
          return_url:
            Platform.OS === 'web'
              ? `${Constants?.expoConfig?.extra?.baseUrl.web}/applications/${applicationId}/pay`
              : `${Constants?.expoConfig?.extra?.baseUrl.mobile}applications/${applicationId}/pay`,
          cancel_url:
            Platform.OS === 'web'
              ? `${Constants?.expoConfig?.extra?.baseUrl.web}/applications/${applicationId}/pay`
              : `${Constants?.expoConfig?.extra?.baseUrl.mobile}applications/${applicationId}/pay`,
        },
      },
    },
  }

  const payPalRequestId = generateRandomNumber(10000000000000)

  const headers: HeadersInit = {
    'PayPal-Request-Id': `${payPalRequestId}`,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
    'PayPal-Partner-Attribution-Id': partnerAttributionId,
    'PayPal-Auth-Assertion': authAssertion,
  }

  const response = await fetch(url, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  })

  return response.json()
}

export default createPayPalOrder
