import React, { useState, ReactElement } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { Card, Button } from 'react-native-paper'
import sendPasswordResetEmail from '../helpers/firebase/auth/sendPasswordResetEmail'
import ErrorMessage from '../molecules/ErrorMessage'
import FormField from '../molecules/FormField'
import { colours } from '../styleguide'
import { FormFieldTypes } from '../types/application/formField.types'

import { Pages, ScreenProps } from '../types/navigation.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  titleAndCard: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  actions: {
    padding: 16,
  },
  button: {
    width: '100%',
    backgroundColor: colours.primary,
  },
  footerContainer: {
    flex: 3,
    marginHorizontal: 'auto',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    elevation: 2,
  },
  footer: {
    width: 500,
    maxWidth: '100%',
    flex: 3,
    backgroundColor: colours.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  titleContainerOuter: {
    width: 500,
    maxWidth: '100%',
    marginHorizontal: 'auto',
  },
  titleContainerInner: {
    padding: 20,
    marginLeft: 10,
    marginBottom: 20,
    width: '100%',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: 40,
  },
  messageContainerOuter: {
    width: 500,
    maxWidth: '100%',
    marginHorizontal: 'auto',
  },
  messageContainerInner: {
    marginBottom: 20,
    width: '100%',
    alignItems: 'flex-start',
    paddingHorizontal: 20,
    paddingBottom: 20,
    marginLeft: 10,
  },
  message: {
    fontSize: 15,
  },
})

interface Props extends ScreenProps<Pages.RECOVER_ACCOUNT> {
  showBanner: boolean
  error: string
  isEmailSent: boolean
  email: string
}

const RecoverAccountScreen = (props: Props): ReactElement => {
  const { navigation } = props

  const [error, setError] = useState<string>(props.error)
  const [email, setEmail] = useState<string>(props.email)
  const [isEmailSent, setIsEmailSent] = useState<boolean>(props.isEmailSent)

  const [showError, setShowError] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>('')
  const [isNotError, setIsNotError] = useState<boolean>(false)

  const dismissErrorMessage = () => {
    setShowError(false)
  }

  const sendRecoveryEmail = async () => {
    setError('')
    setIsNotError(false)
    try {
      await sendPasswordResetEmail(email)
      setErrorText(`Success! An email has been sent to ${email} with recovery instructions`)
      setIsNotError(true)
      setShowError(true)
      setIsEmailSent(true)
    } catch {
      setErrorText(`Sorry! The recovery email could not be sent`)
      setShowError(true)
    }
  }

  const goBack = () => {
    navigation.goBack()
  }

  return (
    <View style={styles.container} testID="recoveryEmail">
      <View style={styles.titleAndCard}>
        <View style={styles.titleContainerOuter}>
          <View style={styles.titleContainerInner}>
            <Text style={styles.title}>Forgot your password?</Text>
          </View>
        </View>
        <View style={styles.messageContainerOuter}>
          <View style={styles.messageContainerInner}>
            <Text style={styles.message}>
              Confirm your email address and an email will be sent to you with directions on how to reset your password.
            </Text>
          </View>
        </View>
        <View style={styles.footerContainer}>
          <Animatable.View animation="fadeInUpBig" style={styles.footer}>
            <Card.Content>
              <FormField
                field={{
                  type: FormFieldTypes.TEXT,
                  identifier: 'email',
                  label: 'Email',
                  error,
                  value: email,
                  validators: [],
                }}
                onInput={newAnswer => setEmail(newAnswer as string)}
              />
            </Card.Content>
            <Card.Actions style={styles.actions}>
              {isEmailSent ? (
                <Button
                  mode="elevated"
                  textColor={colours.black}
                  uppercase={false}
                  onPress={goBack}
                  style={styles.button}
                >
                  Go to login page
                </Button>
              ) : (
                <Button
                  mode="elevated"
                  textColor={colours.black}
                  uppercase={false}
                  onPress={sendRecoveryEmail}
                  style={styles.button}
                >
                  Send email
                </Button>
              )}
            </Card.Actions>
          </Animatable.View>
        </View>
      </View>
      <ErrorMessage
        errorMessage={errorText}
        showError={showError}
        onPress={dismissErrorMessage}
        isNotError={isNotError}
      />
    </View>
  )
}

RecoverAccountScreen.defaultProps = {
  showBanner: false,
  error: '',
  email: '',
  isEmailSent: false,
}

export default RecoverAccountScreen
