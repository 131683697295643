import React, { ReactElement } from "react";
import { View, StyleSheet, Text, Linking } from "react-native";
import { Icon } from "react-native-elements";
import { Card } from "react-native-paper";
import Link from "../atoms/Link";
import { colours } from "../styleguide";
import BannerTemplate from "../templates/BannerTemplate";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    maxWidth: 500,
    width: "100%",
  },
  cardContainer: {
    maxWidth: "100%",
    width: "100%",
  },
  action: {
    marginBottom: 10,
  },
  title: {
    textAlign: "left",
    flex: 1,
  },
  icon: {
    marginRight: 10,
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "2%",
    paddingVertical: "4%",
  },
  childrenContent: {
    paddingLeft: "2%",
  },
});

const HelpScreen = (): ReactElement => {

  return (
    <BannerTemplate testID="helpScreen">
      <View style={styles.container}>
        <Card.Content style={styles.cardContainer}>
          <View style={styles.action}>
            <View
              style={styles.content}
            >
              <Icon
                name="contacts"
                type="antdesign"
                color={colours.darkGrey}
                size={20}
                style={styles.icon}
              />
              <Text style={styles.title}>Contact us</Text>
            </View>
            <View
              style={styles.childrenContent}
            >
              <Text>
                Having a problem or need to contact us? Click this&nbsp;
                <Link onPress={() => Linking.openURL('https://openmarkets.london/contact/')}>
                  link
                </Link>
                .{"\n\n"}
              </Text>
            </View>
          </View>
        
        </Card.Content>
      </View>
    </BannerTemplate>
  );
};

export default HelpScreen;
