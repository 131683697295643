import { AnyField } from '../../../types/application/formField.types'
import { ValidatorTypes } from '../../../types/application/validator.types'
import { InMemoryFormFields } from '../../../types/form.types'
import validateConditionalRequiredField from './validators/validateConditionalRequiredField'
import validateFutureTimeField from './validators/validateFutureTimeField'
import validateIdentityField from './validators/validateIdentityField'
import validateMaxLengthField from './validators/validateMaxLengthField'
import validatePastTimeField from './validators/validatePastTimeField'
import validateRequiredField from './validators/validateRequiredField'
import validateTextmatchField from './validators/validateTextmatchField'
import validateXorField from './validators/validateXorField'

const applyValidators = (field: AnyField, fields: InMemoryFormFields): string => {
  const noErrorMessage = ''
  return (field.validators || []).reduce((errorMessage: string, validator) => {
    if (errorMessage) {
      return errorMessage
    } else if (validator.type === ValidatorTypes.REQUIRED) {
      return validateRequiredField(field, validator)
    } else if (validator.type === ValidatorTypes.TEXTMATCH) {
      return validateTextmatchField(field, validator)
    } else if (validator.type === ValidatorTypes.XOR) {
      return validateXorField(field, validator, fields)
    } else if (validator.type === ValidatorTypes.PAST_TIME) {
      return validatePastTimeField(field, validator)
    } else if (validator.type === ValidatorTypes.FUTURE_TIME) {
      return validateFutureTimeField(field, validator)
    } else if (validator.type === ValidatorTypes.MAX_LENGTH) {
      return validateMaxLengthField(field, validator)
    } else if (validator.type === ValidatorTypes.IDENTITY) {
      return validateIdentityField(field, validator, fields)
    } else if (validator.type === ValidatorTypes.CONDITIONAL_REQUIRED) {
      return validateConditionalRequiredField(field, validator, fields)
    } else {
      return noErrorMessage
    }
  }, noErrorMessage)
}

export default applyValidators
