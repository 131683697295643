import { FirebaseError } from '@firebase/util'
import Constants from 'expo-constants'
import React, { useState, ReactElement } from 'react'
import { StyleSheet } from 'react-native'
import { Card, Divider, Button, Title, Paragraph } from 'react-native-paper'
import Link from '../atoms/Link'
import getCurrentUser from '../helpers/firebase/auth/getCurrentUser'
import signOutUser from '../helpers/firebase/auth/signOutUser'
import useOMLContext from '../hooks/useOMLContext'
import { colours } from '../styleguide'
import BannerTemplate from '../templates/BannerTemplate'
import { Pages, ScreenProps } from '../types/navigation.types'

const styles = StyleSheet.create({
  card: {
    width: '100%',
    maxWidth: 360,
    marginBottom: 20,
  },
  actions: {
    padding: 16,
  },
  paragraph: {
    paddingBottom: 14,
  },
  button: {
    backgroundColor: colours.primary,
  },
})

interface Props extends ScreenProps<Pages.VERIFY_ACCOUNT> {
  showBanner: boolean
  loading: boolean
  error: string
}

const VerificationScreen = (props: Props): ReactElement => {
  const [showBanner, setShowBanner] = useState<boolean>(props.showBanner)
  const [loading, setLoading] = useState<boolean>(props.loading)
  const [bannerText, setBannerText] = useState<string>(props.error)
  const [isNotError, setIsNotError] = useState<boolean>(false)

  const [context, setContext] = useOMLContext()

  const onResendPress = async () => {
    setLoading(true)
    setShowBanner(false)
    setBannerText('')
    setIsNotError(false)

    try {
      const currentUser = getCurrentUser()
      if (!currentUser) {
        throw new Error('User not found')
      }
      const actionCodeSettings = {
        url: `https://${Constants?.expoConfig?.extra?.firebaseConfig?.authDomain}/`,
        iOS: {
          bundleId: __DEV__ ? 'com.tabled.oml.staging' : 'london.openmarkets',
        },
        android: {
          packageName: __DEV__ ? 'com.tabled.oml.staging' : 'london.openmarkets',
          installApp: true,
        },
      }

      await currentUser.sendEmailVerification(actionCodeSettings)
      setBannerText(`An email has been sent to ${currentUser.email} with verification instructions`)
      setIsNotError(true)
    } catch (error) {
      if (error instanceof Error) {
        setBannerText(error.message)
      } else {
        setBannerText('This request could not be processed')
      }
    } finally {
      setShowBanner(true)
    }

    setLoading(false)
  }

  const onLogoutPress = async () => {
    setLoading(true)
    setShowBanner(false)
    setBannerText('')

    try {
      const hasSignedOut = await signOutUser()

      if (hasSignedOut) {
        setContext({ ...context, firebaseUser: null, externalUser: null })
      }
    } catch (error) {
      const { message } = error as FirebaseError
      setBannerText(message || 'This request could not be processed.')

      setShowBanner(true)
    }

    setLoading(false)
  }

  const dismissErrorMessage = () => {
    setShowBanner(false)
  }

  return (
    <BannerTemplate
      testID="VerificationScreen"
      loading={loading}
      showBanner={showBanner}
      bannerText={bannerText}
      isNotError={isNotError}
      dismissError={dismissErrorMessage}
    >
      <Card style={styles.card}>
        <Card.Content>
          <Title>Welcome to Open Markets</Title>
          <Paragraph style={styles.paragraph}>
            We have sent you a registration email. Please verify your account to access the platform.{'\n\n'}Please also
            check your Junk Folder for the Verification Email.
          </Paragraph>
        </Card.Content>
        <Divider />
        <Card.Actions style={styles.actions}>
          <Button
            mode="elevated"
            textColor={colours.black}
            uppercase={false}
            onPress={onResendPress}
            style={styles.button}
          >
            Resend verification email
          </Button>
        </Card.Actions>
      </Card>
      <Paragraph>
        Already verified? <Link onPress={onLogoutPress}>Login again</Link>.
      </Paragraph>
    </BannerTemplate>
  )
}

VerificationScreen.defaultProps = {
  showBanner: false,
  loading: false,
  error: '',
}

export default VerificationScreen
