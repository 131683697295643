import Constants from 'expo-constants'
import { Platform } from 'react-native'
import { ERRORS } from '../../../constants'
import { retrieveTabledAuthToken } from '../localStorage'

const getLicencePass = async (credentialId: number): Promise<Response | null> => {
  const headers: HeadersInit = {
    accessKeyId: `${Constants?.expoConfig?.extra?.accessKeyId}`,
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const response = await fetch(
    new Request(
      `${Constants?.expoConfig?.extra?.hostname}/ext/credentials/${credentialId}/wallet-pass?device=${Platform.OS}`,
      {
        method: 'GET',
        headers,
      }
    )
  )
  if (response.status === 200) {
    return response
  } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
    throw new Error(ERRORS.UPGRADE_REQUIRED.message)
  }

  return null
}

export default getLicencePass
