import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { Licence } from '../../types/licence/licence.types'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

const getLicence = async (licenceId: number): Promise<Licence | null> => {
  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/credentials/${licenceId}`
  const headers: HeadersInit = {
    accessKeyId: `${Constants?.expoConfig?.extra?.accessKeyId}`,
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  let licence = null

  await fetch(
    new Request(url, {
      method: 'GET',
      headers,
    })
  ).then(async response => {
    if (response.status === 200) {
      await response.json().then(tabledLicence => {
        if (tabledLicence) {
          licence = {
            id: tabledLicence.id,
            council: tabledLicence.workspaceName,
            market: tabledLicence.market,
            daysOfWeek: tabledLicence.daysOfWeek,
            referenceNumber: tabledLicence.licenceNumber,
            startDate: Number(tabledLicence.dateOfIssue),
            endDate: Number(tabledLicence.expiryDate),
            url: tabledLicence.url,
            councilLogo: tabledLicence.workspaceLogo,
            traderPhoto: tabledLicence.traderPhoto,
            traderFullName: tabledLicence.licenceeFullName,
          }
        }
      })
    } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
      throw new Error(ERRORS.UPGRADE_REQUIRED.message)
    } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
      const res = await response.text()
      if (res === ERRORS.TOKEN_EXPIRED.message) {
        signOutUser()
      } else {
        throw new Error('Failed to retrieve licence')
      }
    } else {
      throw new Error('Failed to retrieve licence')
    }
  })
  return licence
}

export default getLicence
