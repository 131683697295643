/**
 * A validator is a set of instructions about how a field should be validated at runtime.
 * @module Validator
 */

/**
 * The base interface used to extend other validator interfaces.
 */
interface BasicValidator {
  /** A message to be displayed if the field is invalid */
  failureMessage: string
}

/**
 * The kinds of validators
 */
export enum ValidatorTypes {
  REQUIRED = 'required',
  TEXTMATCH = 'textmatch',
  PAST_TIME = 'past',
  FUTURE_TIME = 'future',
  MAX_LENGTH = 'max-length',
  XOR = 'XOR',
  IDENTITY = 'identity',
  CONDITIONAL_REQUIRED = 'conditional-required',
}

/**
 * Validate that the field has not been skipped
 */
export interface RequiredValidator extends BasicValidator {
  type: ValidatorTypes.REQUIRED
}

/**
 * Validate that the field matches a text string. Note that empty input is considered valid - see {@link RequiredValidator} to require input.
 */
export interface TextmatchValidator extends BasicValidator {
  type: ValidatorTypes.TEXTMATCH
  arguments: {
    /** A regex without leading or trailing forward slash */
    pattern: string
  }
}

/**
 * Validate that a date or time field is in the past
 */
export interface PastTimeValidator extends BasicValidator {
  type: ValidatorTypes.PAST_TIME
}

/**
 * Validate that a date or time field is in the future
 */
export interface FutureTimeValidator extends BasicValidator {
  type: ValidatorTypes.FUTURE_TIME
}

/**
 * A validator for defining relations between fields
 */
interface RelationalValidator extends BasicValidator {
  arguments: {
    /** An array of {@link BasicField.identifier} that are in an XOR relation with this field */
    partners: string[]
  }
}

/**
 * Validate that this field has been filled, or a partner field has been filled. If both fields are filled, the validator function should consider the field invalid.
 */
export interface XORValidator extends RelationalValidator {
  type: ValidatorTypes.XOR
}

/**
 * Validate that this field matches another
 */
export interface IdentityValidator extends RelationalValidator {
  type: ValidatorTypes.IDENTITY
}

/**
 * Validate that this field has not been skipped if its partner has a value
 */
export interface ConditionalRequiredValidator extends RelationalValidator {
  type: ValidatorTypes.CONDITIONAL_REQUIRED
}

/**
 * Validate that a field's length does not exceed a given number
 */
export interface MaxLengthValidator extends BasicValidator {
  type: ValidatorTypes.MAX_LENGTH
  arguments: {
    length: number
  }
}

export type AnyValidator =
  | RequiredValidator
  | TextmatchValidator
  | PastTimeValidator
  | FutureTimeValidator
  | MaxLengthValidator
  | XORValidator
  | IdentityValidator
  | ConditionalRequiredValidator
