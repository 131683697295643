import { INVALID_DATE } from '../../../../../constants'
import { AnyField } from '../../../../types/application/formField.types'
import { ConditionalRequiredValidator } from '../../../../types/application/validator.types'
import { InMemoryFormFields } from '../../../../types/form.types'
import doesFormValueExist from '../doesFormValueExist'

const validateConditionalRequiredField = (
  field: AnyField,
  validator: ConditionalRequiredValidator,
  fields: InMemoryFormFields
): string => {
  const partners: string[] = validator.arguments.partners
  if (!partners) {
    return ''
  }

  const numberOfValidPartners = partners
    .map(partnerIdentifier => {
      const partner = fields[partnerIdentifier] || { value: null }

      return doesFormValueExist(partner.value, partner.type)
    })
    .filter(item => !!item).length

  const allPartnersValid = numberOfValidPartners === partners.length

  const doesValueExist = doesFormValueExist(field.value, field.type)

  const isThisFieldFilled =
    typeof field.value === 'string' ? doesValueExist && field.value.toLowerCase() !== INVALID_DATE : doesValueExist

  const isValid = (allPartnersValid && isThisFieldFilled) || !allPartnersValid

  return isValid ? '' : validator.failureMessage
}

export default validateConditionalRequiredField
