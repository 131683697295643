import React, { ReactElement, ReactNode } from "react";
import { StyleSheet, Text } from "react-native";
import { colours } from "../styleguide";

const styles = StyleSheet.create({
  link: {
    fontWeight: "bold",
  },
});

interface Props {
  onPress: () => void;
  children: ReactNode;
  colour?: string;
}

const Link = (props: Props): ReactElement => {
  const { onPress, children, colour = colours.primary } = props;


  return (
    <Text style={[styles.link, {color: colour}]} onPress={onPress}>
      {children}
    </Text>
  );
};

export default Link;
