import { ApplicationProperties, ApplicationStageProperties } from '../../../types/application/application.types'

import { AnyField } from '../../../types/application/formField.types'
import { DocumentReference } from '../firebase.types'

const postApplicationStageFields = (
  applicationReference: DocumentReference,
  fields: AnyField[],
  stageIndex: number
): Promise<void[]> =>
  Promise.all(
    fields.map((field, fieldIndex) => {
      return applicationReference
        .collection(ApplicationProperties.STAGES)
        .doc(stageIndex.toString())
        .collection(ApplicationStageProperties.FIELDS)
        .doc(fieldIndex.toString())
        .set(field)
    })
  )

export default postApplicationStageFields
