import { INVALID_DATE } from '../../constants'
import doesFormValueExist from '../hooks/useForm/helpers/doesFormValueExist'
import {
  User,
  UserProperties,
  UserDocumentProperties,
  UserAdditionalDocumentInformationProperties,
} from '../types/user.types'

const hasUserProvidedRequiredInformation = (user: User | null): boolean => {
  const isUserPublicLiabilityInsuranceDocumentUploaded = doesFormValueExist(
    user?.documents?.[UserDocumentProperties.PUBLIC_LIABILITY_INSURANCE_DOCUMENT] || false
  )

  const isUserPublicLiabilityInsuranceDocumentAcknowledged =
    `${
      user?.ADDITIONAL_DOCUMENT_INFORMATION?.[
        UserAdditionalDocumentInformationProperties.PUBLIC_LIABILITY_INSURANCE_ACKNOWLEDGED
      ]
    }` === 'true'

  const insuranceEndDate =
    user?.ADDITIONAL_DOCUMENT_INFORMATION?.[
      UserAdditionalDocumentInformationProperties.PUBLIC_LIABILITY_INSURANCE_END_DATE
    ]

  const isUserPublicLiabilityInsuranceEndDateProvided = doesFormValueExist(insuranceEndDate || false)
  const isinsuranceEndDateValid =
    typeof insuranceEndDate === 'string' ? insuranceEndDate.toLowerCase() !== INVALID_DATE : true

  const isInsuranceEndDateValid = isUserPublicLiabilityInsuranceDocumentUploaded
    ? isUserPublicLiabilityInsuranceEndDateProvided && isinsuranceEndDateValid
    : true

  return (
    doesFormValueExist(user?.[UserProperties.FIRST_NAME] || false) &&
    doesFormValueExist(user?.[UserProperties.LAST_NAME] || false) &&
    doesFormValueExist(user?.[UserProperties.EMAIL_ADDRESS] || false) &&
    doesFormValueExist(user?.[UserProperties.DATE_OF_BIRTH] || false) &&
    doesFormValueExist(user?.[UserProperties.PHONE_NUMBER] || false) &&
    Object.values(UserDocumentProperties).every(key => {
      if (key === UserDocumentProperties.PUBLIC_LIABILITY_INSURANCE_DOCUMENT) {
        // Ensures that at least one of the following is true, but not both
        // In plain English, read as 'A or B but not A and B'
        return (
          (isUserPublicLiabilityInsuranceDocumentUploaded || isUserPublicLiabilityInsuranceDocumentAcknowledged) &&
          !(isUserPublicLiabilityInsuranceDocumentUploaded && isUserPublicLiabilityInsuranceDocumentAcknowledged)
        )
      }
      return doesFormValueExist(user?.documents?.[key] || false)
    }) &&
    isInsuranceEndDateValid
  )
}

export default hasUserProvidedRequiredInformation
