import React, { useState, ReactElement } from 'react'
import { View, StyleSheet, TextInput, Platform, Switch } from 'react-native'
import { Icon } from 'react-native-elements'
import { Checkbox, HelperText, RadioButton, Subheading } from 'react-native-paper'
import Link from '../atoms/Link'
import openLink from '../helpers/openLink'
import { colours } from '../styleguide'
import { AnyField, FormFieldValue, FormFieldTypes } from '../types/application/formField.types'
import DatePicker from './DatePicker/DatePicker'
import ImageUpload from './ImageUpload'
import ImageUploadSingle from './ImageUploadSingle'
import MultipleSelectList from './MultipleSelectList'
import ObjectMultipleSelectList from './ObjectMultipleSelect'
import PasswordTextField from './PasswordTextField'

interface Props {
  field: AnyField
  onInput: (newAnswer: FormFieldValue) => unknown
  uploading?: boolean
  hideIcon?: boolean
}

const styles = StyleSheet.create({
  error: {
    paddingHorizontal: 0,
    paddingBottom: 14,
    textAlign: 'center',
  },
  link: {
    alignItems: 'center',
  },
  textInput: {
    flex: 1,
    paddingVertical: 0,
    paddingLeft: 10,
    alignItems: 'center',
  },
  action: {
    flexDirection: 'row',
    marginTop: 10,
    borderBottomWidth: 1,
    borderBottomColor: colours.lightGrey,
    paddingBottom: 5,
  },
  icon: {
    paddingTop: Platform.OS === 'android' ? 3 : 0,
  },
  switch: {
    transform: Platform.OS === 'android' ? [{ scaleX: 1 }, { scaleY: 1 }] : [{ scaleX: 0.7 }, { scaleY: 0.7 }],
  },
  switchContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  switchText: {
    flexDirection: 'row',
    width: '80%',
    flexWrap: 'wrap',
  },
})

const getIcon = (type: FormFieldTypes, label: string) => {
  switch (type) {
    case FormFieldTypes.TEXT:
    case FormFieldTypes.TEXTAREA:
      if (label.toLowerCase().includes('email')) {
        return (
          <View style={styles.icon}>
            <Icon name="email" type="fontisto" color={colours.darkGrey} size={20} />
          </View>
        )
      } else if (label.toLowerCase().includes('phone')) {
        return (
          <Icon
            name="phone"
            type="antdesign"
            color={colours.darkGrey}
            size={20}
            style={styles.icon}
          />
        )
      } else if (label.toLowerCase().includes('instagram')) {
        return (
          <Icon name="instagram" type="antdesign" color={colours.darkGrey} size={20} />
        )
      } else if (label.toLowerCase().includes('home')) {
        return <Icon name="home" type="antdesign" color={colours.darkGrey} size={20} />
      }
      return (
        <Icon
          name="edit"
          type="feather"
          color={colours.darkGrey}
          size={20}
          style={styles.icon}
        />
      )
    case FormFieldTypes.PASSWORD_TEXT:
      return (
        <Icon
          name="lock"
          type="antdesign"
          color={colours.darkGrey}
          size={20}
          style={styles.icon}
        />
      )
    case FormFieldTypes.DATE:
      return (
        <Icon
          name="date"
          type="fontisto"
          color={colours.darkGrey}
          size={20}
          style={styles.icon}
        />
      )
    default:
      return (
        <Icon
          name="edit"
          type="feather"
          color={colours.darkGrey}
          size={20}
          style={styles.icon}
        />
      )
  }
}

const FormField = (props: Props): ReactElement => {
  const [localError, setLocalError] = useState<string>('')
  const { field, onInput, uploading, hideIcon } = props
  const handleSwitchValueChange = (value: boolean) => {
    onInput(value)
  }

  return (
    <View>
      {(() => {
        if (field.type === FormFieldTypes.MULTIPLE_SELECT) {
          return (
            <MultipleSelectList title={field.label} options={field.options} value={field.value} onPress={onInput} />
          )
        } else if (field.type === FormFieldTypes.OBJECT_MULTIPLE_SELECT) {
          return (
            <ObjectMultipleSelectList
              title={field.label}
              options={field.objectOptions}
              value={field.value}
              onPress={onInput}
            />
          )
        } else if (field.type === FormFieldTypes.TEXT) {
          return (
            <View style={styles.action}>
              {field.label && !hideIcon && getIcon(field.type, field.label)}
              <TextInput
                value={field.value}
                onChangeText={value => {
                  if (field.upperCase) {
                    onInput(value.toUpperCase())
                  } else {
                    onInput(value)
                  }
                }}
                placeholder={field.label}
                keyboardType={Platform.OS === 'android' ? 'visible-password' : 'default'}
                autoCapitalize={Platform.OS === 'android' && field.upperCase ? 'characters' : 'sentences'}
                placeholderTextColor={colours.darkGrey}
                editable={field.editable ?? true}
                style={styles.textInput}
              />
            </View>
          )
        } else if (field.type === FormFieldTypes.PASSWORD_TEXT) {
          return (
            <View style={styles.action}>
              {field.label && !hideIcon && getIcon(field.type, field.label)}
              <PasswordTextField
                onChangeText={(value: string) => onInput(value)}
                label={field.label}
                value={field.value}
                placeholder={field.label}
                placeholderTextColor={colours.darkGrey}
                autoCapitalize="none"
                dense="true"
              />
            </View>
          )
        } else if (field.type === FormFieldTypes.TEXTAREA) {
          return (
            <View style={styles.action}>
              {field.label && !hideIcon && getIcon(field.type, field.label)}
              <TextInput
                placeholder={field.label}
                placeholderTextColor={colours.darkGrey}
                onChangeText={onInput}
                value={field.value}
                multiline={true}
                style={styles.textInput}
                editable={field.editable ?? true}
                blurOnSubmit={true}
              />
            </View>
          )
        } else if (field.type === FormFieldTypes.CHECKBOX) {
          const checked = field.value.toString() === 'true'
          return (
            <View>
              <Checkbox.Item
                label={field.label}
                status={checked ? 'checked' : 'unchecked'}
                onPress={() => onInput(checked ? 'false' : 'true')}
                color={colours.primary}
                mode={'android'}
              />
            </View>
          )
        } else if (field.type === FormFieldTypes.IMAGE) {
          return (
            <ImageUpload
              label={field.label}
              value={field.value}
              onChange={onInput}
              setLocalError={setLocalError}
              uploading={uploading}
            />
          )
        } else if (field.type === FormFieldTypes.IMAGE_SINGLE) {
          return (
            <ImageUploadSingle
              label={field.label}
              value={field.value}
              onChange={onInput}
              setLocalError={setLocalError}
              uploading={uploading}
            />
          )
        } else if (field.type === FormFieldTypes.DATE) {
          return (
            <View>
              {Platform.OS !== 'web' && <Subheading>{field.label}</Subheading>}
              <DatePicker onChange={onInput} label={field.label} value={field.value} />
            </View>
          )
        } else if (field.type === FormFieldTypes.RADIO) {
          return (
            <View>
              <Subheading>{field.label}</Subheading>
              <RadioButton.Group onValueChange={onInput} value={field.value ? field.value.toLowerCase() : ''}>
                {field.options.map(option => (
                  <View key={option}>
                    <RadioButton.Item
                      value={option.toLowerCase()}
                      label={option}
                      color={colours.primary}
                      mode={'android'}
                    />
                  </View>
                ))}
              </RadioButton.Group>
            </View>
          )
        } else if (field.type === FormFieldTypes.TOGGLE) {
          return (
            <View style={styles.switchContainer}>
              <Subheading style={styles.switchText}>{field.label}</Subheading>
              <Switch
                trackColor={{
                  false: colours.darkGrey,
                  true: colours.primary,
                }}
                ios_backgroundColor={colours.darkGrey}
                thumbColor={colours.white}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-expect-error
                activeThumbColor={colours.white}
                onValueChange={() => handleSwitchValueChange(!field.value)}
                value={field.value}
                style={styles.switch}
              />
            </View>
          )
        }
      })()}
      {field.link ? (
        <View style={styles.link}>
          <Link onPress={() => openLink(field?.link?.url || '#')}>{field.link.text}</Link>
        </View>
      ) : null}
      <HelperText style={styles.error} type="error" visible={!!(field.error || localError)}>
        {field.error || localError}
      </HelperText>
    </View>
  )
}

export default FormField
