import {
  ApplicationProperties,
  ApplicationStage,
  ApplicationStageProperties,
} from '../../../types/application/application.types'
import { Collections } from '../../../types/firebase.types'
import { firestore } from '../firebase'
import putApplicationStageFields from './putApplicationStageFields'

const replaceApplicationStages = (applicationId: string, stages: ApplicationStage[]): Promise<void[]> =>
  Promise.all(
    stages.map(async (stage, index) => {
      const fields = await firestore
        .collection(Collections.APPLICATIONS)
        .doc(applicationId)
        .collection(ApplicationProperties.STAGES)
        .doc(index.toString())
        .collection(ApplicationStageProperties.FIELDS)
        .get()

      for (let i = stage.fields.length; i < fields.size; i++) {
        firestore
          .collection(Collections.APPLICATIONS)
          .doc(applicationId)
          .collection(ApplicationProperties.STAGES)
          .doc(index.toString())
          .collection(ApplicationStageProperties.FIELDS)
          .doc(i.toString())
          .delete()
      }
      await putApplicationStageFields(applicationId, index, stage.fields)
    })
  )

export default replaceApplicationStages
