import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { SimplifiedLicence } from '../../types/licence/licence.types'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

const getLicences = async (): Promise<SimplifiedLicence[] | void> => {
  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/credentials`
  const headers: HeadersInit = {
    accessKeyId: `${Constants?.expoConfig?.extra?.accessKeyId}`,
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const licences: SimplifiedLicence[] = []

  await fetch(
    new Request(url, {
      method: 'GET',
      headers,
    })
  ).then(async response => {
    if (response.status === 200) {
      await response.json().then(licence => {
        licences.push(...licence)
      })
    } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
      throw new Error(ERRORS.UPGRADE_REQUIRED.message)
    } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
      signOutUser()
    }
  })
  return licences
}

export default getLicences
