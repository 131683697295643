import React, { ReactElement, useState } from "react";
import { View, StyleSheet, Text, Platform } from "react-native";
import { Icon } from "react-native-elements";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { INVALID_DATE } from "../../../constants";
import { colours } from "../../styleguide";

const styles = StyleSheet.create({
  action: {
    flexDirection: "row",
    marginTop: 10,
    borderBottomWidth: 1,
    borderBottomColor: colours.lightGrey,
    paddingBottom: 5,
  },
  text: {
    color: colours.black,
    marginTop: Platform.OS === 'ios' ? 3 : 0,
    marginLeft: 10,
    width: '80%',
  },
  placeHolderText: {
    color: colours.darkGrey,
  },
  dateField: {
    width: "95%",
    flexDirection: 'row',
    justifyContent: "space-between",
  },
});

interface Props {
  label: string;
  value: number | string | null;
  onChange: (newDate: number | null) => void;
}

const DatePickerMobile = (props: Props): ReactElement => {
  const { onChange, value } = props;
  const [showDatePicker, setShowDatePicker] = useState(false)



  let fieldValue = value

  if (fieldValue && typeof fieldValue === 'string' && fieldValue.toLowerCase() === INVALID_DATE) {
    fieldValue = null
  }

  const clearDate = () => {
    onChange(null)
  }

  const date = fieldValue ? new Date(fieldValue) : null;

  return date ? (
        <View style={styles.action}>
          <Icon
            name="date"
            type="fontisto"
            color={colours.darkGrey}
            size={20}
          />
          {!showDatePicker ? (
            <View style={styles.dateField}>
              <Text style={styles.text} onPress={() => setShowDatePicker(true)}>{date.getDate()}/{date.getMonth()+1}/{date.getFullYear()}</Text>
              <Icon
                name="cancel"
                type="materialicon"
                color={colours.darkGrey}
                size={20}
                onPress={() => clearDate()}
              />
            </View>
          ) : (
            <DateTimePickerModal
              isVisible={showDatePicker}
              mode="date"
              date={date}
              onConfirm={(date) => {
                setShowDatePicker(false)
                onChange(date.getTime())
              }}
              onCancel={() => setShowDatePicker(false)}
            />
          )}
        </View>
      ) : (
        <View style={styles.action}>
          <Icon
            name="date"
            type="fontisto"
            color={colours.darkGrey}
            size={20}
          />
          {!showDatePicker ? (
              <Text style={[styles.text, styles.placeHolderText]} onPress={() => setShowDatePicker(true)}>Select date</Text>
            ) : (
              <DateTimePickerModal
                isVisible={showDatePicker}
                mode="date"
                onConfirm={(date) => {
                  setShowDatePicker(false)
                  onChange(date.getTime())
                }}
                onCancel={() => setShowDatePicker(false)}
              />
            )}
        </View>
      )
};

export default DatePickerMobile;
