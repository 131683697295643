import Constants from 'expo-constants'
import React, { useState, ReactElement } from 'react'
import { StyleSheet, Image, View, Text, SafeAreaView } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { Icon } from 'react-native-elements'
import { Button, Paragraph, ActivityIndicator } from 'react-native-paper'
import Link from '../atoms/Link'
import PasswordTextField from '../molecules/PasswordTextField'
import { colours } from '../styleguide'
import { Pages, ScreenProps } from '../types/navigation.types'

const styles = StyleSheet.create({
  button: {
    marginBottom: 10,
    width: '100%',
    backgroundColor: colours.primary,
  },
  container: {
    flexGrow: 1,
    backgroundColor: colours.primary,
  },
  logoAndCard: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  logoContainer: {
    marginVertical: '2%',
    width: '100%',
    flex: 1,
    alignItems: 'center',
  },
  logo: {
    width: 500,
    maxWidth: '70%',
    height: '100%',
  },
  spinnerContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colours.backgroundGrey,
  },
  loadingText: {
    color: colours.description,
    opacity: 0.7,
    marginTop: 10,
  },

  footerContainer: {
    flex: 3,
    marginHorizontal: 'auto',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    elevation: 2,
  },
  footer: {
    width: 500,
    maxWidth: '100%',
    flex: 3,
    backgroundColor: colours.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  action: {
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: colours.lightGrey,
    paddingBottom: 5,
  },
  subheadingContainer: {
    width: '100%',
    alignItems: 'center',
  },
  subheading: {
    fontSize: 30,
    fontWeight: '400',
  },
  textContainer: {
    marginTop: 20,
    width: '100%',
  },
  text: {
    textAlign: 'center',
  },
  errorContainer: {
    marginBottom: 10,
    width: '100%',
    alignItems: 'center',
  },
  error: {
    color: colours.errorPrimary,
    fontWeight: '500',
  },
})

interface Props extends ScreenProps<Pages.ADMIN_VERIFICATION> {
  loading: boolean
  handleAdminLogin: () => Promise<void>
}

const AdminVerificationScreen = (props: Props): ReactElement => {
  const { loading, route } = props
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')

  const onLoginPress = () => {
    if (password === Constants?.expoConfig?.extra?.stagingAdminPassword) {
      route.params.handleAdminLogin()
    } else {
      setError('The provided password is incorrect')
    }
  }

  const openOfficialSite = async () => {
    window.open('https://app.openmarkets.london/', '_self')
  }

  return (
    <View style={styles.container} testID="AdminVerificationScreen">
      {loading ? (
        <SafeAreaView testID="Loading" style={styles.spinnerContainer}>
          <ActivityIndicator size="large" animating={true} />
          <Text style={styles.loadingText}>Loading</Text>
        </SafeAreaView>
      ) : (
        <View style={styles.logoAndCard}>
          <View style={styles.logoContainer}>
            <Image
              source={require('../../assets/logo_clear_background.png')}
              resizeMode="contain"
              style={styles.logo}
            />
          </View>
          <View style={styles.footerContainer}>
            <Animatable.View animation="fadeInUpBig" style={styles.footer}>
              <View style={styles.subheadingContainer}>
                <Text style={styles.subheading}>Testing Portal</Text>
              </View>
              <View style={styles.textContainer}>
                <Paragraph style={styles.text}>
                  This webpage is for testing purposes only.{'\n\n'}If you have found this webpage by mistake, you can
                  find the official OpenMarkets.London webpage <Link onPress={openOfficialSite}>here</Link>.
                </Paragraph>
              </View>
              <View style={styles.action}>
                <Icon name="lock" type="antdesign" color={colours.darkGrey} size={20} />
                <PasswordTextField
                  value={password}
                  onChangeText={(value: string) => setPassword(value)}
                  placeholder={'Password'}
                  placeholderTextColor={colours.darkGrey}
                  autoCapitalize="none"
                  dense="true"
                />
              </View>
              <View style={styles.errorContainer}>
                <Text style={styles.error}>{error}</Text>
              </View>
              <Button
                style={styles.button}
                mode="elevated"
                textColor={colours.black}
                uppercase={false}
                onPress={onLoginPress}
              >
                Log in
              </Button>
            </Animatable.View>
          </View>
        </View>
      )}
    </View>
  )
}

AdminVerificationScreen.defaultProps = {
  loading: false,
}

export default AdminVerificationScreen
