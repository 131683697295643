import React, { useEffect, useState, ReactElement } from 'react'
import { View, StyleSheet } from 'react-native'
import { Card, Button } from 'react-native-paper'
import { FirebaseAuthUser } from '../helpers/firebase/firebase.types'
import getApplicationDeeply from '../helpers/firebase/firestore/getApplicationDeeply'
import getCouncilMarkets from '../helpers/firebase/firestore/getCouncilMarkets'
import getCouncilStages from '../helpers/firebase/firestore/getCouncilStages'
import getMarketFields from '../helpers/firebase/firestore/getMarketFields'
import postApplicationDeeply from '../helpers/firebase/firestore/postApplicationDeeply'
import putApplication from '../helpers/firebase/firestore/putApplication'
import replaceApplicationStages from '../helpers/firebase/firestore/replaceApplicationStages'
import useOMLContext from '../hooks/useOMLContext'
import CouncilAndMarketSelector from '../molecules/CouncilAndMarketSelector'
import { colours } from '../styleguide'
import BannerTemplate from '../templates/BannerTemplate'
import { Application, ShallowApplication, ApplicationStatuses } from '../types/application/application.types'
import { CouncilMarkets } from '../types/miscellaneous.types'
import { Pages, ScreenProps } from '../types/navigation.types'
import { User } from '../types/user.types'

interface Props extends ScreenProps<Pages.NEW_APPLICATION> {
  showBanner: boolean
  loading: boolean
  error: string
  selectedCouncil: string
  selectedMarkets: string[]
  councilMarkets: CouncilMarkets
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    width: '100%',
    maxWidth: 500,
    marginBottom: '5%',
  },
  cardActions: {
    marginTop: 10,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  cardActionButton: {
    marginHorizontal: 10,
    marginVertical: 5,
    flex: 1,
    backgroundColor: colours.primary,
  },
})

const NewApplicationScreen = (props: Props): ReactElement => {
  const { navigation } = props
  const [selectedCouncil, setSelectedCouncil] = useState<string>(props.selectedCouncil)

  const [selectedMarkets, setSelectedMarkets] = useState<string[]>(props.selectedMarkets)

  const [councilMarkets, setCouncilMarkets] = useState<CouncilMarkets>(props.councilMarkets)

  const [context, setContext] = useOMLContext()

  const [loading, setLoading] = useState<boolean>(props.loading)
  const [error, setError] = useState<string>(props.error)
  const [showBanner, setShowBanner] = useState<boolean>(props.showBanner)

  useEffect(() => {
    getCouncilMarkets(true, context.firebaseUser?.email || '')
      .then(newCouncilMarkets => {
        setCouncilMarkets(newCouncilMarkets)
      })
      .catch((err: Error) => {
        setError(err.message)
        setShowBanner(true)
      })
  }, [])

  useEffect(() => {
    setSelectedMarkets([])
  }, [selectedCouncil])

  const postForm = async (): Promise<Application> => {
    if (!context.firebaseUser?.uid) {
      setShowBanner(true)
      setError(
        'Required information about the current user could not be found. Please reload the page, or log in and out.'
      )
    }

    const [marketFields, councilStages] = await Promise.all([
      getMarketFields(selectedMarkets),
      getCouncilStages(selectedCouncil),
    ])

    const application: ShallowApplication = {
      applicant: context.firebaseUser!.uid,
      council: selectedCouncil,
      markets: selectedMarkets,
      status: ApplicationStatuses.DRAFT,
      externalId: '',
    }

    const stages = [
      {
        title: 'markets',
        fields: marketFields,
      },
      ...councilStages,
    ]

    if (context.currentApplication) {
      const latestApplication = await getApplicationDeeply(context.currentApplication)
      application.id = context.currentApplication

      if (latestApplication.council !== selectedCouncil || latestApplication.markets !== selectedMarkets) {
        await replaceApplicationStages(application.id, stages)
        await putApplication(application)
        const updatedApplication = await getApplicationDeeply(context.currentApplication)
        updatedApplication.stages[1].fields.sort((a, b) => (a.index! > b.index! ? 1 : b.index! > a.index! ? -1 : 0))

        return updatedApplication
      } else {
        await putApplication(application)

        latestApplication.stages[1].fields.sort((a, b) => (a.index! > b.index! ? 1 : b.index! > a.index! ? -1 : 0))

        return latestApplication
      }
    } else {
      const applicationReference = await postApplicationDeeply(
        application,
        stages,
        context.externalUser ? context.externalUser : ({} as User),
        context.firebaseUser ? context.firebaseUser : ({} as FirebaseAuthUser)
      )

      if (!applicationReference?.id) {
        throw 'No application reference ID.'
      }

      setContext({
        ...context,
        currentApplication: applicationReference.id,
      })

      const latestApplication = await getApplicationDeeply(applicationReference.id)

      latestApplication.stages[1].fields.sort((a, b) => (a.index! > b.index! ? 1 : b.index! > a.index! ? -1 : 0))

      return latestApplication
    }
  }

  const handleSubmission = async (navigationFn: () => void) => {
    setLoading(true)
    try {
      await navigationFn()
    } catch {
      setError('There was an error preparing your application form. Please try again.')
      setShowBanner(true)
    } finally {
      setLoading(false)
    }
  }

  const submitAndContinue = async () => {
    setLoading(true)
    const application: Application = await postForm()
    setLoading(false)
    return handleSubmission(() =>
      navigation.navigate(Pages.FILL_OUT_APPLICATION, {
        application,
        onStage: 0,
      })
    )
  }

  const dismissErrorMessage = () => {
    setShowBanner(false)
  }

  return (
    <BannerTemplate
      testID="NewApplication"
      loading={loading}
      showBanner={showBanner}
      bannerText={error}
      dismissError={dismissErrorMessage}
    >
      <View style={styles.container}>
        <Card>
          <Card.Title title="Select Markets" />

          <CouncilAndMarketSelector
            councilMarkets={councilMarkets}
            setSelectedCouncil={setSelectedCouncil}
            setSelectedMarkets={setSelectedMarkets}
            selectedCouncil={selectedCouncil}
            selectedMarkets={selectedMarkets}
          />

          <Card.Actions style={styles.cardActions}>
            <Button
              disabled={!selectedMarkets.length}
              style={styles.cardActionButton}
              mode="elevated"
              textColor={colours.black}
              onPress={submitAndContinue}
            >
              Save and Continue
            </Button>
          </Card.Actions>
        </Card>
      </View>
    </BannerTemplate>
  )
}

NewApplicationScreen.defaultProps = {
  showBanner: false,
  loading: false,
  error: '',
  selectedCouncil: '',
  selectedMarkets: [],
  councilMarkets: {},
}

export default NewApplicationScreen
