import { INVALID_DATE } from '../../../constants'
import { optionalMyDetailsForm } from '../../static-forms/myDetailsForm'
import { optionalMyDocumentsForm } from '../../static-forms/myDocumentsForm'
import { FormFieldTypes } from '../../types/application/formField.types'
import { User, UserAdditionalFieldProperties, UserProperties } from '../../types/user.types'

const updateUserDetails = (user: User): FormData => {
  const userInfoFields = optionalMyDetailsForm
  const userDocumentFields = optionalMyDocumentsForm

  const additionalFields = Object.entries(user[UserProperties.ADDITIONAL_FIELDS])
    .map(([key, value]) => {
      const field = userInfoFields.find(field => field.identifier === key)
      if (key === UserAdditionalFieldProperties.BUSINESS_TYPE && value.length > 0 && value.toLowerCase() === 'other') {
        const otherValue = user[UserProperties.ADDITIONAL_FIELDS][UserAdditionalFieldProperties.BUSINESS_TYPE_OTHER]
        const label = field?.label || key
        return { name: key, label, value: `${otherValue}`, inputType: FormFieldTypes.TEXT }
      } else if (key === UserAdditionalFieldProperties.BUSINESS_TYPE_OTHER) {
        return undefined
      }
      // The check for `INVALID_DATE` here is to ensure that date values are of a valid format
      if (!value || (typeof value === 'string' && value.length > 0 && value.toLowerCase() === INVALID_DATE)) {
        return { name: key, label: key, value: null, inputType: null }
      } else {
        if (Array.isArray(value)) {
          if (value.length === 0) {
            value = null
          } else {
            value = value.join(', ')
          }
        }
        const inputType = field ? (field.type === FormFieldTypes.RADIO ? FormFieldTypes.TEXT : field.type) : null
        const label = field?.label || key
        if (key === UserAdditionalFieldProperties.BUSINESS_TYPE && field?.options) {
          const valueIndex = field.options?.findIndex(val => val.toString().toLowerCase() === value)
          const arrayValue = valueIndex && valueIndex >= 0 ? field.options[valueIndex] : value
          return { name: key, label, value: `${arrayValue}`, inputType }
        }
        return { name: key, label, value: `${value}`, inputType }
      }
    })
    .filter(value => value != undefined)

  let additionalDocumentInformation

  if (typeof user[UserProperties.ADDITIONAL_DOCUMENT_INFORMATION] === 'object') {
    additionalDocumentInformation = Object.entries(user[UserProperties.ADDITIONAL_DOCUMENT_INFORMATION])
      .map(([key, value]) => {
        // The check for `INVALID_DATE` here is to ensure that date values are of a valid format
        if (!value || (typeof value === 'string' && value.length > 0 && value.toLowerCase() === INVALID_DATE)) {
          return { name: key, label: key, value: null, inputType: null }
        } else {
          if (Array.isArray(value)) {
            if (value.length === 0) {
              value = null
            } else {
              value = value.join(', ')
            }
          }
          const field = userDocumentFields.find(field => field.identifier === key)
          const inputType = field ? field.type : null
          const label = field?.label || key
          return { name: key, label, value: `${value}`, inputType }
        }
      })
      .filter(value => value != undefined)
  }

  const formData = new FormData()
  formData.append('firstName', user[UserProperties.FIRST_NAME] || '')
  formData.append('lastName', user[UserProperties.LAST_NAME] || '')
  formData.append('phoneNumber', user[UserProperties.PHONE_NUMBER] || '')
  formData.append('birthDate', user[UserProperties.DATE_OF_BIRTH] || '')

  if (additionalFields) {
    formData.append('additionalFields', JSON.stringify(additionalFields))
  }
  if (additionalDocumentInformation) {
    formData.append('additionalDocumentInformation', JSON.stringify(additionalDocumentInformation))
  }

  return formData
}

export default updateUserDetails
