import React, { ReactElement } from 'react'
import { StyleSheet, View, Image, Platform } from 'react-native'
import { Button, Card } from 'react-native-paper'
import fallbackImage from '../../assets/no-preview-available.png'
import { FileValue } from '../types/application/formField.types'

const styles = StyleSheet.create({
  grid: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  buttons: {
    flexDirection: 'row-reverse',
    marginLeft: 0,
  },
  card: {
    width: 300,
    maxWidth: '100%',
    margin: 20
  }, 
  button: {
    flex: 1,
    margin: 0,
  }
})

interface Props {
  images: FileValue[]
  actions?: { label: string; fn: (index: number) => unknown }[]
}

const ImageGrid = (props: Props): ReactElement | null => {
  const { images, actions } = props
  if (!images.length) {
    // adding to an empty list causes itemDimension to be ignored, so re-render the list when you get a new item
    return null
  }

  const platformSpecificButtonsStyles = () => {
    if (Platform.OS === 'web') {
      return {
        paddingLeft: 0,
      }
    } else {
      return {
        paddingRight: 0
      }
    }
  }

  let defaultImage = ""
  if (Platform.OS === "web") { 
    defaultImage = fallbackImage
  } else {
    defaultImage = Image.resolveAssetSource(fallbackImage)?.uri
  }

  return (
    <View style={styles.grid}>
      {images.map((image, index) => (
        <Card key={index} style={styles.card}>
          <Card.Cover source={{ uri: image?.uri ?? defaultImage }} testID={image?.uri} />
          {actions ? (
            <Card.Actions style={[styles.buttons, {...platformSpecificButtonsStyles()}]}>
              {actions.map(action => (
                <Button key={action.label} style={styles.button}onPress={() => action.fn(index)}>
                  {action.label}
                </Button>
              ))}
            </Card.Actions>
          ) : null}
        </Card>
      ))}
    </View>
  )
}

export default ImageGrid
