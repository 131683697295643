import React, { ReactElement } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Badge, Caption, Card } from 'react-native-paper'
import { colours } from '../styleguide'

interface Props {
  council: string
  market: string
  key: string | undefined
  isExpired: boolean
  onPress: () => void
}

const styles = StyleSheet.create({
  applicationSummary: {
    marginTop: '3%',
  },
  cardText: {
    padding: 10,
    flexDirection: 'row',
  },
  cardTitle: {
    fontSize: 15,
  },
  cardDescription: {
    fontSize: 12,
    color: colours.description,
  },
  licenceStatus: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  view: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  caption: {
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 15,
  },
})

const LicenceSummary = (props: Props): ReactElement => {
  const { council, market, isExpired, onPress } = props

  return (
    <Card onPress={onPress} style={styles.applicationSummary}>
      <View style={styles.cardText}>
        <View>
          <Text style={styles.cardTitle}>{council}</Text>
          <Text style={styles.cardDescription}>{market}</Text>
        </View>
        {isExpired && (
          <View style={styles.licenceStatus}>
            <View style={styles.view} accessibilityRole="summary">
              <View>
                <Badge
                  style={{
                    backgroundColor: colours.status.rejected,
                  }}
                  size={15}
                />
              </View>
              <Caption style={styles.caption}>Expired</Caption>
            </View>
          </View>
        )}
      </View>
    </Card>
  )
}

export default LicenceSummary
