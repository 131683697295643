import { AnyField, FormFieldTypes } from '../types/application/formField.types'

import { ValidatorTypes } from '../types/application/validator.types'
import { AssistantProperties, AssistantDocumentProperties } from '../types/user.types'

import { requiredValidator } from './genericValidators'

export const assistantForm: AnyField[] = [
  {
    type: FormFieldTypes.TEXT,
    identifier: AssistantProperties.FIRST_NAME,
    label: 'First name',
    validators: [requiredValidator],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.TEXT,
    identifier: AssistantProperties.LAST_NAME,
    label: 'Last name',
    validators: [requiredValidator],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.TEXTAREA,
    identifier: AssistantProperties.ADDRESS,
    label: 'Home address',
    validators: [],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.TEXT,
    identifier: AssistantProperties.NATIONAL_INSURANCE_NUMBER,
    label: 'National insurance number',
    validators: [
      {
        type: ValidatorTypes.TEXTMATCH,
        failureMessage: 'This is not a valid national insurance number',
        arguments: {
          pattern: '^[A-CEGHJ-PR-TW-Z]{2}\\d{6}[A-D]$',
        },
      },
    ],
    error: '',
    value: '',
    upperCase: true,
  },
  {
    type: FormFieldTypes.DATE,
    identifier: AssistantProperties.DATE_OF_BIRTH,
    label: 'Date of birth',
    validators: [
      {
        type: ValidatorTypes.PAST_TIME,
        failureMessage: 'The entered date of birth must be in the past',
      },
    ],
    error: '',
    value: null,
  },
  {
    type: FormFieldTypes.TOGGLE,
    identifier: AssistantProperties.IS_SUBJECT_TO_IMMIGRATION_CONTROL,
    label: 'Is the assistant subject to immigration control?',
    validators: [],
    error: '',
    value: false,
  },
  {
    type: FormFieldTypes.TOGGLE,
    identifier: AssistantProperties.IS_MANAGER,
    label: 'Is the assistant a manager? (A licence application can have up to 1 manager and 3 additional assistants).',
    validators: [],
    error: '',
    value: false,
  },
  {
    type: FormFieldTypes.IMAGE,
    identifier: AssistantDocumentProperties.ASSISTANT_PHOTO,
    label: 'Please upload a photograph of the assistant, clear passport style with a white background.',
    validators: [],
    error: '',
    value: [],
  },
  {
    type: FormFieldTypes.IMAGE,
    identifier: AssistantDocumentProperties.PROOF_OF_ID,
    label: `Please upload proof of the assistant's identity (passport or driving licence). The ID document must be valid, not expired and include a high quality photo of the assistant.`,
    validators: [],
    error: '',
    value: [],
  },
  {
    type: FormFieldTypes.IMAGE,
    identifier: AssistantDocumentProperties.PROOF_OF_ADDRESS,
    label: `Please upload proof of the assistant's home address (utility bill or bank statement within the last 3 months).`,
    validators: [],
    error: '',
    value: [],
  },
  {
    type: FormFieldTypes.IMAGE,
    identifier: AssistantDocumentProperties.NATIONAL_INSURANCE_PROOF_PHOTO,
    label: `Upload a scan of the assistant's national insurance card, or proof of their national insurance number (e.g. a payslip).`,
    validators: [],
    error: '',
    value: [],
  },
]

export const requiredMyDetailsForm: AnyField[] = assistantForm.map(field => {
  return {
    ...field,
    validators: [...(field.validators || [])],
  }
})
