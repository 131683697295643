import { RouteProp as Route } from '@react-navigation/native'
import { StackNavigationProp as Navigation } from '@react-navigation/stack'
import { Application } from './application/application.types'
import { Council } from './miscellaneous.types'

export interface NavAction {
  title: string
  fn: () => unknown
}

export enum Pages {
  LOGIN = 'Login',
  REGISTER = 'Register',
  RECOVER_ACCOUNT = 'Recover account',
  VERIFY_ACCOUNT = 'Verify account',
  WELCOME = 'Welcome',
  WELCOME_MESSAGE = 'How Open Markets works',
  APPLICATIONS = 'Applications',
  MY_MESSAGES = 'Messages',
  MESSAGE = 'Message',
  NEW_APPLICATION = 'New application',
  FILL_OUT_APPLICATION = 'Fill out application',
  FINALISE = 'Application submission',
  ERROR = 'Something went wrong',
  PROFILE_AND_ACCOUNT = 'Profile and account',
  APPLICATION_SUMMARY = 'Application summary',
  MY_DETAILS = 'My details',
  MY_DOCUMENTS = 'My documents',
  MY_ACCOUNT = 'Settings',
  MISSING_PROFILE_INFORMATION = 'Missing information',
  FAQ = 'FAQ',
  HELP = 'Help',
  LICENCES = 'Licences',
  LICENCE_SUMMARY = 'Licence summary',
  PAYMENT = 'Payment',
  ASSISTANTS = 'Assistants',
  ASSISTANT_SUMMARY = 'Assistant summary',
  NEW_ASSISTANT = 'New assistant',
  ADMIN_VERIFICATION = 'Admin verification',
}

type RootPageParams = {
  [Pages.APPLICATIONS]: undefined
  [Pages.MY_MESSAGES]: undefined
  [Pages.MESSAGE]: {
    title: string
    council: Council
    externalTaskId: number
  }
  [Pages.ERROR]: {
    errorMessage?: string
    resetError: () => void
  }
  [Pages.LOGIN]: undefined
  [Pages.ADMIN_VERIFICATION]: {
    handleAdminLogin: () => Promise<void>
  }
  [Pages.REGISTER]: undefined
  [Pages.RECOVER_ACCOUNT]: undefined
  [Pages.VERIFY_ACCOUNT]: undefined
  [Pages.WELCOME]: undefined
  [Pages.NEW_APPLICATION]: undefined
  [Pages.PROFILE_AND_ACCOUNT]: undefined
  [Pages.FILL_OUT_APPLICATION]: {
    application: Application
    onStage: number
  }
  [Pages.FINALISE]: { application: Application; status: number; submitApplicationForm: () => void }
  [Pages.APPLICATION_SUMMARY]: {
    applicationId: string
  }
  [Pages.MISSING_PROFILE_INFORMATION]: { application: Application }
  [Pages.LICENCES]: undefined
  [Pages.LICENCE_SUMMARY]: {
    licenceId: number
  }
  [Pages.PAYMENT]: {
    applicationId: number
    workspaceId: number
    licenceFee: number
    paymentAuthToken: string
  }
  [Pages.ASSISTANTS]: undefined
  [Pages.ASSISTANT_SUMMARY]: {
    assistantId?: number
    onSubmit?: () => void
  }
  [Pages.NEW_ASSISTANT]: {
    assistantId?: number
    onSubmit?: () => void
  }
}

type TabPageParams = {
  [Pages.WELCOME_MESSAGE]: undefined
  [Pages.MY_DETAILS]: undefined
  [Pages.MY_DOCUMENTS]: undefined
  [Pages.MY_ACCOUNT]: undefined
  [Pages.FAQ]: undefined
  [Pages.HELP]: undefined
}

export type RootStackParamList = RootPageParams & TabPageParams

type RouteProp<ScreenPage extends keyof RootStackParamList> = Route<RootStackParamList, ScreenPage>

type NavigationProp<ScreenPage extends keyof RootStackParamList> = Navigation<RootStackParamList, ScreenPage>

export interface ScreenProps<Page extends keyof RootStackParamList> {
  route: RouteProp<Page>
  navigation: NavigationProp<Page>
}
