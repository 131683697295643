import Constants from 'expo-constants'
import React, {ReactElement, useState} from "react";
import {View, StyleSheet, Switch, Text, Platform, Linking} from "react-native";
import {Icon} from "react-native-elements";
import {TouchableOpacity} from "react-native-gesture-handler";
import {Card} from "react-native-paper";
import { ERRORS } from '../../constants';
import patchUser from '../helpers/api/patchUser';
import postDeviceToken from '../helpers/api/postDeviceToken';
import signOutUser from '../helpers/firebase/auth/signOutUser';
import { registerForPushNotificationsAsync } from '../helpers/notifications/notificationBackgroundFetch';
import useOMLContext from '../hooks/useOMLContext';
import usePushNotifications from '../hooks/usePushNotifications';
import ModalTemplate from "../molecules/ModalTemplate";
import PrivacyPolicy from "../molecules/PrivacyPolicy"
import TermsAndConditions from "../molecules/TermsAndConditions"
import {colours} from "../styleguide";
import BannerTemplate from "../templates/BannerTemplate";
import {Pages} from "../types/navigation.types";
import { User, UserProperties } from '../types/user.types';

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    maxWidth: 500,
    width: "100%",
  },
  cardContainer: {
      maxWidth: "100%",
      width: "100%",
  },
  appVersionContainer: {
    maxWidth: "100%",
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  appVersion: {
    textAlign: "center",
    color: colours.description,
  },
  switchContainer: {
    flex: 1,
    alignItems: "flex-end",
  },
  switch: {
    transform: Platform.OS === 'android' ? [{ scaleX: 1 }, { scaleY: 1 }] : [{ scaleX: 0.7 }, { scaleY: 0.7 }],
  },
  label: {
    color: colours.black,
  },
  icon: {
    marginRight: 10,
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    padding: "2%",
  },
  contentGroup: {
    flexDirection: "row",
    alignItems: "center",
  },
  notificationContent: {
    width: "100%",
    padding: "2%",
  },
  notificationType: {
    paddingTop: "1%",
    marginLeft: "10%",
    flexDirection: "row",
    alignItems: "center",

  },
});

const MyAccountScreen = (props: { navigation: any }): ReactElement => {
    const {navigation} = props;

    const [context, setContext] = useOMLContext();

    const [privacyModalVisible, setPrivacyModalVisible] =
        useState<boolean>(false);
    const [termsModalVisible, setTermsVisible] = useState<boolean>(false);

    const {
      setExpoPushToken,
    } = usePushNotifications();


    const togglePushNotifications = async (value: boolean) => {
      const formData = new FormData()
      formData.append('sendPushNotifications', `${value}`)

      if (context.externalUser) {
        const externalUserId = context.externalUser[UserProperties.EXTERNAL_USER_ID]
        if (context.externalUser[UserProperties.SEND_PUSH_NOTIFICATIONS] === false) {
          registerForPushNotificationsAsync().then((token) => {
            if (token?.data) {
              setExpoPushToken(token.data)
              postDeviceToken(externalUserId, token.data)
              patchUser(formData, externalUserId).then(response => {
                if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
                  setContext({
                    ...context,
                    error: ERRORS.UPGRADE_REQUIRED
                  })
                } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
                  signOutUser()
                } else if (response.status === 200) {
                  const updatedUser = {
                    ...context.externalUser,
                  } as User
                  updatedUser[UserProperties.SEND_PUSH_NOTIFICATIONS] = value
                  setContext({ ...context, externalUser: updatedUser });
                }
              })
            } else {
              Linking.openSettings()
            }
          })
        } else {
          patchUser(formData, externalUserId).then(response => {
            if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
              setContext({
                ...context,
                error: ERRORS.UPGRADE_REQUIRED
              })
            } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
              signOutUser()
            } else if (response.status === 200) {
              const updatedUser = {
                ...context.externalUser,
              } as User
              updatedUser[UserProperties.SEND_PUSH_NOTIFICATIONS] = value
              setContext({ ...context, externalUser: updatedUser });
            }
          });
        }
      }
    }

    const toggleEmailNotifications = async (value: boolean) => {
      const formData = new FormData()
      formData.append('sendEmailNotifications', `${value}`)

      if (context.externalUser) {
        await patchUser(formData, context.externalUser.id).then(response => {
          if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
            setContext({
              ...context,
              error: ERRORS.UPGRADE_REQUIRED
            })
          } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
            signOutUser()
          } else if (response.status === 200) {
            const updatedUser = {
              ...context.externalUser,
            } as User
            updatedUser[UserProperties.SEND_EMAIL_NOTIFICATIONS] = value
            setContext({ ...context, externalUser: updatedUser });
          }
        });
      }
    }

    const togglePrivacyPolicy = () => {
        setPrivacyModalVisible(!privacyModalVisible);
    };
    const toggleTermsAndConditions = () => {
        setTermsVisible(!termsModalVisible);
    };

    const notificationsIcon = () => {
        return context.externalUser?.[UserProperties.SEND_PUSH_NOTIFICATIONS] || context.externalUser?.[UserProperties.SEND_EMAIL_NOTIFICATIONS] ? (
          <Icon
              name="notifications-outline"
              type="ionicon"
              color={colours.darkGrey}
              size={20}
              style={styles.icon}
          />
        )
         : 
        (
          <Icon
              name="ios-notifications-off-outline"
              type="ionicon"
              color={colours.darkGrey}
              size={20}
              style={styles.icon}
          />
        );
    };

    return (
        <BannerTemplate testID="myAccountScreen">
            <View style={styles.container}>
                <Card.Content style={styles.cardContainer}>
                    <View style={styles.notificationContent}>
                        <View style={styles.contentGroup}>
                          {notificationsIcon()}
                          <Text style={styles.label}>Notifications</Text>
                        </View>
                        <View style={styles.notificationType}>
                            <Text style={styles.label}>Push notifications</Text>
                            <View style={styles.switchContainer}>
                                <Switch
                                    trackColor={{
                                    false: colours.darkGrey,
                                    true: colours.primary
                                    }}
                                    ios_backgroundColor={colours.darkGrey}
                                    thumbColor={colours.white}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    //@ts-expect-error
                                    activeThumbColor={colours.white}
                                    onValueChange={togglePushNotifications}
                                    value={context.externalUser?.[UserProperties.SEND_PUSH_NOTIFICATIONS]}
                                    style={styles.switch}
                                />
                            </View>
                        </View>
                        <View style={styles.notificationType}>
                            <Text style={styles.label}>Email notifications</Text>
                            <View style={styles.switchContainer}>
                                <Switch
                                    trackColor={{
                                    false: colours.darkGrey,
                                    true: colours.primary
                                    }}
                                    ios_backgroundColor={colours.darkGrey}
                                    thumbColor={colours.white}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    //@ts-expect-error
                                    activeThumbColor={colours.white}
                                    onValueChange={toggleEmailNotifications}
                                    value={context.externalUser?.[UserProperties.SEND_EMAIL_NOTIFICATIONS]}
                                    style={styles.switch}
                                />
                            </View>
                        </View>
                    </View>
                    <TouchableOpacity onPress={togglePrivacyPolicy}>
                        <View style={styles.content}>
                            <Icon
                                name="privacy-tip"
                                type="materialicon"
                                color={colours.darkGrey}
                                size={20}
                                style={styles.icon}
                            />
                            <Text style={styles.label}>Privacy Policy</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={toggleTermsAndConditions}>
                        <View style={styles.content}>
                            <Icon
                                name="ios-document-text-outline"
                                type="ionicon"
                                color={colours.darkGrey}
                                size={20}
                                style={styles.icon}
                            />
                            <Text style={styles.label}>Terms of Service</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate(Pages.HELP);
                        }}
                    >
                        <View style={styles.content}>
                            <Icon
                                name="help-outline"
                                type="materialicon"
                                color={colours.darkGrey}
                                size={20}
                                style={styles.icon}
                            />
                            <Text style={styles.label}>Help</Text>
                        </View>
                    </TouchableOpacity>
                </Card.Content>
                <View style={styles.appVersionContainer}>
                  <Text style={styles.appVersion}>Version {Constants?.expoConfig?.version}</Text>
                </View>
            </View>
            <ModalTemplate
                title="Privacy Policy"
                content={<PrivacyPolicy/>}
                setModalVisible={togglePrivacyPolicy}
                isVisible={privacyModalVisible}
            />
            <ModalTemplate
                title="Terms of Service"
                content={<TermsAndConditions/>}
                setModalVisible={toggleTermsAndConditions}
                isVisible={termsModalVisible}
            />
        </BannerTemplate>
    );
};

export default MyAccountScreen;