/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { useFocusEffect } from '@react-navigation/native'
import Constants from 'expo-constants'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { StyleSheet, View, Text, Platform, Animated, SafeAreaView } from 'react-native'
import { ActivityIndicator, Button, Card, Subheading } from 'react-native-paper'
import { WebView } from 'react-native-webview'
import createPayPalOrder from '../helpers/api/createPayPalOrder'
import getPayPalConfig from '../helpers/api/getPayPalConfig'
import postFinaliseLicencePayment from '../helpers/api/postFinaliseLicencePayment'
import processPayPalPayment from '../helpers/api/processPayPalPayment'
import ErrorCard from '../molecules/ErrorCard'
import { colours } from '../styleguide'
import { Pages, ScreenProps } from '../types/navigation.types'

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    paddingTop: 30,
    flex: 1,
  },
  webContainer: {
    paddingHorizontal: 20,
    flexGrow: 1,
    maxWidth: '100%',
    width: 500,
    paddingBottom: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinnerContainer: {
    position: 'absolute',
    flex: 1,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colours.backgroundGrey,
  },
  loadingText: {
    color: colours.description,
    opacity: 0.7,
    marginTop: 10,
  },
  payPalButtonsContainer: {
    flex: 1,
    width: '100%',
  },
  card: {
    maxWidth: 360,
    marginBottom: 20,
  },
  button: {
    marginTop: 10,
    backgroundColor: colours.primary,
  },
  successText: {
    marginVertical: 10,
  },
  subheading: {
    fontWeight: '500',
  },
  paymentStatusContainer: {
    marginTop: Platform.OS === 'web' ? 10 : 0,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentFailedText: {
    color: colours.errorPrimary,
    fontWeight: '500',
  },
  mobileContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingBottom: 50,
  },
  title: {
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleContainerMobile: {
    paddingHorizontal: 15,
  },
  paymentDetail: {
    marginTop: '5%',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    paddingBottom: '1%',
    marginBottom: '5%',
    borderBottomColor: colours.darkGrey,
    width: '100%',
  },
  paymentName: {
    fontSize: 15,
    fontWeight: '700',
    flex: 1,
    color: colours.description,
  },
  price: {
    fontSize: 18,
    fontWeight: '700',
    flex: 1,
    textAlign: 'right',
  },
})

const PaymentScreen = (props: ScreenProps<Pages.PAYMENT>): ReactElement => {
  const { route, navigation } = props

  const [loading, setLoading] = useState<boolean>(true)
  const [merchantId, setMerchantId] = useState<string>('')
  const [authToken, setAuthToken] = useState<string>('')
  const [clientId, setClientId] = useState<string>('')
  const [partnerAttributionId, setPartnerAttributionId] = useState<string>('')
  const [authAssertion, setAuthAssertion] = useState<string>('')
  const [paymentStatus, setPaymentStatus] = useState<string>()
  const [isWebViewLoading, setIsWebViewLoading] = useState<boolean>(true)
  const [isProcessingPayment, setIsProcessingPayment] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const payByCardAnimation = React.useRef(new Animated.Value(0)).current
  payByCardAnimation.addListener(() => {
    return
  })

  useEffect(() => {
    if (clientId.length && authToken.length) {
      setLoading(false)
    }
  }, [clientId, authToken])

  useFocusEffect(
    useCallback(() => {
      getPayPalConfig(route.params.workspaceId).then(res => {
        if (res && Object.keys(res).length) {
          const { merchantId, clientId, payPalAuthToken, payPalPartnerAttributionId, authAssertion } = res
          setMerchantId(merchantId)
          setAuthToken(payPalAuthToken)
          setClientId(clientId)
          setPartnerAttributionId(payPalPartnerAttributionId)
          setAuthAssertion(authAssertion)
        } else {
          setIsError(true)
          setLoading(false)
        }
      })
    }, [route.params.applicationId])
  )

  const createOrderWeb = async (): Promise<string> => {
    const { id } = await createPayPalOrder(
      authToken,
      route.params.applicationId,
      route.params.licenceFee,
      merchantId,
      partnerAttributionId,
      authAssertion
    )
    return id
  }

  const onApprove = async (data: { orderID: string }): Promise<void> => {
    setLoading(true)
    if (!authToken) {
      getPayPalConfig(route.params.workspaceId).then(res => {
        if (res && Object.keys(res).length) {
          const { payPalAuthToken } = res
          processPayment(payPalAuthToken, data.orderID)
        } else {
          setPaymentStatus('FAILED')
          setLoading(false)
        }
      })
    } else {
      processPayment(authToken, data.orderID)
    }
  }

  const processPayment = async (payPalAuthToken: string, orderId: string) => {
    processPayPalPayment(payPalAuthToken, orderId, partnerAttributionId, authAssertion).then(res => {
      const { status } = res
      if (status === 'COMPLETED') {
        setPaymentStatus(status)
        setLoading(false)
        postFinaliseLicencePayment(route.params.applicationId)
      } else {
        setPaymentStatus('FAILED')
        setLoading(false)
      }
    })
  }

  const goHome = () =>
    navigation.reset({
      index: 0,
      routes: [{ name: Pages.APPLICATIONS }],
    })

  const onLoad = (state: { url: string }) => {
    if (state.url.toLowerCase().includes('status=completed')) {
      setLoading(false)
      setPaymentStatus('COMPLETED')
      postFinaliseLicencePayment(route.params.applicationId)
    } else if (state.url.toLowerCase().includes('status=')) {
      setLoading(false)
      setPaymentStatus('FAILED')
    }
  }

  const onMessage = (message: string) => {
    if (message.toLowerCase() === 'processing') {
      setIsProcessingPayment(true)
      setIsWebViewLoading(true)
    }
  }

  const retryPayment = (applicationId: number) => {
    navigation.replace(Pages.PAYMENT, {
      applicationId,
      workspaceId: route.params.workspaceId,
      licenceFee: route.params.licenceFee,
      paymentAuthToken: route.params.paymentAuthToken,
    })
  }

  if (isError) {
    return (
      <View style={[styles.container, Platform.OS === 'web' && { alignItems: 'center' }]} testID="paymentScreen">
        <ErrorCard title="Sorry" bodyText="This payment cannot be proccessed right now. Please try again later." />
      </View>
    )
  }

  return (
    <View style={[styles.container, Platform.OS === 'web' && { alignItems: 'center' }]} testID="paymentScreen">
      {loading && Platform.OS === 'web' && (
        <View style={styles.flex}>
          <View testID="Loading" style={styles.spinnerContainer}>
            <ActivityIndicator size="large" animating={true} />
            <Text style={styles.loadingText}>Loading</Text>
          </View>
        </View>
      )}
      {!loading && Platform.OS === 'web' && paymentStatus !== 'COMPLETED' && (
        <View style={styles.webContainer}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>Payment Summary</Text>
          </View>
          <View style={styles.paymentDetail}>
            <Text style={styles.paymentName}>Licence Fee</Text>
            <Text style={styles.price}>£{route.params.licenceFee}</Text>
          </View>
          {paymentStatus === 'FAILED' && (
            <View style={styles.paymentStatusContainer}>
              <Text style={styles.paymentFailedText}>
                We weren&apos;t able to process your payment. Please try again.
              </Text>
            </View>
          )}
          <View style={styles.payPalButtonsContainer}>
            {Platform.OS === 'web' && (
              <PayPalScriptProvider
                options={{
                  merchantId,
                  clientId,
                  currency: 'GBP',
                }}
              >
                <PayPalButtons createOrder={createOrderWeb} onApprove={onApprove} />
              </PayPalScriptProvider>
            )}
          </View>
        </View>
      )}
      {!isNaN(route.params.applicationId) && Platform.OS !== 'web' && paymentStatus !== 'COMPLETED' && (
        <View style={styles.mobileContainer}>
          {paymentStatus !== 'FAILED' ? (
            <View style={styles.flex}>
              {!isWebViewLoading && (
                <View style={[styles.titleContainer, styles.titleContainerMobile]}>
                  <Text style={styles.title}>Payment Summary</Text>
                </View>
              )}
              {isProcessingPayment && (
                <View testID="Loading" style={[styles.spinnerContainer, { zIndex: +1 }]}>
                  <ActivityIndicator size="large" animating={true} />
                  <Text style={styles.loadingText}>Loading</Text>
                </View>
              )}
              <WebView
                source={{
                  uri: `${Constants?.expoConfig?.extra?.hostname}/integrations/paypal/payment?token=${route.params.paymentAuthToken}`,
                }}
                startInLoadingState={true}
                renderLoading={() => {
                  return (
                    <SafeAreaView testID="Loading" style={styles.spinnerContainer}>
                      <ActivityIndicator size="large" animating={true} />
                      <Text style={styles.loadingText}>Loading</Text>
                    </SafeAreaView>
                  )
                }}
                onLoadEnd={() => setIsWebViewLoading(false)}
                onNavigationStateChange={onLoad}
                onMessage={event => onMessage(event.nativeEvent.data)}
                style={{ flex: isWebViewLoading ? 0 : 1 }}
              />
            </View>
          ) : (
            <View style={styles.paymentStatusContainer}>
              <Card style={styles.card}>
                <Card.Title title="Payment Failed!" />
                <Card.Content>
                  <Text style={styles.successText}>We weren&apos;t able to process your payment.</Text>
                  <Button
                    mode="elevated"
                    textColor={colours.black}
                    onPress={() => retryPayment(Number(route.params.applicationId))}
                    style={styles.button}
                  >
                    Try again
                  </Button>
                </Card.Content>
              </Card>
            </View>
          )}
        </View>
      )}
      {paymentStatus === 'COMPLETED' && (
        <View style={styles.paymentStatusContainer}>
          <Card style={styles.card}>
            <Card.Title title="Success!" />
            <Card.Content>
              <Subheading style={styles.subheading}>Payment Submitted</Subheading>
              <Text style={styles.successText}>
                Your payment was successful. Please allow time for us to review and finalise your licence application.
              </Text>
              <Button mode="elevated" textColor={colours.black} onPress={goHome} style={styles.button}>
                Go to your applications
              </Button>
            </Card.Content>
          </Card>
        </View>
      )}
    </View>
  )
}

export default PaymentScreen
