import Constants from 'expo-constants'
import { retrieveTabledAuthToken } from '../localStorage'

const postMessage = async (
  userId: number,
  messageContent: string,
  workspaceId: number,
  externalTaskId?: number
): Promise<Response> => {
  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const formData = new FormData()

  formData.append('content', messageContent)
  formData.append('externalUserId', JSON.stringify(userId))

  return fetch(
    new Request(`${Constants?.expoConfig?.extra?.hostname}/ext/tasks/${externalTaskId}/messages`, { method: 'POST' }),
    {
      body: formData,
      headers,
    }
  )
}

export default postMessage
