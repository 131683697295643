import { FormFieldTypes } from '../types/application/formField.types'
import { ValidatorTypes } from '../types/application/validator.types'

export const registrationForm = [
  {
    type: FormFieldTypes.TEXT,
    identifier: 'email',
    label: 'Email',
    validators: [
      {
        type: ValidatorTypes.REQUIRED,
        failureMessage: 'An email is required',
      },
    ],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.PASSWORD_TEXT,
    identifier: 'password',
    label: 'Password',
    validators: [
      {
        type: ValidatorTypes.REQUIRED,
        failureMessage: 'A password is required',
      },
      {
        type: ValidatorTypes.TEXTMATCH,
        failureMessage: 'The password should have at least eight characters, including one letter and one number',
        arguments: {
          pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[!-~]{8,}$',
        },
      },
    ],
    error: '',
    value: '',
  },
  {
    type: FormFieldTypes.PASSWORD_TEXT,
    identifier: 'passwordRepeat',
    label: 'Confirm password',
    validators: [
      {
        type: ValidatorTypes.REQUIRED,
        failureMessage: 'Please confirm your password',
      },
      {
        type: ValidatorTypes.IDENTITY,
        failureMessage: 'Passwords do not match',
        arguments: {
          partners: ['password'],
        },
      },
    ],
    error: '',
    value: '',
  },
]
