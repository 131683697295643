import Constants from "expo-constants";
import {MutableRefObject, ReactElement, useEffect, useRef, useState} from "react";
import SailsIOJS from "sails.io.js";
import io from "socket.io-client";
import { ERRORS } from "./constants";
import {retrieveTabledAuthToken} from "./src/helpers/localStorage";
import {loadUserMessages} from "./src/helpers/user/loadUser";
import useOMLContext from "./src/hooks/useOMLContext";
import {Client} from "./src/types/sails/sails.types"
import {UnreadMessages, Message, Messages, UserProperties} from "./src/types/user.types";

interface Props {
  setShouldReloadApplications: (value: boolean) => void;
}

const WebSocket = (props: Props): ReactElement => {

  const {
    setShouldReloadApplications,
  } = props;

  const client: MutableRefObject<Client | null> = useRef(null);
  const currentExternalUserId: MutableRefObject<number | null> = useRef(null);

  const [messages, setMessages] = useState({} as Messages);
  const messagesRef = useRef({} as Messages);

  const [unreadMessages, setUnreadMessages] = useState({});
  const unreadMessagesRef: MutableRefObject<UnreadMessages> = useRef({});

  const [context, setContext] = useOMLContext();

  useEffect(() => {
    if (!context.externalUser) {
      return;
    }

    unreadMessagesRef.current = unreadMessages;
  }, [unreadMessages]);

  useEffect(() => {
    unreadMessagesRef.current = context.externalUser?.[UserProperties.UNREAD_MESSAGES] ?? {};
  }, [context.externalUser?.[UserProperties.UNREAD_MESSAGES]])

  useEffect(() => {
    if (!context.externalUser) {
      return;
    }

    messagesRef.current = messages;

    setContext({
      ...context,
      externalUser: {
        ...context.externalUser,
        [UserProperties.MESSAGES]: messages,
        [UserProperties.UNREAD_MESSAGES]: unreadMessages,
      },
    });
  }, [messages]);

  useEffect(() => {
    messagesRef.current = context.externalUser?.[UserProperties.MESSAGES] ?? {};
  }, [context.externalUser?.[UserProperties.MESSAGES]])

  useEffect(() => {
    retrieveTabledAuthToken().then((token) => {
      // IF LOGGED OUT THEN DELETE ROOM CONNECTION
      if (!context.externalUser && client.current?.socket && currentExternalUserId?.current) {
        // Message socket
        client.current?.socket.request({
          method: 'delete',
          url: `/ext/external-users/${currentExternalUserId?.current}/messages/connection`,
          headers: {
            'tabled-auth-token': token,
            'api-version': Constants?.expoConfig?.extra?.apiVersion,
          },
        });
        // Task socket
        client.current?.socket.request({
          method: 'delete',
          url: `/ext/external-users/${currentExternalUserId?.current}/tasks/connection`,
          headers: {
            'tabled-auth-token': token,
            'api-version': Constants?.expoConfig?.extra?.apiVersion,
          },
        });

        setMessages({})
        setUnreadMessages({})
      }
    })
  }, [context.externalUser?.[UserProperties.EXTERNAL_USER_ID]])

  useEffect(() => {
      if (!client.current) {
        const socketClient: Client = SailsIOJS(io);

        socketClient.sails.url = `${Constants?.expoConfig?.extra?.hostname}`;
        socketClient.sails.path = "/socket.io";
        socketClient.sails.reconnection = true;
        socketClient.sails.timeout = 59999;

        client.current = socketClient;

        // ON NEW MESSAGE RECEIVED
        client.current.socket.on(`message`, msg => {
          onMessageReceived(msg);
        });
        
        client.current.socket.on(`task-update`, () => {
          onTaskUpdate();
        });
      }

      if (!context.externalUser) {
        return;
      }

      // TODO verify this
      joinMessageRoom()
      joinTaskRoom()

      client.current.socket.on('reconnect', async () => {
        if (!context.externalUser?.[UserProperties.EXTERNAL_USER_ID]) {
          return;
        }

        try {
          const {messages, unreadMessages} = await loadUserMessages(
            context.externalUser[UserProperties.EXTERNAL_USER_ID]
          )
  
          setMessages(messages);
          setUnreadMessages(unreadMessages);
  
          joinMessageRoom()
          joinTaskRoom()
        } catch (err) {
          if (err instanceof Error && err.message === ERRORS.UPGRADE_REQUIRED.message) {
            setContext({
              ...context,
              error: ERRORS.UPGRADE_REQUIRED
            })
          }
        }
      });

      currentExternalUserId.current = context.externalUser?.[UserProperties.EXTERNAL_USER_ID];

    }, [context.externalUser?.[UserProperties.EXTERNAL_USER_ID]]
  )

  const onMessageReceived = (incomingMessage: Message) => {
    const updatedMessages: Message[] = messagesRef.current[incomingMessage.externalTask] ?? [];
    updatedMessages.push(incomingMessage);

    setMessages({...messagesRef.current, [incomingMessage.externalTask]: [...updatedMessages]} as Messages)

    if (incomingMessage.externalRecipient && !incomingMessage.externalSentBy) {
      const currentUnreadCount = unreadMessagesRef.current[incomingMessage.externalTask] ?? 0;
      setUnreadMessages({...unreadMessagesRef.current, [incomingMessage.externalTask]: currentUnreadCount + 1});
    }
  };
  const onTaskUpdate = () => {
    setShouldReloadApplications(true)
  };

  const joinMessageRoom = () => {
    retrieveTabledAuthToken().then(token => {
      client.current?.socket.request({
        method: 'get',
        url: `/ext/external-users/${context.externalUser?.[UserProperties.EXTERNAL_USER_ID]}/messages/connection`,
        headers: {
          'tabled-auth-token': token,
          'api-version': Constants?.expoConfig?.extra?.apiVersion,
        },
      }, function (resData, jwres) {
        if (jwres.error) {
          if (jwres.statusCode === ERRORS.UPGRADE_REQUIRED.statusCode) {
            setContext({
              ...context,
              error: ERRORS.UPGRADE_REQUIRED
            })
          }
          return;
        }
      });
    })
  }

  const joinTaskRoom = () => {
    retrieveTabledAuthToken().then(token => {
      client.current?.socket.request({
        method: 'get',
        url: `/ext/external-users/${context.externalUser?.[UserProperties.EXTERNAL_USER_ID]}/tasks/connection`,
        headers: {
          'tabled-auth-token': token,
          'api-version': Constants?.expoConfig?.extra?.apiVersion,
        },
      }, function (resData, jwres) {
        if (jwres.error) {
          if (jwres.statusCode === ERRORS.UPGRADE_REQUIRED.statusCode) {
            setContext({
              ...context,
              error: ERRORS.UPGRADE_REQUIRED
            })
          }
          return;
        }
      });
    })
  }

  return (null as unknown as ReactElement);
}

export default WebSocket;