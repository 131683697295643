import { AnyField } from '../../../../types/application/formField.types'
import { TextmatchValidator } from '../../../../types/application/validator.types'

const validateTextmatchField = (field: AnyField, validator: TextmatchValidator): string => {
  const regex = new RegExp(validator.arguments.pattern)
  const isValid = !field.value || (typeof field.value === 'string' && regex.test(field.value))

  return isValid ? '' : validator.failureMessage
}

export default validateTextmatchField
