import React, { useState, ReactElement, useEffect } from "react";
import { View, StyleSheet, Text, SafeAreaView } from "react-native";
import {
  Subheading,
  RadioButton,
  Card,
  ActivityIndicator,
} from "react-native-paper";
import { colours } from "../styleguide";
import { CouncilMarkets } from "../types/miscellaneous.types";
import MultipleSelectList from "./MultipleSelectList";

const styles = StyleSheet.create({
  subheading: {
    marginTop: 20,
    marginBottom: 10,
  },
  spinnerContainer: {
    flex: 1,
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingText: {
    color: colours.description,
    opacity: 0.7,
    marginTop: 10,
  },
});

interface Props {
  councilMarkets: CouncilMarkets;
  setSelectedCouncil: (newCouncil: string) => void;
  setSelectedMarkets: (markets: string[]) => void;
  selectedCouncil: string;
  selectedMarkets: string[];
  availableMarkets: string[];
}

const CouncilAndMarketSelector = (props: Props): ReactElement => {
  const {
    councilMarkets,
    setSelectedCouncil,
    setSelectedMarkets,
    selectedCouncil,
    selectedMarkets,
  } = props;

  const [availableMarkets, setAvailableMarkets] = useState<string[]>(
    props.availableMarkets
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedCouncil) {
      setAvailableMarkets(councilMarkets[selectedCouncil])
    }
  }, [councilMarkets, selectedCouncil]);

  const changeSelectedCouncil = (newCouncil: string) => {
    setLoading(true);
    setSelectedCouncil(newCouncil);
    setAvailableMarkets(councilMarkets[newCouncil]);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <Card.Content>
      <Subheading style={styles.subheading}>Local Authorities</Subheading>

      <RadioButton.Group
        onValueChange={changeSelectedCouncil}
        value={selectedCouncil}
      >
        {Object.keys(councilMarkets).map((council) => (
          <View key={council}>
            <RadioButton.Item
              value={council}
              label={council}
              color={colours.primary}
              mode={"android"}
            />
          </View>
        ))}
      </RadioButton.Group>

      {loading ? (
        <SafeAreaView style={styles.spinnerContainer}>
          <ActivityIndicator size="large" animating={true} />
          <Text style={styles.loadingText}>Loading</Text>
        </SafeAreaView>
      ) : (
        !!availableMarkets.length && (
          <MultipleSelectList
            title={`Markets in ${selectedCouncil}`}
            value={selectedMarkets}
            options={availableMarkets}
            onPress={setSelectedMarkets}
          />
        )
      )}
    </Card.Content>
  );
};

CouncilAndMarketSelector.defaultProps = {
  availableMarkets: [],
};

export default CouncilAndMarketSelector;
