/**
 * The possible entries of {@link UserDocuments}
 */
import { FileValue } from './application/formField.types'
export interface Message {
  createdAt: number
  id: number
  content: string
  externalSentBy?: number
  externalRecipient?: number
  externalTask: number
  workspace: number
  hasSeenDate?: number
}
export interface Messages {
  // External Task id to Messages.
  [key: string]: Message[]
}
export interface UnreadMessages {
  [key: string]: number
}

export enum UserDocumentProperties {
  ID_PHOTOS = 'idPhotos',
  PROOF_OF_ADDRESS = 'proofOfAddress',
  PUBLIC_LIABILITY_INSURANCE_DOCUMENT = 'publicLiabilityInsuranceDocument',
  NATIONAL_INSURANCE_PROOF_PHOTO = 'nationalInsuranceProofDocument',
  TRADER_PHOTO = 'traderPhoto',
}

/**
 * The documents associated with a {@link User}
 */
export interface UserDocuments {
  [UserDocumentProperties.ID_PHOTOS]?: FileValue[]
  [UserDocumentProperties.PROOF_OF_ADDRESS]?: FileValue[]
  [UserDocumentProperties.PUBLIC_LIABILITY_INSURANCE_DOCUMENT]?: FileValue[]
  [UserDocumentProperties.NATIONAL_INSURANCE_PROOF_PHOTO]?: FileValue[]
  [UserDocumentProperties.TRADER_PHOTO]?: FileValue[]
}

export type UserInfo = UserProperties.EMAIL_ADDRESS &
  UserProperties.FIRST_NAME &
  UserProperties.LAST_NAME &
  UserProperties.PHONE_NUMBER &
  UserProperties.DATE_OF_BIRTH &
  UserProperties.SEND_PUSH_NOTIFICATIONS &
  UserProperties.SEND_EMAIL_NOTIFICATIONS &
  UserAdditionalFields &
  UserDocuments

/**
 * The possible entries of {@link UserAdditionalFields}
 */
export enum UserAdditionalFieldProperties {
  RESIDENTIAL_ADDRESS = 'address',
  HOME_PHONE_NUMBER = 'homePhoneNumber',
  INSTAGRAM_NAME = 'instagram',
  BUSINESS_NAME = 'businessName',
  BUSINESS_TYPE = 'businessType',
  BUSINESS_TYPE_OTHER = 'businessTypeOther',
}

/**
 * The UserAdditionalFields associated with a {@link User}
 */
export interface UserAdditionalFields {
  [UserAdditionalFieldProperties.RESIDENTIAL_ADDRESS]?: string
  [UserAdditionalFieldProperties.HOME_PHONE_NUMBER]?: string
  [UserAdditionalFieldProperties.INSTAGRAM_NAME]?: string
  [UserAdditionalFieldProperties.BUSINESS_NAME]?: string
  [UserAdditionalFieldProperties.BUSINESS_TYPE]?: string
  [UserAdditionalFieldProperties.BUSINESS_TYPE_OTHER]?: string
}

/**
 * The possible entries of {@link UserAdditionalDocumentInformation}
 */
export enum UserAdditionalDocumentInformationProperties {
  PUBLIC_LIABILITY_INSURANCE_END_DATE = 'publicLiabilityInsuranceEndDate',
  PUBLIC_LIABILITY_INSURANCE_ACKNOWLEDGED = 'publicLiabilityInsuranceAcknowledged',
}

/**
 * The UserAdditionalFields associated with a {@link User}
 */
export interface UserAdditionalDocumentInformation {
  [UserAdditionalDocumentInformationProperties.PUBLIC_LIABILITY_INSURANCE_END_DATE]?: string
  [UserAdditionalDocumentInformationProperties.PUBLIC_LIABILITY_INSURANCE_ACKNOWLEDGED]?: boolean
}

/**
 * The properties of a {@link User}
 */

export enum UserProperties {
  EXTERNAL_USER_ID = 'id',
  EMAIL_ADDRESS = 'emailAddress',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  DATE_OF_BIRTH = 'birthDate',
  SEND_PUSH_NOTIFICATIONS = 'sendPushNotifications',
  SEND_EMAIL_NOTIFICATIONS = 'sendEmailNotifications',
  ADDITIONAL_FIELDS = 'additionalFields',
  DOCUMENTS = 'documents',
  MESSAGES = 'messages',
  UNREAD_MESSAGES = 'unreadMessages',
  TABLED_AUTH_TOKEN = 'tabledAuthToken',
  ADDITIONAL_DOCUMENT_INFORMATION = 'ADDITIONAL_DOCUMENT_INFORMATION',
}

/**
 * A user of the App
 */
export interface User {
  [UserProperties.EXTERNAL_USER_ID]: number
  [UserProperties.EMAIL_ADDRESS]: string
  [UserProperties.FIRST_NAME]?: string
  [UserProperties.LAST_NAME]?: string
  [UserProperties.PHONE_NUMBER]?: string
  [UserProperties.DATE_OF_BIRTH]?: string
  [UserProperties.SEND_PUSH_NOTIFICATIONS]: boolean
  [UserProperties.SEND_EMAIL_NOTIFICATIONS]: boolean
  [UserProperties.ADDITIONAL_FIELDS]: UserAdditionalFields
  [UserProperties.DOCUMENTS]: UserDocuments
  [UserProperties.MESSAGES]: Messages
  [UserProperties.UNREAD_MESSAGES]: UnreadMessages
  [UserProperties.TABLED_AUTH_TOKEN]: string
  [UserProperties.ADDITIONAL_DOCUMENT_INFORMATION]: UserAdditionalDocumentInformation
}

export enum AssistantDocumentProperties {
  PROOF_OF_ID = 'proofOfID',
  PROOF_OF_ADDRESS = 'proofOfAddress',
  NATIONAL_INSURANCE_PROOF_PHOTO = 'nationalInsuranceProofDocument',
  ASSISTANT_PHOTO = 'assistantPhoto',
}

/**
 * The documents associated with a {@link Assistant}
 */
export interface AssistantDocuments {
  [AssistantDocumentProperties.PROOF_OF_ID]?: FileValue[]
  [AssistantDocumentProperties.PROOF_OF_ADDRESS]?: FileValue[]
  [AssistantDocumentProperties.NATIONAL_INSURANCE_PROOF_PHOTO]?: FileValue[]
  [AssistantDocumentProperties.ASSISTANT_PHOTO]?: FileValue[]
}

/**
 * The properties of an {@link Assistant}
 */

export enum AssistantProperties {
  ASSISTANT_ID = 'id',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ADDRESS = 'address',
  DATE_OF_BIRTH = 'birthDate',
  NATIONAL_INSURANCE_NUMBER = 'nationalInsuranceNumber',
  DOCUMENTS = 'documents',
  IS_SUBJECT_TO_IMMIGRATION_CONTROL = 'isSubjectToImmigrationControl',
  IS_COMPLETED = 'isCompleted',
  IS_MANAGER = 'isManager',
}

/**
 * A user of the App
 */
export interface Assistant {
  [AssistantProperties.ASSISTANT_ID]: number
  [AssistantProperties.FIRST_NAME]: string
  [AssistantProperties.LAST_NAME]: string
  [AssistantProperties.ADDRESS]?: string
  [AssistantProperties.DATE_OF_BIRTH]?: number
  [AssistantProperties.NATIONAL_INSURANCE_NUMBER]?: string
  [AssistantProperties.DOCUMENTS]: AssistantDocuments
  [AssistantProperties.IS_SUBJECT_TO_IMMIGRATION_CONTROL]: boolean
  [AssistantProperties.IS_COMPLETED]: boolean
  [AssistantProperties.IS_MANAGER]: boolean
}

/**
 * The properties of an {@link AssistantSummary}
 */

export enum AssistantSummaryProperties {
  ASSISTANT_ID = 'id',
  FULL_NAME = 'fullName',
  PROFILE_PICTURE = 'profilePicture',
  IS_COMPLETED = 'isCompleted',
}

export interface AssistantSummary {
  [AssistantSummaryProperties.ASSISTANT_ID]: number
  [AssistantSummaryProperties.FULL_NAME]: string
  [AssistantSummaryProperties.PROFILE_PICTURE]: string
  [AssistantSummaryProperties.IS_COMPLETED]: boolean
}
