import React, { ReactElement } from "react";
import {
  View,
  StyleSheet,
  Text,
	ScrollView,
	Platform,
} from "react-native";
import { DataTable } from "react-native-paper";


const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 800,
    paddingHorizontal: 20,
    justifyContent: "center",
    marginBottom: 15,
    paddingBottom: 75,
  },
  title: {
      fontSize: 24,
      fontWeight: "400",
      marginBottom: 10,
  },
  bigSubtitle: {
    fontSize: 17,
    fontWeight: "400",
    marginTop: 10,
  },
  smallSubtitle: {
    fontSize: 15,
    fontWeight: "400",
    marginTop: 10,
  },
  paragraph: {
    textAlign: "justify",
    fontSize: 12,
    marginTop: 5,
  },
	scrollView:{
		marginTop: 10,
		marginBottom: 10,
	},
  table: {
  },
	dataTableRow: {
	},
	dataTableCell: {
	},
  tableRow: {
		flexDirection: "row",
		alignItems: "center",
  },
  tableColumn1: {
		width: Platform.OS === 'web' ? 1100 : 300,
		padding: 5,
	},
  tableColumn2: {
		width: Platform.OS === 'web' ? 600 : 300,
		padding: 5,
  },
  tableColumn3: {
		width: Platform.OS === 'web' ? 1700 : 300,
		padding: 5,
  },
	tableColumnText: {
		flexWrap: "wrap",
	},
	tableColumnTitle: {
		fontWeight: "600",
		flexWrap: "wrap",
	}
});

const PrivacyPolicy = (): ReactElement => {


    return (
        <View style={styles.container}>
        <Text style={styles.title}>Introduction</Text>
        <Text style={styles.title}>Welcome to OpenMarkets.London&apos;s privacy policy.</Text>
        <Text style={styles.paragraph}>Tabled respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</Text>
        <Text style={styles.smallSubtitle}>1. Important Information and Who We Are</Text>
        <Text style={styles.paragraph}>Tabled.io, the website, application, mobile applications, and integrations, such as the Tabled Email Triage Outlook application and gmail addin are operated by Tabled Technologies Limited, a company incorporated in the United Kingdom (together defined as “Tabled”).</Text>
            <Text style={styles.smallSubtitle}>Purpose of this Privacy Policy</Text>
            <Text style={styles.paragraph}>This privacy policy aims to give you information on how Tabled collects and processes your personal data through your use of this website, including any data you may provide through this website.</Text>
            <Text style={styles.paragraph}>This website is not intended for children and we do not knowingly collect data relating to children.</Text>
            <Text style={styles.paragraph}>It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.</Text>
            <Text style={styles.smallSubtitle}>Controller</Text>
            <Text style={styles.paragraph}>Unless otherwise agreed in a contract for services between Tabled and your employer, Tabled is the controller and responsible for your personal data (collectively referred to as ”Tabled”, “we”, “us” or “our” in this privacy policy).  If there is a contract governing your use of Tabled between Tabled and your employer or another company under which you use Tabled, then Tabled may be the data processor and your employer or other company the data controller.</Text>
            <Text style={styles.paragraph}>We have appointed a data protection officer (DPO) who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact support@tabled.io.</Text>
            <Text style={styles.paragraph}>You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</Text>
            <Text style={styles.paragraph}>Changes to the Privacy Policy and your duty to inform us of changes</Text>
            <Text style={styles.paragraph}>We keep our privacy policy under regular review.</Text>
            <Text style={styles.paragraph}>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</Text>
            <Text style={styles.paragraph}>Third-party links</Text>
            <Text style={styles.paragraph}>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</Text>
            <Text style={styles.smallSubtitle}>2. The Data We Collect About You</Text>
            <Text style={styles.paragraph}>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</Text>
            <Text style={styles.paragraph}>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</Text>
            <Text style={styles.paragraph}>Identity Data includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.</Text>
            <Text style={styles.paragraph}>Contact Data includes billing address, delivery address, email address and telephone numbers.</Text>
            <Text style={styles.paragraph}>Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</Text>
            <Text style={styles.paragraph}>Profile Data includes your username and password, purchases or orders made by you, your interests, your job title, preferences, feedback and survey responses.</Text>
            <Text style={styles.paragraph}>Usage Data includes information about how you use our website, products and services.</Text>
            <Text style={styles.paragraph}>Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.</Text>
            <Text style={styles.paragraph}>We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.</Text>
            <Text style={styles.paragraph}>We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</Text>
            <Text style={styles.smallSubtitle}>If you fail to provide personal data</Text>
            <Text style={styles.paragraph}>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</Text>
            <Text style={styles.smallSubtitle}>3. How Is Your Personal Data Collected?</Text>
            <Text style={styles.paragraph}>We use different methods to collect data from and about you including through:</Text>
            <Text style={styles.paragraph}>Direct interactions. You may give us your Identity and Contact Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:</Text>
            <Text style={styles.paragraph}>create an account on our website;</Text>
            <Text style={styles.paragraph}>subscribe to our service or publications;</Text>
            <Text style={styles.paragraph}>request marketing to be sent to you;</Text>
            <Text style={styles.paragraph}>enter a competition, promotion or survey; or</Text>
            <Text style={styles.paragraph}>give us feedback or contact us.</Text>
            <Text style={styles.paragraph}>Automated technologies or interactions. </Text>
            <Text style={styles.paragraph}>As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies. </Text>
            <Text style={styles.smallSubtitle}>4. How We Use Your Personal Data</Text>
            <Text style={styles.paragraph}>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</Text>
            <Text style={styles.paragraph}>Where we need to perform the contract we are about to enter into or have entered into with you.</Text>
            <Text style={styles.paragraph}>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</Text>
            <Text style={styles.paragraph}>Where we need to comply with a legal obligation.</Text>
            <Text style={styles.paragraph}>Generally, we do not rely on consent as a legal basis for processing your personal data although we will get your consent before sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.</Text>
            <Text style={styles.smallSubtitle}>Purposes for which we use your personal data</Text>
            <Text style={styles.paragraph}>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</Text>
            <Text style={styles.paragraph}>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</Text>
						<ScrollView scrollEventThrottle={0} horizontal={true} style={styles.scrollView}>
							<DataTable style={styles.table}>
								<DataTable.Row style={styles.dataTableRow}>
                    <DataTable.Cell style={styles.dataTableCell}>
											<View style={styles.tableRow}>
												<View style={styles.tableColumn1}><Text style={styles.tableColumnTitle}>Purpose/Activity</Text></View>
												<View style={styles.tableColumn2}><Text style={styles.tableColumnTitle}>Type of data</Text></View>
												<View style={styles.tableColumn3}><Text style={styles.tableColumnTitle}>Lawful basis for processing including basis of legitimate interest</Text></View>
											</View>
                    </DataTable.Cell>
                </DataTable.Row>
								

                <DataTable.Row style={styles.dataTableRow}>
                    <DataTable.Cell style={styles.dataTableCell}>
											<View style={styles.tableRow}>
												<View style={styles.tableColumn1}><Text style={styles.tableColumnText}>To register you as a new customer</Text></View>
												<View style={styles.tableColumn2}><Text style={styles.tableColumnText}>(a) Identity (b) Contact</Text></View>
												<View style={styles.tableColumn3}><Text style={styles.tableColumnText}>Performance of a contract with you</Text></View>
											</View>
                    </DataTable.Cell>
                </DataTable.Row>

								<DataTable.Row style={styles.dataTableRow}>
                    <DataTable.Cell style={styles.dataTableCell}>
											<View style={styles.tableRow}>
												<View style={styles.tableColumn1}><Text style={styles.tableColumnText}>To manage our relationship with you which will include: (a) Notifying you about changes to our terms or privacy policy (b) Asking you to leave a review or take a survey</Text></View>
												<View style={styles.tableColumn2}><Text style={styles.tableColumnText}>(a) Identity (b) Contact (c) Profile (d) Marketing and Communications</Text></View>
												<View style={styles.tableColumn3}><Text style={styles.tableColumnText}>(a) Performance of a contract with you (b) Necessary to comply with a legal obligation (c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</Text></View>
											</View>
                    </DataTable.Cell>
                </DataTable.Row>

								<DataTable.Row style={styles.dataTableRow}>
                    <DataTable.Cell style={styles.dataTableCell}>
											<View style={styles.tableRow}>
												<View style={styles.tableColumn1}><Text style={styles.tableColumnText}>To enable you to partake in a prize draw, competition or complete a survey</Text></View>
												<View style={styles.tableColumn2}><Text style={styles.tableColumnText}>(a) Identity (b) Contact (c) Profile (d) Usage (e) Marketing and Communications</Text></View>
												<View style={styles.tableColumn3}><Text style={styles.tableColumnText}>(a) Performance of a contract with you (b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)</Text></View>
											</View>
                    </DataTable.Cell>
                </DataTable.Row>

								<DataTable.Row style={styles.dataTableRow}>
                    <DataTable.Cell style={styles.dataTableCell}>
											<View style={styles.tableRow}>
												<View style={styles.tableColumn1}><Text style={styles.tableColumnText}>To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</Text></View>
												<View style={styles.tableColumn2}><Text style={styles.tableColumnText}>(a) Identity (b) Contact (c) Technical</Text></View>
												<View style={styles.tableColumn3}><Text style={styles.tableColumnText}>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise) (b) Necessary to comply with a legal obligation</Text></View>
											</View>
                    </DataTable.Cell>
                </DataTable.Row>

								<DataTable.Row style={styles.dataTableRow}>
                    <DataTable.Cell style={styles.dataTableCell}>
											<View style={styles.tableRow}>
												<View style={styles.tableColumn1}><Text style={styles.tableColumnText}>To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</Text></View>
												<View style={styles.tableColumn2}><Text style={styles.tableColumnText}>(a) Identity (b) Contact (c) Profile (d) Usage (e) Marketing and Communications (f) Technical</Text></View>
												<View style={styles.tableColumn3}><Text style={styles.tableColumnText}>Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)</Text></View>
											</View>
                    </DataTable.Cell>
                </DataTable.Row>

								<DataTable.Row style={styles.dataTableRow}>
                    <DataTable.Cell style={styles.dataTableCell}>
											<View style={styles.tableRow}>
												<View style={styles.tableColumn1}><Text style={styles.tableColumnText}>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</Text></View>
												<View style={styles.tableColumn2}><Text style={styles.tableColumnText}>(a) Technical (b) Usage</Text></View>
												<View style={styles.tableColumn3}><Text style={styles.tableColumnText}>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)</Text></View>
											</View>
                    </DataTable.Cell>
                </DataTable.Row>

								<DataTable.Row style={styles.dataTableRow}>
                    <DataTable.Cell style={styles.dataTableCell}>
											<View style={styles.tableRow}>
												<View style={styles.tableColumn1}><Text style={styles.tableColumnText}>To make suggestions and recommendations to you about goods or services that may be of interest to you</Text></View>
												<View style={styles.tableColumn2}><Text style={styles.tableColumnText}>(a) Identity (b) Contact (c) Technical (d) Usage (e) Profile (f) Marketing and Communications</Text></View>
												<View style={styles.tableColumn3}><Text style={styles.tableColumnText}>Necessary for our legitimate interests (to develop our products/services and grow our business)</Text></View>
											</View>
                    </DataTable.Cell>
                </DataTable.Row>
            	</DataTable>
						</ScrollView>
            <Text style={styles.smallSubtitle}>Marketing</Text>
            <Text style={styles.paragraph}>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.</Text>
            <Text style={styles.paragraph}>Promotional offers from us</Text>
            <Text style={styles.paragraph}>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).</Text>
            <Text style={styles.paragraph}>You will receive marketing communications from us if you have requested information from us.</Text>
            <Text style={styles.paragraph}>Third-party marketing</Text>
            <Text style={styles.paragraph}>We will get your express opt-in consent before we share your personal data with any third party for marketing purposes.</Text>
            <Text style={styles.paragraph}>Opting out</Text>
            <Text style={styles.paragraph}>You can ask us or third parties to stop sending you marketing messages at any time by contacting us at any time.</Text>
            <Text style={styles.paragraph}>Change of purpose</Text>
            <Text style={styles.paragraph}>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</Text>
            <Text style={styles.paragraph}>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</Text>
            <Text style={styles.paragraph}>Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</Text>
            <Text style={styles.smallSubtitle}>6. International Transfers</Text>
            <Text style={styles.paragraph}>While this is not the norm, some external third parties may be based outside the EEA so their processing of your personal data will involve a transfer of data outside the EEA.</Text>
            <Text style={styles.paragraph}>Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it by ensuring that we will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. For further details, see European Commission: Adequacy of the protection of personal data in non-EU countries.</Text>
            <Text style={styles.paragraph}>Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.</Text>
            <Text style={styles.smallSubtitle}>7. Data Security</Text>
            <Text style={styles.paragraph}>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</Text>
            <Text style={styles.paragraph}>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</Text>
            <Text style={styles.smallSubtitle}>8. Data Retention</Text>
            <Text style={styles.paragraph}>How long will you use my personal data for?</Text>
            <Text style={styles.paragraph}>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</Text>
            <Text style={styles.paragraph}>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</Text>
            <Text style={styles.smallSubtitle}>9. Sharing data with Third Parties and Third Party Integrations</Text>
            <Text style={styles.bigSubtitle}>Tabled&apos;s use and transfer of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.  This applies where you use the Tabled gmail or Google Drive integration.</Text>
            <Text style={styles.smallSubtitle}>10. Your legal rights</Text>
            <Text style={styles.paragraph}>Under certain circumstances, you have rights under data protection laws in relation to your personal data. If you wish to exercise any such rights, please contact us.</Text>
            <Text style={styles.paragraph}>No fee usually required</Text>
            <Text style={styles.paragraph}>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</Text>
            <Text style={styles.paragraph}>What we may need from you</Text>
            <Text style={styles.paragraph}>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</Text>
            <Text style={styles.paragraph}>Time limit to respond</Text>
            <Text style={styles.paragraph}>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</Text>
            <Text style={styles.smallSubtitle}>11. Glossary</Text>
            <Text style={styles.paragraph}>Lawful basis</Text>
            <Text style={styles.paragraph}>Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</Text>
            <Text style={styles.paragraph}>Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</Text>
            <Text style={styles.paragraph}>Comply with a legal obligation means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</Text>
            <Text style={styles.smallSubtitle}>Your legal rights</Text>
            <Text style={styles.paragraph}>You have the right to:</Text>
            <Text style={styles.paragraph}>Request access to your personal data (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</Text>
            <Text style={styles.paragraph}>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</Text>
            <Text style={styles.paragraph}>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</Text>
            <Text style={styles.paragraph}>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</Text>
            <Text style={styles.paragraph}>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:</Text>
            <Text style={styles.paragraph}>If you want us to establish the data&apos;s accuracy.</Text>
            <Text style={styles.paragraph}>Where our use of the data is unlawful but you do not want us to erase it.</Text>
            <Text style={styles.paragraph}>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</Text>
            <Text style={styles.paragraph}>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</Text>
            <Text style={styles.paragraph}>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</Text>
            <Text style={styles.paragraph}>Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</Text>
        </View>
    );
};


export default PrivacyPolicy;
