import React, { ReactElement } from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { AnyField } from "../types/application/formField.types";
import FormFieldValueSummary from "./FormFieldValueSummary";

const styles = StyleSheet.create({
  question: {
    marginVertical: 5,
  },
});

interface Props {
  field: AnyField;
}

const FormFieldSummary = (props: Props): ReactElement => {
  const { field } = props;

  return (
    <View style={styles.question}>
      <Text>{field.label}</Text>
      <FormFieldValueSummary field={field} />
    </View>
  );
};

export default FormFieldSummary;
