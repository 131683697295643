import React, { ReactElement } from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import useOMLContext from "../hooks/useOMLContext";
import { colours } from "../styleguide";
import { UserProperties, Message} from "../types/user.types";

interface Props {
  messages: Message[];
}

const styles = StyleSheet.create({
  noMessagesMessage: {
    textAlign: "center",
  },
  textBubble: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
    marginTop: 5,
    maxWidth: "50%",
  },
  council: {
    backgroundColor: colours.messages.council,
    borderRadius: 5,
    marginLeft: "5%",
    alignSelf: "flex-start",
  },
  user: {
    backgroundColor: colours.primary,
    marginLeft: "45%",
    borderRadius: 5,
    marginRight: "5%",
    alignSelf: "flex-end",
  },
  arrow: {
    position: "absolute",
    width: 20,
    height: 25,
    bottom: 0,
  },
  rightArrow: {
    backgroundColor: colours.primary,
    right: -10,
    borderBottomLeftRadius: 25,
  },
  leftArrow: {
    backgroundColor: colours.messages.council,
    left: -10,
    borderBottomRightRadius: 25,
  },
  overlap: {
    position: "absolute",
    backgroundColor: colours.backgroundGrey,
    width: 20,
    height: 35,
    bottom: -6,
  },
  rightArrowOverlap: {
    right: -20,
    borderBottomLeftRadius: 18,
  },

  leftArrowOverlap: {
    left: -20,
    borderBottomRightRadius: 18,
  },
  userText: {
    fontSize: 16,
    color: colours.black,
  },
  councilText: {
    fontSize: 16,
    color: colours.black,
  },
  container: {
    width: "100%",
    alignItems: "center",
  },
  conversation: {
    maxWidth: Platform.OS === "web" ? 500 : "100%",
    width: "100%",
  }
});

const formatConversation = (messages: Message[], externalUserId: number) => {
  const bubbles = [];
  for (let i = 0; i < messages.length; i++) {
    if (messages[i].externalSentBy == externalUserId) {
      bubbles.push(
        <View style={[styles.textBubble, styles.user]} key={i}>
          <Text style={styles.userText}>{messages[i].content}</Text>
          <View style={[styles.arrow, styles.rightArrow]}></View>
          <View style={[styles.overlap, styles.rightArrowOverlap]}></View>
        </View>
      );
    } else {
      bubbles.push(
        <View style={[styles.textBubble, styles.council]} key={i}>
          <Text style={styles.councilText}>{messages[i].content}</Text>
          <View style={[styles.arrow, styles.leftArrow]}></View>
          <View style={[styles.overlap, styles.leftArrowOverlap]}></View>
        </View>
      );
    }
  }
  if (bubbles.length > 0) {
    return bubbles;
  }
  return (
    <Text style={styles.noMessagesMessage}>
      You don&apos;t have any messages yet
    </Text>
  );
};

const MessageConversation = (props: Props): ReactElement => {
  const { messages } = props;
  const [{ externalUser }] = useOMLContext();

  return (
    <View style={styles.container}>
      <View style={styles.conversation}>
        {formatConversation(
          messages ?? [],
          externalUser?.[UserProperties.EXTERNAL_USER_ID] ?? -1
        )}
      </View>
    </View>
  );
};

export default MessageConversation;
