import Constants from 'expo-constants'
import { retrieveTabledAuthToken } from '../localStorage'

const patchUser = async (body: FormData, userId: number): Promise<Response> => {
  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  return fetch(
    new Request(`${Constants?.expoConfig?.extra?.hostname}/ext/external-users/${userId}`, {
      method: 'PATCH',
    }),
    {
      body,
      headers,
    }
  )
}

export default patchUser
