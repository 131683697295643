import DateFnsUtils from "@date-io/date-fns";
import { createTheme } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import React, { useState, useEffect, ReactElement, useCallback } from "react";
import { View, StyleSheet } from "react-native";
import { DateValue } from "../../types/application/formField.types";

const styles = StyleSheet.create({
  webContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const dateTimePickerTheme = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
  },
});

interface Props {
  label: string;
  value: DateValue;
  onChange: (newDate: DateValue) => void;
}

const DatePickerWeb = (props: Props): ReactElement => {
  const { label, value, onChange } = props;

  const getDisplayDate = useCallback((newValue: any) => {
    return newValue;
  }, []);

  const [displayDate, setDisplayDate] = useState<Date | null>(null);

  useEffect(
    () => setDisplayDate(getDisplayDate(value)),
    [value, getDisplayDate]
  );

  const handleWebDate = (date: Date | null) => {
    if (date === null) {
      onChange(null);
      return;
    }

    const timestamp = date.valueOf();
    setDisplayDate(date);
    onChange(timestamp);
  };
  
  const pastOrFutureDatePicker = (label: string) => {
    if (label.toLowerCase().includes("birth")) {
      return (
        <KeyboardDatePicker
            disableToolbar
            format="dd/MM/yyyy"
            label={label}
            value={displayDate}
            onChange={handleWebDate}
            invalidDateMessage=""
            maxDateMessage=""
            minDateMessage=""
            openTo="year"
            maxDate={new Date()}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
      )
    }
    return (
      <KeyboardDatePicker
            disableToolbar
            format="dd/MM/yyyy"
            label={label}
            value={displayDate}
            onChange={handleWebDate}
            invalidDateMessage=""
            maxDateMessage=""
            minDateMessage=""
            minDate={new Date()}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
    )
  };

  return (
    <View style={styles.webContainer}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={dateTimePickerTheme}>
          {pastOrFutureDatePicker(label)}
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </View>
  );
};

export default DatePickerWeb;
