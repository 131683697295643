import { ApplicationStatuses } from '../types/application/application.types'

const setApplicationStatus = (incomingTaskStatus: string): ApplicationStatuses => {
  let applicationStatus: ApplicationStatuses = ApplicationStatuses.PENDING
  switch (incomingTaskStatus.toLowerCase()) {
    case 'submitted':
      applicationStatus = ApplicationStatuses.PENDING
      break
    case 'under review':
      applicationStatus = ApplicationStatuses.UNDER_REVIEW
      break
    case 'archived':
      applicationStatus = ApplicationStatuses.ARCHIVED
      break
    case 'changes required':
      applicationStatus = ApplicationStatuses.CHANGES_REQUIRED
      break
    case 'payment requested':
      applicationStatus = ApplicationStatuses.PAYMENT_REQUESTED
      break
    case 'payment received':
      applicationStatus = ApplicationStatuses.PAYMENT_COMPLETED
      break
    case 'accepted':
      applicationStatus = ApplicationStatuses.APPROVED
      break
    case 'licence issued':
      applicationStatus = ApplicationStatuses.APPROVED
      break
    case 'rejected':
      applicationStatus = ApplicationStatuses.REJECTED
      break
    default:
      applicationStatus = ApplicationStatuses.PENDING
  }

  return applicationStatus
}

export default setApplicationStatus
