import React, { ReactElement } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Card } from 'react-native-paper'
import ApplicationSummaryStatus from '../atoms/ApplicationSumaryStatus'
import { colours } from '../styleguide'
import { ApplicationStatuses } from '../types/application/application.types'

interface Props {
  council: string
  markets: string[]
  status: ApplicationStatuses
  key: string | undefined
  onPress: () => void
}

const styles = StyleSheet.create({
  applicationSummary: {
    marginTop: '3%',
    height: 75,
  },
  cardText: {
    padding: 10,
    flexDirection: 'row',
  },
  cardTitle: {
    fontSize: 17,
    fontWeight: '500',
    color: colours.darkPrimary,
  },
  cardDescription: {
    fontSize: 13,
    color: colours.description,
  },
  cardStatus: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  cardStatusContainer: {
    height: '100%',
    justifyContent: 'center',
    width: 60,
  },
  paymentRequired: {
    borderWidth: 1,
    borderColor: colours.status.paymentRequested,
  },
})

const ApplicationSummary = (props: Props): ReactElement => {
  const { council, markets, status, onPress } = props

  const marketsList = markets.length > 1 ? `${markets[0]} and ${markets.length - 1}` : `${markets[0]}`

  return (
    <Card
      onPress={onPress}
      style={[styles.applicationSummary, status === ApplicationStatuses.PAYMENT_REQUESTED && styles.paymentRequired]}
    >
      <View style={styles.cardText}>
        <View>
          <Text style={styles.cardTitle}>{council}</Text>
          <Text style={styles.cardDescription}>{marketsList}</Text>
        </View>
        <View style={styles.cardStatus}>
          <View style={styles.cardStatusContainer}>
            <ApplicationSummaryStatus status={status} />
          </View>
        </View>
      </View>
    </Card>
  )
}

export default ApplicationSummary
