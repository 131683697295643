import React, { ReactElement } from 'react'
import {
  StyleSheet,
  View,
} from "react-native";
import CustomErrorFallback from '../molecules/ErrorBoundary';
import { colours } from '../styleguide';
import { ScreenProps, Pages } from '../types/navigation.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: colours.primary,
  },
});

interface Props extends ScreenProps<Pages.ERROR> {
  errorMessage: string;
  resetError: () => void;
}

const ErrorScreen = (props: Props): ReactElement => {
  const errorMessage = props?.route?.params?.errorMessage ?? props?.errorMessage

  return (
    <View style={styles.container} testID="ErrorScreen">
      <CustomErrorFallback
        error={new Error(errorMessage)}
        resetError={props?.resetError}
      ></CustomErrorFallback>
    </View>
  )
}

ErrorScreen.defaultProps = {
  errorMessage: 'Something went wrong.'
}

export default ErrorScreen
