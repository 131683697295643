import React, {ReactElement, useEffect, useState} from "react";
import {Platform, StyleSheet, Text, View} from "react-native";
import * as Animatable from "react-native-animatable";
import {IconButton} from "react-native-paper";
import getApplications from "../helpers/api/getApplications";
import useFirebase from "../hooks/useFirebase";
import useOMLContext from "../hooks/useOMLContext";
import MessageBrowser from "../molecules/MessageBrowser";
import MessageCreateSelection from "../molecules/MessageCreateSelection";
import {colours} from "../styleguide";
import BannerTemplate from "../templates/BannerTemplate";
import {Application} from "../types/application/application.types"
import {Council} from "../types/miscellaneous.types";
import {Pages, ScreenProps} from "../types/navigation.types";
import { Message, UserProperties} from "../types/user.types";

const styles = StyleSheet.create({
  title: {
    textAlign: "left",
    fontSize: 20,
    fontWeight: "bold",
  },
  titleContainer: {
    width: "100%",
    flexDirection: "row",
    marginBottom: "5%",
    alignItems: "center",
  },
  newMessageBtnContainer: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "flex-end",
    shadowOffset:
      Platform.OS === "web" ? {width: 0, height: 0} : {width: 0, height: 1},
    shadowOpacity: 0.2,
    elevation: Platform.OS === "android" ? 5 : 1,
  },
  newMessageBtn: {
    borderRadius: 50,
    backgroundColor: colours.primary,
    elevation: Platform.OS === "android" ? 5 : 1,
    shadowOffset: {width: 1, height: 1},
    shadowOpacity: 0.1,
  },
  container: {
    flexGrow: 1,
    maxWidth: "100%",
    width: 500,
  },
});

interface Props extends ScreenProps<Pages.MY_MESSAGES> {
  showBanner: boolean;
  loading: boolean;
  error: string;
}

const MyMessagesScreen = (props: Props): ReactElement => {
  const {navigation} = props;

  const [showBanner, setShowBanner] = useState<boolean>(props.showBanner);
  const [loading, setLoading] = useState<boolean>(props.loading);
  const [error, setError] = useState<string>(props.error);

  const [councilData, setCouncils] = useState<Council[]>([]);
  const [applications, setApplications] = useState<Application[]>([]);

  const [messageListVisible, setMessageListVisible] = useState<boolean>(false);

  const [{externalUser, firebaseUser}] = useOMLContext();
  const {getCouncils} = useFirebase();

  useEffect(() => {
    getCouncils.then((data) => {
      setCouncils(data);
    });
    getApplications(
      firebaseUser!.uid,
      externalUser![UserProperties.EXTERNAL_USER_ID]
    ).then((applications) => {
      setApplications(applications);
      setLoading(false);
    });
  }, []);

  const goToMessage = (message: Message, council: Council) => {
    setLoading(true);
    if (!message.id || !council.workspaceId) {
      setShowBanner(true);
      setError("This message cannot be displayed");
      return;
    }

    navigation.navigate(Pages.MESSAGE, {
      title: council.councilName,
      council,
      externalTaskId: message.externalTask,
    });
    setLoading(false);
  };

  const startNewMessage = (applicationId: string) => {
    const application = applications.find((app) => app.id === applicationId);
    if (!application || !application.id) {
      setError(`There was a problem creating this message.`)
      setShowBanner(true)
      return;
    }

    const council = councilData.find(
      (c) => c.councilName === application.council
    );

    const externalTaskId = Number(application.externalId);

    if (!council || !externalTaskId) {
      setError(`An account with this email already exists.`)
      setShowBanner(true)
      return;
    }

    setMessageListVisible(false);
    navigation.navigate(Pages.MESSAGE, {
      title: council.councilName,
      council,
      externalTaskId,
    });
  };

  const newMessage = () => {
    setMessageListVisible(true);
  };

  const toggleMessageList = () => {
    setMessageListVisible(!messageListVisible);
  };

  const dismissErrorMessage = () => {
    setShowBanner(false);
  }

  return (
    <BannerTemplate
      testID="MyMessagesScreen"
      loading={loading}
      showBanner={showBanner}
      bannerText={error}
      dismissError={dismissErrorMessage}
    >
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>Your Messages</Text>
          <View style={styles.newMessageBtnContainer}>
            <IconButton
              testID="addApplicationBtn"
              style={styles.newMessageBtn}
              icon="plus"
              size={30}
              onPress={newMessage}
            />
          </View>
        </View>
        {externalUser && (
          <MessageBrowser
            councils={councilData}
            goToMessage={goToMessage}
            messages={externalUser?.messages ?? []}
            applications={applications}
          />
        )}
        {messageListVisible && (
          <Animatable.View animation="slideInDown" duration={2000}>
            <MessageCreateSelection
              applications={applications}
              setModalVisible={toggleMessageList}
              isVisible={messageListVisible}
              startNewMessage={startNewMessage}
            />
          </Animatable.View>
        )}
      </View>
    </BannerTemplate>
  );
};

MyMessagesScreen.defaultProps = {
  showBanner: false,
  loading: true,
  error: "",
};

export default MyMessagesScreen;
