import { User, UserProperties } from '../types/user.types'

const hasUserBeenWelcomed = (user: User): boolean => {
  if (user == null) {
    return false
  }

  return !!(user[UserProperties.FIRST_NAME] && user[UserProperties.LAST_NAME])
}

export default hasUserBeenWelcomed
