import { AnyField } from '../../../types/application/formField.types'
import { InMemoryFormFields } from '../../../types/form.types'
import applyValidators from './applyValidators'

interface ReturnValues {
  isValid: boolean
  copyOfFormFields: InMemoryFormFields
}

const checkIsFormValid = (visibleFields: AnyField[], formFields: InMemoryFormFields): ReturnValues => {
  const copyOfFormFields = { ...formFields }
  let isValid = true

  visibleFields.forEach(field => {
    const copyOfField = { ...field }
    const error = applyValidators(copyOfField, copyOfFormFields)
    if (error) {
      isValid = false
    }

    copyOfFormFields[copyOfField.identifier] = {
      ...copyOfField,
      error,
    }
  })

  return { isValid, copyOfFormFields }
}

export default checkIsFormValid
