import Constants from 'expo-constants'
import { retrieveTabledAuthToken } from '../localStorage'

const putLicenceIsRemoved = async (credentialId: number): Promise<Response> => {
  const headers: HeadersInit = {
    accessKeyId: `${Constants?.expoConfig?.extra?.accessKeyId}`,
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  return fetch(
    new Request(`${Constants?.expoConfig?.extra?.hostname}/ext/credentials/${credentialId}/is-removed`, {
      method: 'PUT',
    }),
    {
      headers,
    }
  )
}

export default putLicenceIsRemoved
