/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, ReactElement } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  Text,
  SafeAreaView,
  KeyboardAvoidingView,
} from "react-native";
import { ActivityIndicator } from "react-native-paper";
import ErrorMessage from "../molecules/ErrorMessage";
import { colours } from "../styleguide";

const styles = StyleSheet.create({
  view: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
  },
  sendControlContainerOuter: {
    flex: 1,
    justifyContent: "flex-end",
  },
  scrollView: {
    backgroundColor: colours.backgroundGrey,
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    padding: 20,
  },
  spinnerContainer: {
    flex: 1,
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingText: {
    color: colours.description,
    opacity: 0.7,
    marginTop: 10,
  },
});

interface Props {
  testID: string;
  loading: boolean;
  showBanner: boolean;
  bannerText: string;
  children: ReactNode;
  atBottom?: () => void;
  isNotError? : boolean;
  dismissError?: () => void;
  tabNavigatorActive?: boolean;
}

const BannerTemplate = (props: Props): ReactElement => {
  const {
    testID,
    loading,
    showBanner,
    bannerText,
    children,
    atBottom,
    isNotError,
    dismissError,
    tabNavigatorActive,
  } = props;

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: {
    layoutMeasurement: any;
    contentOffset: any;
    contentSize: any;
  }) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  if (Platform.OS === "web") {
    window.addEventListener("scroll", () => {
      browserCloseToBottom();
    });
  }

  const browserCloseToBottom = () => {
    if (
      atBottom &&
      window.innerHeight + window.scrollY >= document.body.scrollHeight
    ) {
      atBottom();
    }
  };

  return (
    <View testID={testID} style={styles.container}>
      {loading ? (
        <SafeAreaView testID="Loading" style={[styles.spinnerContainer, {marginTop: Platform.OS === 'android' && tabNavigatorActive ? -105 : 0}]}>
          <ActivityIndicator size="large" animating={true} /> 
          <Text style={styles.loadingText}>Loading</Text>
        </SafeAreaView>
      ) : (
        <KeyboardAvoidingView
          style={styles.sendControlContainerOuter}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          keyboardVerticalOffset={64}
        >
          <View style={styles.view}>
            <ErrorMessage errorMessage={bannerText} showError={showBanner} isNotError={isNotError} onPress={dismissError}/>
            <ScrollView
              scrollIndicatorInsets={{ right: 1 }}
              onScroll={({ nativeEvent }) => {
                if (isCloseToBottom(nativeEvent) && atBottom) {
                  atBottom();
                }
              }}
              scrollEventThrottle={0}
              contentContainerStyle={styles.scrollView}
              keyboardShouldPersistTaps="always"
            >
              {children}
            </ScrollView>
          </View>
        </KeyboardAvoidingView>
      )}
    </View>
  );
};

BannerTemplate.defaultProps = {
  loading: false,
  showBanner: false,
  bannerText: "",
  tabNavigatorActive: false,
};

export default BannerTemplate;
