import { useFocusEffect } from '@react-navigation/native'
import React, { useState, ReactElement, useCallback, useEffect } from 'react'
import { View, StyleSheet } from 'react-native'
import { HelperText, Card, ProgressBar, Text, Button } from 'react-native-paper'
import { ERRORS } from '../../constants'
import getAssistants from '../helpers/api/getAssistants'
import patchApplication from '../helpers/api/patchApplication'
import postApplication from '../helpers/api/postApplication'
import signOutUser from '../helpers/firebase/auth/signOutUser'
import { FirebaseAuthUser } from '../helpers/firebase/firebase.types'
import putApplicationStageFields from '../helpers/firebase/firestore/putApplicationStageFields'
import deleteExpiredImages from '../helpers/form/deleteExpiredImages'
import uploadFormFiles from '../helpers/form/uploadFormFiles'
import hasUserProvidedRequiredInformation from '../helpers/hasUserProvidedRequiredInformation'
import useForm from '../hooks/useForm/useForm'
import useOMLContext from '../hooks/useOMLContext'
import ApplicationFormButtons from '../molecules/ApplicationFormButtons'

import FormField from '../molecules/FormField'
import { colours } from '../styleguide'
import BannerTemplate from '../templates/BannerTemplate'
import {
  Application,
  ApplicationProperties,
  ApplicationStageProperties,
  ApplicationStatuses,
} from '../types/application/application.types'

import { FormFieldTypes, FormFieldValue } from '../types/application/formField.types'
import { Pages, ScreenProps } from '../types/navigation.types'
import { User } from '../types/user.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    marginBottom: '10%',
    width: '100%',
    maxWidth: 500,
  },
  errorCard: {
    padding: 35,
  },
  errorTitle: {
    padding: 0,
  },
  error: {
    paddingHorizontal: 0,
    paddingBottom: 14,
    textAlign: 'center',
  },
  progressBar: {
    marginBottom: 15,
  },
  subheading: {
    fontSize: 18,
    fontWeight: '500',
    marginBottom: 10,
  },
  noAssistants: {
    width: '100%',
    textAlign: 'center',
  },
  button: {
    backgroundColor: colours.primary,
  },
})

interface Props extends ScreenProps<Pages.FILL_OUT_APPLICATION> {
  loading: boolean
  error: string
  generalFormError: string
  showBanner: false
}

const FillOutApplicationScreen = (props: Props): ReactElement => {
  const { navigation } = props
  const { application, onStage } = props.route.params
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>(props.error)
  const [generalFormError, setGeneralFormError] = useState<string>(props.generalFormError)
  const [showBanner, setShowBanner] = useState<boolean>(props.showBanner)
  const [context, setContext] = useOMLContext()
  const [assistantsHaveBeenFetched, setAssistantsHaveBeenFetched] = useState<boolean>(false)
  const [hasAssistants, setHasAssistants] = useState<boolean>(false)

  const { visibleFields, updateFormValue, validateForm, getFirebaseFormattedForm, cleanForm } = useForm(
    application.stages[onStage].fields,
    application.markets
  )

  useFocusEffect(
    useCallback(() => {
      if (!context.externalUser || !visibleFields.length) {
        return
      }
      if (application.stages.length === 4 && onStage === 2) {
        const field = visibleFields[0]
        if (field.type === FormFieldTypes.OBJECT_MULTIPLE_SELECT && !assistantsHaveBeenFetched) {
          fetchAssistants()
        }
      }
    }, [visibleFields])
  )

  const fetchAssistants = () => {
    if (!context.externalUser?.id) {
      return
    }
    const field = visibleFields[0]
    setLoading(true)
    getAssistants(context.externalUser.id)
      .then(tabledAssistants => {
        if (tabledAssistants) {
          setAssistantsHaveBeenFetched(true)
          setHasAssistants(tabledAssistants.length > 0)
          const options = tabledAssistants
            .filter(assistant => assistant.isCompleted)
            .map(assistant => ({
              label: assistant.fullName,
              value: `${assistant.id}`,
            }))
          if (field.type === FormFieldTypes.OBJECT_MULTIPLE_SELECT) {
            field.objectOptions = options
            application.stages[onStage].fields = [field]
          }
          cleanForm()
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
        if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
          setContext({
            ...context,
            error: ERRORS.UPGRADE_REQUIRED,
          })
        } else {
          setError('Failed to load assistants. Please, try again later.')
          setShowBanner(true)
        }
      })
  }

  useEffect(() => {
    cleanForm()
  }, [application.stages[onStage].fields])

  const saveChanges = async (navigatefn: (newApplication: Application) => void, isSubmitting = false) => {
    if (isSubmitting) {
      setLoading(true)
    }
    setGeneralFormError('')
    const isValid = validateForm()
    if (!isValid || !application.id) {
      setGeneralFormError('There are one or more error in this form')
      setLoading(false)
      return
    }
    try {
      const currentFields = getFirebaseFormattedForm()
      const newFields = await uploadFormFiles(currentFields, context.firebaseUser ? context.firebaseUser.uid : '')

      await deleteExpiredImages(currentFields, newFields)
      await putApplicationStageFields(application.id, onStage, newFields)

      const stages = application.stages
      stages[onStage][ApplicationStageProperties.FIELDS] = newFields
      setContext({
        ...context,
      })
      navigatefn({ ...application, stages })
    } catch (e) {
      setShowBanner(true)
      setError('This form could not be submitted. Please try again')
    }
    if (!isSubmitting) {
      setLoading(false)
    }
  }

  const saveDraft = () => {
    const goHome = () => navigation.navigate(Pages.APPLICATIONS)
    return saveChanges(goHome)
  }

  const submitForm = () => {
    const goToSuccessPage = async (application: Application) => {
      setLoading(true)
      if (application[ApplicationProperties.STATUS] === ApplicationStatuses.CHANGES_REQUIRED) {
        await patchApplication(
          application,
          application.stages,
          context.externalUser ? context.externalUser : ({} as User),
          context.firebaseUser ? context.firebaseUser : ({} as FirebaseAuthUser)
        )
          .then(({ newStages, status }) => {
            application.stages = newStages
            navigation.reset({
              index: 0,
              routes: [{ name: Pages.FINALISE, params: { application, status, submitApplicationForm: submitForm } }],
            })
          })
          .catch(err => {
            if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
              setContext({
                ...context,
                error: ERRORS.UPGRADE_REQUIRED,
              })
            } else if (err.message === ERRORS.TOKEN_EXPIRED.message) {
              signOutUser()
            } else {
              navigation.reset({
                index: 0,
                routes: [
                  {
                    name: Pages.FINALISE,
                    params: { application, didSubmissionSucceed: false, submitApplicationForm: submitForm },
                  },
                ],
              })
              setLoading(false)
            }
          })
      } else {
        await postApplication(
          application,
          application.stages,
          context.externalUser ? context.externalUser : ({} as User),
          context.firebaseUser ? context.firebaseUser : ({} as FirebaseAuthUser),
          true
        )
          .then(({ newStages, status }) => {
            application.stages = newStages
            navigation.reset({
              index: 0,
              routes: [{ name: Pages.FINALISE, params: { application, status, submitApplicationForm: submitForm } }],
            })
          })
          .catch(err => {
            if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
              setContext({
                ...context,
                error: ERRORS.UPGRADE_REQUIRED,
              })
            } else if (err.message === ERRORS.TOKEN_EXPIRED.message) {
              signOutUser()
            } else {
              navigation.reset({
                index: 0,
                routes: [
                  {
                    name: Pages.FINALISE,
                    params: { application, didSubmissionSucceed: false, submitApplicationForm: submitForm },
                  },
                ],
              })
              setLoading(false)
            }
          })
      }
    }

    const goToMissingProfileInformationPage = (application: Application) => {
      navigation.navigate(Pages.MISSING_PROFILE_INFORMATION, {
        application,
      })
    }
    return hasUserProvidedRequiredInformation(context.externalUser)
      ? saveChanges(goToSuccessPage, true)
      : saveChanges(goToMissingProfileInformationPage)
  }

  const goToNextStage = () => {
    const goToNextPage = (newApplication: Application) => {
      navigation.push(Pages.FILL_OUT_APPLICATION, {
        application: newApplication,
        onStage: onStage + 1,
      })
    }
    return saveChanges(goToNextPage)
  }

  const dismissErrorMessage = () => {
    setShowBanner(false)
  }

  const createNewAssistant = () => {
    setAssistantsHaveBeenFetched(false)
    navigation.navigate(Pages.NEW_ASSISTANT, {
      onSubmit: () =>
        navigation.push(Pages.FILL_OUT_APPLICATION, {
          application: application,
          onStage: onStage,
        }),
    })
  }

  try {
    return (
      <BannerTemplate
        testID="FillOutApplicationScreen"
        showBanner={showBanner}
        loading={loading}
        bannerText={error}
        dismissError={dismissErrorMessage}
      >
        <View style={styles.container}>
          <View style={styles.progressBar}>
            <ProgressBar progress={(onStage + 1) / application.stages.length} />
          </View>
          <Card>
            <Card.Title title={`${application.council} Application`} />
            <Card.Content>
              {application.stages.length === 4 && onStage === 2 && <Text style={styles.subheading}>Assistants</Text>}
              {visibleFields.map(field => (
                <FormField
                  key={field.identifier}
                  field={field}
                  onInput={(newValue: FormFieldValue) => updateFormValue(newValue, field.identifier)}
                />
              ))}
              {application.stages.length === 4 && onStage === 2 && (
                <View>
                  {!hasAssistants && <Text style={styles.noAssistants}>You currently have no assistants</Text>}
                  <Button mode="elevated" textColor={colours.black} onPress={createNewAssistant} style={styles.button}>
                    Create new assistant
                  </Button>
                </View>
              )}
              <HelperText type="error" visible={!!generalFormError} style={styles.error}>
                {generalFormError}
              </HelperText>

              <ApplicationFormButtons
                isUserOnFinalStage={application.stages.length === onStage + 1}
                onSubmit={submitForm}
                onContinue={goToNextStage}
                onSaveDraft={saveDraft}
              />
            </Card.Content>
          </Card>
        </View>
      </BannerTemplate>
    )
  } catch {
    return (
      <BannerTemplate testID="FillOutApplicationScreen">
        <Card style={styles.errorCard}>
          <Card.Title style={styles.errorTitle} title="This form could not be loaded" />
        </Card>
      </BannerTemplate>
    )
  }
}

FillOutApplicationScreen.defaultProps = {
  loading: false,
  error: '',
  generalFormError: '',
  showBanner: false,
}

export default FillOutApplicationScreen
