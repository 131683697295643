import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { FileValue } from '../../types/application/formField.types'
import { User, UserDocuments, UserProperties } from '../../types/user.types'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

interface TabledDocument {
  file: string
  id: number
  name: string
  permissions: any
}

const getUserDocuments = async (user: User, documentTypes: string[]): Promise<UserDocuments | null> => {
  let result: UserDocuments | null = null

  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const encodedTypes = documentTypes.map(p => `documentNames=${encodeURIComponent(p)}`).join('&')
  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/external-users/${
    user[UserProperties.EXTERNAL_USER_ID]
  }/documents/?${encodedTypes}`

  await fetch(
    new Request(url, {
      method: 'GET',
      headers,
    })
  )
    .then(async response => {
      if (response.status == 200) {
        await response.json().then(async body => {
          for (const document of body?.documents as TabledDocument[]) {
            const currentDocuments =
              (result?.[document.name as keyof UserDocuments] as FileValue[]) ?? ([] as FileValue[])
            result = {
              ...result,
              [document.name]: [
                ...currentDocuments,
                {
                  externalDocumentId: document.id,
                  canRemove: document.permissions.canRemove,
                },
              ],
            }
          }
        })
      } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
        await signOutUser()
      }
    })
    .catch(err => {
      throw err
    })

  return result
}

export default getUserDocuments
