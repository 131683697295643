import { AnyField } from '../../../../types/application/formField.types'
import { MaxLengthValidator } from '../../../../types/application/validator.types'

const validateMaxLengthField = (field: AnyField, validator: MaxLengthValidator): string => {
  const hasLengthProperty = field.value && field.value.hasOwnProperty('length')

  if (!field.value || !hasLengthProperty) {
    return ''
  }

  const value = field.value as string | string[]

  const isValid = value.length <= validator.arguments.length

  return isValid ? '' : validator.failureMessage
}

export default validateMaxLengthField
