import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

const deleteAssistantDocument = async (documentId: number, userId: number, assistantId: number): Promise<boolean> => {
  let result = false

  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/external-users/${userId}/assistants/${assistantId}/documents/${documentId}`

  await fetch(new Request(url, { method: 'DELETE' }), { headers })
    .then(async response => {
      if (response.status == 200) {
        result = true
      } else {
        const errorMessage = response.headers.get('x-exit')
        if (errorMessage === ERRORS.UPGRADE_REQUIRED.message) {
          throw new Error(ERRORS.UPGRADE_REQUIRED.message)
        } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
          await signOutUser()
        } else {
          throw Error(`There was a problem when trying to save the assistant. Please, try again later.`)
        }
      }
    })
    .catch(err => {
      throw err
    })

  return result
}

export default deleteAssistantDocument
