import React, { ReactElement } from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Modal,
  TouchableOpacity,
} from "react-native";
import { Divider, IconButton } from "react-native-paper";
import ApplicationSummaryStatus from "../atoms/ApplicationSumaryStatus";
import { colours } from "../styleguide";
import { Application, ApplicationStatuses } from "../types/application/application.types";

const styles = StyleSheet.create({
  cardText: {
    padding: 10,
    flexDirection: "row",
  },
  cardTitle: {
    fontSize: 15,
  },
  cardDescription: {
    fontSize: 12,
    color: colours.description,
  },
  cardStatus: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  cardStatusContainer: {
    width: 55,
  },
  modalContainer: {
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  modalView: {
    maxWidth: 500,
    width: "90%",
    height: "100%",
    marginHorizontal: 20,
    marginTop: 50,
    backgroundColor: colours.white,
    borderRadius: 20,
    shadowColor: colours.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  closeModalBtn: {
    width: "100%",
    alignItems: "center",
  },
});

interface Props {
  applications: Application[];
  setModalVisible: () => void;
  isVisible: boolean;
  startNewMessage: (applicationId: string) => void;
}

const MessageCreateSelection = (props: Props): ReactElement => {
  const { applications, setModalVisible, isVisible, startNewMessage } = props;

  const Item = ({
    title,
    description,
    status,
    id,
  }: {
    title: string;
    description: string;
    status: ApplicationStatuses;
    id: string;
  }) => (
    <TouchableOpacity onPress={() => startNewMessage(id)}>
      <View style={styles.cardText}>
        <View>
          <Text style={styles.cardTitle}>{title}</Text>
          <Text style={styles.cardDescription}>{description}</Text>
        </View>
        <View style={styles.cardStatus}>
          <View style={styles.cardStatusContainer}>
            <ApplicationSummaryStatus status={status} />
          </View>
        </View>
      </View>
      <Divider />
    </TouchableOpacity>
  );

  const renderItem = ({ item }: any) => (
    <Item
      title={item.title}
      description={item.description}
      status={item.status}
      id={item.id}
    />
  );

  const getMessageList = (applications: Application[]) => {
    const data = [];
    for (let i = 0; i < applications.length; i++) {
      data.push({
        id: applications[i].id || "",
        title: applications[i].council,
        description: applications[i].markets.join(", "),
        status: applications[i].status,
      });
    }
    if (data.length > 0) {
      return (
        <FlatList
          data={data}
          renderItem={(item) => renderItem(item)}
          keyExtractor={(item) => item.id}
        />
      );
    }
    return;
  };

  return (
    <Modal animationType="slide" transparent={true} visible={isVisible}>
      <View style={styles.modalContainer}>
        <View style={styles.modalView}>
          <TouchableOpacity
            onPress={setModalVisible}
            style={styles.closeModalBtn}
          >
            <IconButton icon={"down"} onPress={setModalVisible}/>
          </TouchableOpacity>
          {getMessageList(applications)}
        </View>
      </View>
    </Modal>
  );
};

export default MessageCreateSelection;
