/* eslint-disable @typescript-eslint/no-var-requires */
import { FirebaseError } from '@firebase/util'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useNavigation } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import Constants from 'expo-constants'
import React, { ReactElement, useEffect, useState } from 'react'
import { Platform, SafeAreaView, StyleSheet, Text } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import { ERRORS } from './constants'
import signOutUser from './src/helpers/firebase/auth/signOutUser'
import hasUserBeenWelcomed from './src/helpers/hasUserBeenWelcomed'
import { storeExternalUserId, storeMessageNotifications } from './src/helpers/localStorage'
import useOMLContext from './src/hooks/useOMLContext'
import Navbar from './src/molecules/Navbar/Navbar'
import { colours } from './src/styleguide'
import { Pages, RootStackParamList } from './src/types/navigation.types'
import { UserProperties } from './src/types/user.types'

const styles = StyleSheet.create({
  spinnerContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colours.backgroundGrey,
  },
  loadingText: {
    color: colours.description,
    opacity: 0.7,
    marginTop: 10,
  },
})

interface Props {
  errorMessage: string
  loading: boolean
  setRouteName: (value: string) => void
}

const Stack = createStackNavigator<RootStackParamList>()

const Router = (props: Props): ReactElement => {
  const [loading, setLoading] = useState(props.loading)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isUserWelcome, setUserWelcome] = useState(true)
  const [routeName, setRouteName] = useState('')
  const [isAdminVerified, setIsAdminVerified] = useState<boolean>(true)

  const [context, setContext] = useOMLContext()

  const navigationRef = useNavigation()

  const logoutAction = {
    title: 'Log out',
    fn: async () => {
      setErrorMessage('')
      try {
        const hasSignedOut = await signOutUser()

        if (hasSignedOut) {
          setContext({ ...context, firebaseUser: null, externalUser: null })
          storeExternalUserId(-1)
          storeMessageNotifications({})
          setUserWelcome(true)
          await AsyncStorage.removeItem('isAdminVerified')
          setIsAdminVerified(false)

          navigationRef.navigate(Pages.LOGIN as never)
        }
      } catch (error: unknown) {
        if (error instanceof FirebaseError) {
          setErrorMessage(error.message)
        } else {
          setErrorMessage('This request could not be processed.')
        }
      }
    },
  }

  useEffect(() => {
    if (context.externalUser?.[UserProperties.EXTERNAL_USER_ID]) {
      setLoading(false)
      hasUserBeenWelcomed(context.externalUser) ? setUserWelcome(true) : setUserWelcome(false)
    }
  }, [context.externalUser?.[UserProperties.FIRST_NAME], context.externalUser?.[UserProperties.LAST_NAME]])

  // FOR SOME REASON PROPS AREN'T SET PROPERLY HERE

  useEffect(() => {
    AsyncStorage.getItem('isAdminVerified').then(verified => {
      setIsAdminVerified(!!verified)
    })
  }, [])

  useEffect(() => {
    props.setRouteName(routeName)
  }, [routeName])

  useEffect(() => {
    setLoading(props.loading)
  }, [props.loading])

  useEffect(() => {
    setErrorMessage(props.errorMessage)
  }, [props.errorMessage])

  const handleAdminLogin = async () => {
    await AsyncStorage.setItem('isAdminVerified', 'true')
    setIsAdminVerified(true)
  }

  if (Constants?.expoConfig?.extra?.stagingAdminPassword && !isAdminVerified && Platform.OS === 'web') {
    return (
      <Stack.Navigator
        screenOptions={() => ({
          title: `OpenMarkets.London - Staging`,
        })}
      >
        <Stack.Screen
          name={Pages.ADMIN_VERIFICATION}
          options={{ headerShown: false }}
          getComponent={() => require('./src/screens/AdminVerificationScreen').default}
          initialParams={{
            handleAdminLogin,
          }}
        />
      </Stack.Navigator>
    )
  }

  if (loading) {
    return (
      <SafeAreaView testID="Loading" style={styles.spinnerContainer}>
        <ActivityIndicator size="large" animating={true} />
        <Text style={styles.loadingText}>Loading</Text>
      </SafeAreaView>
    )
  }

  if (
    context.error?.statusCode === ERRORS.UPGRADE_REQUIRED.statusCode &&
    context.error?.message === ERRORS.UPGRADE_REQUIRED.message
  ) {
    if (Platform.OS !== 'web') {
      throw new Error(ERRORS.UPGRADE_REQUIRED.message)
    }
    setContext({
      ...context,
      error: null,
    })
    window.location.reload()
  }

  if (errorMessage) {
    return (
      <Stack.Navigator
        screenOptions={({ route }) => ({
          title: `OpenMarkets.London - ${route.name}`,
          header: ({ navigation, back, route }) => {
            setRouteName(route.name)
            return (
              <Navbar
                navigation={navigation}
                back={back}
                title={route.name}
                navigationOptions={[]}
                actions={[]}
                messagesVisible={false}
              />
            )
          },
        })}
      >
        <Stack.Screen
          name={Pages.ERROR}
          options={{ headerShown: false }}
          getComponent={() => require('./src/screens/ErrorScreen').default}
          initialParams={{
            errorMessage: errorMessage,
            resetError: () => {
              setErrorMessage(undefined)
            },
          }}
        />
      </Stack.Navigator>
    )
  } else if (!context.firebaseUser) {
    return (
      <Stack.Navigator
        screenOptions={({ route }) => ({
          title: `OpenMarkets.London - ${route.name}`,
          header: ({ navigation, back, route }) => {
            setRouteName(route.name)
            return (
              <Navbar
                navigation={navigation}
                back={back}
                title={route.name}
                navigationOptions={[Pages.LOGIN, Pages.REGISTER, Pages.RECOVER_ACCOUNT, Pages.FAQ]}
                actions={[]}
                messagesVisible={false}
              />
            )
          },
        })}
      >
        <Stack.Screen
          name={Pages.LOGIN}
          getComponent={() => require('./src/screens/LoginScreen').default}
          options={{ headerShown: false }}
        />
        <Stack.Screen name={Pages.REGISTER} getComponent={() => require('./src/screens/RegistrationScreen').default} />
        <Stack.Screen
          name={Pages.RECOVER_ACCOUNT}
          getComponent={() => require('./src/screens/RecoverAccountScreen').default}
        />
        <Stack.Screen name={Pages.FAQ} getComponent={() => require('./src/screens/FAQScreen').default} />
      </Stack.Navigator>
    )
  } else if (!context.firebaseUser.emailVerified) {
    return (
      <Stack.Navigator
        screenOptions={({ route }) => ({
          title: `OpenMarkets.London - ${route.name}`,
          header: ({ navigation, back, route }) => {
            setRouteName(route.name)
            return (
              <Navbar
                navigation={navigation}
                back={back}
                title={route.name}
                navigationOptions={[Pages.VERIFY_ACCOUNT, Pages.FAQ]}
                actions={[logoutAction]}
                messagesVisible={false}
              />
            )
          },
        })}
      >
        <Stack.Screen
          name={Pages.VERIFY_ACCOUNT}
          getComponent={() => require('./src/screens/VerificationScreen').default}
        />

        <Stack.Screen name={Pages.FAQ} getComponent={() => require('./src/screens/FAQScreen').default} />
      </Stack.Navigator>
    )
  } else if (isUserWelcome) {
    return (
      <Stack.Navigator
        initialRouteName={Pages.APPLICATIONS}
        screenOptions={({ route }) => ({
          title: `OpenMarkets.London - ${route.name}`,
          header: ({ navigation, back, route }) => {
            setRouteName(route.name)
            return (
              <Navbar
                navigation={navigation}
                back={back}
                //TODO: https://github.com/react-navigation/react-navigation/issues/7855
                // typings problem in react navigation means we can't use typescript properly here.
                // Needs changing when fixed in above bug.
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                title={route?.params?.['title'] ?? route.name}
                navigationOptions={[
                  Pages.APPLICATIONS,
                  Pages.MY_MESSAGES,
                  Pages.PROFILE_AND_ACCOUNT,
                  Pages.LICENCES,
                  Pages.ASSISTANTS,
                  Pages.MY_ACCOUNT,
                  Pages.FAQ,
                ]}
                actions={[logoutAction]}
              />
            )
          },
        })}
      >
        <Stack.Screen name={Pages.APPLICATIONS} getComponent={() => require('./src/screens/HomeScreen').default} />
        <Stack.Screen name={Pages.MY_MESSAGES} getComponent={() => require('./src/screens/MyMessagesScreen').default} />
        <Stack.Screen
          name={Pages.MESSAGE}
          getComponent={() => require('./src/screens/MessageConversationScreen').default}
        />
        <Stack.Screen
          name={Pages.NEW_APPLICATION}
          getComponent={() => require('./src/screens/NewApplicationScreen').default}
        />
        <Stack.Screen
          name={Pages.FILL_OUT_APPLICATION}
          getComponent={() => require('./src/screens/FillOutApplicationScreen').default}
        />
        <Stack.Screen
          name={Pages.FINALISE}
          getComponent={() => require('./src/screens/ApplicationFinalisedScreen').default}
        />
        <Stack.Screen
          name={Pages.APPLICATION_SUMMARY}
          getComponent={() => require('./src/screens/ApplicationSummaryScreen').default}
        />
        <Stack.Screen
          name={Pages.PROFILE_AND_ACCOUNT}
          getComponent={() => require('./src/screens/ProfileAndAccountScreen').default}
        />
        <Stack.Screen name={Pages.LICENCES} getComponent={() => require('./src/screens/LicencesScreen').default} />
        <Stack.Screen
          name={Pages.LICENCE_SUMMARY}
          getComponent={() => require('./src/screens/LicenceSummaryScreen').default}
        />
        <Stack.Screen name={Pages.ASSISTANTS} getComponent={() => require('./src/screens/AssistantsScreen').default} />
        <Stack.Screen
          name={Pages.ASSISTANT_SUMMARY}
          getComponent={() => require('./src/screens/AssistantSummaryScreen').default}
        />
        <Stack.Screen
          name={Pages.NEW_ASSISTANT}
          getComponent={() => require('./src/screens/AssistantSummaryScreen').default}
        />
        <Stack.Screen name={Pages.MY_ACCOUNT} getComponent={() => require('./src/screens/MyAccountScreen').default} />
        <Stack.Screen
          name={Pages.MISSING_PROFILE_INFORMATION}
          getComponent={() => require('./src/screens/MissingProfileInformationScreen').default}
        />
        <Stack.Screen name={Pages.FAQ} getComponent={() => require('./src/screens/FAQScreen').default} />
        <Stack.Screen name={Pages.HELP} getComponent={() => require('./src/screens/HelpScreen').default} />
        <Stack.Screen name={Pages.PAYMENT} getComponent={() => require('./src/screens/PaymentScreen').default} />
      </Stack.Navigator>
    )
  } else {
    return (
      <Stack.Navigator
        initialRouteName={Pages.WELCOME}
        screenOptions={({ route }) => ({
          title: `OpenMarkets.London - ${route.name}`,
          header: ({ navigation, back, route }) => {
            setRouteName(route.name)
            return (
              <Navbar
                navigation={navigation}
                back={back}
                title={route.name}
                navigationOptions={[Pages.PROFILE_AND_ACCOUNT]}
                actions={[logoutAction]}
                messagesVisible={false}
              />
            )
          },
        })}
      >
        <Stack.Screen
          name={Pages.WELCOME}
          getComponent={() => require('./src/screens/WelcomeScreen').default}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name={Pages.PROFILE_AND_ACCOUNT}
          getComponent={() => require('./src/screens/ProfileAndAccountScreen').default}
          options={{ headerShadowVisible: false }}
        />
      </Stack.Navigator>
    )
  }
}

Router.defaultProps = {
  errorMessage: '',
  loading: true,
}

export default Router
