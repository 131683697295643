import {auth} from "../firebase";

const signOutUser = async (): Promise<boolean> => {
    return auth
        .signOut()
        .then(() => {
            return true;
        });
};

export default signOutUser;
