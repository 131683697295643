import React, { ReactElement } from "react";
import { Platform } from "react-native";
import { DateValue } from "../../types/application/formField.types";

import DatePickerMobile from "./DatePickerMobile";
import DatePickerWeb from "./DatePickerWeb";

interface Props {
  label: string;
  value: DateValue;
  onChange: (newDate: DateValue) => void;
  Platform: { OS: string };
}

const DatePicker = (props: Props): ReactElement => {
  const { label, onChange, value, Platform } = props;

  const dateValue = typeof value === 'string' ? parseInt(value) : value

  return Platform.OS === "web" ? (
    <DatePickerWeb label={label} value={dateValue} onChange={onChange} />
  ) : (
    <DatePickerMobile label={label} value={dateValue} onChange={onChange} />
  );
};

export default DatePicker;

DatePicker.defaultProps = {
  Platform,
};
