export const NUMBER_OF_STAGES = 4

export const MARKETS = {
  LAMBETH: 'Lambeth',
  TOWER_HAMLETS: 'Tower Hamlets',
  HACKNEY: 'Hackney',
  SOUTHWARK: 'Southwark',
  RBKC: 'RBKC',
  CAMDEN: 'Camden',
  SOUTHWARK_BLUE_MARKET: 'Southwark (Blue Market)',
  TEST_COUNCIL: 'Test Council',
}

export const ERRORS = {
  NOT_FOUND: {
    statusCoode: 404,
    message: 'notFound',
  },
  UPGRADE_REQUIRED: {
    statusCode: 409,
    message: 'upgradeRequired',
  },
  TOKEN_EXPIRED: {
    statusCode: 401,
    message: 'tokenExpired',
  },
  // When the National insurance number of an assistant is already being
  // used by one of the users existing assistants
  ASSISTANT_ALREADY_EXISTS: {
    statusCode: 400,
    message: 'assistantAlreadyExists',
  },
  GOOGLE: {
    SIGN_IN_CANCELLED: {
      MESSAGE: 'The popup has been closed by the user',
      STATUS_CODE: -5,
    },
  },
}

export const VALID_FILE_TYPE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'pdf']
export const VALID_FILE_TYPES = {
  PNG: {
    extension: 'png',
    mimeType: 'image/png',
  },
  JPG: {
    extension: 'jpg',
    mimeType: 'image/jpg',
  },
  JPEG: {
    extension: 'jpeg',
    mimeType: 'image/jpeg',
  },
  PDF: {
    extension: 'pdf',
    mimeType: 'application/pdf',
  },
}

export const APPSTORE_LINKS = {
  IOS: 'https://apps.apple.com/gb/app/open-markets-london/id1628847327',
  ANDROID: 'https://play.google.com/store/apps/details?id=london.openmarkets&gl=GB',
}

export const SAFETY_CERTIFICATE = 'SafetyCertificate'

export const INVALID_DATE = 'invalid date'
