import 'expo-firestore-offline-persistence'
import Constants from 'expo-constants'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'

const firebaseApp = firebase.apps.length
  ? firebase.app()
  : firebase.initializeApp(Constants.expoConfig?.extra?.firebaseConfig)

firebaseApp.firestore().settings({ experimentalForceLongPolling: true, merge: true })

firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

const firestore = firebaseApp.firestore()
const auth = firebaseApp.auth()
const storage = firebaseApp.storage()
const functions = firebase.functions()

const { GoogleAuthProvider } = firebase.auth
const googleProvider = new GoogleAuthProvider()

if (__DEV__) {
  const origin = Constants?.expoConfig?.hostUri?.split(':').shift() || 'localhost'
  auth.useEmulator(`http://${origin}:9099/`)
  firestore.useEmulator(origin, 8080)
  storage.useEmulator(origin, 9199)
  functions.useEmulator(origin, 5001)
}

export { firestore, auth, storage, functions, GoogleAuthProvider, googleProvider }
