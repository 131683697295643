import { Linking, Platform } from "react-native";

const openLink = (link: string): void => {
  if (Platform.OS === "web") {
    window.open(link, "_blank");
  } else {
    Linking.openURL(link);
  }
};

export default openLink;
