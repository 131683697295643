import React, { ReactElement, useEffect, useState, useCallback } from 'react'
import { Linking, StyleSheet, Text, View, Image, Platform } from 'react-native'
import { Card, Button, Subheading } from 'react-native-paper'
import Link from '../atoms/Link'
import putApplication from '../helpers/firebase/firestore/putApplication'
import putApplicationStageFields from '../helpers/firebase/firestore/putApplicationStageFields'
import makeApplicationFilesImmutable from '../helpers/form/makeApplicationFilesImmutable'
import useOMLContext from '../hooks/useOMLContext'
import { colours } from '../styleguide'
import BannerTemplate from '../templates/BannerTemplate'
import { ApplicationProperties, ApplicationStatuses, ShallowApplication } from '../types/application/application.types'
import { FileValue } from '../types/application/formField.types'
import { Pages, ScreenProps } from '../types/navigation.types'
import { UserDocuments, UserProperties } from '../types/user.types'

const styles = StyleSheet.create({
  card: {
    width: '100%',
    maxWidth: 360,
    marginBottom: 20,
  },
  button: {
    marginTop: 10,
    backgroundColor: colours.primary,
  },
  successText: {
    marginVertical: 10,
  },
  subheading: {
    fontWeight: '500',
  },
  zettleLogoContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  zettleTermsText: {
    fontStyle: 'italic',
  },
  logo: {
    width: 100,
    height: 100,
  },
  zettleButton: {
    backgroundColor: colours.zettlePrimary,
    marginTop: Platform.OS === 'web' ? 10 : 0,
  },
})

interface Props extends ScreenProps<Pages.FINALISE> {
  showBanner: boolean
  loading: boolean
  error: string
  didSubmissionSucceed: boolean
  status: number
  submitApplicationForm: () => void
}

const ApplicationFinalisedScreen = (props: Props): ReactElement => {
  const { navigation, route } = props
  const [loading, setLoading] = useState<boolean>(props.loading)
  const [didSubmissionSucceed, setDidSubmissionSucceed] = useState<boolean>(props.didSubmissionSucceed)
  const { application, status, submitApplicationForm } = route.params
  const [context, setContext] = useOMLContext()

  const finaliseSubmission = useCallback(async () => {
    if (!application?.id) {
      return
    }
    setLoading(true)
    if (status === 200) {
      try {
        const stages = await makeApplicationFilesImmutable(application.stages)

        await Promise.all(
          stages.map((stage, index) => putApplicationStageFields(application.id as string, index, stage.fields))
        )

        const shallowApplication: ShallowApplication = {
          [ApplicationProperties.ID]: application.id,
          [ApplicationProperties.APPLICANT]: application.applicant,
          [ApplicationProperties.COUNCIL]: application.council,
          [ApplicationProperties.MARKETS]: application.markets,
          [ApplicationProperties.STATUS]: ApplicationStatuses.PENDING,
          [ApplicationProperties.EXTERNAL_ID]: application.externalId,
        }

        const documents = context.externalUser?.documents

        if (documents) {
          const updatedDocuments = Object.entries(documents).map(([name, docValues]) => {
            const updatedDocument = docValues.map((values: FileValue) => {
              return {
                canRemove: false,
                externalDocumentId: values.externalDocumentId,
                uri: values.uri,
              }
            })
            return [name, updatedDocument]
          })

          const userDocuments: UserDocuments = Object.fromEntries(updatedDocuments)

          const externalUser = context.externalUser
          if (externalUser) {
            externalUser[UserProperties.DOCUMENTS] = userDocuments
            setContext({
              ...context,
              externalUser: externalUser,
            })
          }
        }

        setDidSubmissionSucceed(true)
        await putApplication(shallowApplication)
      } catch (e) {
        setDidSubmissionSucceed(false)
      } finally {
        setLoading(false)
      }
    } else {
      setDidSubmissionSucceed(false)
      setLoading(false)
    }
  }, [route.params])

  const tryAgain = () => {
    setLoading(true)
    submitApplicationForm()
  }

  useEffect(() => {
    finaliseSubmission()
  }, [finaliseSubmission])

  const goHome = () =>
    navigation.reset({
      index: 0,
      routes: [{ name: Pages.APPLICATIONS }],
    })

  return (
    <BannerTemplate testID="successScreen" loading={loading}>
      {didSubmissionSucceed ? (
        <View>
          <Card style={styles.card}>
            <Card.Title title="Success!" />
            <Card.Content>
              <Subheading style={styles.subheading}>Your application has been submitted</Subheading>
              <Text style={styles.successText}>
                You can now use the same documents to apply to trade in more Local Authorities. {'\n\n'}Where will you
                trade on your free days?
              </Text>
              <Button mode="elevated" textColor={colours.black} onPress={goHome} style={styles.button}>
                Go to your applications
              </Button>
            </Card.Content>
          </Card>
          <Card style={styles.card}>
            <Card.Content>
              <View style={styles.zettleLogoContainer}>
                <Image source={require('../../assets/zettle_logo.png')} resizeMode="contain" style={styles.logo} />
              </View>
              <Subheading style={styles.subheading}>Zettle Supports OpenMarkets to help traders grow.</Subheading>
              <Text>
                {'\n'}
                Get your Zettle Reader for just £19 (excl. VAT) and your first £1,000 of transactions free.
                {'\n'}
              </Text>
              <Button
                mode="elevated"
                textColor={colours.white}
                onPress={() =>
                  Linking.openURL(
                    'https://register.zettle.com/gb/?utm_source=partnership&utm_medium=reseller&utm_campaign=openmarkets&utm_term=localconnector'
                  )
                }
                style={styles.zettleButton}
              >
                Sign Up here
              </Button>
              <Text style={styles.zettleTermsText}>
                {'\n'}
                See Terms and Conditions of the Offer&nbsp;
                <Link
                  colour={colours.zettlePrimary}
                  onPress={() =>
                    Linking.openURL(
                      'https://openmarkets.london/zettle-by-paypal-openmarkets-offer-terms-and-conditions/'
                    )
                  }
                >
                  here
                </Link>
                {'\n\n'}
                See Zettle service Terms &nbsp;
                <Link colour={colours.zettlePrimary} onPress={() => Linking.openURL('https://www.zettle.com/gb/legal')}>
                  here&nbsp;
                </Link>
                and the sale and provision of hardware delivery terms&nbsp;
                <Link
                  colour={colours.zettlePrimary}
                  onPress={() => Linking.openURL('https://shop.izettle.com/gb/terms-and-conditions')}
                >
                  here
                </Link>
              </Text>
            </Card.Content>
          </Card>
        </View>
      ) : (
        <Card style={styles.card}>
          <Card.Title title="There was a problem submitting your application" titleNumberOfLines={2} />
          <Card.Content>
            <Button mode="elevated" textColor={colours.black} onPress={tryAgain} style={styles.button}>
              Try again
            </Button>
          </Card.Content>
        </Card>
      )}
    </BannerTemplate>
  )
}

ApplicationFinalisedScreen.defaultProps = {
  showBanner: false,
  loading: true,
  didSubmissionSucceed: false,
  error: '',
  status: 500,
}

export default ApplicationFinalisedScreen
