import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { optionalMyDetailsForm } from '../../static-forms/myDetailsForm'
import {
  User,
  UserAdditionalFieldProperties,
  UserAdditionalFields,
  UserProperties,
  UserAdditionalDocumentInformation,
  UserAdditionalDocumentInformationProperties,
} from '../../types/user.types'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

const getUser = async (userEmailAddress: string): Promise<User | null> => {
  let result: User | null = null

  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/external-users?emailAddress=${userEmailAddress}`

  await fetch(
    new Request(url, {
      method: 'GET',
      headers,
    })
  ).then(async response => {
    if (response.status == 200) {
      await response.json().then(({ externalUser: tabledUser }) => {
        result = {} as User

        result[UserProperties.EXTERNAL_USER_ID] = tabledUser.id
        result[UserProperties.EMAIL_ADDRESS] = tabledUser.emailAddress
        result[UserProperties.FIRST_NAME] = tabledUser.firstName
        result[UserProperties.LAST_NAME] = tabledUser.lastName
        result[UserProperties.PHONE_NUMBER] = tabledUser.phoneNumber
        result[UserProperties.DATE_OF_BIRTH] = tabledUser.birthDate
          ? Number(tabledUser.birthDate)
          : tabledUser.birthDate
        result[UserProperties.SEND_PUSH_NOTIFICATIONS] = tabledUser.sendPushNotifications
        result[UserProperties.SEND_EMAIL_NOTIFICATIONS] = tabledUser.sendEmailNotifications

        const additionalFields: UserAdditionalFields = {} as UserAdditionalFields
        for (const additionalFieldName of Object.values(UserAdditionalFieldProperties)) {
          const foundField = tabledUser.additionalFields.find((field: never) => field['name'] === additionalFieldName)

          if (foundField) {
            if (foundField.name === 'Market choices') {
              foundField.value = foundField.value.split(', ')
            }

            if (additionalFieldName === UserAdditionalFieldProperties.BUSINESS_TYPE) {
              const businessTypeField = optionalMyDetailsForm.find(
                field => field.identifier === UserAdditionalFieldProperties.BUSINESS_TYPE
              )
              if (
                !businessTypeField?.options
                  ?.map(option => option.toString().toLowerCase())
                  .includes(foundField.value.toLowerCase())
              ) {
                additionalFields[additionalFieldName as UserAdditionalFieldProperties] = 'Other'
                additionalFields[UserAdditionalFieldProperties.BUSINESS_TYPE_OTHER as UserAdditionalFieldProperties] =
                  foundField.value
              } else {
                additionalFields[additionalFieldName as UserAdditionalFieldProperties] = foundField.value
              }
            } else {
              additionalFields[additionalFieldName as UserAdditionalFieldProperties] = foundField.value
            }
          }
        }

        result[UserProperties.ADDITIONAL_FIELDS] = additionalFields

        const additionalDocumentInformation: UserAdditionalDocumentInformation = {} as UserAdditionalDocumentInformation
        for (const additionalFieldName of Object.values(
          UserAdditionalDocumentInformationProperties as unknown as string
        )) {
          const foundField = tabledUser.additionalDocumentInformation.find(
            (field: never) => field['name'] === additionalFieldName
          )

          if (foundField) {
            if (additionalFieldName.toLowerCase().includes('date')) {
              foundField.value = parseInt(foundField.value)
            }
            additionalDocumentInformation[additionalFieldName as UserAdditionalDocumentInformationProperties] =
              foundField.value
          }
        }

        result[UserProperties.ADDITIONAL_DOCUMENT_INFORMATION] = additionalDocumentInformation

        result[UserProperties.MESSAGES] = {}
      })
    } else if (response.status == 409) {
      throw new Error(ERRORS.UPGRADE_REQUIRED.message)
    } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
      await signOutUser()
    }
  })

  return result
}

export default getUser
