import React, { ReactElement } from "react";
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
} from "react-native";
import { DataTable } from "react-native-paper";


const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 800,
    paddingHorizontal: 20,
    justifyContent: "center",
    marginBottom: 15,
    paddingBottom: 75,
  },
  title: {
      fontSize: 24,
      fontWeight: "400",
      marginBottom: 10,
  },
  smallSubtitle: {
    fontSize: 15,
    fontWeight: "400",
    marginTop: 10,
  },
  paragraph: {
    textAlign: "justify",
    fontSize: 12,
    marginTop: 5,
  },
  indentation: {
    paddingLeft: 15,
  },
  table: {
    width: "100%",
  },
	dataTableRow: {
		width: "100%",
	},
	dataTableCell: {
		width: "100%",
	},
  tableRow: {
    width: "100%",
		flexDirection: "row",
		alignItems: "center",
  },
  tableColumn1: {
    width: 200,
  },
  tableColumn2: {
  },
});

const TermsAndConditions = (): ReactElement => {


    return (
        <View style={styles.container}>
            <Text style={styles.title}>Terms and Conditions</Text>
            <Text style={styles.paragraph}>These Terms and Conditions, together with any and all other documents referred to, set out the terms of use under which you may use tabled.io (“Our Web App”).  Please read these Terms and Conditions carefully and ensure that you understand them.  You will be required to read and accept these Terms and Conditions when signing up for an Account and purchasing a Subscription.  If you do not agree to comply with and be bound by these Terms and Conditions, you must stop using Our Web App immediately.</Text>
            <Text style={styles.title}>Definitions and Interpretation </Text>
            <Text style={styles.paragraph}>In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:</Text>
            <ScrollView scrollEventThrottle={0} horizontal={true}>
							<DataTable style={styles.table}>
								<DataTable.Row style={styles.dataTableRow}>
									<DataTable.Cell style={styles.dataTableCell}>
										<View style={styles.tableRow}>
											<Text style={styles.tableColumn1}>“Account”</Text>
											<Text style={styles.tableColumn2}>means an account required to access and use Our Web App, as detailed in Clause 4;</Text>
										</View>
									</DataTable.Cell>
								</DataTable.Row>

								<DataTable.Row>
									<DataTable.Cell>
										<View style={styles.tableRow}>
											<Text style={styles.tableColumn1}>“Content”</Text>
											<Text style={styles.tableColumn2}>means any and all text, images, audio, video, scripts, code, software, databases and any other form of information capable of being stored on a computer that appears on, or forms part of, Our Web App;</Text>
										</View>
									</DataTable.Cell>
								</DataTable.Row>

								<DataTable.Row>
									<DataTable.Cell>
										<View style={styles.tableRow}>
											<Text style={styles.tableColumn1}>“Contract”</Text>
											<Text style={styles.tableColumn2}>means the contract between Us and you for the purchase and sale of a Subscription to Our Web App, as explained in Clause 4 and Clause 5 below;</Text>
										</View>
									</DataTable.Cell>
								</DataTable.Row>
								
								<DataTable.Row>
									<DataTable.Cell>
										<View style={styles.tableRow}>
											<Text style={styles.tableColumn1}>“Order”</Text>
											<Text style={styles.tableColumn2}>means your order for a Subscription;</Text>
										</View>
									</DataTable.Cell>
								</DataTable.Row>
								
								<DataTable.Row>
									<DataTable.Cell>
										<View style={styles.tableRow}>
											<Text style={styles.tableColumn1}>“Subscription Confirmation“</Text>
											<Text style={styles.tableColumn2}>means Our acceptance and confirmation of your Order;</Text>
										</View>
									</DataTable.Cell>
								</DataTable.Row>

								<DataTable.Row>
									<DataTable.Cell>
										<View style={styles.tableRow}>
											<Text style={styles.tableColumn1}>“Subscription”</Text>
											<Text style={styles.tableColumn2}>means a subscription to access Our Web App, purchased in accordance with these Terms and Conditions;</Text>
										</View>
									</DataTable.Cell>
								</DataTable.Row>

								<DataTable.Row>
									<DataTable.Cell>
										<View style={styles.tableRow}>
											<Text style={styles.tableColumn1}>“User”</Text>
											<Text style={styles.tableColumn2}>means a user of Our Web App;</Text>
										</View>
									</DataTable.Cell>
								</DataTable.Row>

								<DataTable.Row>
									<DataTable.Cell>
										<View style={styles.tableRow}>
											<Text style={styles.tableColumn1}>“User Content“</Text>
											<Text style={styles.tableColumn2}>means and information and documents created and/or uploaded by Users in or to Our Web App;</Text>
										</View>
									</DataTable.Cell>
								</DataTable.Row>

								<DataTable.Row>
									<DataTable.Cell>
										<View style={styles.tableRow}>
											<Text style={styles.tableColumn1}>“We/Us/Our“</Text>
											<Text style={styles.tableColumn2}>means Tabled Technologies LImited, a limited company registered in England under company number 12050791, whose registered address is Northcliffe House, Young Street, Kensington, London, W8 5EH.</Text>
										</View>
									</DataTable.Cell>
								</DataTable.Row>
							</DataTable>
            </ScrollView>
            <Text style={styles.smallSubtitle}>1.Information About US</Text>
            <Text style={styles.paragraph}>1.1 Our Web App is owned and operated by Tabled Technologies Limited.  Our VAT number is 328463587.</Text>
            <Text style={styles.smallSubtitle}>2. Access and Changes to Our Web App</Text>
            <Text style={styles.paragraph}>2.1 Access to Our Web App requires a Subscription or is free for your first three full users.   We may introduce feature and volume limits on these free users in the future which will mean only paying users can access all features and use the platform free of usage restrictions.  Larger teams require a paid subscription as set out below.</Text>
            <Text style={styles.paragraph}>2.2 We may from time to time make changes to Our Web App as follows:</Text>
            <View style={styles.indentation}>
                <Text style={styles.paragraph}>Minor changes may be required to make underlying technical alterations, for example, to fix an error or to address a security issue.  We will inform you by email of any such changes (including, if applicable, anything that you need to do); and</Text>
                <Text style={styles.paragraph}>We will continue to develop and improve Our Web App over time, in some cases making significant changes to it.  You will be kept fully informed of any and all such changes.</Text>
            </View>
            <Text style={styles.paragraph}>2.3 We will use reasonable endeavours to ensure that Our Web App is available at all times.  In certain limited cases, however, We may need to temporarily suspend availability to make certain changes outlined under sub-Clause 3.2.  Unless We are responding to an emergency or an urgent issue, We will inform you in advance of any interruptions to the availability of Our Web App.  If We need to suspend Our Web App for longer than 1 day within any quarterly (three month) period, We will add the corresponding time to the duration of your current Subscription period at no cost to you, rounded up to a full day in each case.  </Text>
            <Text style={styles.smallSubtitle}>3. Accounts</Text>
            <Text style={styles.paragraph}>3.1 An Account is required to use Our Web App.</Text>
            <Text style={styles.paragraph}>3.2 You may not create an Account if you are under 18 years of age.</Text>
            <Text style={styles.paragraph}>3.3 When creating an Account, the information you provide must be accurate and complete.  If any of your information changes at a later date, it is your responsibility to ensure that your Account is kept up-to-date.</Text>
            <Text style={styles.paragraph}>3.4 We require that you choose a strong password for your Account, consisting of a combination of lowercase and uppercase letters, numbers, and symbols.  It is your responsibility to keep your password safe.  You must not share your Account with anyone else.  If you believe your Account is being used without your permission, please contact Us immediately.  We will not be liable for any unauthorised use of your Account.</Text>
            <Text style={styles.paragraph}>3.5 You must not use anyone else&apos;s Account.</Text>
            <Text style={styles.paragraph}>3.6 Any personal information provided in your Account will be collected, used, and held in accordance with your rights and Our obligations under the GDPR.</Text>
            <Text style={styles.paragraph}>3.7 If you wish to close and delete your Account, you may do so at any time in the settings section.  Closing your Account will result in the removal of your information from Our system.  If you have an active Subscription, your Account can be reopened for the duration of the remainder of the Subscription period you are currently in.  Closing your Account will cancel the auto-renewal of your Subscription, where applicable.  Closing your Account will also remove any User Content that you have created or uploaded from Our system.  To avoid losing anything that you have created or uploaded using Our Web App, please inform us prior to cancelling your Account that you wish to store data and we will use reasonable endeavours to assist with exporting your data, subject to a charge where we incur an expense.</Text>
            <Text style={styles.smallSubtitle}>4. Subscriptions, Pricing and Availability</Text>
            <Text style={styles.paragraph}>4.1 Where appropriate, you may be required to select your required Subscription.  Different types of Subscription provide access to different features in Our Web App.  Please ensure that you select the appropriate Subscription when prompted.  If Our Web App does not require this, then you will be opted into our standard subscription costing £40 +VAT per lawyer / full user per month.</Text>
            <Text style={styles.paragraph}>4.2 All pricing information is correct at the time of going online.  We reserve the right to change prices and to add, alter, or remove special offers from time to time and as necessary.   Changes to pricing will not affect Subscriptions that have already been purchased, but will affect renewals of Subscriptions.</Text>
            <Text style={styles.paragraph}>4.3 All prices exclude VAT.</Text>
            <Text style={styles.smallSubtitle}>5. Subscriptions - How Contracts Are Formed</Text>
            <Text style={styles.paragraph}>After registering for the platform and creating a workspace, your first three full users in your workspace are free. After this, you will automatically be charged £40+VAT for each full user for each month, including your first 3 users.  Law Firms have additional features and a minimum annual fee of £5,000 applies for these features.  Client users (ie non-lawyers using the platform to submit legal work) are not deemed full users and are not charged for.  We will monitor your number of users and invoice you in arrears.  We will invoice you either quarterly or every 6 months.  We may introduce feature and volume limits on free users in the future which will mean only paying users can access all features and use the platform free of usage restrictions.</Text>
            <Text style={styles.smallSubtitle}>6. Payment</Text>
            <Text style={styles.paragraph}>6.1 Payment for Subscriptions will be due within 30 days of invoice from Us.</Text>
            <Text style={styles.paragraph}>6.2We accept payment by bank transfer;</Text>
            <Text style={styles.paragraph}>6.3 We do not charge any additional fees for any of the payment methods listed above.</Text>
            <Text style={styles.smallSubtitle}>7. Cancellation</Text>
            <Text style={styles.paragraph}>7.1 Unless otherwise agreed in writing by the parties, You may cancel your subscription at any time with 30 days notice.  You will be charged for the final 30 days if a subscription fee is payable.</Text>
            <Text style={styles.paragraph}>7.2 To cancel a Subscription for any reason, please inform us using one of the following methods:</Text>
            <View style={styles.indentation}>
                <Text style={styles.paragraph}>7.2.1 Online using Our form at Tabled.</Text>
            </View>
            <Text style={styles.paragraph}>7.3 In certain limited circumstances such as we deem you are using Our App inappropriately or contrary to these Terms and Conditions or if you fail to pay an invoice, We may cancel your Subscription and/or close your Account.  If We take such action, you will be notified by email and We will provide an explanation for the cancellation and/or closure.</Text>
            <Text style={styles.paragraph}>7.4 If your Account is closed and your Subscription cancelled because you have breached these Terms and Conditions, you will not be entitled to a refund.  If you believe We have closed your Account and cancelled your Subscription in error, please contact Us at Tabled.</Text>
            <Text style={styles.smallSubtitle}>8. Our Intellectual Property Rights and Licence</Text>
            <Text style={styles.paragraph}>8.1 We grant Users a limited, non-exclusive, revocable, worldwide, non-transferable licence to use Our Web App for personal (including research and private study) and business purposes, subject to these Terms and Conditions.</Text>
            <Text style={styles.paragraph}>8.2 Subject to the licence granted to Us under sub-Clause 12.3, Users retain the ownership of copyright and other intellectual property rights in their User Content (subject to any third party rights in that User Content and the terms of any licence under which you use such Content).</Text>
            <Text style={styles.paragraph}>8.3 All other Content included in Our Web App (including all user-facing material, and all underlying material such as code, software and databases) and the copyright and other intellectual property rights in that Content, unless specifically labelled otherwise, belongs to or has been licensed by Us.  All Content is protected by applicable United Kingdom and international intellectual property laws and treaties.</Text>
            <Text style={styles.paragraph}>8.4 By accepting these Terms and Conditions, you hereby undertake:</Text>
            <View style={styles.indentation}>
                <Text style={styles.paragraph}>1. Not to copy, download or otherwise attempt to acquire any part of Our Web App;</Text>
                <Text style={styles.paragraph}>2. Not to disassemble, decompile or otherwise reverse engineer Our Web App;</Text>
                <Text style={styles.paragraph}>3. Not to allow or facilitate any use of Our Web App that would constitute a breach of these Terms and Conditions; and</Text>
                <Text style={styles.paragraph}>4. Not to embed or otherwise distribute Our Web App on any website, ftp server or similar.</Text>
            </View>
            <Text style={styles.smallSubtitle}>9. Links to Our Web App</Text>
            <Text style={styles.paragraph}>9.1 You may link to Our Web App provided that:</Text>
            <View style={styles.indentation}>
                <Text style={styles.paragraph}>1. You do so in a fair and legal manner;</Text>
                <Text style={styles.paragraph}>2. You do not do so in a manner that suggests any form of association, endorsement or approval on Our part where none exists;</Text>
                <Text style={styles.paragraph}>3. You do not use any of Our logos or trade marks (or any others displayed on Our Web App) without Our express written permission; and</Text>
                <Text style={styles.paragraph}>4. You do not do so in a way that is calculated to damage Our reputation or to take unfair advantage of it.</Text>
            </View>
            <Text style={styles.paragraph}>9.2 You may not link to Our Web App from any other website the content of which contains material that:</Text>
            <View style={styles.indentation}>
                <Text style={styles.paragraph}>1. Is sexually explicit;</Text>
                <Text style={styles.paragraph}>2. Is obscene, deliberately offensive, hateful or otherwise inflammatory;</Text>
                <Text style={styles.paragraph}>3. Promotes violence;</Text>
                <Text style={styles.paragraph}>4. Promotes or assists in any form of unlawful activity;</Text>
                <Text style={styles.paragraph}>5. Discriminates against, or is in any way defamatory of, any person, group or class of persons, race, sex, religion, nationality, disability, sexual orientation, or age;</Text>
                <Text style={styles.paragraph}>6. Is designed or is otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;</Text>
                <Text style={styles.paragraph}>7. Is calculated or is otherwise likely to deceive another person;</Text>
                <Text style={styles.paragraph}>8. Is designed or is otherwise likely to infringe (or threaten to infringe) another person&apos;s privacy;</Text>
                <Text style={styles.paragraph}>9. Misleadingly impersonates any person or otherwise misrepresents the identity or affiliation of a particular person in a way that is calculated to deceive (obvious parodies are not included in this definition provided that they do not fall within any of the other provisions of this sub-Clause 10.2);</Text>
                <Text style={styles.paragraph}>10. Implies any form of affiliation with Us where none exists;</Text>
                <Text style={styles.paragraph}>11. Infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, trade marks, patents and database rights) of any other party; or</Text>
                <Text style={styles.paragraph}>12. Is made in breach of any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence.</Text>
            </View>
            <Text style={styles.smallSubtitle}>10. Links to Other Content</Text>
            <Text style={styles.paragraph}>We may provide links to other content such as websites, web apps and downloadable apps.  Unless expressly stated, this content is not under Our control.  We neither assume or accept responsibility or liability for such third party content.  The provision of a link by Us is for reference only and does not imply any endorsement of the linked content or of those in control of it.</Text>
            <Text style={styles.smallSubtitle}>11. User Content</Text>
            <Text style={styles.paragraph}>11.1 You agree that you will be solely responsible for any and all User Content that you create or upload using Our Web App.  Specifically, you agree, represent and warrant that you have the right to create or upload the User Content and the right to use all materials of which it is comprised and that it will not contravene any aspect of Our Acceptable Usage Policy, detailed in Clause 14 and will not contain any malware or other malicious code.</Text>
            <Text style={styles.paragraph}>11.2 You agree that you will be liable to Us and will, to the fullest extent permissible by law, indemnify Us for any breach of the warranties given by you under sub-Clause 12.1.  You will be responsible for any loss or damage suffered by Us as a result of such breach.</Text>
            <Text style={styles.paragraph}>11.3 You (or your licensors, as appropriate) retain ownership of your User Content and all intellectual property rights subsisting therein.  By creating or uploading User Content, you grant Us an unconditional, non-exclusive, fully transferable, royalty-free, perpetual, worldwide licence to use, store, archive, syndicate, publish, transmit, adapt, edit, reproduce, distribute, prepare derivative works from, display, perform and sub-licence your User Content for the purposes of operating Our Web App.</Text>
            <Text style={styles.paragraph}>11.4 If you wish to remove User Content, you may do so by deleting that content if you have the permissions to do so.  Removing User Content also revokes the licence granted to Us to use that User Content under sub-Clause 12.3.  You acknowledge, however, that caching or references to your User Content may not be made immediately unavailable (or may not be made unavailable at all where they are outside of Our reasonable control).</Text>
            <Text style={styles.smallSubtitle}>12. Intellectual Property Rights over User Content</Text>
            <Text style={styles.paragraph}>All User Content and the intellectual property rights subsisting therein, unless specifically labelled otherwise, belongs to or has been licenced by the relevant User.  All User Content is protected by applicable United Kingdom and international intellectual property laws and treaties.</Text>
            <Text style={styles.smallSubtitle}>13. Acceptable Usage Policy</Text>
            <Text style={styles.paragraph}>13.1 You may only use Our Web App in a manner that is lawful and that complies with the provisions of this Clause 14.  Specifically:</Text>
            <View style={styles.indentation}>
                <Text style={styles.paragraph}>1. You must ensure that you comply fully with any and all applicable local, national and international laws and/or regulations;</Text>
                <Text style={styles.paragraph}>2. You must not use Our Web App in any way, or for any purpose, that is unlawful or fraudulent;</Text>
                <Text style={styles.paragraph}>3. You must not use Our Web App to knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware, or any other code designed to adversely affect computer hardware, software or any data of any kind; and</Text>
                <Text style={styles.paragraph}>4. You must not use Our Web App in any way, or for any purpose, that is intended to harm any person or persons in any way.</Text>
            </View>
            <Text style={styles.paragraph}>13.2 The following types of User Content are not permitted on Our Web App and you must not create, submit, communicate or otherwise do anything that:</Text>
            <View style={styles.indentation}>
                <Text style={styles.paragraph}>1. is sexually explicit;</Text>
                <Text style={styles.paragraph}>2. is obscene, deliberately offensive, hateful, or otherwise inflammatory</Text>
                <Text style={styles.paragraph}>3. promotes violence;</Text>
                <Text style={styles.paragraph}>4. promotes or assists in any form of unlawful activity;</Text>
                <Text style={styles.paragraph}>5. discriminates against, or is in any way defamatory of, any person, group or class of persons, race, sex, religion, nationality, disability, sexual orientation, or age;</Text>
                <Text style={styles.paragraph}>6. is intended or otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;</Text>
                <Text style={styles.paragraph}>7. is calculated or otherwise likely to deceive;</Text>
                <Text style={styles.paragraph}>8. is intended or otherwise likely to infringe (or threaten to infringe) another person&apos;s right to privacy;</Text>
                <Text style={styles.paragraph}>9. misleadingly impersonates any person or otherwise misrepresents your identity or affiliation in a way that is calculated to deceive (obvious parodies are not included within this definition provided that they do not fall within any of the other provisions of this sub-Clause 14.2);</Text>
                <Text style={styles.paragraph}>10. implies any form of affiliation with Us where none exists;</Text>
                <Text style={styles.paragraph}>11. infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, patents, trade marks and database rights) of any other party; or</Text>
                <Text style={styles.paragraph}>12. is in breach of any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence.</Text>
            </View>
            <Text style={styles.paragraph}>13.3 We reserve the right to suspend or terminate your Account and/or your access to Our Web App if you materially breach the provisions of this Clause 14 or any of the other provisions of these terms and conditions.  Specifically, We may take one or more of the following actions:</Text>
            <View style={styles.indentation}>
                <Text style={styles.paragraph}>1. Suspend, whether temporarily or permanently, your Account and/or your right to access Our Web App (for more details regarding such cancellation, please refer to sub-Clause 8.9);</Text>
                <Text style={styles.paragraph}>2. Remove any of your User Content which violates this Acceptable Usage Policy;</Text>
                <Text style={styles.paragraph}>3. Issue you with a written warning;</Text>
                <Text style={styles.paragraph}>4. Take legal proceedings against you for reimbursement of any and all relevant costs on an indemnity basis resulting from your breach;</Text>
                <Text style={styles.paragraph}>5. Take further legal action against you as appropriate;</Text>
                <Text style={styles.paragraph}>6. Disclose such information to law enforcement authorities as required or as we deem reasonably necessary; and/or</Text>
                <Text style={styles.paragraph}>7. Any other actions which We deem reasonably appropriate (and lawful).</Text>
            </View>
            <Text style={styles.paragraph}>13.4 We hereby exclude any and all liability arising out of any actions (including, but not limited to, those set out above) that We may take in response to breaches of these Terms and Conditions.</Text>
            <Text style={styles.smallSubtitle}>14. Disclaimers</Text>
            <Text style={styles.paragraph}>14.1 No part of Our Web App or any accompanying documentation (whether provided in electronic form or otherwise) constitutes advice on which you should rely and is provided for general information purposes only.  Professional or specialist advice should always be sought before taking any action relating to obtaining legal advice and legal services.</Text>
            <Text style={styles.paragraph}>14.2 Insofar as is permitted by law, We make no representation, warranty, or guarantee that Our Web App will meet your requirements, that it will be fit for a particular purpose, that it will not infringe the rights of third parties, that it will be compatible with all software and hardware, or that it will be secure.</Text>
            <Text style={styles.paragraph}>14.3 We make reasonable efforts to ensure that the content contained within Our Web App is complete, accurate and up-to-date.  We do not, however, make representations, warranties or guarantees (whether express or implied) that Our Web App (and the content therein) is complete, accurate or up-to-date.</Text>
            <Text style={styles.paragraph}>14.4 We are not responsible for the content or accuracy, or for any opinions, views, or values expressed in any User Content created or uploaded using Our Web App.  Any such opinions, views, or values are those of the relevant User, and do not reflect Our opinions, views, or values in any way.</Text>
            <Text style={styles.smallSubtitle}>15. Our Liability</Text>
            <Text style={styles.paragraph}>15.1 To the fullest extent permissible by law, We accept no liability for any foreseeable loss in contract, tort (including negligence), for breach of statutory duty, or otherwise arising out of or in connection with the use of (or inability to use) Our Web App or the use of or reliance upon any Content (whether that Content is provided by Us or whether it is User Content) included in Our Web App.</Text>
            <Text style={styles.paragraph}>15.2 To the fullest extent permissible by law, We accept no liability to consumers or businesses for loss or damage that is not foreseeable.</Text>
            <Text style={styles.paragraph}>15.3 To the fullest extent permissible by law, We exclude all representations, warranties, and guarantees (whether express or implied) that may apply to Our Web App or any Content (including User Content) included in Our Web App.</Text>
            <Text style={styles.paragraph}>15.4 We accept no liability for loss of profits, sales, business or revenue; loss of business opportunity, goodwill or reputation; loss of anticipated savings; business interruption; or for any indirect or consequential loss or damage.</Text>
            <Text style={styles.paragraph}>15.5 Our maximum aggregate liability, whether in contract, tort (including negligence), breach of statutory duty or otherwise - for any and all breaches and/or non-performance of its obligations or liability under this Agreement in any 12 month period shall be limited to the total Fees paid by the You in such 12 month period.</Text>
            <Text style={styles.paragraph}>15.6 We exercise all reasonable skill and care to ensure that Our Web App is free from viruses and other malware.  Subject to sub-Clause 15.2.1, We accept no liability for any loss or damage resulting from a virus or other malware, a distributed denial of service attack, or other harmful material that may adversely affect your hardware, software, data or other material that occurs as a result of your use of Our Web App (including the downloading of any Content (including User Content) from it) or any other website or service that We may provide a link to.</Text>
            <Text style={styles.paragraph}>15.7 We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of Our Web App resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.</Text>
            <Text style={styles.paragraph}>15.8 Nothing in these Terms and Conditions excludes or restricts Our liability in any situation where it would be unlawful for us to do so including fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be excluded or restricted by law.  For full details of applicable consumers&apos; legal rights, including those relating to digital content, please contact your local Citizens&apos; Advice Bureau or Trading Standards Office.</Text>
            <Text style={styles.smallSubtitle}>16. Viruses, Malware and Security</Text>
            <Text style={styles.paragraph}>16.1 We exercise all reasonable skill and care to ensure that Our Web App is secure and free from viruses and other malware.  We do not, however, guarantee that Our Web App is secure or free from viruses or other malware and accept no liability in respect of the same, as detailed in sub-Clause 18.6.</Text>
            <Text style={styles.paragraph}>16.2 You are responsible for protecting your hardware, software, data and other material from viruses, malware and other internet security risks.</Text>
            <Text style={styles.paragraph}>16.3 You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Our Web App.</Text>
            <Text style={styles.paragraph}>16.4 You must not attempt to gain unauthorised access to any part of Our Web App, the server on which Our Web App is stored, or any other server, computer, or database connected to Our Web App.</Text>
            <Text style={styles.paragraph}>16.5 You must not attach Our Web App by means of a denial of service attack, a distributed denial of service attack, or by any other means.</Text>
            <Text style={styles.paragraph}>16.6 By breaching the provisions of sub-Clauses 19.3 to 19.5 you may be committing a criminal offence under the Computer Misuse Act 1990.  Any and all such breaches will be reported to the relevant law enforcement authorities and We will cooperate fully with those authorities by disclosing your identity to them.  Your right to use Our Web App will cease immediately in the event of such a breach and, where applicable, your Account will be suspended and/or deleted.</Text>
            <Text style={styles.smallSubtitle}>17. Data Protection</Text>
            <Text style={styles.paragraph}>All personal information that We may collect (including, but not limited to, your name and contact details) will be collected, used, and held in accordance with the provisions of the General Data Protection Regulations and your rights and Our obligations under those Regulations and as further set out in our Privacy Policy.</Text>
            <Text style={styles.smallSubtitle}>18. Communications from Us</Text>
            <Text style={styles.paragraph}>18.1 If you have an Account, We may from time to time send you important notices by email.  Such notices may relate to matters including, but not limited to, service changes, changes to these Terms and Conditions, changes to Our Web App, and changes to your Account.</Text>
            <Text style={styles.paragraph}>18.2 We will never send you marketing emails of any kind without your express consent.  If you do give such consent, you may opt out at any time.  Any and all marketing emails sent by Us include an unsubscribe link.  </Text>
            <Text style={styles.paragraph}>18.3 For questions or complaints about email communications from Us (including, but not limited to, marketing emails), please contact Us via Tabled.</Text>
            <Text style={styles.smallSubtitle}>19. Other Important Terms</Text>
            <Text style={styles.paragraph}>19.1 We may transfer (assign) Our obligations and rights under these Terms and Conditions (and under the Contract, as applicable) to a third party (this may happen, for example, if We sell Our business).  If this occurs, you will be informed by Us in writing.  Your rights under these Terms and Conditions will not be affected and Our obligations under these Terms and Conditions will be transferred to the third party who will remain bound by them.</Text>
            <Text style={styles.paragraph}>19.2 You may not transfer (assign) your obligations and rights under these Terms and Conditions (and under the Contract, as applicable) without Our express written permission.</Text>
            <Text style={styles.paragraph}>19.3 The Contract is between you and Us.  It is not intended to benefit any other person or third party in any way and no such person or party will be entitled to enforce any provision of these Terms and Conditions.</Text>
            <Text style={styles.paragraph}>19.4 If any of the provisions of these Terms and Conditions are found to be unlawful, invalid or otherwise unenforceable by any court or other authority, that / those provision(s) shall be deemed severed from the remainder of these Terms and Conditions.  The remainder of these Terms and Conditions shall be valid and enforceable.</Text>
            <Text style={styles.paragraph}>19.5 No failure or delay by Us in exercising any of Our rights under these Terms and Conditions means that We have waived that right, and no waiver by Us of a breach of any provision of these Terms and Conditions means that We will waive any subsequent breach of the same or any other provision.</Text>
            <Text style={styles.smallSubtitle}>20. Changes to these Terms and Conditions</Text>
            <Text style={styles.paragraph}>20.1 We may agree with you alterations to these Terms and Conditions in writing.  Where We enter into a signed agreement with you for the provision of Our Web App to you, in the event of any conflict between these Terms and Conditions and the signed agreement between us, the signed agreement shall take precedence.</Text>
            <Text style={styles.paragraph}>20.2 We may alter these Terms and Conditions at any time.   Any such changes will become binding on you upon your first use of Our Web App after the changes have been implemented.  You are therefore advised to check this page from time to time.</Text>
            <Text style={styles.paragraph}>20.3 In the event of any conflict between the current version of these Terms and Conditions and any previous version(s), the provisions current and in effect shall prevail unless it is expressly stated otherwise.</Text>
            <Text style={styles.smallSubtitle}>21. Contacting Us</Text>
            <Text style={styles.paragraph}>To contact Us, please submit an email to us at Tabled.</Text>
            <Text style={styles.smallSubtitle}>22. Law and Jurisdiction</Text>
            <Text style={styles.paragraph}>22.1 These Terms and Conditions, the Contract, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and construed in accordance with the law of England & Wales.</Text>
            <Text style={styles.paragraph}>22.2 Any disputes concerning these Terms and Conditions, the relationship between you and Us, or any related matters (whether contractual or otherwise) shall be subject to the exclusive jurisdiction of the courts of England and Wales.</Text>
        </View>
    );
};


export default TermsAndConditions;
