import { Application, ApplicationProperties } from '../../../types/application/application.types'
import { Collections } from '../../../types/firebase.types'
import { firestore } from '../firebase'

const getFirebaseApplications = async (firebaseUid: string): Promise<Application[]> => {
  const applications = await firestore
    .collection(Collections.APPLICATIONS)
    // .orderBy(pageOrderParameter)
    .where(ApplicationProperties.APPLICANT, '==', firebaseUid)
    .get()

  return applications.docs.map(doc => {
    const { status, council, markets, stages, applicant, externalId } = doc.data()
    const { id } = doc
    return { status, council, markets, stages, applicant, id, externalId }
  })
}

export default getFirebaseApplications
