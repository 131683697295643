import React, { useMemo, ReactElement, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { Button, Card } from 'react-native-paper'
import hydrateUserInfoForm from '../helpers/form/hydrateUserInfoForm'
import useForm from '../hooks/useForm/useForm'
import { colours } from '../styleguide'
import { AnyField, FormFieldValue } from '../types/application/formField.types'
import { UserInfo } from '../types/user.types'
import FormField from './FormField'

const styles = StyleSheet.create({
  button: {
    backgroundColor: colours.primary,
  },
})

interface Props {
  title: string
  form: AnyField[]
  seedData: UserInfo
  submitButtonText: string
  submitButtonAction: (newFields: AnyField[]) => unknown
  submitUserDocumentAction?: (newValue: FormFieldValue, identifier: string) => unknown
  enforceValidation: boolean
  setPageError: React.Dispatch<string>
  setLoading: React.Dispatch<boolean>
}

const UserInfoForm = (props: Props): ReactElement => {
  const {
    title,
    form,
    seedData,
    submitButtonText,
    submitButtonAction,
    submitUserDocumentAction,
    enforceValidation = false,
    setPageError,
    setLoading,
  } = props

  const [uploading, setUploading] = useState<boolean>(false)

  const startingFields = useMemo(() => hydrateUserInfoForm(form, seedData), [form, seedData])

  const { visibleFields, updateFormValue, validateForm, getFirebaseFormattedForm } = useForm(startingFields)

  const submitForm = async () => {
    setLoading(true)
    const isValid = validateForm()

    if (enforceValidation && !isValid) {
      setPageError('This form contains one or more errors. Please fix the issues and try again.')

      return
    }

    try {
      const currentFields = getFirebaseFormattedForm()

      submitButtonAction(currentFields)
    } catch (e) {
      setPageError('This form could not be submitted. Please try again')
    }
  }

  return (
    <View>
      <Card.Title title={title} />
      <Card.Content>
        {visibleFields.map(field => (
          <FormField
            key={field.identifier}
            field={field}
            onInput={async (newValue: FormFieldValue) => {
              //Callback for uploading images via ImageUpload directly.
              if (submitUserDocumentAction) {
                setUploading(true)
                await submitUserDocumentAction(newValue, field.identifier)
                setUploading(false)
              }

              updateFormValue(newValue, field.identifier)
            }}
            uploading={uploading}
          />
        ))}
        <Button mode="elevated" textColor={colours.black} onPress={submitForm} style={styles.button}>
          {submitButtonText}
        </Button>
      </Card.Content>
    </View>
  )
}

export default UserInfoForm
