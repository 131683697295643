import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { Message } from '../../types/user.types'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

const patchMessages = async (userId: number, externalTaskId: number): Promise<Message[]> => {
  let messages = [] as Message[]

  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const formData = new FormData()
  formData.append('externalUserId', userId.toString())

  await fetch(
    new Request(`${Constants?.expoConfig?.extra?.hostname}/ext/tasks/${externalTaskId}/messages`, { method: 'PATCH' }),
    {
      body: formData,
      headers,
    }
  ).then(async response => {
    if (response.status === 200) {
      await response.json().then((patchedMessages: Message[]) => {
        messages = patchedMessages
      })
    } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
      throw new Error(ERRORS.UPGRADE_REQUIRED.message)
    } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
      await signOutUser()
    }
  })

  return messages
}

export default patchMessages
