import { AnyField } from '../../../types/application/formField.types'
import { Collections } from '../../../types/firebase.types'
import { firestore } from '../firebase'

const getMarketFields = async (selectedMarkets: string[]): Promise<AnyField[]> => {
  const marketsRef = firestore.collection(Collections.MARKET_FORM_TEMPLATES)
  // firebase has an 'in' operator but you can query by max 10 possibilities, so we have to map
  const marketFormResults = await Promise.all(
    selectedMarkets.map(async market => {
      const snapshot = await marketsRef.where('market', '==', market).get()

      return snapshot.docs.map(doc => doc.data().fields)
    })
  )

  return marketFormResults.flat(2)
}

export default getMarketFields
