import { AnyField } from '../../../types/application/formField.types'
import { AnyValidator, ValidatorTypes, XORValidator } from '../../../types/application/validator.types'
import { InMemoryFormFields } from '../../../types/form.types'

const cleanXorErrorMessages = (field: AnyField, formValues: InMemoryFormFields): InMemoryFormFields => {
  const formValuesCopy = { ...formValues }
  const XORValidatorForThisField = field.validators?.find(
    (validator: AnyValidator): validator is XORValidator => validator.type === ValidatorTypes.XOR
  )

  if (field.error || !XORValidatorForThisField) {
    return formValuesCopy
  }

  XORValidatorForThisField.arguments.partners.forEach((partner: string) => {
    formValuesCopy[partner].error = ''
  })
  return formValuesCopy
}

export default cleanXorErrorMessages
