import { useCallback } from 'react'
import { Platform } from 'react-native'
import { FirebaseAuthUserCredential } from '../helpers/firebase/firebase.types'
import getFirebaseUserToken from '../helpers/firebase/firestore/getFirebaseUserToken'
import putFirebaseUser from '../helpers/firebase/firestore/putFirebaseUser'
import { User, UserProperties } from '../types/user.types'
import googleSignInMobile from './googleSignIn'
import googleSignInWeb from './googleSignIn.web'
import useOMLContext from './useOMLContext'

interface ReturnValue {
  promptGoogleSignIn: () => Promise<void>
}

const useGoogleSignIn = (): ReturnValue => {
  const [context, setContext] = useOMLContext()

  const processUserSignIn = useCallback(
    async (result: FirebaseAuthUserCredential) => {
      const { user } = result

      if (!user) {
        throw new Error('User not found')
      }

      const { uid, email } = user
      if (!uid || !email) {
        throw new Error('User not found')
      }

      const userData = {
        [UserProperties.EMAIL_ADDRESS]: email,
      } as User

      const tabledAuthToken = await getFirebaseUserToken(uid)
      if (!tabledAuthToken) {
        await putFirebaseUser(uid, userData)
      }

      setContext({ ...context, firebaseUser: user })
    },
    [context, setContext]
  )

  const isPlatformWeb = Platform.OS === 'web'

  const promptGoogleSignIn = async () => {
    if (isPlatformWeb) {
      const result = await googleSignInWeb()
      processUserSignIn(result)
    } else {
      const result = await googleSignInMobile().catch(error => {
        throw new Error(error)
      })
      if (result) {
        processUserSignIn(result)
      } else {
        throw new Error('Google services unavailable')
      }
    }
  }

  return { promptGoogleSignIn }
}

export default useGoogleSignIn
