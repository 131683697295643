import { MaxLengthValidator, RequiredValidator, ValidatorTypes } from '../types/application/validator.types'

export const requiredValidator: RequiredValidator = {
  type: ValidatorTypes.REQUIRED,
  failureMessage: 'This field is required',
}

export const maxThreeImagesValidator: MaxLengthValidator = {
  type: ValidatorTypes.MAX_LENGTH,
  failureMessage: 'No more than three images are allowed.',
  arguments: {
    length: 3,
  },
}
