import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { AnyField } from '../../types/application/formField.types'
import { Assistant } from '../../types/user.types'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

export interface PatchAssistantResult {
  assistant: Assistant | null
}

/**
 *  Update the Assistant data in Tabled.
 *
 * @param {number} externalUserId - The ID of user updating an assistant.
 * @param {number} assistantId - The ID of assistant to update.
 * @param {AnyField[]} assistantDetails - The fields containing the assistants details.
 * @param {boolean} isCompleted - Whether or not all fields have been completed for the assistant.
 * @throws appNotFound, userDoesNotExist, unauthorised, externalUserAssistantAlreadyExists
 * @returns {Assistant} The updated assistant
 */

const patchAssistant = async (
  externalUserId: number,
  assistantId: number,
  assistantDetails: AnyField[],
  isCompleted: boolean
): Promise<PatchAssistantResult> => {
  const result: PatchAssistantResult = {
    assistant: null,
  }

  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const formData = new FormData()
  assistantDetails.forEach(field => {
    formData.append(field.identifier, `${field.value}`)
  })

  formData.append('isCompleted', `${isCompleted}`)

  await fetch(
    new Request(
      `${Constants?.expoConfig?.extra?.hostname}/ext/external-users/${externalUserId}/assistants/${assistantId}`,
      {
        method: 'PATCH',
      }
    ),
    {
      body: formData,
      headers,
    }
  ).then(async response => {
    if (response.status == 200) {
      await response.json().then(assistant => {
        result.assistant = assistant
      })
    } else {
      const errorMessage = response.headers.get('x-exit')
      if (errorMessage === ERRORS.UPGRADE_REQUIRED.message) {
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
        await signOutUser()
      } else if (errorMessage === ERRORS.ASSISTANT_ALREADY_EXISTS.message) {
        throw Error(`This National Insurance Number is already held by one of your assistants.`)
      } else {
        throw Error(`There was a problem when trying to save the assistant. Please, try again later.`)
      }
    }
  })

  return result
}

export default patchAssistant
