import * as BackgroundFetch from 'expo-background-fetch'
import { dismissNotificationAsync } from 'expo-notifications'
import { Platform } from 'react-native'
import { CouncilWorkspaces, MessageNotification, MessageNotifications } from '../../types/miscellaneous.types'
import { Message, Messages } from '../../types/user.types'

import getLatestMessages from '../api/getLatestMessages'
import getCouncilNameFromWorkspace from '../councilWorkspace'
import getCouncilWorkspaces from '../firebase/firestore/getCouncilWorkspaces'
import {
  retrieveExternalUserId,
  retrieveLastCheckedMessages,
  retrieveMessageNotifications,
  retrieveSettings,
  storeLastCheckedMessages,
  storeMessageNotifications,
} from '../localStorage'
import { retrieveCurrentNotification, scheduleNotification } from './notifications'

const BACKGROUND_FETCH_MESSAGES_TASK_NAME = 'background-fetch-messages'

const backgroundMessageNotificationTask = async () => {
  const deviceSettings = await retrieveSettings()
  const currentExternalUserId = await retrieveExternalUserId()

  if (currentExternalUserId != null) {
    const lastCheckedMessages: number | null = await retrieveLastCheckedMessages()

    let messages: Messages | null = null
    if (lastCheckedMessages) {
      messages = await getLatestMessages(currentExternalUserId, lastCheckedMessages)
    } else {
      messages = await getLatestMessages(currentExternalUserId, Date.now())
    }

    storeLastCheckedMessages(Date.now())

    if (!deviceSettings.notificationsEnabled) {
      return
    }

    if (messages != null) {
      const latestMessages: Messages = {}
      Object.keys(messages).forEach(taskId => {
        const taskMessages = messages![taskId]

        latestMessages[taskId] = taskMessages
          .filter(msg => msg.externalSentBy !== currentExternalUserId)
          .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
      })

      const currentMessageNotifications: MessageNotifications = await retrieveMessageNotifications()
      const councilWorkspaces: CouncilWorkspaces = await getCouncilWorkspaces()

      for (const taskId of Object.keys(latestMessages)) {
        const currentMessages: Message[] = latestMessages[taskId]

        if (currentMessages != null && currentMessages.length > 0) {
          const councilName = getCouncilNameFromWorkspace(currentMessages[0].workspace, councilWorkspaces) ?? 'OML'

          let newMessageNotification
          if (Platform.OS === 'ios') {
            for (const msg of currentMessages) {
              newMessageNotification = await createNewMessageNotification(
                councilName,
                currentMessages[0].workspace,
                currentMessages[0].externalTask,
                [msg]
              )
            }
          } else {
            if (currentMessageNotifications[taskId]) {
              newMessageNotification = await appendNewMessageNotification(
                councilName,
                currentMessageNotifications[taskId],
                currentMessages
              )
            } else {
              newMessageNotification = await createNewMessageNotification(
                councilName,
                currentMessages[0].workspace,
                currentMessages[0].externalTask,
                currentMessages
              )
            }
          }

          if (newMessageNotification) currentMessageNotifications[taskId] = newMessageNotification
        }
      }

      storeMessageNotifications(currentMessageNotifications)

      return BackgroundFetch.BackgroundFetchResult.NewData
    }
  }
  return BackgroundFetch.BackgroundFetchResult.NoData
}

const createNewMessageNotification = async (
  councilName: string,
  externalTask: number,
  workspace: number,
  latestMessages: Message[]
): Promise<MessageNotification> => {
  const newMessageNotification = {
    externalTask: externalTask,
    workspace: workspace,
    createdAt: new Date(Date.now()),
    updatedAt: new Date(Date.now()),
    notificationIdentifier: undefined,
  } as MessageNotification

  const newMessage = truncateAndAppend(latestMessages)
  const newNotificationId = await scheduleNotification(councilName, newMessage, newMessageNotification)

  return { ...newMessageNotification, notificationIdentifier: newNotificationId }
}

const appendNewMessageNotification = async (
  councilName: string,
  previousMessageNotification: MessageNotification,
  latestMessages: Message[]
): Promise<MessageNotification> => {
  // Has to be a previous notification stored.
  if (!previousMessageNotification.notificationIdentifier) {
    return await createNewMessageNotification(
      councilName,
      latestMessages[0].externalTask,
      latestMessages[0].workspace,
      latestMessages
    )
  }

  const currentNotification = await retrieveCurrentNotification(previousMessageNotification.notificationIdentifier)
  const currentNotificationMessage: string = currentNotification?.request?.content?.body ?? ''

  let newMessage = ''
  if (currentNotificationMessage === '') {
    newMessage = currentNotificationMessage + '\n' + truncateAndAppend(latestMessages)
  } else {
    newMessage = truncateAndAppend(latestMessages)
  }

  const newNotificationId = await scheduleNotification(councilName, newMessage, previousMessageNotification)
  await dismissNotificationAsync(previousMessageNotification.notificationIdentifier)

  return { ...previousMessageNotification, notificationIdentifier: newNotificationId }
}

const truncateAndAppend = (messages: Message[]): string =>
  messages.map(msg => (msg.content.length <= 15 ? msg.content : msg.content.substring(0, 15) + '...')).join('\n')

export { BACKGROUND_FETCH_MESSAGES_TASK_NAME, backgroundMessageNotificationTask }
