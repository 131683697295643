import Constants from 'expo-constants'
import { ERRORS, NUMBER_OF_STAGES } from '../../../constants'
import {
  Application,
  ApplicationProperties,
  ApplicationStage,
  ApplicationStageProperties,
  ApplicationStatuses,
  ShallowApplication,
} from '../../types/application/application.types'
import { AnyField, DateValue, FileValue, TabledFormFieldTypes } from '../../types/application/formField.types'
import { Collections } from '../../types/firebase.types'
import { AssistantSummary } from '../../types/user.types'
import signOutUser from '../firebase/auth/signOutUser'
import { firestore } from '../firebase/firebase'
import getCouncilMarkets from '../firebase/firestore/getCouncilMarkets'
import getMarketFields from '../firebase/firestore/getMarketFields'
import { retrieveTabledAuthToken } from '../localStorage'
import setApplicationStatus from '../setApplicationStatus'

interface CustomField {
  id: number
  name: string
  label: string
  inputType: string
  options: string
  value: string
  values: string[]
}

interface Result {
  application: {
    workspace: string
    externalId: string
    status: string
    customFields: CustomField[]
    licenceFee?: number
    workspaceId?: number
    assistants: AssistantSummary[]
    paymentAuthToken?: string
  }
}

interface FieldValue {
  value: string | boolean | string[] | FileValue[] | DateValue
}

const getApplication = async (userId: string, externalTaskId: string): Promise<Application | null> => {
  let applicationResult: Application | null = null

  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/tasks/${Number(externalTaskId)}`

  await fetch(
    new Request(url, {
      method: 'GET',
      headers,
    })
  )
    .then(async response => {
      const errorMessage = response.headers.get('x-exit')
      if (response.status == 200) {
        await response.json().then(async (result: Result) => {
          const res = result.application

          const result_status: ApplicationStatuses = setApplicationStatus(res.status)

          const marketsField = res.customFields.find(field => field.label === 'Market')
          const markets = marketsField && Array.isArray(marketsField.values) ? marketsField.values : []

          const applicationDocument = await firestore
            .collection(Collections.APPLICATIONS)
            .limit(1)
            .where(ApplicationProperties.APPLICANT, '==', userId)
            .where(ApplicationProperties.EXTERNAL_ID, '==', res.externalId)
            .get()

          const document = applicationDocument?.docs?.[0]?.ref

          const shallowApplication: ShallowApplication = {
            [ApplicationProperties.ID]: document.id,
            [ApplicationProperties.APPLICANT]: userId,
            [ApplicationProperties.COUNCIL]: res.workspace,
            [ApplicationProperties.MARKETS]: markets,
            [ApplicationProperties.STATUS]: result_status,
            [ApplicationProperties.EXTERNAL_ID]: res.externalId,
          }

          applicationResult = {
            ...shallowApplication,
            [ApplicationProperties.STAGES]: [] as ApplicationStage[],
            [ApplicationProperties.ASSISTANTS]: res.assistants,
            [ApplicationProperties.LICENCE_FEE]: res.licenceFee,
            [ApplicationProperties.WORKSPACE_ID]: res.workspaceId,
            [ApplicationProperties.PAYMENT_AUTH_TOKEN]: res.paymentAuthToken,
          }

          await document.set(shallowApplication)

          const councilMarkets = await getCouncilMarkets().catch(() => {
            throw new Error()
          })
          const availableMarkets = councilMarkets[shallowApplication.council]
          const otherMarketFields = await getMarketFields(availableMarkets).catch(() => {
            throw new Error()
          })

          for (let i = 0; i < NUMBER_OF_STAGES; i++) {
            const fields = await document
              .collection(ApplicationProperties.STAGES)
              .doc(String(i))
              .collection(ApplicationStageProperties.FIELDS)
              .get()

            let fieldIndex = fields.docs.length
            let stageFields = fields.docs.map(field => field.data() as AnyField)
            const fieldsToUpdate = [...stageFields]

            const filteredOtherMarketFields = [...otherMarketFields].filter(field => {
              const fieldMatched = [...fieldsToUpdate].some(f => {
                return f.identifier === field.identifier
              })
              return !fieldMatched
            })

            if (i === 0) {
              fieldsToUpdate.push(...filteredOtherMarketFields)
            }

            const assistantsField = fieldsToUpdate.find(field => field.identifier === 'assistants')
            if (assistantsField) {
              assistantsField.value = res.assistants.map(assistant => `${assistant.id}`)
              await document
                .collection(ApplicationProperties.STAGES)
                .doc(i.toString())
                .collection(ApplicationStageProperties.FIELDS)
                .doc('0')
                .set(assistantsField)
              fieldIndex++
            }

            for (const fieldToUpdate of fieldsToUpdate) {
              const field = res.customFields.find(f => f.name === fieldToUpdate.identifier)
              let removeMarket = false
              if (i === 0) {
                const marketName = fieldToUpdate.identifier.replace(' days', '')
                const marketIsSelected = markets.some(m => {
                  return m.includes(marketName)
                })

                removeMarket = !marketIsSelected
              }

              if (removeMarket) {
                const documentToRemove = fields.docs.find(
                  fieldDoc => fieldDoc.data()['identifier'] === fieldToUpdate.identifier
                )?.ref

                if (documentToRemove) {
                  const filteredStageFields = [...stageFields].filter(field => {
                    return field.identifier !== fieldToUpdate.identifier
                  })
                  stageFields = filteredStageFields
                  await documentToRemove.delete()
                }
              } else {
                if (field?.value || field?.values) {
                  const fieldDocumentToUpdate = fields.docs.find(
                    fieldDoc =>
                      fieldDoc.data()['identifier'] === field.name &&
                      JSON.stringify(fieldDoc.data()['value']) != JSON.stringify(field.value)
                  )?.ref

                  if (fieldDocumentToUpdate) {
                    const fieldValue = { value: null } as FieldValue
                    if (field.inputType === TabledFormFieldTypes.CHECKBOX) {
                      fieldValue.value = field.value === 'true' ? true : false
                    } else if (field.inputType === TabledFormFieldTypes.MULTIPLE_SELECT) {
                      fieldValue.value = field.values
                    } else {
                      fieldValue.value = field.value
                    }
                    fieldToUpdate.value = fieldValue.value
                    await fieldDocumentToUpdate.update({
                      value: fieldValue.value,
                    })
                  } else if (
                    i === 0 &&
                    !fields.docs.find(fieldDoc => fieldDoc.data()['identifier'] === field.name)?.ref
                  ) {
                    const fieldValue = { value: null } as FieldValue
                    if (field.inputType === TabledFormFieldTypes.CHECKBOX) {
                      fieldValue.value = field.value === 'true' ? true : false
                    } else if (field.inputType === TabledFormFieldTypes.MULTIPLE_SELECT) {
                      fieldValue.value = field.values
                    } else {
                      fieldValue.value = field.value
                    }
                    stageFields.push(fieldToUpdate)
                    fieldToUpdate.value = fieldValue.value
                    await document
                      .collection(ApplicationProperties.STAGES)
                      .doc(i.toString())
                      .collection(ApplicationStageProperties.FIELDS)
                      .doc(fieldIndex.toString())
                      .set(fieldToUpdate)
                    fieldIndex++
                  }
                }
              }
            }

            if (stageFields && stageFields.length) {
              applicationResult[ApplicationProperties.STAGES].push({
                [ApplicationStageProperties.FIELDS]: stageFields,
              } as ApplicationStage)
            }
          }
        })
      } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      } else if (errorMessage === ERRORS.TOKEN_EXPIRED.message) {
        await signOutUser()
      } else if (errorMessage === ERRORS.NOT_FOUND.message) {
        throw new Error(ERRORS.NOT_FOUND.message)
      } else {
        throw new Error('Failed to load application')
      }
    })
    .catch(err => {
      throw err
    })

  return applicationResult
}

export default getApplication
