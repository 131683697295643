import moment from "moment";
import React, { ReactElement } from "react";
import { StyleSheet, View, Text } from "react-native";
import {Application} from "../types/application/application.types";
import { Council} from "../types/miscellaneous.types";
import { Message, Messages} from "../types/user.types"
import MessageSummary from "./MessageSummary";

interface Props {
  councils: Council[];
  goToMessage: (message: Message, council: Council) => void;
  messages: Messages;
  applications: Application[];
}

const styles = StyleSheet.create({
  noApplicationsMessage: {
    textAlign: "center",
  },
});

const MessageBrowser = (props: Props): ReactElement => {
  const { councils, goToMessage, messages, applications } = props;

  return (
    <View>{getMessages(councils, goToMessage, messages, applications)}</View>
  );
};

const getMessages = (
  councils: Council[],
  goToMessage: (message: Message, council: Council) => void,
  messages: Messages,
  applications: Application[]
) => {
  const cards = [];
  const latestMessages: Message[] = [];

  Object.entries(messages).forEach(([, messages]) => {
    if (messages.length > 0) {
      latestMessages.push(messages[messages.length - 1]);
    }
  });

  for (let i = 0; i < latestMessages.length; i++) {
    const msgDate = formatDate(new Date(latestMessages[i].createdAt));
    const council: Council =
      councils.find(
        (council) => council.workspaceId === latestMessages[i].workspace
      ) ?? (null as unknown as Council);

    if (council === null) {
      throw new Error(`Cannot find council using workspace: ${latestMessages[i].workspace}`)
    }

    const application = applications.find(
      (a) => Number(a.externalId) === latestMessages[i].externalTask
    );
    const markets = !application?.markets
      ? ""
      : application?.markets.length === 1
      ? `${application?.markets[0]}`
      : `${application?.markets[0]} + ${application?.markets.length - 1}`;
    cards.push(
      <MessageSummary
        key={i}
        council={council}
        markets={markets}
        latestMessage={latestMessages[i].content}
        date={msgDate}
        hasSeen={(!!latestMessages[i].hasSeenDate) || !!latestMessages[i].externalSentBy}
        onPress={() => goToMessage(latestMessages[i], council)}
      />
    );
  }
  if (cards.length > 0) {
    return cards;
  }
  return (
    <Text style={styles.noApplicationsMessage}>
      You don&apos;t have any messages yet
    </Text>
  );
};

const formatDate = (someDateTimeStamp: Date) => {
  const dt = new Date(someDateTimeStamp);
  const date = dt.getDate();
  const today = new Date();
  const diffDays = today.getDate() - date;
  const diffMonths = today.getMonth() - dt.getMonth();
  const diffYears = today.getFullYear() - dt.getFullYear();
  const diffMilliseconds = today.getTime() - dt.getTime();

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
    return moment(dt).format("HH:mm");
  } else if (diffMilliseconds < 86400000 && diffMilliseconds > 0) {
    return "Yesterday";
  } else {
    return moment(dt).format("DD/MM/YYYY");
  }
};

export default MessageBrowser;
