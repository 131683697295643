import { FileValue } from '../../../types/application/formField.types'
import { storage } from '../firebase'

import { XHRBlob } from '../firebase.types'

// adapted from the expo docs
// https://docs.expo.dev/versions/latest/sdk/imagepicker/
const uploadToFirebaseStorage = async (file: FileValue, newPath: string): Promise<FileValue> => {
  if (file.firebaseStoragePath) {
    return file
  }

  // Why are we using XMLHttpRequest? See:
  // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  const blob: XHRBlob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      resolve(xhr.response)
    }
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'))
    }
    xhr.responseType = 'blob'
    xhr.open('GET', file.uri, true)
    xhr.send(null)
  })

  const ref = storage.ref().child(newPath)

  const snapshot = await ref.put(blob)
  blob?.close && blob.close()

  const newUri = await snapshot.ref.getDownloadURL()

  const metadata = await snapshot.ref.getMetadata()

  return { uri: newUri, firebaseStoragePath: metadata.fullPath }
}

export default uploadToFirebaseStorage
