import {Subscription} from "expo-modules-core";
import * as TaskManager from "expo-task-manager";
import {useRef, useState} from "react";
import {BACKGROUND_FETCH_APPLICATIONS_TASK_NAME} from "../helpers/notifications/applicationNotificationTask";
import {BACKGROUND_FETCH_MESSAGES_TASK_NAME} from "../helpers/notifications/messageNotificationTask";
import {
  registerBackgroundFetchAsync,
  unregisterBackgroundFetchAsync
} from "../helpers/notifications/notificationBackgroundFetch";

const usePushNotifications = () => {
  const [isMessageNotificationsRegistered, setIsMessageNotificationsRegistered] = useState(false);
  const [isApplicationNotificationsRegistered, setIsApplicationNotificationsRegistered] = useState(false);

  const [expoPushToken, setExpoPushToken] = useState('');

  const messageNotificationResponseListener = useRef<Subscription>();

  const checkBackgroundMessageTaskState = async () => {
    const isRegistered = await TaskManager.isTaskRegisteredAsync(BACKGROUND_FETCH_MESSAGES_TASK_NAME);
    setIsMessageNotificationsRegistered(isRegistered);
  };

  const checkBackgroundApplicationTaskState = async () => {
    const isRegistered = await TaskManager.isTaskRegisteredAsync(BACKGROUND_FETCH_APPLICATIONS_TASK_NAME);
    setIsApplicationNotificationsRegistered(isRegistered);
  }

  const toggleBackgroundApplicationTask = async () => {
    if (isApplicationNotificationsRegistered) {
      await unregisterBackgroundFetchAsync(BACKGROUND_FETCH_APPLICATIONS_TASK_NAME);
    } else {
      await registerBackgroundFetchAsync(BACKGROUND_FETCH_APPLICATIONS_TASK_NAME);
    }

    checkBackgroundApplicationTaskState();
  }

  const toggleBackgroundMessageTask = async () => {
    if (isMessageNotificationsRegistered) {
      await unregisterBackgroundFetchAsync(BACKGROUND_FETCH_MESSAGES_TASK_NAME);
    } else {
      await registerBackgroundFetchAsync(BACKGROUND_FETCH_MESSAGES_TASK_NAME);
    }

    checkBackgroundMessageTaskState();
  };

  return {
    expoPushToken,
    setExpoPushToken,

    toggleBackgroundMessageTask,
    toggleBackgroundApplicationTask,
    messageNotificationResponseListener,
  }
}

export default usePushNotifications;