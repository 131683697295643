/**
 * Types for Firebase
 * @module Firebase
 */

/**
 * The Firebase collections for the project.
 */
export enum Collections {
  /** {@link Application | Applications} created by market traders */
  APPLICATIONS = "applications",
  /** Sets of questions used by the frontend to compile applications */
  COUNCIL_FORM_TEMPLATES = "councilFormTemplates",
  /** Sets of questions used by the frontend to compile applications */
  MARKET_FORM_TEMPLATES = "marketFormTemplates",
  /** A mapping of councils to markets */
  COUNCIL_MARKETS = "councilMarkets",
  /** {@link User | Users} of the application */
  USERS = "users",
}

/**
 * User files are saved to "mutable" or "immutable" folders. Permissions are then applied to user accounts, but not applied to admin/server accounts.
 */
export enum UserFileFolders {
  MUTABLE = "mutable",
  IMMUTABLE = "immutable",
}
