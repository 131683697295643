import AsyncStorage from "@react-native-async-storage/async-storage";
import {ApplicationStatusNotifications, DeviceSettings, MessageNotifications} from "../types/miscellaneous.types";

const storeData = async (key: string, value: string): Promise<void> => {
    try {
        await AsyncStorage.setItem(
            key,
            value
        );
    } catch (error) {
        // Error saving data
    }
};

const retrieveData = async (key: string): Promise<string> => {
    try {
        const value = await AsyncStorage.getItem(key);
        if (value !== null) {
            return value;
        }
    } catch (error) {
        // Error retrieving data
    }

    return "";
};

const removeData = async (key: string): Promise<void> => {
    try {
        await AsyncStorage.removeItem(
            key
        );
    } catch (error) {
        // Error saving data
    }
};

export const retrieveSettings = async (): Promise<DeviceSettings> => {
    const storedSettings = await retrieveData("settings");

    if (storedSettings)
        return JSON.parse(storedSettings) as DeviceSettings;

    return {notificationsEnabled: true} as DeviceSettings;
}

export const storeSettings = async (settings: DeviceSettings): Promise<void> => {
    return await storeData("settings", JSON.stringify(settings));
}

export const retrieveMessageNotifications = async (): Promise<MessageNotifications> => {
    const storedMessageNotifications = await retrieveData("messageNotifications");

    if (storedMessageNotifications)
        return JSON.parse(storedMessageNotifications) as MessageNotifications;

    return {} as MessageNotifications;
}

export const storeMessageNotifications = async (messageNotifications: MessageNotifications): Promise<void> => {
    if (!messageNotifications || Object.keys(messageNotifications).length === 0) {
        return await removeData("messageNotifications");
    }

    return await storeData("messageNotifications", JSON.stringify(messageNotifications));
}

export const retrieveApplicationNotifications = async (): Promise<ApplicationStatusNotifications> => {
    const storedApplicationNotifications = await retrieveData("applicationNotifications");

    if (storedApplicationNotifications)
        return JSON.parse(storedApplicationNotifications) as ApplicationStatusNotifications;

    return {} as ApplicationStatusNotifications;
}

export const storeApplicationNotifications = async (applicationNotifications: ApplicationStatusNotifications): Promise<void> => {
  if (!applicationNotifications || Object.keys(applicationNotifications).length === 0) {
    return await removeData("applicationNotifications");
  }

  return await storeData("applicationNotifications", JSON.stringify(applicationNotifications));
}

export const retrieveExternalUserId = async (): Promise<number | null> => {
    const storedId = await retrieveData("externalUserId");

    if (!storedId) {
        return null;
    }

    return Number(storedId);
}

export const storeExternalUserId = async (externalUserId: number): Promise<void> => {
    if (externalUserId === -1) {
        return await removeData("externalUserId");
    }

    return await storeData("externalUserId", JSON.stringify(externalUserId));
}

export const retrieveLastCheckedMessages = async (): Promise<number | null> => {
    const storedEpoch = await retrieveData("lastCheckedMessages");

    if (!storedEpoch) {
        return 0;
    }

    return Number(storedEpoch)
}

export const storeLastCheckedMessages = async (epoch: number): Promise<void> => {
  await storeData("lastCheckedMessages", JSON.stringify(epoch));
};

export const retrieveTabledAuthToken = async (): Promise<string> => {
  const tabledAuthToken = await retrieveData("tabledAuthToken");

  if (!tabledAuthToken) {
    return "";
  }

  return tabledAuthToken;
};

export const storeTabledAuthToken = async (tabledAuthToken: string): Promise<void> => {
  await storeData("tabledAuthToken", tabledAuthToken);
};