/* eslint-disable react-hooks/exhaustive-deps */
import { useFocusEffect } from '@react-navigation/native'
import React, { ReactElement, useCallback, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Button } from 'react-native-paper'
import { ERRORS } from '../../constants'
import getAssistants from '../helpers/api/getAssistants'
import useOMLContext from '../hooks/useOMLContext'
import AssistantSummaryCard from '../molecules/AssistantSummaryCard'
import { colours } from '../styleguide'
import BannerTemplate from '../templates/BannerTemplate'
import { Pages, ScreenProps } from '../types/navigation.types'
import { AssistantSummary, AssistantSummaryProperties } from '../types/user.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    maxWidth: '100%',
    width: 500,
    backgroundColor: colours.backgroundGrey,
  },
  bottomButton: {
    marginTop: 20,
    backgroundColor: colours.primary,
  },
  title: {
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleContainer: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: '5%',
    alignItems: 'center',
  },
  noAssistantsText: {
    textAlign: 'center',
    fontWeight: '400',
    marginBottom: 20,
  },
})

interface Props extends ScreenProps<Pages.LICENCES> {
  showBanner: boolean
  loading: boolean
  error: string
}

const AssistantsScreen = (props: Props): ReactElement => {
  const { navigation } = props
  const [showBanner, setShowBanner] = useState<boolean>(props.showBanner)
  const [loading, setLoading] = useState<boolean>(props.loading)
  const [error, setError] = useState<string>(props.error)
  const [context, setContext] = useOMLContext()
  const [assistants, setAssistants] = useState<AssistantSummary[]>([])

  navigation.setOptions({ headerShown: !loading })

  useFocusEffect(
    useCallback(() => {
      if (!assistants.length && context.externalUser) {
        setLoading(true)
        fetchAssistants(context.externalUser.id)
      }
    }, [])
  )

  const fetchAssistants = async (externalUserId: number) => {
    getAssistants(externalUserId)
      .then(assistants => {
        if (assistants) {
          setAssistants(assistants)
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
        if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
          setContext({
            ...context,
            error: ERRORS.UPGRADE_REQUIRED,
          })
        } else {
          setError('Failed to load assistants. Please, try again later.')
          setShowBanner(true)
        }
      })
  }

  const dismissErrorMessage = () => {
    setShowBanner(false)
  }

  const createNewAssistant = () => {
    navigation.navigate(Pages.NEW_ASSISTANT, {})
  }

  const openAssistant = (assistantId: number) => {
    navigation.navigate(Pages.ASSISTANT_SUMMARY, { assistantId })
  }

  return (
    <BannerTemplate
      testID="AssistantsScreen"
      loading={loading}
      showBanner={showBanner}
      bannerText={error}
      dismissError={dismissErrorMessage}
    >
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>Your Assistants</Text>
        </View>
        {assistants.length ? (
          assistants
            .sort((a, b) =>
              a[AssistantSummaryProperties.FULL_NAME].localeCompare(b[AssistantSummaryProperties.FULL_NAME])
            )
            .map((assistant, index) => {
              return (
                <AssistantSummaryCard
                  key={`${index}`}
                  assistantName={assistant.fullName}
                  assistantPhoto={assistant.profilePicture || ''}
                  isCompleted={assistant.isCompleted}
                  onPress={() => openAssistant(assistant.id)}
                />
              )
            })
        ) : (
          <View>
            <Text style={styles.noAssistantsText}>No assistants to display.</Text>
          </View>
        )}
        <Button
          icon="plus"
          mode="elevated"
          textColor={colours.black}
          style={styles.bottomButton}
          onPress={createNewAssistant}
        >
          Create Assistant
        </Button>
      </View>
    </BannerTemplate>
  )
}

AssistantsScreen.defaultProps = {
  showBanner: false,
  loading: true,
  error: '',
}
export default AssistantsScreen
