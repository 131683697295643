import { ERRORS } from '../../../constants'
import { Messages, UnreadMessages, User, UserDocumentProperties, UserDocuments } from '../../types/user.types'
import getMessages from '../api/getMessages'
import getUserDocuments from '../api/getUserDocuments'

const loadUserDocuments = async (user: User): Promise<UserDocuments> => {
  let result: UserDocuments = {}

  const documentTypeNames = []
  for (const documentTypeName in UserDocumentProperties) {
    const documentType: UserDocumentProperties =
      UserDocumentProperties[documentTypeName as keyof typeof UserDocumentProperties]

    documentTypeNames.push(documentType)
  }

  await getUserDocuments(user, documentTypeNames)
    .then(documents => {
      result = documents ?? {}
    })
    .catch(err => {
      if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      }
    })

  return result
}

const loadUserMessages = async (externalUserId: number) => {
  let messages = {} as Messages

  await getMessages(externalUserId)
    .then(newMessages => newMessages && (messages = newMessages))
    .catch(err => {
      if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      }
    })

  const unreadMessages = {} as UnreadMessages
  Object.values(messages).forEach(msgs =>
    msgs.forEach(msg => {
      if (!msg.hasSeenDate && !msg.externalSentBy) {
        const currentCount = unreadMessages[msg.externalTask] ?? 0
        unreadMessages[msg.externalTask] = currentCount + 1
      }
    })
  )

  return { messages, unreadMessages }
}

export { loadUserDocuments, loadUserMessages }
