import Constants from 'expo-constants'
import { retrieveTabledAuthToken } from '../localStorage'

const putApplicationAssistants = async (externalTaskId: number, assistantsIds: string[]): Promise<Response> => {
  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }
  const formData = new FormData()

  assistantsIds.forEach(id => formData.append('assistantIds', id))

  return fetch(
    new Request(`${Constants?.expoConfig?.extra?.hostname}/ext/tasks/${externalTaskId}/assistants`, {
      method: 'PUT',
    }),
    {
      body: formData,
      headers,
    }
  )
}

export default putApplicationAssistants
