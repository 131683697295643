import React, { ReactElement } from 'react'
import { Linking, Platform, StyleSheet, View } from 'react-native'
import { Button, Paragraph, Title } from 'react-native-paper'
import { APPSTORE_LINKS, ERRORS } from '../../constants'
import signOutUser from '../helpers/firebase/auth/signOutUser'
import useOMLContext from '../hooks/useOMLContext'
import { colours } from '../styleguide'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: Platform.OS === 'web' ? colours.primary : colours.primary,
  },

  titleContainer: {
    marginVertical: '2%',
    width: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
  title: {
    marginVertical: '10%',
  },

  footerContainer: {
    flex: 3,
    marginHorizontal: 'auto',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    elevation: 2,
  },
  footer: {
    width: 500,
    maxWidth: '100%',
    flex: 3,
    backgroundColor: colours.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 20,
    paddingVertical: 30,
  },

  errorParagraph: {
    textAlign: 'center',
  },
  supportParagraph: {
    marginTop: '10%',
    marginBottom: '10%',
    textAlign: 'center',
  },

  button: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    backgroundColor: colours.primary,
  },
})

const CustomErrorFallback = (props: { error: Error; resetError: () => void }): ReactElement => {
  const [context, setContext] = useOMLContext()

  const onReturnHomePress = async () => {
    setContext({
      ...context,
      error: null,
    })
    props.resetError()
    await signOutUser()
  }

  const updateApp = () => {
    const link = Platform.OS === 'ios' ? APPSTORE_LINKS.IOS : APPSTORE_LINKS.ANDROID
    Linking.openURL(link)
    onReturnHomePress()
  }

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        {props.error.message === ERRORS.UPGRADE_REQUIRED.message ? (
          <Title style={styles.title}>Update Required!</Title>
        ) : (
          <Title style={styles.title}>Oops, something went wrong!</Title>
        )}
      </View>
      <View style={styles.footerContainer}>
        <View style={styles.footer}>
          {props.error.message === ERRORS.UPGRADE_REQUIRED.message ? (
            <View>
              <Paragraph style={styles.supportParagraph}>
                The current version of the app is no longer supported, please update to continue.
              </Paragraph>
              <Button
                style={styles.button}
                mode="elevated"
                textColor={colours.black}
                uppercase={false}
                onPress={updateApp}
              >
                Update
              </Button>
            </View>
          ) : (
            <View>
              <Paragraph style={styles.errorParagraph}>Error: {props?.error?.message}</Paragraph>
              <Paragraph style={styles.supportParagraph}>
                If this issue persists, please contact support@tabled.io
              </Paragraph>
              <Button
                style={styles.button}
                mode="elevated"
                textColor={colours.black}
                uppercase={false}
                onPress={onReturnHomePress}
              >
                Ok
              </Button>
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

CustomErrorFallback.defaultProps = {
  error: new Error('Default Error'),
  resetError: () => {
    return null
  },
}

export default CustomErrorFallback
