import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import moment from 'moment'
import React, { ReactElement, useEffect, useState, useCallback } from 'react'
import { StyleSheet, View, Text, Image, Platform, TouchableOpacity, Linking } from 'react-native'
import { Badge, Button, Caption } from 'react-native-paper'
import QRCode from 'react-native-qrcode-svg'
import AddToGoogleWalletSVG from '../../assets/addToGoogleWalletSVG.svg'
import { ERRORS } from '../../constants'
import getLicence from '../helpers/api/getLicence'
import getLicencePass from '../helpers/api/getLicencePass'
import putLicenceIsRemoved from '../helpers/api/putLicenceIsRemoved'
import signOutUser from '../helpers/firebase/auth/signOutUser'
import useOMLContext from '../hooks/useOMLContext'
import { colours } from '../styleguide'
import BannerTemplate from '../templates/BannerTemplate'
import { Licence } from '../types/licence/licence.types'
import { Pages, ScreenProps } from '../types/navigation.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    maxWidth: '100%',
    width: 500,
  },
  title: {
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 'bold',
  },
  titleContainer: {
    marginTop: 13,
    flexDirection: 'row',
  },
  councilLogoContainer: {
    flexDirection: 'row',
    width: '100%',
    height: 50,
    paddingHorizontal: '5%',
    justifyContent: 'center',
    marginVertical: 20,
  },
  councilLogo: {
    width: 300,
    height: 50,
  },
  noCouncilLogo: {
    backgroundColor: colours.lightGrey,
    borderWidth: 1,
    borderColor: colours.darkGrey,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noImageText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colours.darkGrey,
    textAlign: 'center',
  },
  licenceDetails: {
    flexDirection: 'row',
  },
  traderPhotoContainer: {
    position: 'relative',
    top: 0,
    right: 0,
    padding: 10,
  },
  traderPhoto: {
    height: 150,
    width: 150,
    borderRadius: 75,
  },
  noTraderPhoto: {
    backgroundColor: colours.lightGrey,
    borderWidth: 1,
    borderColor: colours.darkGrey,
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsTextContainer: {
    flex: 1,
    marginTop: 10,
  },
  detailsText: {
    marginBottom: 15,
    fontWeight: 'bold',
    flexWrap: 'wrap',
  },
  qrCodeOuterContainer: {
    marginTop: 10,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  qrCodeInnerContainer: {
    padding: 10,
    backgroundColor: colours.white,
  },
  marketDaysContainer: {
    width: '90%',
    marginLeft: 10,
    flexDirection: 'row',
  },
  marketDays: {
    width: '50%',
    flexWrap: 'wrap',
  },
  addToWalletBtnContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
  },
  addToGoogleWalletButton: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    borderRadius: 10,
    width: 250,
  },
  removeLicenceButtonContainer: {
    marginTop: 10,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  removeLicenceButton: {
    marginTop: 20,
    backgroundColor: colours.status.rejected,
  },
  licenceStatus: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  view: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  caption: {
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 15,
  },
})

const LicenceSummaryScreen = (props: ScreenProps<Pages.LICENCE_SUMMARY>): ReactElement => {
  const { navigation, route } = props
  const [loading, setLoading] = useState<boolean>(true)
  const [licence, setLicence] = useState<Licence>()
  const [licenceId, setLicenceId] = useState<number | null>(route.params.licenceId)
  const [context, setContext] = useOMLContext()
  const [loadingLicence, setLoadingLicence] = useState<boolean>(false)
  const isFocused = useIsFocused()

  useFocusEffect(
    useCallback(() => {
      setLoadingLicence(false)
    }, [])
  )

  useEffect(() => {
    setLicenceId(route.params.licenceId)
  }, [route.params.licenceId])

  useEffect(() => {
    if (((!licence && licenceId) || licence?.id !== Number(licenceId)) && !loadingLicence && isFocused) {
      setLoadingLicence(true)
      getLicence(route.params.licenceId)
        .then(licence => {
          if (licence) {
            setLicence(licence)
            setLoadingLicence(false)
          }
        })
        .catch(err => {
          setLoading(false)
          setLicenceId(null)
          if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
            setContext({
              ...context,
              error: ERRORS.UPGRADE_REQUIRED,
            })
          }
          navigation.navigate(Pages.LICENCES)
        })
    }
  }, [licence, route.params.licenceId, loadingLicence, setContext, context, licenceId, navigation, isFocused])

  useEffect(() => {
    if (licence && !loadingLicence) {
      setLoading(false)
    }
  }, [licence, loadingLicence])

  const getGoogleWalletPass = () => {
    if (!licence) {
      return
    }
    getLicencePass(licence.id).then(res => {
      if (res) {
        res.json().then(({ link }) => {
          if (link) {
            Linking.openURL(link)
          }
        })
      }
    })
  }

  const isLicenceExpired = () => {
    if (!licence) {
      return
    }
    const formattedExpiryDate = Date.parse(moment(licence.endDate).format('YYYY-MM-DD'))
    const today = Date.parse(moment().format('YYYY-MM-DD'))

    return today > formattedExpiryDate
  }

  const removeLicence = async () => {
    if (!licence) {
      return
    }
    putLicenceIsRemoved(licence.id).then(async response => {
      if (response.status === 200) {
        navigation.navigate(Pages.LICENCES)
      } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
        await signOutUser()
      }
    })
  }

  return (
    <BannerTemplate testID="applicationSummary" loading={loading}>
      {!!licence && !loading && !loadingLicence && (
        <View style={styles.container}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>Your Licence</Text>
            {isLicenceExpired() && (
              <View style={styles.licenceStatus}>
                <View style={styles.view} accessibilityRole="summary">
                  <View>
                    <Badge
                      style={{
                        backgroundColor: colours.status.rejected,
                      }}
                      size={15}
                    />
                  </View>
                  <Caption style={styles.caption}>Expired</Caption>
                </View>
              </View>
            )}
          </View>
          <View style={styles.councilLogoContainer}>
            {licence.councilLogo ? (
              <Image source={{ uri: licence.councilLogo }} resizeMode="contain" style={styles.councilLogo} />
            ) : (
              <View style={[styles.councilLogo, styles.noCouncilLogo]}>
                <Text style={styles.noImageText}>No logo available</Text>
              </View>
            )}
          </View>
          <View style={styles.licenceDetails}>
            <View style={styles.detailsTextContainer}>
              <Text style={styles.detailsText}>Name: {licence.traderFullName}</Text>
              <Text style={styles.detailsText}>Ref: {licence.referenceNumber}</Text>
              <Text style={styles.detailsText}>
                Licence Start:{' '}
                {`${new Date(licence.startDate).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}`}
              </Text>
              <Text style={styles.detailsText}>
                Licence End: {`${new Date(licence.endDate).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}`}
              </Text>
              <Text style={styles.detailsText}>Market: {licence.market}</Text>
            </View>
            <View style={styles.traderPhotoContainer}>
              {licence.traderPhoto ? (
                <Image source={{ uri: licence.traderPhoto }} resizeMode="contain" style={styles.traderPhoto} />
              ) : (
                <View style={[styles.traderPhoto, styles.noTraderPhoto]}>
                  <Text style={styles.noImageText}>No photo available</Text>
                </View>
              )}
            </View>
          </View>
          <View>
            {licence.daysOfWeek.map((day, index) => {
              return (
                <View key={`${index}`} style={styles.marketDaysContainer}>
                  <Text>{`\u2022\u00A0`}</Text>
                  <Text style={[styles.marketDays, styles.detailsText]}>{day}</Text>
                </View>
              )
            })}
          </View>
          <View style={styles.qrCodeOuterContainer}>
            <View style={styles.qrCodeInnerContainer}>
              <QRCode value={licence.url} size={250} />
            </View>
          </View>
          {Platform.OS === 'android' && !isLicenceExpired() && (
            <View style={styles.addToWalletBtnContainer}>
              <TouchableOpacity style={styles.addToGoogleWalletButton} onPress={getGoogleWalletPass}>
                <AddToGoogleWalletSVG width="100%" height="100%" />
              </TouchableOpacity>
            </View>
          )}
          {isLicenceExpired() && (
            <View style={styles.removeLicenceButtonContainer}>
              <Button
                mode="elevated"
                textColor={colours.white}
                icon="delete"
                style={styles.removeLicenceButton}
                onPress={removeLicence}
              >
                Remove licence
              </Button>
            </View>
          )}
        </View>
      )}
    </BannerTemplate>
  )
}

export default LicenceSummaryScreen
