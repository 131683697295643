import Constants from 'expo-constants'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { Platform } from 'react-native'
import { ERRORS, SAFETY_CERTIFICATE, VALID_FILE_TYPES } from '../../../constants'
import { ApplicationStage, ShallowApplication } from '../../types/application/application.types'
import { CustomField, FileValue, FormFieldTypes } from '../../types/application/formField.types'
import { Collections } from '../../types/firebase.types'
import { User, UserProperties } from '../../types/user.types'
import { firestore } from '../firebase/firebase'
import { FirebaseAuthUser } from '../firebase/firebase.types'
import getApplicationStageFields from '../firebase/firestore/getApplicationStageFields'
import getCouncilMarkets from '../firebase/firestore/getCouncilMarkets'
import getCouncilWorkspaces from '../firebase/firestore/getCouncilWorkspaces'
import putApplication from '../firebase/firestore/putApplication'
import putApplicationStageFields from '../firebase/firestore/putApplicationStageFields'
import { retrieveTabledAuthToken } from '../localStorage'

const getDocumentIds = async (application: ShallowApplication): Promise<number[]> => {
  const applicationReference = firestore.collection(Collections.APPLICATIONS).doc(application.id)

  const stageOneFields = await getApplicationStageFields(applicationReference, 1)
  const documentIds: number[] = []

  const foodCertificateField = stageOneFields.find(field => field.identifier === 'foodSafetyCertificate')
  const gasSafetyField = stageOneFields.find(field => field.identifier === 'gasSafetyCertificate')

  if (foodCertificateField) {
    if (foodCertificateField && foodCertificateField.value && Array.isArray(foodCertificateField.value)) {
      for (const value of foodCertificateField.value as FileValue[]) {
        documentIds.push(value.externalDocumentId ? value.externalDocumentId : -1)
      }
    }
  }
  if (gasSafetyField) {
    if (gasSafetyField && gasSafetyField.value && Array.isArray(gasSafetyField.value)) {
      for (const value of gasSafetyField.value as FileValue[]) {
        documentIds.push(value.externalDocumentId ? value.externalDocumentId : -1)
      }
    }
  }
  return documentIds.filter(x => x >= 0)
}
const prepareRequest = async (
  application: ShallowApplication,
  stages: ApplicationStage[],
  user: User,
  firebaseUser: FirebaseAuthUser
) => {
  const userName = `${user?.firstName} ${user?.lastName}`
  const applicationTitle =
    application.markets.length == 1
      ? `${application.markets[0]} - ${userName}`
      : `${application.markets.length} Markets - ${userName}`

  const councilMarkets = await getCouncilMarkets()

  const marketField = {
    id: 0,
    name: 'market',
    label: 'Market',
    type: FormFieldTypes.MULTIPLE_SELECT,
    options: councilMarkets[application.council],
    position: 0,
    value: `{${application.markets.toString()}}`,
  }

  const workspaces = await getCouncilWorkspaces()

  const formData = new FormData()

  formData.append('title', applicationTitle)
  formData.append('fields', JSON.stringify(marketField))
  formData.append('workspaceId', `${workspaces[application.council]}`)

  if (user?.[UserProperties.EXTERNAL_USER_ID]) {
    const id = user[UserProperties.EXTERNAL_USER_ID]
    formData.append('externalUserId', id.toString())
  }

  let finalStageIndex = 2
  if (stages[2].fields[0].type === FormFieldTypes.OBJECT_MULTIPLE_SELECT) {
    finalStageIndex = 3
    const assistantIds = stages[2].fields[0].value
    assistantIds.forEach(id => formData.append('assistantIds', id))
  }

  const allCustomFields = stages[0].fields.concat(stages[1].fields, stages[finalStageIndex].fields)

  let idCount = 1

  const customFields: CustomField[] = []
  // Formatting all custom fields except those that include a document/image
  for (const field of allCustomFields) {
    if (field.type !== FormFieldTypes.IMAGE) {
      let fieldValue = field.value
      if (field.type === 'date' && field.value) {
        fieldValue = `${field.value}`
      } else if (typeof field.value === 'boolean') {
        fieldValue = field.value.toString()
      } else if (field.type === FormFieldTypes.MULTIPLE_SELECT) {
        fieldValue = `{${field.value.toString()}}`
      }

      if (fieldValue && typeof fieldValue === 'string') {
        fieldValue = fieldValue.trim()
      }

      const newField: CustomField = {
        id: idCount,
        name: field.identifier,
        label: field.label,
        type: field.type,
        position: field.index,
        options: field.options ? field.options : [],
        value: fieldValue,
        dependee: [],
      }
      customFields.push(newField)
      idCount++
    }
  }

  customFields.forEach(field => formData.append('fields', JSON.stringify(field)))

  const applicationReference = firestore.collection(Collections.APPLICATIONS).doc(application.id)

  const stageOneFields = await getApplicationStageFields(applicationReference, 1)

  // Formmating all fields that include a document/image
  for await (const documentField of stageOneFields) {
    if (documentField.type !== FormFieldTypes.IMAGE || !documentField.value || !Array.isArray(documentField.value)) {
      continue
    }
    const imageField = documentField.value as FileValue[]
    const documentIndexes = [1, 2, 3]
    const documentsNotPostedToTabled = []

    let imageUri: string

    for await (const image of imageField) {
      imageUri = image.uri
      if (image.externalDocumentName && image.externalDocumentId) {
        const indexOfDocument = Number(image.externalDocumentName.split('-')[1].charAt(0))
        const index = documentIndexes.indexOf(indexOfDocument)
        if (index > -1) {
          documentIndexes.splice(index, 1)
        }
      } else {
        documentsNotPostedToTabled.push(image.firebaseStoragePath?.split('mutable/').pop())
      }
    }
    for (const path of documentsNotPostedToTabled) {
      const storage = getStorage()
      const url = await getDownloadURL(ref(storage, `users/${firebaseUser?.uid}/mutable/${path}`))

      await new Promise<void>(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = () => {
          const file = xhr.response

          const matchedType = Object.values(VALID_FILE_TYPES).find(value => value.mimeType === file.type)
          const extension = matchedType ? matchedType.extension : file.type.split('/')[1] || null

          const fileName = `${documentField.identifier}-${documentIndexes.shift()}.${extension}`

          const validExtensions = Object.values(VALID_FILE_TYPES).map(value => value.extension)
          if (!!extension && validExtensions.includes(extension)) {
            if (Platform.OS === 'web') {
              formData.append('filesToUpload', file, fileName)
            } else {
              formData.append('filesToUpload', {
                uri: imageUri,
                type: file.type,
                name: fileName,
              })
            }

            const fieldToEdit = stages[1].fields.find(field => field.identifier === documentField.identifier)

            if (fieldToEdit?.value) {
              const imageValue = fieldToEdit.value as FileValue[]
              const indexOfImage = imageValue.findIndex(
                obj => obj.firebaseStoragePath?.split('mutable/').pop() === path
              )
              if (Array.isArray(fieldToEdit.value) && fieldToEdit.value[indexOfImage]) {
                const firebaseStoragePath = imageValue[indexOfImage].firebaseStoragePath
                const uri = imageValue[indexOfImage].uri
                const externalDocumentName = fileName
                fieldToEdit.value[indexOfImage] = {
                  firebaseStoragePath,
                  uri,
                  externalDocumentName,
                }
              }
            }

            stages.map((stage, index) => putApplicationStageFields(application.id as string, index, stage.fields))
          }
          resolve()
        }
        xhr.open('GET', url)
        xhr.send()
      })
    }
  }

  return formData
}

const patchApplication = async (
  application: ShallowApplication,
  stages: ApplicationStage[],
  user: User,
  firebaseUser: FirebaseAuthUser
): Promise<{ newStages: ApplicationStage[]; status: number }> => {
  const applicationCopy = Object.assign({}, application)
  const stagesCopy = [...stages]
  // Format all of the applications data
  const formData = await prepareRequest(applicationCopy, stagesCopy, user, firebaseUser)
  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const documentIds = await getDocumentIds(application)

  const queryParams = () => {
    if (documentIds.length > 0) {
      return '?documentIds=' + documentIds.join()
    }
    return '?documentIds=-1'
  }

  const task = await fetch(
    new Request(`${Constants?.expoConfig?.extra?.hostname}/ext/tasks/${application.externalId}` + queryParams(), {
      method: 'PATCH',
    }),
    {
      body: formData,
      headers,
    }
  )

  switch (task.status) {
    case 200:
      await task.json().then(async response => {
        if (response.documents && response.documents.length > 0) {
          const applicationReference = firestore.collection(Collections.APPLICATIONS).doc(application.id)

          const stageOneFields = await getApplicationStageFields(applicationReference, 1)

          stageOneFields.forEach(documentField => {
            const isSafetyCertificate =
              documentField.identifier.includes(SAFETY_CERTIFICATE) &&
              documentField.value &&
              Array.isArray(documentField.value)
            if (isSafetyCertificate) {
              const imageField = documentField.value as FileValue[]
              documentField.value = imageField
                .map(file => {
                  const documentDetails = response.documents.find(
                    (doc: { documentId: number; documentName: string }) =>
                      doc.documentName === file.externalDocumentName
                  )
                  if (!documentDetails) {
                    return { uri: '' }
                  }

                  const firebaseStoragePath = file.firebaseStoragePath as string
                  const uri = file.uri
                  const externalDocumentId = documentDetails.documentId
                  const externalDocumentName = documentDetails.documentName
                  if (externalDocumentId && externalDocumentName) {
                    return {
                      firebaseStoragePath,
                      uri,
                      externalDocumentId,
                      externalDocumentName,
                    }
                  }
                  return {
                    firebaseStoragePath,
                    uri,
                  }
                })
                .filter(value => !!value.uri.length)

              const index = stages[1].fields.findIndex(obj => obj.identifier === documentField.identifier)

              stages[1].fields[index].value = documentField.value

              stages.map((stage, index) => putApplicationStageFields(application.id as string, index, stage.fields))
            }
          })
        }
      })
      putApplication(application)
      return {
        newStages: stages,
        status: task.status,
      }
    case 409:
      throw new Error(ERRORS.UPGRADE_REQUIRED.message)
    case 401:
      throw new Error(ERRORS.TOKEN_EXPIRED.message)
    default:
      throw new Error('Failed to submit the application')
  }
}

export default patchApplication
