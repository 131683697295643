import * as AppleAuthentication from 'expo-apple-authentication'
import * as Crypto from 'expo-crypto'
import firebase from 'firebase/compat/app'
import { useCallback } from 'react'
import { FirebaseAuthUserCredential } from '../helpers/firebase/firebase.types'
import getFirebaseUserToken from '../helpers/firebase/firestore/getFirebaseUserToken'
import putFirebaseUser from '../helpers/firebase/firestore/putFirebaseUser'
import { UserProperties, User } from '../types/user.types'
import useOMLContext from './useOMLContext'

const signIn = async () => {
  const nonce = Math.random().toString(36).substring(2, 10)
  const hashedNonce = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, nonce)
  const appleCredential = await AppleAuthentication.signInAsync({
    requestedScopes: [AppleAuthentication.AppleAuthenticationScope.EMAIL],
    nonce: hashedNonce,
  })
  const { identityToken } = appleCredential
  const provider = new firebase.auth.OAuthProvider('apple.com')
  const credential = provider.credential({
    idToken: identityToken || undefined,
    rawNonce: nonce,
  })
  return await firebase.auth().signInWithCredential(credential)
}

interface ReturnValue {
  promptAppleSignIn: () => Promise<void>
}

const useAppleSignIn = (): ReturnValue => {
  const [context, setContext] = useOMLContext()

  const processUserSignIn = useCallback(
    async (result: FirebaseAuthUserCredential) => {
      const { user } = result

      if (!user) {
        throw new Error('User not found')
      }

      const { uid, email } = user
      if (!uid || !email) {
        throw new Error('User not found')
      }

      const userData = {
        [UserProperties.EMAIL_ADDRESS]: email,
      } as User

      const tabledAuthToken = await getFirebaseUserToken(uid)
      if (!tabledAuthToken) {
        await putFirebaseUser(uid, userData)
      }

      setContext({ ...context, firebaseUser: user })
    },
    [context, setContext]
  )

  const promptAppleSignIn = async () => {
    const result = await signIn()
    processUserSignIn(result)
  }

  return {
    promptAppleSignIn,
  }
}

export default useAppleSignIn
