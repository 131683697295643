import { AnyField, FormFieldValue } from '../../types/application/formField.types'

import { UserInfo, UserProperties, UserDocumentProperties, UserAdditionalFieldProperties } from '../../types/user.types'

const hydrateUserInfoForm = (formWithStaleData: AnyField[], user: UserInfo): AnyField[] => {
  return formWithStaleData.map(field => {
    const identifier: UserProperties | UserDocumentProperties | UserAdditionalFieldProperties = field.identifier as
      | UserProperties
      | UserDocumentProperties
      | UserAdditionalFieldProperties

    let value: FormFieldValue | undefined = user[identifier]

    //Documents is an array of arrays.
    if (
      Object.values(UserDocumentProperties).includes(identifier as UserDocumentProperties) &&
      user[UserProperties.DOCUMENTS]
    ) {
      value = user[UserProperties.DOCUMENTS][identifier]
    }

    //UserAdditionalProperties
    if (
      Object.values(UserAdditionalFieldProperties).includes(identifier as UserAdditionalFieldProperties) &&
      user[UserProperties.ADDITIONAL_FIELDS]
    ) {
      value = user[UserProperties.ADDITIONAL_FIELDS]?.[identifier]
    }

    if (value === undefined) {
      return field
    }

    return { ...field, value } as AnyField
  })
}

export default hydrateUserInfoForm
