import { INVALID_DATE } from '../../../../../constants'
import { AnyField } from '../../../../types/application/formField.types'
import { FutureTimeValidator } from '../../../../types/application/validator.types'

import doesFormValueExist from '../doesFormValueExist'

const validateFutureTimeField = (field: AnyField, validator: FutureTimeValidator): string => {
  let value = field.value

  if (!doesFormValueExist(value) || !value || (typeof value === 'string' && value.toLowerCase() === INVALID_DATE)) {
    return ''
  }

  if (typeof value === 'string') {
    try {
      value = parseInt(value)
    } catch {
      throw new Error(`Non-date type passed to validateFutureTimeField. field.value was ${value}`)
    }
  } else if (typeof field.value !== 'number') {
    throw new Error(`Non-date type passed to validateFutureTimeField. field.value was ${value}`)
  }

  if (typeof value !== 'number') {
    return validator.failureMessage
  }

  const today = new Date()
  const tomorrow = new Date(new Date().setDate(today.getDate() + 1)).setHours(0, 0, 0, 0)

  const isValid = tomorrow <= value
  return isValid ? '' : validator.failureMessage
}
export default validateFutureTimeField
