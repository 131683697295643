import uuid from 'uuid-random'

import doesFormValueExist from '../../hooks/useForm/helpers/doesFormValueExist'
import {
  AnyField,
  FileValue,
  FormFieldTypes,
  ImageUploadField,
  SingleImageUploadField,
} from '../../types/application/formField.types'
import { UserFileFolders } from '../../types/firebase.types'
import uploadToFirebaseStorage from '../firebase/storage/uploadToFirebaseStorage'

const uploadFormFiles = (currentFields: AnyField[], firebaseUserId: string): Promise<AnyField[]> => {
  return Promise.all(
    currentFields.map(async field => {
      const isFieldImageField = FormFieldTypes.IMAGE === field.type || FormFieldTypes.IMAGE_SINGLE === field.type

      if (!doesFormValueExist(field.value) || !isFieldImageField) {
        return field
      }

      const imageField = field as ImageUploadField | SingleImageUploadField

      const value = await Promise.all(
        imageField.value.map((file: FileValue) => {
          const newPath = `users/${firebaseUserId}/${UserFileFolders.MUTABLE}/${uuid()}`

          return uploadToFirebaseStorage(file, newPath)
        })
      )

      const newField = {
        ...imageField,
        value,
      }
      return newField
    })
  )
}

export default uploadFormFiles
