import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { User, UserProperties } from '../../types/user.types'
import signOutUser from '../firebase/auth/signOutUser'
import postErrorMessageToSlack from './postErrorMessageToSlack'

const getToken = async (
  userEmailAddress: User[UserProperties.EMAIL_ADDRESS],
  tabledAuthToken: User[UserProperties.TABLED_AUTH_TOKEN]
): Promise<string | null> => {
  let result: string | null = null

  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/token?emailAddress=${userEmailAddress}`
  const headers: HeadersInit = {
    accessKeyId: `${Constants?.expoConfig?.extra?.accessKeyId}`,
    'tabled-auth-token': tabledAuthToken,
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  await fetch(
    new Request(url, {
      method: 'GET',
      headers,
    })
  ).then(async response => {
    if (response.status === 200) {
      await response.json().then(({ authToken }) => {
        result = authToken
      })
    } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
      throw new Error(ERRORS.UPGRADE_REQUIRED.message)
    } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
      await signOutUser()
    } else {
      const tokenError = new Error(
        `There was a problem when obtaining the authentication token for ${userEmailAddress} using token: ${tabledAuthToken}. (getToken.ts)`
      )
      postErrorMessageToSlack(tokenError, '', userEmailAddress)
      throw tokenError
    }
  })

  return result
}

export default getToken
