import React, { ReactElement } from 'react'
import { StyleSheet, Text, View, Image, Platform } from 'react-native'
import { Badge, Caption, Card } from 'react-native-paper'
import { colours } from '../styleguide'

interface Props {
  assistantName: string
  assistantPhoto: string
  isCompleted: boolean
  onPress: () => void
}

const styles = StyleSheet.create({
  applicationSummary: {
    marginTop: '3%',
    flexDirection: 'row',
  },
  cardContainer: {
    width: '100%'
  },
  cardContent: {
    padding: 10,
    flexDirection: 'row',
    width: '100%',
  },
  cardTitleContainer: {
    textAlign: 'left',
    justifyContent: 'center',
    flex: 1,
  },
  cardTitle: {
    fontSize: 15,
  },
  assistantStatus: {
    justifyContent: 'center',
    width: 60,
  },
  view: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  caption: {
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 15,
  },
  photoContainer: {
    position: 'relative',
    width: Platform.OS === 'web' ? '10%' : '15%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  photo: {
    width: '100%',
    aspectRatio: 1,
    borderRadius: 100,
    backgroundColor: colours.lightGrey,
    borderWidth: 1.5,
    borderColor: colours.darkGrey,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noPhoto: {
    fontSize: 25,
    color: colours.darkGrey,
  },
  assistantPhoto: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
  },
})

const AssistantSummaryCard = (props: Props): ReactElement => {
  const { assistantName, assistantPhoto, isCompleted, onPress } = props

  return (
    <Card onPress={onPress} style={styles.applicationSummary} contentStyle={styles.cardContainer}>
      <View style={styles.cardContent}>
        <View style={styles.photoContainer}>
          <View style={styles.photo}>
            {assistantPhoto ? (
              <Image source={{ uri: assistantPhoto }} resizeMode="contain" style={styles.assistantPhoto} />
            ) : (
              <Text style={styles.noPhoto}>{assistantName.charAt(0)}</Text>
            )}
          </View>
        </View>
        <View style={styles.cardTitleContainer}>
          <Text style={styles.cardTitle}>{assistantName}</Text>
        </View>
        <View style={styles.assistantStatus}>
          <View style={styles.view} accessibilityRole="summary">
            <View>
              <Badge
                style={{
                  backgroundColor: isCompleted ? colours.status.approved : colours.status.draft,
                }}
                size={15}
              />
            </View>
            <Caption style={styles.caption}>{isCompleted ? 'Completed' : 'Draft'}</Caption>
          </View>
        </View>
      </View>
    </Card>
  )
}

export default AssistantSummaryCard
