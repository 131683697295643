import Constants from 'expo-constants'
import generateRandomNumber from '../generateRandomNumber'

interface OrderResponse {
  status: string
}
const processPayPalPayment = async (
  authToken: string,
  orderId: string,
  partnerAttributionId: string,
  authAssertion: string
): Promise<OrderResponse> => {
  const url = `${Constants.manifest?.extra?.payPalLink}/v2/checkout/orders/${orderId}/capture`

  const payPalRequestId = generateRandomNumber(10000000000000)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'PayPal-Request-Id': `${payPalRequestId}`,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'PayPal-Partner-Attribution-Id': partnerAttributionId,
      'PayPal-Auth-Assertion': authAssertion,
    },
  })

  return response.json()
}

export default processPayPalPayment
