import React, { ReactElement } from "react";
import { View } from "react-native";
import { Subheading, Checkbox } from "react-native-paper";
import { colours } from "../styleguide";

interface Props {
  title: string;
  options: string[];
  value: string[];
  onPress: (option: string[]) => void;
}

const MultipleSelectList = (props: Props): ReactElement => {
  const { title, options, value, onPress } = props;

  const handleSelection = (option: string) => {
    const newValues = value.includes(option)
      ? value.filter((value) => value !== option)
      : [...value, option];
    onPress(newValues);
  };

  return (
    <View>
      <Subheading>{title}</Subheading>
      {options.map((option) => (
        <Checkbox.Item
          key={option}
          label={option}
          status={value.includes(option) ? "checked" : "unchecked"}
          onPress={() => handleSelection(option)}
          color={colours.primary}
          mode={"android"}
        />
      ))}
    </View>
  );
};

export default MultipleSelectList;
