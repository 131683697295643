import React, { ReactElement } from "react";
import {
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { IconButton, Title } from "react-native-paper";

import { colours } from "../styleguide";

const styles = StyleSheet.create({
  centeredView: {
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  modalView: {
    alignItems: "center",
    minWidth: Platform.OS === 'web' ? 500 : 300,
    maxWidth: Platform.OS === 'web' ? 800 : 500,
    width: Platform.OS === 'web' ? '80%' : '95%',
    height: "100%",
    marginHorizontal: 20,
    marginTop: 50,
    backgroundColor: colours.white,
    borderRadius: 20,
    shadowColor: colours.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  closeModalBtn: {
    width: "100%",
    minWidth: "100%",
    alignItems: "center",
  },
  title: {
    marginBottom: 20,
    alignSelf: "center",
  },
  scrollview: {
    height: "100%",
    width: "100%",
    alignContent: "center",
  },
});

interface Props {
  title: string;
  content: any;
  setModalVisible: () => void;
  isVisible: boolean;
}

const ModalTemplate = (props: Props): ReactElement => {
  const { title, content, setModalVisible, isVisible } = props;
  return (
    <Modal animationType="slide" transparent={true} visible={isVisible}>
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <TouchableOpacity
            onPress={setModalVisible}
            style={styles.closeModalBtn}
          >
            <IconButton icon={"down"} onPress={setModalVisible}/>
          </TouchableOpacity>
          <Title style={styles.title}>{title}</Title>
          <ScrollView style={styles.scrollview} scrollEventThrottle={0}>
            {content}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default ModalTemplate;
