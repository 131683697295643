import * as ImageManipulator from 'expo-image-manipulator'
import {
  ImagePickerOptions,
  MediaTypeOptions,
  requestMediaLibraryPermissionsAsync,
  launchImageLibraryAsync,
  requestCameraPermissionsAsync,
  launchCameraAsync,
} from 'expo-image-picker'
import React, { ReactElement } from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import { Button, Subheading, ActivityIndicator } from 'react-native-paper'
import { colours } from '../styleguide'
import { FileValue } from '../types/application/formField.types'
import ImageGrid from './ImageGrid'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    justifyContent: 'center',
  },
  mobileButton: {
    marginTop: 15,
    margin: 5,
    flexGrow: 1,
    minWidth: Platform.OS === 'android' ? 200 : 300,
    maxWidth: Platform.OS === 'ios' ? '100%' : 300,
    backgroundColor: colours.primary,
  },
  mobileUploadPhotoButton: {
    marginTop: 15,
    margin: 5,
    flexGrow: 1,
    minWidth: Platform.OS === 'android' ? 200 : 300,
    maxWidth: Platform.OS === 'ios' ? '100%' : 300,
    backgroundColor: colours.primary,
  },
  loading: {
    height: 100,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: colours.primary,
  },
})

export interface ImageUploadProps {
  label: string
  value: FileValue[]
  onChange: (addresses: FileValue[]) => void
  setLocalError: (newError: string) => void
  uploading?: boolean
}

const uploadOptions: ImagePickerOptions = {
  mediaTypes: MediaTypeOptions.Images,
  allowsEditing: false,
  quality: 1,
  base64: true,
}

const ImageUpload = (props: ImageUploadProps): ReactElement => {
  const { label, value, onChange, setLocalError, uploading } = props

  const canRemove = !value.some(val => val.canRemove === false)

  let totalFileSize = 0

  type PermissionsFn = typeof requestMediaLibraryPermissionsAsync | typeof requestCameraPermissionsAsync
  type CollectionFn = typeof launchImageLibraryAsync | typeof launchCameraAsync

  const getPicture = async (permissionsFn: PermissionsFn, collectionFn: CollectionFn) => {
    if (Platform.OS !== 'web') {
      const { status } = await permissionsFn()

      if (status !== 'granted') {
        setLocalError('Sorry, we need camera roll permissions to make this work!')
        return
      }
    }

    try {
      setLocalError('')
      const result = await collectionFn(uploadOptions)

      if (result.canceled) {
        return
      }

      result.assets.map(asset => {
        if (asset.base64) {
          const fileSizeBytes = asset.base64.length * (3 / 4)
          const fileSizeMegaBytes = Math.round((fileSizeBytes / Math.pow(1024, 2)) * 100) / 100
          totalFileSize += fileSizeMegaBytes
          if (fileSizeMegaBytes > 8) {
            throw new Error('fileSizeExceedsLimit')
          }
        }
      })

      value.forEach(image => {
        if (image.fileSize) {
          totalFileSize += image.fileSize
        }
      })

      if (totalFileSize > 8) {
        throw new Error('fileSizeExceedsLimit')
      }

      const fileSize = result.assets[0].base64
        ? Math.round(((result.assets[0].base64.length * (3 / 4)) / Math.pow(1024, 2)) * 100) / 100
        : 0

      //https://github.com/expo/expo/issues/9984: URI is base64 string on web.
      if (fileSize > 4) {
        const compressedImage = await ImageManipulator.manipulateAsync(
          result.assets[0].uri,
          [{ resize: { width: 1000 } }],
          { base64: true, compress: 0.7, format: ImageManipulator.SaveFormat.JPEG }
        )
        const compressedImageSize =
          Math.round((((compressedImage.base64?.length || 0) * (3 / 4)) / Math.pow(1024, 2)) * 100) / 100
        onChange([...value, { uri: compressedImage.uri, fileSize: compressedImageSize }])
      } else {
        onChange([...value, { uri: result.assets[0].uri, fileSize }])
      }
    } catch (err) {
      if (err instanceof Error && err.toString().includes('fileSizeExceedsLimit')) {
        setLocalError('File(s) exceeded total limit of 8MB')
      } else {
        setLocalError('File could not be uploaded')
      }
    }
  }

  const pickPicture = () => getPicture(requestMediaLibraryPermissionsAsync, launchImageLibraryAsync)

  const takePicture = () => getPicture(requestCameraPermissionsAsync, launchCameraAsync)

  const removeItem = (indexToRemove: number) => onChange(value.filter((_, index) => index !== indexToRemove))
  // we have to use index not uri because a user can upload the same image twice, and if so we should let them just delete one

  return (
    <View>
      <Subheading>{label}</Subheading>

      {Platform.OS === 'web' ? (
        <Button mode="elevated" textColor={colours.black} icon="addfolder" onPress={pickPicture} style={styles.button}>
          Upload
        </Button>
      ) : (
        <View style={styles.row}>
          <Button
            mode="elevated"
            textColor={colours.black}
            icon="addfile"
            onPress={pickPicture}
            style={styles.mobileUploadPhotoButton}
          >
            Upload photo
          </Button>
          <Button
            mode="elevated"
            textColor={colours.black}
            icon="camera"
            onPress={takePicture}
            style={styles.mobileButton}
          >
            Take photo
          </Button>
        </View>
      )}
      {uploading ? (
        <View style={styles.loading}>
          <ActivityIndicator size="large" animating={true} />
        </View>
      ) : canRemove ? (
        <ImageGrid images={value} actions={[{ label: 'remove', fn: removeItem }]} />
      ) : (
        <ImageGrid images={value} />
      )}
    </View>
  )
}

export default ImageUpload
