import { AnyField, FormFieldTypes } from '../types/application/formField.types'
import { ValidatorTypes } from '../types/application/validator.types'
import { UserAdditionalDocumentInformationProperties, UserDocumentProperties } from '../types/user.types'
import { requiredValidator } from './genericValidators'

export const optionalMyDocumentsForm: AnyField[] = [
  {
    type: FormFieldTypes.IMAGE,
    identifier: UserDocumentProperties.TRADER_PHOTO,
    label: 'Please upload a photograph of yourself, clear passport style with a white background.',
    validators: [requiredValidator],
    error: '',
    value: [],
  },
  {
    type: FormFieldTypes.IMAGE,
    identifier: UserDocumentProperties.ID_PHOTOS,
    label:
      'Please upload proof of your identity (passport or driving licence). Your ID document must be valid, not expired and include a high quality photo of yourself.',
    validators: [requiredValidator],
    error: '',
    value: [],
  },
  {
    type: FormFieldTypes.IMAGE,
    identifier: UserDocumentProperties.PROOF_OF_ADDRESS,
    label: 'Please upload proof of your home address (utility bill or bank statement within the last 3 months).',
    validators: [requiredValidator],
    error: '',
    value: [],
  },
  {
    type: FormFieldTypes.IMAGE,
    identifier: UserDocumentProperties.NATIONAL_INSURANCE_PROOF_PHOTO,
    label:
      'Upload a scan of your national insurance card, or proof of your national insurance number (e.g. a payslip).',
    validators: [requiredValidator],
    error: '',
    value: [],
  },
  {
    type: FormFieldTypes.IMAGE,
    identifier: UserDocumentProperties.PUBLIC_LIABILITY_INSURANCE_DOCUMENT,
    label:
      'You are required to have public liability insurance of at least £5m. Please upload a scan of your valid insurance certificate.',
    validators: [
      {
        type: ValidatorTypes.XOR,
        failureMessage: '',
        arguments: {
          partners: ['publicLiabilityInsuranceAcknowledged'],
        },
      },
    ],
    error: '',
    value: [],
  },
  {
    type: FormFieldTypes.DATE,
    label: 'Public liability insurance end date',
    identifier: UserAdditionalDocumentInformationProperties.PUBLIC_LIABILITY_INSURANCE_END_DATE,
    validators: [
      {
        type: ValidatorTypes.FUTURE_TIME,
        failureMessage: 'Insurance end date must be in the future',
      },
      {
        type: ValidatorTypes.CONDITIONAL_REQUIRED,
        failureMessage: 'Insurance end date is required',
        arguments: {
          partners: [UserDocumentProperties.PUBLIC_LIABILITY_INSURANCE_DOCUMENT],
        },
      },
    ],
    value: null,
    error: '',
  },
  {
    type: FormFieldTypes.CHECKBOX,
    identifier: UserAdditionalDocumentInformationProperties.PUBLIC_LIABILITY_INSURANCE_ACKNOWLEDGED,
    label: 'Alternatively, check this box to acknowledge that insurance will be obtained before the licence starts.',
    validators: [
      {
        type: ValidatorTypes.XOR,
        failureMessage:
          'You must either upload evidence of your public liability insurance, or acknowledge that it will be required (not both)',
        arguments: {
          partners: [UserDocumentProperties.PUBLIC_LIABILITY_INSURANCE_DOCUMENT],
        },
      },
    ],
    value: false,
    error: '',
  },
]

export const requiredMyDocumentsForm = optionalMyDocumentsForm.map(field => {
  return {
    ...field,
    validators: [...(field.validators || [])],
  }
})
