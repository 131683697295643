import { StackHeaderProps } from '@react-navigation/stack'
import React, { ReactElement, useState } from 'react'
import { Platform, Linking, StyleSheet, TouchableOpacity, Image, View, Text } from 'react-native'
import { Icon } from 'react-native-elements'
import { Appbar, Button } from 'react-native-paper'
import calculateUnreadMessages from '../../helpers/calculateUnreadMessages'
import useOMLContext from '../../hooks/useOMLContext'
import { colours } from '../../styleguide'
import { NavAction, Pages } from '../../types/navigation.types'
import { UserProperties } from '../../types/user.types'
import ErrorMessage from '../ErrorMessage'
import MessageIcon from '../MessageIcon'

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    alignItems: 'center',
    elevation: 0,
    shadowOpacity: 0,
    backgroundColor: colours.transparent,
  },

  logoContainer: {
    flex: 1,
    alignItems: 'center',
  },
  logo: {
    width: 100,
    height: 100,
  },
  backButton: {
    zIndex: 1,
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightSection: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: '100%',
    alignItems: 'center',
  },
  navLinksContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  navLinksWrapper: {
    margin: 0,
    padding: 0,
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  activeLinkItem: {
    borderBottomWidth: 2,
    borderColor: colours.primary,

    height: '80%',
  },
  linkItem: {
    height: '100%',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex',
  },
  activeLink: {
    color: colours.black,
  },
  icon: {
    marginRight: 10,
  },
  linkText: {
    color: colours.black,
    fontWeight: '600',
  },
  marketDirectory: {
    borderRadius: 50,
    height: '80%',
    justifyContent: 'center',
    marginRight: 10,
    backgroundColor: colours.primary,
  },
})

interface Props {
  navigation: StackHeaderProps['navigation']
  back: StackHeaderProps['back']
  title: string
  navigationOptions: Pages[]
  actions: NavAction[]
  burgerVisible: boolean
  messagesVisible: boolean
}

const NavbarWeb = (props: Props): ReactElement => {
  const { navigation, back, title, navigationOptions, actions } = props
  const [showError, setShowError] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>('')

  const dismissErrorMessage = () => {
    setShowError(false)
  }

  const [context] = useOMLContext()

  const performAction = (actionFunction: () => unknown) => {
    return actionFunction()
  }

  const directoryAction = () => {
    Linking.openURL('https://openmarkets.london/browse-markets/').catch(() => {
      setErrorText('Sorry! Something went wrong, try again later.')
      setShowError(true)
    })
  }

  const getIcon = (name: string): string => {
    switch (name) {
      case 'Applications':
        return 'home'
      case 'Messages':
        return 'message1'
      case 'Profile and account':
        return 'user'
      case 'Register':
        return 'user'
      case 'Settings':
        return 'setting'
      case 'FAQ':
        return 'questioncircleo'
      case 'Log out':
        return 'logout'
      case 'Login':
        return 'login'
      case 'Recover account':
        return 'unlock'
      case 'Verify account':
        return 'checkcircleo'
      case 'Licences':
        return 'idcard'
      case 'Assistants':
        return 'adduser'
      default:
        return ''
    }
  }

  return (
    <View>
      <Appbar.Header style={styles.header}>
        <View style={styles.leftSection}>
          {Platform.OS === 'web' && (
            <View style={styles.logoContainer}>
              <Image
                source={require('../../../assets/logo_clear_background.png')}
                resizeMode="contain"
                style={styles.logo}
              />
            </View>
          )}
          {back ? (
            <Appbar.BackAction
              color={colours.black}
              onPress={navigation.goBack}
              testID="backButton"
              style={styles.backButton}
            />
          ) : null}
        </View>
        <View style={styles.rightSection}>
          {!!navigationOptions.length || !!actions.length ? (
            <View style={styles.navLinksContainer}>
              <View style={styles.navLinksWrapper}>
                <Button
                  icon="isv"
                  mode="elevated"
                  textColor={colours.black}
                  style={styles.marketDirectory}
                  onPress={() => performAction(directoryAction)}
                >
                  Market Directory
                </Button>
                {navigationOptions.map(option => {
                  const pageName: string = option
                  return option.toLowerCase() === 'messages' ? (
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate(Pages.MY_MESSAGES)
                      }}
                      key={`page-${option}`}
                      style={[styles.linkItem, title === option && styles.activeLinkItem]}
                    >
                      <View style={styles.icon}>
                        <MessageIcon
                          value={calculateUnreadMessages(context.externalUser?.[UserProperties.UNREAD_MESSAGES] ?? {})}
                        />
                      </View>
                      <Text style={[styles.linkText, title === option && styles.activeLink]}>{option}</Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      onPress={() => performAction(() => navigation.navigate(pageName))}
                      key={`page-${option}`}
                      style={[styles.linkItem, title === option && styles.activeLinkItem]}
                    >
                      <Icon name={getIcon(option)} color={colours.black} type="antdesign" style={styles.icon} />
                      <Text style={[styles.linkText, title === option && styles.activeLink]}>{option}</Text>
                    </TouchableOpacity>
                  )
                })}

                {actions.map(action => (
                  <TouchableOpacity
                    onPress={() => performAction(action.fn)}
                    key={`action-${action.title}`}
                    style={styles.linkItem}
                  >
                    <Icon name={getIcon(action.title)} color={colours.black} type="antdesign" style={styles.icon} />
                    <Text style={styles.linkText}>{action.title}</Text>
                  </TouchableOpacity>
                ))}
              </View>
            </View>
          ) : null}
        </View>
      </Appbar.Header>
      <ErrorMessage errorMessage={errorText} showError={showError} onPress={dismissErrorMessage} />
    </View>
  )
}

NavbarWeb.defaultProps = {
  navigationOptions: [],
  actions: [],
  burgerVisible: false,
  messagesVisible: true,
}

export default NavbarWeb
