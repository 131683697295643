import { auth } from "../firebase";
import { FirebaseAuthUserCredential } from "../firebase.types";

const signUp = (
  email: string,
  password: string
): Promise<FirebaseAuthUserCredential> =>
  auth.createUserWithEmailAndPassword(email.trim(), password);

export default signUp;
