import { Collections } from '../../../types/firebase.types'
import { CouncilWorkspaces } from '../../../types/miscellaneous.types'
import { firestore } from '../firebase'

const getCouncilWorkspaces = async (): Promise<CouncilWorkspaces> => {
  const res = await firestore.collection(Collections.COUNCIL_MARKETS).get()
  return res.docs.reduce((acc: CouncilWorkspaces, doc) => {
    const { council, workspaceId } = doc.data()

    if (!workspaceId && !council) throw `No workspace found.`

    if (!workspaceId && council) throw `No workspace found for council: ${council}.`

    if (!council && workspaceId) throw `No workspace found for id: ${workspaceId}.`

    if (acc[council]) throw `A workspace has already been provided for ${council}.`

    acc[council] = workspaceId
    return acc
  }, {})
}

export default getCouncilWorkspaces
