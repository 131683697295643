import { StackHeaderProps } from '@react-navigation/stack'
import React, { ReactElement, useState } from 'react'

import { Dimensions } from 'react-native'
import { useMediaQuery } from 'react-responsive'
import { NavAction, Pages } from '../../types/navigation.types'
import NavbarMobile from './NavbarMobile'
import NavbarWeb from './NavbarWeb'

interface Props {
  navigation: StackHeaderProps['navigation']
  back: StackHeaderProps['back']
  title: string
  navigationOptions: Pages[]
  actions: NavAction[]
  burgerVisible: boolean
  messagesVisible: boolean
}

const Navbar = (props: Props): ReactElement => {
  const { navigation, back, title, navigationOptions, actions } = props

  const [burgerVisible] = useState(props.burgerVisible)
  const [messagesVisible] = useState(props.messagesVisible)

  const isDesktop = useMediaQuery({
    minDeviceWidth: 1080,
  })

  const useBurger = !isDesktop || Dimensions.get('window').width < 1350

  return useBurger ? (
    <NavbarMobile
      navigation={navigation}
      back={back}
      title={title}
      navigationOptions={navigationOptions}
      actions={actions}
      burgerVisible={burgerVisible}
      messagesVisible={messagesVisible}
    />
  ) : (
    <NavbarWeb
      navigation={navigation}
      back={back}
      title={title}
      navigationOptions={navigationOptions}
      actions={actions}
      messagesVisible={false}
    />
  )
}

Navbar.defaultProps = {
  navigationOptions: [],
  actions: [],
  burgerVisible: false,
  messagesVisible: true,
}

export default Navbar
