import Constants from 'expo-constants'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { Platform } from 'react-native'
import { ERRORS, VALID_FILE_TYPES, SAFETY_CERTIFICATE } from '../../../constants'
import { ApplicationProperties, ApplicationStage, ShallowApplication } from '../../types/application/application.types'
import { CustomField, Dependency, FileValue, FormFieldTypes } from '../../types/application/formField.types'
import { Collections } from '../../types/firebase.types'
import { User, UserProperties } from '../../types/user.types'
import { firestore } from '../firebase/firebase'
import { DocumentReference, FirebaseAuthUser } from '../firebase/firebase.types'
import getApplicationStageFields from '../firebase/firestore/getApplicationStageFields'
import getCouncilMarkets from '../firebase/firestore/getCouncilMarkets'
import getCouncilWorkspaces from '../firebase/firestore/getCouncilWorkspaces'
import getMarketFields from '../firebase/firestore/getMarketFields'
import putApplication from '../firebase/firestore/putApplication'
import putApplicationStageFields from '../firebase/firestore/putApplicationStageFields'
import formatCustomFieldDependencies from '../formatCustomFieldDependencies'
import { retrieveTabledAuthToken } from '../localStorage'

const prepareRequest = async (
  application: ShallowApplication,
  stages: ApplicationStage[],
  user: User,
  firebaseUser: FirebaseAuthUser
) => {
  const userName = `${user?.firstName} ${user?.lastName}`
  const applicationTitle =
    application.markets.length == 1
      ? `${application.markets[0]} - ${userName}`
      : `${application.markets.length} Markets - ${userName}`

  const councilMarkets = await getCouncilMarkets()

  const marketField = {
    id: 0,
    name: 'market',
    label: 'Market',
    type: FormFieldTypes.MULTIPLE_SELECT,
    options: councilMarkets[application.council],
    position: 0,
    value: `{${application.markets.toString()}}`,
    validators: [{ type: 'required', value: 'true' }],
  }

  const workspaces = await getCouncilWorkspaces()

  const formData = new FormData()

  formData.append('title', applicationTitle)
  formData.append('fields', JSON.stringify(marketField))
  formData.append('workspaceId', `${workspaces[application.council]}`)

  if (user?.[UserProperties.EXTERNAL_USER_ID]) {
    const id = user[UserProperties.EXTERNAL_USER_ID]
    formData.append('externalUserId', id.toString())
  }

  let finalStageIndex = 2
  if (stages[2].fields[0].type === FormFieldTypes.OBJECT_MULTIPLE_SELECT) {
    finalStageIndex = 3
    const assistantIds = stages[2].fields[0].value
    assistantIds.forEach(id => formData.append('assistantIds', id))
  }

  // Adding and formatting all fields related to market selection to the request that were not
  // selected in the application (used for dynamically editing the form)
  const availableMarkets = councilMarkets[application.council]
  const otherMarketFields = await getMarketFields(availableMarkets)
  const filteredOtherMarketFields = [...otherMarketFields].filter(field => {
    const fieldMatched = [...stages[0].fields].some(f => {
      return f.identifier === field.identifier
    })
    return !fieldMatched
  })
  const updatedStage0 = { fields: [...stages[0].fields, ...filteredOtherMarketFields] }
  updatedStage0.fields = updatedStage0.fields.filter((value, index, self) => self.indexOf(value) === index)

  let fieldIndex = 1
  let formattedStages = [updatedStage0, { ...stages[1] }, { ...stages[finalStageIndex] }]

  formattedStages = formattedStages.map(stage => {
    const updatedStage = {
      ...stage,
    }
    updatedStage.fields = updatedStage.fields.map(field => {
      const updatedField = {
        ...field,
        index: fieldIndex,
      }

      fieldIndex++

      return updatedField
    })
    return updatedStage
  })

  const allCustomFields = formattedStages[0].fields.concat(formattedStages[1].fields, formattedStages[2].fields)

  let idCount = 1

  const customFields: CustomField[] = []

  for (const field of allCustomFields) {
    if (field.type !== FormFieldTypes.IMAGE) {
      let fieldValue = field.value
      if (field.type === 'date' && field.value) {
        fieldValue = `${field.value}`
      } else if (typeof field.value === 'boolean') {
        fieldValue = field.value.toString()
      } else if (field.type === FormFieldTypes.MULTIPLE_SELECT) {
        fieldValue = `{${field.value.toString()}}`
      }

      if (fieldValue && typeof fieldValue === 'string') {
        fieldValue = fieldValue.trim()
      }

      const newField: CustomField = {
        id: idCount,
        name: field.identifier,
        label: field.label,
        type: field.type,
        position: field.index,
        options: field.options ? field.options : [],
        value: fieldValue,
        dependee: [],
        validators: [],
      }

      if (field.label.includes('Which day or days of the week do you want to trade at')) {
        const marketName = field.identifier.replace(' days', '')
        const dependency: Dependency = {
          id: 0,
          valueToMatch: marketName,
        }
        newField.dependee?.push(dependency)
      }
      if (field.validators.length) {
        field.validators.forEach(v => {
          if (v.type === 'required') {
            newField.validators?.push({ type: 'required', value: 'true' })
          }
        })
      }
      customFields.push(newField)
      idCount++
    }
  }

  // Identifies fields with dependencies and adds them to the field object
  const formattedCustomFields = await formatCustomFieldDependencies(application.council, customFields)

  formattedCustomFields.forEach(field => formData.append('fields', JSON.stringify(field)))

  for await (const field of allCustomFields) {
    if (field.type !== FormFieldTypes.IMAGE) {
      continue
    }
    const fieldToEdit = formattedStages[1].fields.find(f => f.identifier === field.identifier)
    if (fieldToEdit) {
      if (field.value && Array.isArray(field.value)) {
        let docCounter = 1

        for await (const value of field.value) {
          const storage = getStorage()

          if (value) {
            const fileInfo = value as FileValue
            const imageName = fileInfo.firebaseStoragePath?.split('mutable/').pop()
            const url = await getDownloadURL(ref(storage, `users/${firebaseUser?.uid}/mutable/${imageName}`))

            await new Promise<void>(resolve => {
              const xhr = new XMLHttpRequest()
              xhr.responseType = 'blob'
              xhr.onload = () => {
                const file = xhr.response

                const matchedType = Object.values(VALID_FILE_TYPES).find(value => value.mimeType === file.type)
                const extension = matchedType ? matchedType.extension : file.type.split('/')[1] || null

                const validExtensions = Object.values(VALID_FILE_TYPES).map(value => value.extension)
                if (!!extension && validExtensions.includes(extension)) {
                  const fileName = `${field.identifier}-${docCounter}.${extension}`

                  if (Platform.OS === 'web') {
                    formData.append('filesToUpload', file, fileName)
                  } else {
                    formData.append('filesToUpload', {
                      uri: fileInfo.uri,
                      type: file.type,
                      name: fileName,
                    })
                  }

                  if (Array.isArray(fieldToEdit.value) && fieldToEdit.value[docCounter - 1]) {
                    const firebaseStoragePath = fileInfo.firebaseStoragePath as string
                    const uri = fileInfo.uri
                    const externalDocumentName = fileName
                    fieldToEdit.value[docCounter - 1] = {
                      firebaseStoragePath,
                      uri,
                      externalDocumentName,
                    }
                  }

                  formattedStages.map((stage, index) =>
                    putApplicationStageFields(application.id as string, index, stage.fields)
                  )
                  docCounter += 1
                }
                resolve()
              }
              xhr.open('GET', url)
              xhr.send()
            })
          }
        }
      }
    }
  }

  return formData
}

const postApplication = async (
  application: ShallowApplication,
  stages: ApplicationStage[],
  user: User,
  firebaseUser: FirebaseAuthUser,
  postToTabled = false
): Promise<{ ref: DocumentReference; newStages: ApplicationStage[]; status: number }> => {
  if (postToTabled) {
    const applicationCopy = Object.assign({}, application)
    const stagesCopy = [...stages]
    const formData = await prepareRequest(applicationCopy, stagesCopy, user, firebaseUser)

    const headers: HeadersInit = {
      'tabled-auth-token': await retrieveTabledAuthToken(),
      'api-version': Constants?.expoConfig?.extra?.apiVersion,
    }

    const task = await fetch(
      new Request(`${Constants?.expoConfig?.extra?.hostname}/ext/tasks`, {
        method: 'POST',
      }),
      {
        body: formData,
        headers,
      }
    )

    switch (task.status) {
      case 200:
        await task.json().then(async response => {
          application[ApplicationProperties.EXTERNAL_ID] = response.taskId

          if (response.documents && response.documents.length > 0) {
            const applicationReference = firestore.collection(Collections.APPLICATIONS).doc(application.id)

            const stageOneFields = await getApplicationStageFields(applicationReference, 1)

            stageOneFields.forEach(documentField => {
              const isSafetyCertificate =
                documentField.identifier.includes(SAFETY_CERTIFICATE) &&
                documentField.value &&
                Array.isArray(documentField.value)
              if (isSafetyCertificate) {
                const imageField = documentField.value as FileValue[]
                documentField.value = imageField
                  .map(file => {
                    const documentDetails = response.documents.find(
                      (doc: { documentId: number; documentName: string }) =>
                        doc.documentName === file.externalDocumentName
                    )

                    if (!documentDetails) {
                      return { uri: '' }
                    }

                    const firebaseStoragePath = file.firebaseStoragePath as string
                    const uri = file.uri
                    const externalDocumentId = documentDetails.documentId
                    const externalDocumentName = documentDetails.documentName

                    return {
                      firebaseStoragePath,
                      uri,
                      externalDocumentId,
                      externalDocumentName,
                    }
                  })
                  .filter(value => !!value.uri.length)

                const index = stages[1].fields.findIndex(obj => obj.identifier === documentField.identifier)

                stages[1].fields[index].value = documentField.value

                stages.map((stage, index) => putApplicationStageFields(application.id as string, index, stage.fields))
              }
            })
          }
        })
        putApplication(application)
        return {
          ref: firestore.doc(`applications/${application.id}`),
          newStages: stages,
          status: task.status,
        }
      case 409:
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      case 401:
        throw new Error(ERRORS.TOKEN_EXPIRED.message)
      default:
        throw new Error('Failed to submit the application')
    }
  }
  const ref = await firestore.collection(Collections.APPLICATIONS).add(application)

  return { ref, newStages: stages, status: 200 }
}

export default postApplication
