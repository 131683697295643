import React, { ReactElement } from 'react'
import { StyleSheet } from 'react-native'
import { Button } from 'react-native-paper'
import { colours } from '../styleguide'

const styles = StyleSheet.create({
  button: {
    marginTop: 10,
    backgroundColor: colours.primary,
  },
})

interface Props {
  isUserOnFinalStage: boolean
  onSubmit: () => void
  onContinue: () => void
  onSaveDraft: () => void
}

const ApplicationFormButtons = (props: Props): ReactElement => {
  const { isUserOnFinalStage, onContinue, onSubmit, onSaveDraft } = props

  return (
    <>
      {isUserOnFinalStage ? (
        <Button mode="elevated" textColor={colours.black} onPress={onSubmit} style={styles.button}>
          Submit
        </Button>
      ) : (
        <Button mode="elevated" textColor={colours.black} onPress={onContinue} style={styles.button}>
          Continue
        </Button>
      )}

      <Button mode="elevated" textColor={colours.black} onPress={onSaveDraft} style={styles.button}>
        Save draft
      </Button>
    </>
  )
}

export default ApplicationFormButtons
