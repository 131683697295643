import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

const deleteDocument = async (userId: number, documentId: number): Promise<boolean> => {
  let result = false

  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/external-users/${userId}/documents/${documentId}`

  await fetch(new Request(url, { method: 'DELETE' }), { headers })
    .then(async response => {
      if (response.status == 200) {
        result = true
      } else if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
        await signOutUser()
      }
    })
    .catch(err => {
      throw err
    })

  return result
}

export default deleteDocument
