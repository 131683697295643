import React, { useState, ReactNode, ReactElement } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { Icon } from 'react-native-elements'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Card } from 'react-native-paper'
import { colours } from '../styleguide'

const styles = StyleSheet.create({
  title: {
    textAlign: 'left',
    fontWeight: '400',
    flex: 1,
    fontSize: 18,
  },
  container: {
    justifyContent: 'flex-start',
    marginBottom: '8%',
    padding: '4%',
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2%',
  },

  childrenContent: {
    paddingLeft: '2%',
  },
  icon: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: 50,
    backgroundColor: colours.messageNotification,
  },
  iconContainer: {
    justifyContent: 'center',
    right: 0,
    position: 'absolute',
  },
  iconText: {
    color: colours.white,
    fontWeight: 'bold',
  },
})

interface Props {
  isCollapsed: boolean
  titleComponent: string
  children: ReactNode
  messages?: string[]
  hasAssistants?: boolean
}

const ApplicationSummaryCollapsable = (props: Props): ReactElement => {
  const { titleComponent, children, messages, hasAssistants } = props
  const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed)
  const toggle = () => setIsCollapsed(!isCollapsed)

  const hasMessages = () => {
    if (messages && messages.length > 0) {
      return (
        <View style={styles.iconContainer}>
          <View style={styles.icon}>
            {/* Temporary placeholder until future functionality added */}
            <Text style={styles.iconText}>{messages.length}</Text>
          </View>
        </View>
      )
    }
    return
  }

  const hasAssistantsIcon = () => {
    if (hasAssistants) {
      return (
        <Icon
          name="checksquareo"
          type="antdesign"
          color={colours.status.approved}
          size={20}
        />
      )
    }
    return (
      <Icon
        name="closesquareo"
        type="antdesign"
        color={colours.status.rejected}
        size={20}
      />
    )
  }

  return (
    <Card style={styles.container}>
      <TouchableOpacity onPress={toggle} style={styles.content}>
        <Text style={styles.title}>{titleComponent}</Text>
        {hasMessages()}
        {titleComponent?.toLowerCase() === 'assistants' && hasAssistantsIcon()}
      </TouchableOpacity>
      <Collapsible style={styles.childrenContent} collapsed={isCollapsed}>
        {children}
      </Collapsible>
    </Card>
  )
}

ApplicationSummaryCollapsable.defaultProps = {
  isCollapsed: true,
}

export default ApplicationSummaryCollapsable
