import { AnyField } from '../../../types/application/formField.types'
import { InMemoryFormFields } from '../../../types/form.types'

const convertFromFirebaseFormat = (fields: AnyField[]): InMemoryFormFields =>
  fields.reduce((form: InMemoryFormFields, field: AnyField) => {
    const newField = { ...field }
    form[newField.identifier] = newField
    return form
  }, {})

export default convertFromFirebaseFormat
