import { AnyField } from '../../../../types/application/formField.types'
import { IdentityValidator } from '../../../../types/application/validator.types'
import { InMemoryFormFields } from '../../../../types/form.types'

const validateIdentityField = (field: AnyField, validator: IdentityValidator, fields: InMemoryFormFields): string => {
  const partners = validator.arguments.partners

  const isValid = partners.every(partner => JSON.stringify(field.value) === JSON.stringify(fields[partner].value))

  return isValid ? '' : validator.failureMessage
}

export default validateIdentityField
