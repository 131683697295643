import { Collections } from '../../../types/firebase.types'
import { User } from '../../../types/user.types'
import { firestore } from '../firebase'

const getFirebaseUserToken = async (userId: string): Promise<string | null> => {
  const userSnapshot = await firestore.collection(Collections.USERS).doc(userId).get()

  const user = userSnapshot.data() as User | undefined

  return user?.tabledAuthToken || null
}

export default getFirebaseUserToken
