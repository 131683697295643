import Constants from 'expo-constants'
import { ERRORS } from '../../../constants'
import { FileValue } from '../../types/application/formField.types'
import { Assistant, AssistantDocumentProperties, AssistantDocuments, AssistantProperties } from '../../types/user.types'
import signOutUser from '../firebase/auth/signOutUser'
import { retrieveTabledAuthToken } from '../localStorage'

interface TabledDocument {
  id: number
  name: string
  uri: string
}

const findDocumentType = (identifier: string) => {
  for (const documentType in AssistantDocumentProperties) {
    if (identifier === AssistantDocumentProperties[documentType as keyof typeof AssistantDocumentProperties]) {
      return identifier
    }
  }
}

const getAssistant = async (externalUserId: number, assistantId: number): Promise<Assistant | null> => {
  const url = `${Constants?.expoConfig?.extra?.hostname}/ext/external-users/${externalUserId}/assistants/${assistantId}`
  const headers: HeadersInit = {
    'tabled-auth-token': await retrieveTabledAuthToken(),
    'api-version': Constants?.expoConfig?.extra?.apiVersion,
  }

  let assistant: Assistant | null = null

  await fetch(
    new Request(url, {
      method: 'GET',
      headers,
    })
  ).then(async response => {
    if (response.status === 200) {
      await response.json().then(tabledAssistant => {
        assistant = {} as Assistant
        assistant[AssistantProperties.DOCUMENTS] = {} as AssistantDocuments
        if (tabledAssistant.documents.length) {
          const documents = tabledAssistant.documents
          for (const document of documents as TabledDocument[]) {
            const currentDocument: FileValue = {
              uri: document.uri,
              externalDocumentId: document.id,
            }
            const documentType = findDocumentType(document.name.split('.')[0])
            if (documentType) {
              if (assistant[AssistantProperties.DOCUMENTS][documentType]) {
                assistant[AssistantProperties.DOCUMENTS][documentType]?.push(currentDocument)
              } else {
                assistant[AssistantProperties.DOCUMENTS][documentType] = [currentDocument]
              }
            }
          }
        }
        assistant[AssistantProperties.ASSISTANT_ID] = tabledAssistant.id
        assistant[AssistantProperties.FIRST_NAME] = tabledAssistant.firstName
        assistant[AssistantProperties.LAST_NAME] = tabledAssistant.lastName
        assistant[AssistantProperties.ADDRESS] = tabledAssistant.address
        assistant[AssistantProperties.DATE_OF_BIRTH] = parseInt(tabledAssistant.birthDate)
        assistant[AssistantProperties.NATIONAL_INSURANCE_NUMBER] = tabledAssistant.nationalInsuranceNumber
        assistant[AssistantProperties.IS_SUBJECT_TO_IMMIGRATION_CONTROL] = tabledAssistant.isSubjectToImmigrationControl
        assistant[AssistantProperties.IS_COMPLETED] = tabledAssistant.isCompleted
        assistant[AssistantProperties.IS_MANAGER] = tabledAssistant.isManager
      })
    } else {
      const errorMessage = response.headers.get('x-exit')
      if (errorMessage === ERRORS.UPGRADE_REQUIRED.message) {
        throw new Error(ERRORS.UPGRADE_REQUIRED.message)
      } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
        await signOutUser()
      } else {
        throw Error(`Failed to load assistant. Please, try again later.`)
      }
    }
  })

  return assistant
}

export default getAssistant
