import Constants from 'expo-constants'
import { Collections } from '../../../types/firebase.types'
import { CouncilMarkets } from '../../../types/miscellaneous.types'
import { firestore } from '../firebase'

const getCouncilMarkets = async (filterDisabled = false, userEmailAddress = ''): Promise<CouncilMarkets> => {
  const res = await firestore.collection(Collections.COUNCIL_MARKETS).get()
  return res.docs.reduce((acc: CouncilMarkets, doc) => {
    const { council, markets, isDisabled, isTestWorkspace } = doc.data()

    const userEmailAddressExtension = userEmailAddress.split('@')[1]
    const doesUserHaveAccessToWorkspace =
      !!userEmailAddressExtension && isTestWorkspace
        ? userEmailAddressExtension === Constants?.expoConfig?.extra?.tabledStaffEmailExtension
        : true

    if (filterDisabled && !isDisabled && doesUserHaveAccessToWorkspace) {
      acc[council] = markets
    } else if (!filterDisabled && doesUserHaveAccessToWorkspace) {
      acc[council] = markets
    }
    return acc
  }, {})
}

export default getCouncilMarkets
