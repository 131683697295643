import 'react-native-gesture-handler'
import 'expo-firestore-offline-persistence'
import { DefaultTheme as NavigationDefaultTheme, NavigationContainer } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import * as Notifications from 'expo-notifications'
import { NotificationResponse } from 'expo-notifications'
import React, { ReactElement, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { stopReportingRuntimeErrors } from 'react-error-overlay'
import { LogBox, Platform, StatusBar } from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'
import { DefaultTheme as PaperDefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import AwesomeIcon from 'react-native-vector-icons/AntDesign'
import { ERRORS } from './constants'
import OMLContextProvider from './OMLContextProvider'
import Router from './Router'
import getToken from './src/helpers/api/getToken'
import getUser from './src/helpers/api/getUser'
import patchUser from './src/helpers/api/patchUser'
import postDeviceToken from './src/helpers/api/postDeviceToken'
import postErrorMessageToSlack from './src/helpers/api/postErrorMessageToSlack'
import postUser from './src/helpers/api/postUser'
import signOutUser from './src/helpers/firebase/auth/signOutUser'
import subscribeToAuthStateChange from './src/helpers/firebase/auth/subscribeToAuthStateChange'
import { FirebaseAuthUser } from './src/helpers/firebase/firebase.types'
import getFirebaseUserToken from './src/helpers/firebase/firestore/getFirebaseUserToken'
import patchFirebaseUser from './src/helpers/firebase/firestore/patchFirebaseUser'
import {
  retrieveMessageNotifications,
  storeExternalUserId,
  storeMessageNotifications,
  storeTabledAuthToken,
} from './src/helpers/localStorage'
import { registerForPushNotificationsAsync } from './src/helpers/notifications/notificationBackgroundFetch'
import { loadUserDocuments, loadUserMessages } from './src/helpers/user/loadUser'
import useErrorBoundary from './src/hooks/useErrorBoundary'
import usePushNotifications from './src/hooks/usePushNotifications'
import CustomErrorFallback from './src/molecules/ErrorBoundary'
import { colours } from './src/styleguide'
import { OMLContext } from './src/types/context.types'
import { MessageNotification, MessageNotifications } from './src/types/miscellaneous.types'
import { Pages } from './src/types/navigation.types'
import { Client } from './src/types/sails/sails.types'
import { User, UserProperties } from './src/types/user.types'
import WebSocket from './WebSocket'

//TODO THERE IS NO FIX FOR TIMER + FIREBASE.
LogBox.ignoreLogs(['Setting a timer'])

if (process.env.ENVIRONMENT === 'development' && Platform.OS === 'web') {
  stopReportingRuntimeErrors()
}

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
})

const theme = {
  ...PaperDefaultTheme,
  ...NavigationDefaultTheme,
  colors: {
    ...PaperDefaultTheme.colors,
    ...NavigationDefaultTheme.colors,
    primary: colours.primary,
    accent: colours.accent,
  },
}

interface Props {
  firebaseUser: FirebaseAuthUser
  externalUser: User
  socket: Client
  loading: boolean
}

const prefix = Linking.makeUrl('/')

const App = (props: Props): ReactElement => {
  const [loading, setLoading] = useState<boolean>(props.loading)
  const [error, setError] = useState<string>()
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const [shouldReloadApplications, setShouldReloadApplications] = useState<boolean>(false)
  const [routeName, setRouteName] = useState('')
  const [context, setContext] = useState<OMLContext['context']>({
    firebaseUser: props.firebaseUser,
    externalUser: props.externalUser,
    socket: props.socket,
    initialized: true,
    currentApplication: null,
    error: { statusCode: null, message: null },
    fetchApplications: false,
  })
  const [validatingUser, setValidatingUser] = useState<boolean>(false)

  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        Login: 'login',
        Messages: 'messages',
        Message: {
          path: 'messages/:externalTaskId/:title/:council',
          parse: {
            externalTaskId: (externalTaskId: string) => parseInt(externalTaskId),
            title: () => '',
            council: () => '',
          },
          stringify: {
            title: () => '',
            council: () => '',
          },
        },
        Applications: 'applications',
        'New application': 'new-application',
        'Application summary': {
          path: 'applications/:applicationId',
        },
        'Profile and account': 'profile',
        FAQ: 'faq',
        Settings: 'settings',
        Register: 'register',
        'Recover account': 'recover',
        Licences: 'licences',
        'Licence summary': {
          path: 'licences/:licenceId',
        },
        Payment: {
          path: 'applications/:applicationId/pay/:workspaceId/:licenceFee/:paymentAuthToken',
          parse: {
            workspaceId: (externalTaskId: string) => parseInt(externalTaskId),
            licenceFee: () => '',
            paymentAuthToken: () => '',
          },
          stringify: {
            workspaceId: () => '',
            licenceFee: () => '',
            paymentAuthToken: () => '',
          },
        },
        Assistants: 'assistants',
        'Assistant summary': {
          path: 'assistants/:assistantId/:onSubmit?',
          parse: {
            onSubmit: () => '',
          },
          stringify: {
            onSubmit: () => '',
          },
        },
        'New assistant': {
          path: 'assistants/new-assistant/:onSubmit?',
          parse: {
            onSubmit: () => '',
          },
          stringify: {
            onSubmit: () => '',
          },
        },
        'Fill out application': {
          path: 'applications/edit/:application/:onStage',
          parse: {
            application: () => '',
            onStage: () => '',
          },
          stringify: {
            application: () => '',
            onStage: () => '',
          },
        },
        'My details': 'details',
        'My documents': 'documents',
        'Application submission': {
          path: 'applications/finalise/:application/:status/:submitApplicationForm',
          parse: {
            application: () => '',
            status: () => '',
            submitApplicationForm: () => '',
          },
          stringify: {
            application: () => '',
            status: () => '',
            submitApplicationForm: () => '',
          },
        },
        'Missing information': {
          path: 'applications/missing-information/:application',
          parse: {
            application: () => '',
          },
          stringify: {
            application: () => '',
          },
        },
        'Admin verification': {
          path: 'admin-login/:handleAdminLogin',
          parse: {
            handleAdminLogin: () => '',
          },
          stringify: {
            handleAdminLogin: () => '',
          },
        },
      },
    },
  }

  function handleDeepLink({ url }: { url: string }) {
    Linking.openURL(url)
  }

  useEffect(() => {
    if (Platform.OS === 'android') {
      const linkingEvent = Linking.addEventListener('url', handleDeepLink)
      linkingEvent.remove()
    }
    if (Platform.OS === 'ios') {
      Notifications.addNotificationResponseReceivedListener(async (event: NotificationResponse) => {
        if (Platform.OS === 'ios') {
          const trigger = event.notification.request.trigger
          Object.entries(trigger).forEach(([key, value]) => {
            if (key === 'payload' && value.aps?.link) {
              Linking.openURL(value.aps.link)
            }
          })
        }
      })
    }
  })

  const {
    expoPushToken,
    setExpoPushToken,
    toggleBackgroundMessageTask,
    toggleBackgroundApplicationTask,
    messageNotificationResponseListener,
  } = usePushNotifications()

  const { errorHandler, setUserId } = useErrorBoundary()

  useEffect(() => {
    if (isMobile) {
      try {
        if (window.location.pathname) {
          const pathname = window.location.pathname
          const params = pathname.split('/')[1]
          Linking.openURL(`openmarketslondon://${params}`)
        }
      } catch {
        return
      }
    }
  }, [])

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.title = 'OpenMarkets.London'
      document.body.style.overflow = 'hidden scroll'
    }
  }, [])

  useEffect(() => {
    // Updates the application(s) when the websocket detects there has been a change to the status of an application
    if (shouldReloadApplications && (routeName === Pages.APPLICATIONS || routeName === Pages.APPLICATION_SUMMARY)) {
      setShouldReloadApplications(false)
      setContext({
        ...context,
        fetchApplications: true,
      })
    }
    setShouldReloadApplications(false)
  }, [shouldReloadApplications])

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.title = 'OpenMarkets.London'
    }
    if (!context.initialized) {
      return
    }

    // Phone Notifications.
    if (Platform.OS !== 'web') {
      registerForPushNotificationsAsync().then(token => {
        if (token?.data) {
          setExpoPushToken(token.data)
        }
      })

      messageNotificationResponseListener.current = Notifications.addNotificationResponseReceivedListener(
        async (event: NotificationResponse) => {
          const messageNotification: MessageNotification = event.notification.request.content
            .data as unknown as MessageNotification

          if (messageNotification) {
            let currentMessageNotifications: MessageNotifications
            // DO NOT CHANGE THIS.
            // eslint-disable-next-line prefer-const
            currentMessageNotifications = await retrieveMessageNotifications()

            if (currentMessageNotifications && currentMessageNotifications[messageNotification.externalTask]) {
              delete currentMessageNotifications[messageNotification.externalTask]
              await storeMessageNotifications(currentMessageNotifications)
            }
          }
        }
      )

      toggleBackgroundMessageTask()
      toggleBackgroundApplicationTask()

      return () => {
        if (messageNotificationResponseListener.current) {
          Notifications.removeNotificationSubscription(messageNotificationResponseListener.current)
        }

        toggleBackgroundMessageTask()
        toggleBackgroundApplicationTask()
      }
    }
  }, [context.initialized, expoPushToken])

  useEffect(() => {
    if (Platform.OS !== 'web' && context.externalUser && expoPushToken && !loggedIn) {
      postDeviceToken(context.externalUser[UserProperties.EXTERNAL_USER_ID], expoPushToken)
      // If user has granted permission to push notifications
      // and does not yet have push notifications enabled
      if (context.externalUser[UserProperties.SEND_PUSH_NOTIFICATIONS] === false) {
        const formData = new FormData()
        formData.append('sendPushNotifications', `true`)

        patchUser(formData, context.externalUser.id).then(response => {
          if (response.status === ERRORS.UPGRADE_REQUIRED.statusCode) {
            setContext({
              ...context,
              error: ERRORS.UPGRADE_REQUIRED,
            })
          } else if (response.status === ERRORS.TOKEN_EXPIRED.statusCode) {
            signOutUser()
          } else if (response.status === 200) {
            const updatedUser = {
              ...context.externalUser,
            } as User
            updatedUser[UserProperties.SEND_PUSH_NOTIFICATIONS] = true
            setContext({ ...context, externalUser: updatedUser })
          }
        })
      }
      setLoggedIn(true)
    }
  }, [expoPushToken, context.externalUser])

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.title = 'OpenMarkets.London'
    }

    async function validateUser(firebaseUser: firebase.default.User) {
      if (firebaseUser?.email && firebaseUser?.emailVerified) {
        setLoading(true)

        let currentTabledAuthToken = await getFirebaseUserToken(firebaseUser.uid)

        if (currentTabledAuthToken) {
          try {
            // Get new token if expired
            const tabledAuthToken = await getToken(firebaseUser.email, currentTabledAuthToken)
            if (!tabledAuthToken) {
              setLoading(false)
              return
            }

            // Enforces that the tabled token must be stored in firebase
            while (currentTabledAuthToken !== tabledAuthToken) {
              const userData = {
                [UserProperties.TABLED_AUTH_TOKEN]: tabledAuthToken,
              } as User

              let patchFirebaseUserError = ''
              await patchFirebaseUser(firebaseUser.uid, userData).catch(error => {
                patchFirebaseUserError = error
              })
              currentTabledAuthToken = await getFirebaseUserToken(firebaseUser.uid)

              if (currentTabledAuthToken !== tabledAuthToken) {
                postErrorMessageToSlack(
                  new Error(`The token from user ${firebaseUser.email} was not saved into firebase. Retrying...`),
                  patchFirebaseUserError,
                  firebaseUser.email
                )
              }
            }

            await storeTabledAuthToken(currentTabledAuthToken)

            await getUser(firebaseUser.email).then(async retrievedUser => {
              if (retrievedUser) {
                const documents = await loadUserDocuments(retrievedUser)

                const { messages, unreadMessages } = await loadUserMessages(
                  retrievedUser[UserProperties.EXTERNAL_USER_ID]
                )

                retrievedUser[UserProperties.DOCUMENTS] = documents
                retrievedUser[UserProperties.MESSAGES] = messages
                retrievedUser[UserProperties.UNREAD_MESSAGES] = unreadMessages

                setContext({
                  ...context,
                  firebaseUser,
                  externalUser: retrievedUser,
                })

                setUserId(retrievedUser, firebaseUser)

                // User has no notifications anymore!
                storeMessageNotifications({})
                storeExternalUserId(retrievedUser[UserProperties.EXTERNAL_USER_ID])
              }
            })
          } catch (err) {
            if (err instanceof Error && err.message === ERRORS.UPGRADE_REQUIRED.message) {
              setContext({
                ...context,
                error: ERRORS.UPGRADE_REQUIRED,
              })
              return
            }
          }
        } else {
          await postUser(firebaseUser.email as string)
            .then(async ({ createdUser, tabledAuthToken: { authToken } }) => {
              if (createdUser && authToken) {
                createdUser[UserProperties.DOCUMENTS] = {}
                createdUser[UserProperties.MESSAGES] = {}

                const userData = {
                  [UserProperties.TABLED_AUTH_TOKEN]: authToken,
                } as User

                await patchFirebaseUser(firebaseUser.uid, userData)
                await storeTabledAuthToken(authToken)

                setContext({
                  ...context,
                  firebaseUser,
                  externalUser: createdUser,
                })

                setUserId(createdUser, firebaseUser)

                storeExternalUserId(createdUser[UserProperties.EXTERNAL_USER_ID])
              }
            })
            .catch((err: Error) => {
              if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
                setContext({
                  ...context,
                  error: ERRORS.UPGRADE_REQUIRED,
                })
                return
              }
              setError(err?.message)
            })
        }
      }

      setValidatingUser(false)
      setLoading(false)
    }

    return subscribeToAuthStateChange(async sessionUser => {
      if (sessionUser && !context.firebaseUser && !context.externalUser && !validatingUser) {
        setValidatingUser(true)
        await validateUser(sessionUser)
      } else if (!sessionUser && context.firebaseUser) {
        setError(undefined)
        setContext({ ...context, firebaseUser: null, externalUser: null })
      }

      setLoading(false)
    })
  }, [context.firebaseUser, expoPushToken])

  return (
    <PaperProvider
      theme={theme}
      settings={{
        icon: props => <AwesomeIcon {...props} />,
      }}
    >
      <OMLContextProvider context={context} setContext={setContext}>
        <WebSocket setShouldReloadApplications={setShouldReloadApplications} />
        <ErrorBoundary onError={errorHandler} FallbackComponent={CustomErrorFallback}>
          <NavigationContainer theme={theme} linking={linking}>
            <StatusBar animated={true} barStyle={'dark-content'} showHideTransition={'fade'} />
            <Router errorMessage={error} loading={loading} setRouteName={setRouteName} />
          </NavigationContainer>
        </ErrorBoundary>
      </OMLContextProvider>
    </PaperProvider>
  )
}

App.defaultProps = {
  firebaseUser: null,
  externalUser: null,
  socket: null,
  loading: true,
}

export default App
