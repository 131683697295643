import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Icon } from 'react-native-elements'
import { Button, Card } from 'react-native-paper'
import { ERRORS } from '../../constants'
import ApplicationSummaryStatus from '../atoms/ApplicationSumaryStatus'
import getApplication from '../helpers/api/getApplication'
import getAssistants from '../helpers/api/getAssistants'
import putApplicationAssistants from '../helpers/api/putApplicationAssistants'
import getApplicationDeeply from '../helpers/firebase/firestore/getApplicationDeeply'
import hasUserProvidedRequiredInformation from '../helpers/hasUserProvidedRequiredInformation'
import useFirebase from '../hooks/useFirebase'
import useOMLContext from '../hooks/useOMLContext'
import ApplicationSummaryCollapsable from '../molecules/ApplicationSummaryCollapsable'
import ErrorCard from '../molecules/ErrorCard'
import FormFieldSummary from '../molecules/FormFieldSummary'
import MessageIcon from '../molecules/MessageIcon'
import ObjectMultipleSelectList from '../molecules/ObjectMultipleSelect'
import { colours } from '../styleguide'
import BannerTemplate from '../templates/BannerTemplate'
import {
  Application,
  ApplicationAssistant,
  ApplicationProperties,
  ApplicationStatuses,
} from '../types/application/application.types'
import { AnyField } from '../types/application/formField.types'
import { Council } from '../types/miscellaneous.types'
import { Pages, ScreenProps } from '../types/navigation.types'
import { AssistantSummary, UserProperties } from '../types/user.types'

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    maxWidth: '100%',
    width: 500,
  },
  title: {
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 'bold',
  },
  subtitle: {
    textAlign: 'left',
    fontSize: 15,
    color: colours.description,
    maxWidth: Platform.OS === 'web' ? 450 : '95%',
    flexWrap: 'wrap',
  },
  titleAndStatus: {
    flexDirection: 'row',
    marginTop: '2%',
    marginBottom: '10%',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 2,
  },
  titleContainerColumn: {
    width: '100%',
  },
  applicationSatus: {
    flex: 1,
    alignItems: 'flex-end',
  },
  btnContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3%',
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: '2%',
    paddingHorizontal: '2%',
  },
  cardTitle: {
    textAlign: 'left',
    fontWeight: '400',
    flex: 1,
    fontSize: 18,
  },
  cardContainer: {
    justifyContent: 'flex-start',
    marginBottom: '8%',
    padding: '4%',
  },
  icon: {
    marginLeft: 10,
  },
  paymentBtnContainer: {
    marginBottom: 30,
  },
  paymentButton: {
    backgroundColor: colours.status.paymentRequested,
  },
  assignedAssistants: {
    marginTop: 10,
  },
  button: {
    width: '100%',
    backgroundColor: colours.primary,
  },
})

interface OptionObject {
  label: string
  value: string
}

const ApplicationSummaryScreen = (props: ScreenProps<Pages.APPLICATION_SUMMARY>): ReactElement => {
  const { navigation, route } = props

  const [loading, setLoading] = useState<boolean>(true)
  const [context, setContext] = useOMLContext()
  const requiredFieldsCompleted = hasUserProvidedRequiredInformation(context.externalUser)
  const [application, setApplication] = useState<Application>()
  const [loadingApplication, setLoadingApplication] = useState<boolean>(false)
  const [council, setCouncil] = useState<Council>()
  const [error, setError] = useState<string>('')
  const [showError, setShowError] = useState<boolean>(false)
  const [assistants, setAssistants] = useState<AssistantSummary[]>([])
  const [workspaceId, setWorkspaceId] = useState<number>()
  const [licenceFee, setLicenceFee] = useState<number>()
  const [paymentAuthToken, setPaymentAuthToken] = useState<string>()

  const isFocused = useIsFocused()

  const startingFields = useMemo(() => {
    if (application) {
      const stages = [application.stages[0], application.stages[1], application.stages[application.stages.length - 1]]
      return stages
        .map(stage => {
          stage.fields = stage.fields.sort((a: AnyField, b: AnyField) => (a.index! > b.index! ? 1 : -1))
          return stage
        })
        .flatMap(stage => stage.fields)
    } else return []
  }, [application])

  const { getCouncils } = useFirebase()

  useFocusEffect(
    useCallback(() => {
      if (!context.externalUser) {
        return
      }
      getAssistants(context.externalUser.id)
        .then(tabledAssistants => {
          if (tabledAssistants) {
            const completedAssistants = tabledAssistants.filter(assistant => assistant.isCompleted)
            setAssistants(completedAssistants)
          }
        })
        .catch(err => {
          setLoading(false)
          if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
            setContext({
              ...context,
              error: ERRORS.UPGRADE_REQUIRED,
            })
          } else {
            setError('Failed to load assistants. Please, try again later.')
            setShowError(true)
          }
        })
    }, [route.params.applicationId])
  )

  useEffect(() => {
    setLoadingApplication(false)
  }, [route.params.applicationId])

  useEffect(() => {
    setLoadingApplication(false)
    setLoading(false)
  }, [application])

  useEffect(() => {
    if (!council && application) {
      getCouncils.then((councils: Council[]) => {
        setCouncil(councils.find(c => c.councilName === application[ApplicationProperties.COUNCIL]))
      })
    }
  }, [application, council, getCouncils])

  // True if a request is currently not being processed and the ApplicationSummaryScreen is currently rendered
  const canReloadApplication = !loadingApplication && isFocused
  // Determines if the ID is a Tabled ID or a Firebase ID
  const applicationIdIsANumber = !isNaN(Number(route.params.applicationId))
  // If the application is undefined, or the application ID does not match the ID in the route params
  const isApplicationOutOfSync =
    !application ||
    (application &&
      (applicationIdIsANumber
        ? Number(application.externalId) !== Number(route.params.applicationId)
        : application.id !== route.params.applicationId))

  useEffect(() => {
    if (isApplicationOutOfSync && canReloadApplication) {
      // Fetch application from Tabled
      if (applicationIdIsANumber) {
        setLoadingApplication(true)
        setLoading(true)
        getApplication(context.firebaseUser?.uid || '', route.params.applicationId)
          .then(application => {
            if (application) {
              setApplication(application)
              if (application[ApplicationProperties.WORKSPACE_ID] && application[ApplicationProperties.LICENCE_FEE]) {
                setWorkspaceId(application[ApplicationProperties.WORKSPACE_ID])
                setLicenceFee(application[ApplicationProperties.LICENCE_FEE])
                setPaymentAuthToken(application[ApplicationProperties.PAYMENT_AUTH_TOKEN])
              }
              getCouncils.then((councils: Council[]) => {
                setCouncil(councils.find(c => c.councilName === application[ApplicationProperties.COUNCIL]))
              })
            }
          })
          .catch(err => {
            if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
              setContext({
                ...context,
                error: ERRORS.UPGRADE_REQUIRED,
              })
            }
            setLoading(false)
            setApplication(undefined)
            navigation.navigate(Pages.APPLICATIONS)
          })
      }
      // Fetch application from Firebase (Because the application is a draft)
      else if (!applicationIdIsANumber) {
        setLoadingApplication(true)
        setLoading(true)
        getApplicationDeeply(route.params.applicationId)
          .then(firebaseApplication => {
            setApplication(firebaseApplication)
          })
          .catch(err => {
            if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
              setContext({
                ...context,
                error: ERRORS.UPGRADE_REQUIRED,
              })
            }
            setLoading(false)
            setApplication(undefined)
            navigation.navigate(Pages.APPLICATIONS)
          })
      }
    }
  }, [
    application,
    canReloadApplication,
    context,
    council,
    getCouncils,
    applicationIdIsANumber,
    isApplicationOutOfSync,
    isFocused,
    loadingApplication,
    navigation,
    route.params.applicationId,
    setContext,
  ])

  useEffect(() => {
    if (!context.firebaseUser?.uid || !context.externalUser) {
      return
    }

    if (context.fetchApplications && application) {
      getApplication(context.firebaseUser.uid, application.externalId)
        .then(updatedApplication => {
          if (updatedApplication) {
            setApplication(updatedApplication)
          }
        })
        .catch(err => {
          if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
            setContext({
              ...context,
              error: ERRORS.UPGRADE_REQUIRED,
            })
          }
          return
        })
    }
  }, [context.fetchApplications])

  const unreadCount = useMemo(() => {
    if (context.externalUser && application) {
      return (
        context.externalUser?.[UserProperties.UNREAD_MESSAGES]?.[application?.[ApplicationProperties.EXTERNAL_ID]] ?? 0
      )
    }
  }, [context.externalUser?.[UserProperties.UNREAD_MESSAGES]])

  const updateAnswers = () => {
    if (application) {
      application.stages[1].fields.sort((a, b) => (a.index! > b.index! ? 1 : b.index! > a.index! ? -1 : 0))

      navigation.navigate(Pages.FILL_OUT_APPLICATION, {
        application,
        onStage: 0,
      })
    }
  }

  const continueApplicationButton = (application: Application) => {
    if (application.status === ApplicationStatuses.DRAFT) {
      return (
        <Button mode="elevated" textColor={colours.black} style={styles.button} onPress={updateAnswers}>
          Continue application
        </Button>
      )
    } else if (application.status === ApplicationStatuses.CHANGES_REQUIRED) {
      return (
        <Button mode="elevated" textColor={colours.black} style={styles.button} onPress={updateAnswers}>
          Edit application
        </Button>
      )
    }
  }

  const getDetailsIcon = () => {
    return requiredFieldsCompleted ? (
      <Icon name="checksquareo" type="antdesign" color={colours.status.approved} size={20} style={styles.icon} />
    ) : (
      <Icon name="closesquareo" type="antdesign" color={colours.status.rejected} size={20} style={styles.icon} />
    )
  }

  const handleApplicationAssistant = (assistantIds: string[]) => {
    const selectedAssistants: ApplicationAssistant[] = assistants
      .filter(assistant => assistantIds.includes(`${assistant.id}`))
      .map(assistant => {
        return {
          id: assistant.id,
          fullName: assistant.fullName,
        }
      })

    if (application) {
      const applicationCopy = { ...application }
      applicationCopy.assistants = selectedAssistants
      setApplication(applicationCopy)
    }

    if (!context.firebaseUser || !context.firebaseUser.uid || !application?.externalId || !council) {
      return
    }

    putApplicationAssistants(Number(application.externalId), assistantIds).catch(err => {
      if (err.message === ERRORS.UPGRADE_REQUIRED.message) {
        setContext({
          ...context,
          error: ERRORS.UPGRADE_REQUIRED,
        })
      }
      if (context.firebaseUser) {
        getApplication(context.firebaseUser.uid, application.externalId).then(updatedApplication => {
          if (updatedApplication) {
            setApplication(updatedApplication)
          }
        })
      }
    })
    return
  }

  const dismissErrorMessage = () => {
    setShowError(false)
  }

  return (
    <BannerTemplate
      testID="applicationSummary"
      loading={loading}
      showBanner={showError}
      bannerText={error}
      dismissError={dismissErrorMessage}
    >
      {!loading && application && !loadingApplication && (
        <View style={styles.container}>
          <View style={styles.titleAndStatus}>
            <View style={styles.titleContainer}>
              <View style={styles.titleContainerColumn}>
                <Text style={styles.title}>Application to {application.council}</Text>
                <Text style={styles.subtitle}>{application.markets.join(', ')}</Text>
              </View>
            </View>
            <View style={styles.applicationSatus}>
              <ApplicationSummaryStatus status={application.status} />
            </View>
          </View>
          {application[ApplicationProperties.STATUS] == ApplicationStatuses.PAYMENT_REQUESTED && (
            <View>
              {workspaceId && licenceFee && paymentAuthToken ? (
                <View style={styles.paymentBtnContainer}>
                  <Button
                    mode="elevated"
                    textColor={colours.white}
                    style={styles.paymentButton}
                    onPress={() =>
                      navigation.navigate(Pages.PAYMENT, {
                        applicationId: Number(application.externalId),
                        workspaceId,
                        licenceFee,
                        paymentAuthToken,
                      })
                    }
                  >
                    Pay for licence
                  </Button>
                </View>
              ) : (
                <ErrorCard
                  title="Sorry"
                  bodyText="We cannot accept payments at this time. Please check back later, or alternaltively, message us if you have any concerns."
                />
              )}
            </View>
          )}
          {application[ApplicationProperties.STATUS] != ApplicationStatuses.DRAFT && (
            <Card style={styles.cardContainer}>
              <TouchableOpacity
                onPress={() => {
                  if (council) {
                    navigation.navigate(Pages.MESSAGE, {
                      title: application[ApplicationProperties.COUNCIL],
                      council,
                      externalTaskId: Number(application[ApplicationProperties.EXTERNAL_ID]),
                    })
                  }
                }}
                style={styles.content}
              >
                <Text style={styles.cardTitle}>Your Messages</Text>
                <MessageIcon value={unreadCount ?? 0} />
              </TouchableOpacity>
            </Card>
          )}
          <ApplicationSummaryCollapsable titleComponent={'Your Application'}>
            {startingFields.map((field: AnyField) => (
              <FormFieldSummary key={field.identifier} field={field} />
            ))}
          </ApplicationSummaryCollapsable>
          <Card style={styles.cardContainer}>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(Pages.PROFILE_AND_ACCOUNT)
              }}
              style={styles.content}
            >
              <Text style={styles.cardTitle}>Your Details and Documents</Text>
              {getDetailsIcon()}
            </TouchableOpacity>
          </Card>
          {![ApplicationStatuses.APPROVED, ApplicationStatuses.DRAFT].includes(
            application[ApplicationProperties.STATUS]
          ) ? (
            <ApplicationSummaryCollapsable
              titleComponent={'Assistants'}
              hasAssistants={!!application.assistants?.length}
            >
              <ObjectMultipleSelectList
                title={''}
                options={assistants.map(a => {
                  const option: OptionObject = { value: `${a.id}`, label: a.fullName }
                  return option
                })}
                value={application.assistants ? application.assistants.map(a => `${a.id}`) : []}
                onPress={handleApplicationAssistant}
              />
            </ApplicationSummaryCollapsable>
          ) : (
            application[ApplicationProperties.STATUS] !== ApplicationStatuses.DRAFT && (
              <ApplicationSummaryCollapsable
                titleComponent={'Assistants'}
                hasAssistants={!!application.assistants?.length}
              >
                {application.assistants && application.assistants.length ? (
                  <View>
                    {application.assistants.map(assistant => (
                      <Text style={styles.assignedAssistants} key={`${assistant.id}`}>
                        {`\u2022\u00A0`}
                        {assistant.fullName}
                      </Text>
                    ))}
                  </View>
                ) : (
                  <View>
                    <Text style={styles.assignedAssistants}>No assistants have been assigned to this application</Text>
                  </View>
                )}
              </ApplicationSummaryCollapsable>
            )
          )}
          <View style={styles.btnContainer}>
            {(application.status === ApplicationStatuses.CHANGES_REQUIRED ||
              application.status === ApplicationStatuses.DRAFT) &&
              continueApplicationButton(application)}
          </View>
        </View>
      )}
    </BannerTemplate>
  )
}

export default ApplicationSummaryScreen
