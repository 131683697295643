import { AnyField } from '../../../../types/application/formField.types'
import { XORValidator } from '../../../../types/application/validator.types'
import { InMemoryFormFields } from '../../../../types/form.types'
import doesFormValueExist from '../doesFormValueExist'

const validateXorField = (field: AnyField, validator: XORValidator, fields: InMemoryFormFields): string => {
  const partners: string[] = validator.arguments.partners
  if (!partners) {
    return ''
  }
  const numberOfFilledPartners = partners
    .map(partnerIdentifier => {
      const partner = fields[partnerIdentifier] || {}

      return doesFormValueExist(partner.value, partner.type)
    })
    .filter(item => !!item).length

  const isThereOneFilledPartner = numberOfFilledPartners === 1

  const isThisFieldFilled = doesFormValueExist(field.value, field.type)

  const isValid = (isThereOneFilledPartner || isThisFieldFilled) && !(isThereOneFilledPartner && isThisFieldFilled)

  return isValid ? '' : validator.failureMessage
}

export default validateXorField
